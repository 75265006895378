/**
 * @generated SignedSource<<82ad3a1434f6d89b399f1eb67cd3be36>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PageContent_location$data = {
  readonly name?: string;
  readonly deviceCount?: number | null;
  readonly " $fragmentType": "PageContent_location";
};
export type PageContent_location$key = {
  readonly " $data"?: PageContent_location$data;
  readonly " $fragmentSpreads": FragmentRefs<"PageContent_location">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "deviceCount",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PageContent_location",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "Installation",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "Section",
      "abstractKey": null
    }
  ],
  "type": "Location",
  "abstractKey": "__isLocation"
};
})();

(node as any).hash = "49b8afc6d66199ea0761e32faf3f6968";

export default node;
