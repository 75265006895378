import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useTheme } from '@emotion/react';
import { barContainer, content, fill, labelMax, labels, labelThreshold, main, countDownBarContainer } from './styles';
import { CountDownBarProps } from './types';

const CountDownBar = ({ value, maxValue = 100, fillColor, threshold, children }: CountDownBarProps) => {
  const valuePercentage = value > 0 ? value / maxValue : 0;
  const thresholdPercentage = threshold && threshold.value ? threshold.value / maxValue : 0;
  const theme = useTheme();

  const barFillColor = fillColor || theme.colors.secondary;
  const thresholdColor = threshold?.color || theme.colors.chart.default.warningThreshold;
  const positiveValue = value > 0 ? value : 0;

  const countDown = useSpring({
    config: { duration: 350 },
    delay: 350,
    from: { width: '100%', value: Number(maxValue) },
    to: { width: `${valuePercentage * 100}%`, value: positiveValue },
  });

  return (
    <div css={countDownBarContainer}>
      <animated.div css={content}>{countDown.value.to((x) => Math.floor(x))}</animated.div>
      {children && children}
      <div css={barContainer}>
        <div css={main}>
          <animated.div
            css={fill}
            style={{
              ...countDown,
              backgroundColor: countDown.value.to((x) =>
                threshold === undefined || threshold === null || x > threshold.value
                  ? barFillColor.toString()
                  : thresholdColor,
              ),
            }}
          />
        </div>
        <div css={labels}>
          {threshold?.value && threshold.value < maxValue ? (
            <div css={labelThreshold(thresholdPercentage)}>{threshold.value}</div>
          ) : null}
          <div css={labelMax}>{maxValue}</div>
        </div>
      </div>
    </div>
  );
};

export default CountDownBar;
