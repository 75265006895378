import { css, SerializedStyles, Theme } from '@emotion/react';
import dsmMediaQuery from '../../utils/media-queries/media-queries';

const offcanvasOverlayStyles = css`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: rgba(18, 21, 23, 0.8);
  z-index: 5100;
`;

const offcanvasStyles = css`
  height: 100%;
  overflow-y: auto;
`;

const offcanvasWrapperStyles = (isOpen: boolean): SerializedStyles => css`
  left: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  transform: ${isOpen ? 'translateX(0)' : 'translateX(calc(-100%))'};
  transition: transform 0.3s ease-in-out;
  max-width: 23.5em;
  width: 100%;
  z-index: 100;

  ${dsmMediaQuery('large device')} {
    height: 100vh;
    transform: ${isOpen ? 'translateX(0)' : 'translateX(calc(-100%))'};
  }
`;

export { offcanvasOverlayStyles, offcanvasStyles, offcanvasWrapperStyles };
