import React from 'react';
import tabClicked from '../../../adobe-launch/events/tab-clicked';
import { navItemStyles } from '../styles';

const DeviceNavLink: React.FC<{
  isActive: boolean;
  onClick: () => void;
  analyticsEvent: string;
  children: JSX.Element | string;
}> = ({ children, isActive, onClick, analyticsEvent }) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <a
    href="#"
    className={isActive ? 'active' : undefined}
    css={navItemStyles}
    onClick={(e): void => {
      e.preventDefault();
      onClick();
      tabClicked(analyticsEvent);
    }}
  >
    {children}
  </a>
);

export default DeviceNavLink;
