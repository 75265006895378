import React from 'react';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Tile, TileBody, TileHeader } from '../../../../../../components/Tiles';
import { tileBodyStyles } from '../styles';

const VolumetricContainer = ({ children }: { children: JSX.Element[] | JSX.Element }) => {
  const { t } = useTranslation();
  return (
    <Tile
      key="volumetric"
      styles={css`
        grid-area: volumetric;
      `}
      Header={<TileHeader text={t('WP.VOLUMETRIC.CONCENTRATION.TILE_TITLE')} />}
      Body={<TileBody css={tileBodyStyles}>{children}</TileBody>}
    />
  );
};

export default VolumetricContainer;
