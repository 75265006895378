import { css } from '@emotion/react';

const timeToServiceTileStyles = css`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 3.125rem;
`;

const imgPadding = css`
  padding: 1rem;
`;

export { timeToServiceTileStyles, imgPadding };
