/**
 * @generated SignedSource<<6e8410704d01ebb8215e1be1bc332726>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ControlMode = "MANUAL" | "PULSE" | "ANALOG" | "BATCH";
export type PumpIsRunning = "YES" | "NO";
export type PumpStatusMode = "ACTIVE" | "STOPPED" | "STANDBY" | "SERVICE" | "CALIBRATING" | "DEAERATING";
export type RemoteControlStatus = "OFF" | "ON";
export type StoppedBy = "DISPLAY" | "EXTERNAL" | "DISPLAY_AND_EXTERNAL" | "REMOTE" | "DISPLAY_AND_BUS" | "EXTERNAL_AND_BUS" | "DISPLAY_EXTERNAL_AND_BUS";
import { FragmentRefs } from "relay-runtime";
export type PumpStatusDataDDA_device$data = {
  readonly latestSync: {
    readonly outOfSync: boolean | null;
  } | null;
  readonly descendantDeviceInfo: {
    readonly pumpSettings?: {
      readonly controlMode: ControlMode | null;
      readonly displayColor: {
        readonly color: string;
      } | null;
      readonly pumpStatus: PumpStatusMode | null;
      readonly pumpIsRunning: PumpIsRunning | null;
      readonly stoppedBy: StoppedBy | null;
      readonly remoteControlStatus: RemoteControlStatus | null;
    } | null;
  } | null;
  readonly " $fragmentType": "PumpStatusDataDDA_device";
};
export type PumpStatusDataDDA_device$key = {
  readonly " $data"?: PumpStatusDataDDA_device$data;
  readonly " $fragmentSpreads": FragmentRefs<"PumpStatusDataDDA_device">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PumpStatusDataDDA_device",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LatestSync",
      "kind": "LinkedField",
      "name": "latestSync",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "outOfSync",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "descendantDeviceInfo",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PumpSettingsDDA",
              "kind": "LinkedField",
              "name": "pumpSettings",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "controlMode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DisplayColorData",
                  "kind": "LinkedField",
                  "name": "displayColor",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "color",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "pumpStatus",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "pumpIsRunning",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "stoppedBy",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "remoteControlStatus",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "DDA",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};

(node as any).hash = "5192de54abdb138c61a998689976f626";

export default node;
