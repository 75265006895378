import { graphql, useFragment } from 'react-relay/hooks';
import React from 'react';
import PumpStatus from './PumpStatus';
// eslint-disable-next-line camelcase
import { PumpStatusDataDDA_device$key } from './__generated__/PumpStatusDataDDA_device.graphql';
import { OverviewChemPairingDDAQuery$data } from '../__generated__/OverviewChemPairingDDAQuery.graphql';
import { pumpStatusContainerStyles } from '../styles';
import NoPumpStatusData from '../../Overview/OverviewChemPairing/PumpStatus/NoPumpStatusData';

const PumpStatusData: React.FC<{ device: OverviewChemPairingDDAQuery$data }> = ({ device }) => {
  // eslint-disable-next-line camelcase
  const data = useFragment<PumpStatusDataDDA_device$key>(
    graphql`
      fragment PumpStatusDataDDA_device on Device {
        latestSync {
          outOfSync
        }
        descendantDeviceInfo {
          ... on DDA {
            pumpSettings {
              controlMode
              displayColor {
                color
              }
              pumpStatus
              pumpIsRunning
              stoppedBy
              remoteControlStatus
            }
          }
        }
      }
    `,
    device.node,
  );

  const { controlMode, displayColor, pumpStatus, pumpIsRunning, stoppedBy, remoteControlStatus } =
    data?.descendantDeviceInfo?.pumpSettings || {};

  const isOutOfSync = data?.latestSync?.outOfSync;
  if (isOutOfSync) return <NoPumpStatusData />;

  return (
    <PumpStatus
      backlight={displayColor?.color}
      geniBusControl={remoteControlStatus || undefined}
      pumpStatus={pumpStatus || undefined}
      stoppedBy={stoppedBy || undefined}
      pumpState={pumpIsRunning || undefined}
      controlMode={controlMode || undefined}
      containerStyle={pumpStatusContainerStyles}
    />
  );
};

export default PumpStatusData;
