import * as Sentry from '@sentry/react';
import CorrelatedError from '../errors/CorrelatedError';

const sentryCaptureError = (error: Error, value: string): void => {
  Sentry.withScope((scope) => {
    scope.setTag('error boundary', value);

    if (error instanceof CorrelatedError) scope.setTag('correlationId', error.correlationId);

    Sentry.captureException(error);
  });
};

export default sentryCaptureError;
