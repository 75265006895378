interface PayloadError {
  message: string;
  locations?:
    | Array<{
        line: number;
        column: number;
      }>
    | undefined;
  extensions: {
    code: string;
  };
}

class CorrelatedError extends Error {
  private _correlationId: string;

  private _errors: PayloadError[];

  constructor(message: string, correlationId: string, errors: PayloadError[]) {
    super(message);

    // https://stackoverflow.com/a/41102306/3347855
    Object.setPrototypeOf(this, CorrelatedError.prototype);

    // eslint-disable-next-line no-underscore-dangle
    this._correlationId = correlationId;
    // eslint-disable-next-line no-underscore-dangle
    this._errors = errors;
  }

  get correlationId(): string {
    // eslint-disable-next-line no-underscore-dangle
    return this._correlationId;
  }

  get errors(): PayloadError[] {
    // eslint-disable-next-line no-underscore-dangle
    return this._errors;
  }
}

export default CorrelatedError;
