import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  FormValues,
  FormValuesKeys,
  RemoteControlSettings,
  UseSchemaValidationProps,
  mapSlowModeToValue,
} from '../types';

const scehmaSettings = (
  pumpCapacity: RemoteControlSettings['pumpCapacity'],
  t: (val: string) => string,
  manualMax: number,
  pulseMinMax: RemoteControlSettings['pulseMinMax'],
) => {
  // PULSE
  const pulseValidationMessage = `${t('WP.REMOTE.VALIDATION.PULSE_RANGE_PREFIX')}
  ${pulseMinMax.min?.toFixed(4)} - ${pulseMinMax.max?.toFixed(4)} ${t('WP.REMOTE.VALIDATION.PULSE_RANGE_POSTFIX')}`;

  // Manual settings
  const manualMessage = `${t('WP.REMOTE.VALIDATION.CAPACITY_RANGE_PREFIX')}:
  ${pumpCapacity.min?.toFixed(4)} - ${manualMax.toFixed(4)} ${pumpCapacity.unit}`;

  // Max capacity
  const maxCapacityValidationMessage = `${t('WP.REMOTE.VALIDATION.CAPACITY_RANGE_PREFIX')}:
  ${pumpCapacity.min?.toFixed(4)} - ${pumpCapacity.max?.toFixed(4)} ${pumpCapacity.unit}`;

  return {
    pulseMinMax,
    manualMessage,
    pulseValidationMessage,
    maxCapacityValidationMessage,
  };
};

const useSchemaValidation = ({ pumpCapacity, pulseMinMax }: UseSchemaValidationProps) => {
  const { t } = useTranslation();

  const remoteSchema = React.useMemo(() => {
    const yupFunc = (values: FormValues) => {
      const { slowMode, maxCapacity } = values;
      // The maxmium flow we allow on dda-c pump is determined by either slow mode or max capacity
      // The one that is lowest "wins" and becomes the maximum allowed flow value
      // we have to map our slow mode value to a percentage value respresnted as decimal
      // slow mode is calcualted by the selected slow mode value and then the max pump capacity the pump is born with (not the same as the seleced max capacity)
      const manualMax = Math.min(Number(maxCapacity), Number(pumpCapacity.max) * mapSlowModeToValue(slowMode));
      const { maxCapacityValidationMessage, pulseValidationMessage, manualMessage } = scehmaSettings(
        pumpCapacity,
        t,
        manualMax,
        pulseMinMax,
      );

      const schema: FormValuesKeys = {
        flowControl: Yup.boolean(),
        controlMode: Yup.string().required(t('WP.COMMON.VALIDATION.REQUIRED')),
        mlPerPulse: Yup.number().when('controlMode', {
          is: (val: string) => val === 'PULSE',
          then: Yup.number()
            .typeError(t('WP.COMMON.VALIDATION.NUMBERS_ONLY'))
            .required(t('WP.COMMON.VALIDATION.REQUIRED'))
            .min(Number(pulseMinMax.min), pulseValidationMessage)
            .max(Number(pulseMinMax.max), pulseValidationMessage),
          // no validation if PULSE mode is not selected
          otherwise: undefined,
        }),
        manualDosingFlowValue: Yup.number().when('controlMode', {
          is: (val: string) => val === 'MANUAL',
          then: Yup.number()
            .required(t('WP.COMMON.VALIDATION.REQUIRED'))
            .typeError(t('WP.COMMON.VALIDATION.NUMBERS_ONLY'))
            .min(Number(pumpCapacity?.min), manualMessage)
            .max(manualMax, manualMessage),
          // no validation if MANUAL mode is not selected
          otherwise: undefined,
        }),
        analogDosingFlowHigher: Yup.number().when('controlMode', {
          is: (val: string) => val === 'ANALOG_0_20' || val === 'ANALOG_4_20',
          then: Yup.number()
            .required(t('WP.COMMON.VALIDATION.REQUIRED'))
            .typeError(t('WP.COMMON.VALIDATION.NUMBERS_ONLY'))
            .min(Number(pumpCapacity?.min), manualMessage)
            .max(manualMax, manualMessage),
          otherwise: undefined,
        }),
        maxCapacity: Yup.number()
          .required(t('WP.COMMON.VALIDATION.REQUIRED'))
          .typeError(t('WP.COMMON.VALIDATION.NUMBERS_ONLY'))
          .min(Number(pumpCapacity?.min), maxCapacityValidationMessage)
          .max(Number(pumpCapacity?.max), maxCapacityValidationMessage),
      };

      return Yup.object().shape(schema);
    };

    return Yup.lazy(yupFunc);
  }, [pulseMinMax, pumpCapacity, t]);

  return remoteSchema;
};

export default useSchemaValidation;
