import React, { Suspense, useEffect } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { commitLocalUpdate, graphql, useLazyLoadQuery, useMutation, useRelayEnvironment } from 'react-relay';
import { useTranslation } from 'react-i18next';
import { toGlobalId } from 'graphql-relay';
import { Loader, ProgressIndicator, Step } from '../../components';
import getSteps from './get-steps';
import { progressContainerStyles, mainContainerStyles, progressIndicatorStyles, stepLabelStyles } from './styles';
import { OnboardingQuery } from './__generated__/OnboardingQuery.graphql';
import { OnboardingMutation } from './__generated__/OnboardingMutation.graphql';
import countries, { getCountryCode } from './countries';

const onboardingRecordID = 'client:clientState:onboarding';
const Onboarding: React.FC<{ claimId: string }> = ({ claimId }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const environment = useRelayEnvironment();
  const { onboardingAccounts: accounts } = useLazyLoadQuery<OnboardingQuery>(
    graphql`
      query OnboardingQuery {
        onboardingAccounts {
          edges {
            node {
              uuid
              address
              postalCode
              city
              name
              country
              street
            }
          }
        }
      }
    `,
    { id: toGlobalId('UnclaimedDevice', claimId) },
  );

  const [commit, isInFlight] = useMutation<OnboardingMutation>(graphql`
    mutation OnboardingMutation($id: ID!, $input: OnboardingCreateUserAndAccountInput!) {
      onboardingCreateUserAndAccount(id: $id, input: $input) {
        id
        onboardingId
        account {
          uuid
          company
          address
          street
          postalCode
          grundfosCustomerID
          country
          city
        }
      }
    }
  `);

  const userHasMultipleAdminAccounts = Boolean(accounts?.edges?.length && accounts?.edges?.length > 1);
  const userHasOneAdminAccount = Boolean(accounts?.edges?.length && accounts?.edges?.length === 1);
  const userHasNoAdminAccounts = !userHasOneAdminAccount && !userHasMultipleAdminAccounts;
  useEffect(() => {
    if (userHasNoAdminAccounts) {
      navigate('/onboarding/no-account', { replace: true, state: { claimId } });
    } else if (userHasMultipleAdminAccounts) {
      navigate('/onboarding/create-account', { replace: true, state: { claimId } });
      // Needed the accounts?.edges check to make TS play along
    } else if (userHasOneAdminAccount && accounts?.edges) {
      // We skip the account step, so we update the account here
      commitLocalUpdate(environment, (store) => {
        store
          .getRoot()
          .getLinkedRecord('clientState')
          ?.setLinkedRecord(
            store.get(onboardingRecordID) || store.create(onboardingRecordID, 'Onboarding'),
            'onboarding',
          );
      });
      const selectedAccount = accounts?.edges[0];
      commit({
        variables: {
          id: onboardingRecordID,
          input: {
            uuid: selectedAccount?.node?.uuid,
            address: selectedAccount?.node?.address,
            street: selectedAccount?.node?.street,
            postalCode: selectedAccount?.node?.postalCode,
            city: selectedAccount?.node?.city,
            company: selectedAccount?.node?.name || '',
            // if there is no country set on the account we set a default one
            country: getCountryCode(countries, selectedAccount?.node?.country) || '',
            grundfosCustomerID: '',
          },
        },
        onCompleted: () => navigate('/onboarding/create-or-select-customer', { replace: true, state: { claimId } }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If pathname <= 12 then we haven't redirected yet
  if (pathname.length <= 12 || isInFlight) return <Loader />;

  const hideSteps = userHasNoAdminAccounts;

  const steps = hideSteps ? [] : getSteps(userHasMultipleAdminAccounts);

  return (
    <>
      {steps.length > 0 && (
        <div css={progressContainerStyles}>
          <ProgressIndicator css={progressIndicatorStyles}>
            {steps.map((step, index) => (
              <Step index={index} activeStep={steps.findIndex(({ path }) => pathname.includes(path))} key={step.key}>
                <p css={stepLabelStyles}>{t(step.key)}</p>
              </Step>
            ))}
          </ProgressIndicator>
        </div>
      )}
      <Suspense fallback={<Loader />}>
        <div css={mainContainerStyles}>
          <Outlet />
        </div>
      </Suspense>
    </>
  );
};

export default Onboarding;
