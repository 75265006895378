import { css } from '@emotion/react';
import React from 'react';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { heading05Styles, headingLargeStyles, pageStyles } from '../../styles';
import { Button, Divider } from '../../components';
import { Tile, TileBody } from '../../components/Tiles';
import {
  profileStyles,
  formStyles,
  buttonStyles,
  headerStyles,
  profileContainer,
  regionalSettingsTile,
} from './styles';
import UnitSystemSelect from './Fields/UnitSystemSelect';
import Loading from './Loading';
import { ProfileFormValues, ProfileProps } from './types';
import LanguageSelect from './Fields/LanguageSelect';

const Profile: React.FC<ProfileProps> = ({
  firstName,
  lastName,
  unitSystemName,
  isLoading,
  onSubmit,
  languageIso6391,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleBack = () => {
    // we only go back if there is history.
    if (window.history.state && window.history.state.idx > 0) navigate(-1);
    else navigate('/', { replace: true });
  };

  const SettingsSchema = Yup.object().shape({
    unitSystem: Yup.string()
      .required(t('WP.COMMON.VALIDATION.REQUIRED'))
      .matches(/^(?!SI Unit\b)/i, t('WP.PROFILE.VALIDATION.NOT_SUPPORTED')),
  });

  return (
    <div css={profileContainer}>
      <div css={headerStyles}>
        <h1 css={headingLargeStyles}>
          <span> {firstName}</span>
          <span> {lastName}</span>
        </h1>
      </div>
      <div css={[pageStyles, profileStyles]}>
        <Loading isLoading={isLoading} />
        <Formik<ProfileFormValues>
          initialValues={{
            unitSystem: unitSystemName,
            iso6391: languageIso6391,
          }}
          validationSchema={SettingsSchema}
          onSubmit={onSubmit}
        >
          {({ isValid }) => (
            <Form css={formStyles}>
              <div
                css={css`
                  grid-area: tiles;
                `}
              >
                <h3 css={heading05Styles}>{t('WP.PROFILE.REGIONAL_SETTINGS')}</h3>
                <Divider className="regional-settings-divider" />

                <Tile
                  key="regionalSettings"
                  styles={regionalSettingsTile}
                  Body={
                    <TileBody>
                      <LanguageSelect />
                      <UnitSystemSelect unitSystemName={unitSystemName || ''} />
                    </TileBody>
                  }
                />
              </div>
              <div css={buttonStyles}>
                <Button type="button" variant="secondary" onClick={handleBack}>
                  {t('WP.COMMON.BACK')}
                </Button>
                <Button type="submit" variant="primary" disabled={!isValid || isLoading}>
                  {t('WP.COMMON.SAVE')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Profile;
