import React, { Suspense } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { Navigate, Outlet } from 'react-router-dom';
import { ErrorBoundary, ErrorFallback, Loader } from '../../components';
import { useAccount } from '../../providers/AccountProvider';
import sentryCaptureError from '../../utils/sentry/sentry-capture-error';
import { AppQuery } from './__generated__/AppQuery.graphql';
import AccountEventsError from './AccountEventsError/AccountEventsError';
import AppNavigation from './AppNavigation/AppNavigation';
import { appNavigationStyles, appStyles, mainStyles } from './styles';
import NoAccount from '../Onboarding/Errors/NoAccount';
import useQueryStringParam from '../../hooks/useQueryParameters/useQueryStringParam';

const App: React.FC = () => {
  const {
    account: { id: accountId },
  } = useAccount();

  const data = useLazyLoadQuery<AppQuery>(
    graphql`
      query AppQuery($accountId: ID!) {
        ...AppNavigation_data @arguments(accountId: $accountId)
        events {
          ...AccountEventsError_events
        }
      }
    `,
    {
      accountId,
    },
  );

  return (
    <div id="app" css={appStyles}>
      <AppNavigation data={data} styles={appNavigationStyles} />
      <main css={mainStyles}>
        <AccountEventsError events={data.events} />
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={(error): void => sentryCaptureError(error, 'App')}>
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </ErrorBoundary>
      </main>
    </div>
  );
};

const HasAccount: React.FC = () => {
  const { account } = useAccount();
  const [claimId] = useQueryStringParam('claimId');

  if (claimId) {
    return <Navigate replace to="/onboarding" state={{ claimId }} />;
  }

  if (!account && !claimId) return <NoAccount />;

  return <App />;
};

export default HasAccount;
