import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

const useQueryStringParam = (key: string): [string | null, (value?: string) => void] => {
  const [searchParams] = useSearchParams();
  const param = searchParams.get(key);

  const setParam = useCallback(
    (value?: string) => {
      // Update the value for current key if value is Truthy
      if (value) {
        searchParams.set(key, value);
        // Otherwise we delete the query param (if it exists)
      } else if (param) searchParams.delete(key);
    },
    [key, param, searchParams],
  );
  return [param, setParam];
};

export default useQueryStringParam;
