/**
 * @generated SignedSource<<01b325a1f5abda8b639ca8d3f49a818d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DDACControlMode = "MANUAL" | "PULSE" | "ANALOG_0_20" | "ANALOG_4_20" | "INTERVAL_BATCH" | "PULSE_BATCH" | "WEEK_TIMER_BATCH" | "EXTERNAL_PULSE_BATCH";
export type Delay = "SHORT" | "MEDIUM" | "LONG";
export type PumpState = "START" | "STOP";
export type Sensitivity = "LOW" | "MEDIUM" | "HIGH";
export type SlowMode = "OFF" | "QUARTER" | "HALF";
export type UnitSystem = "EU" | "US";
export type RemoteControlInput = {
  deviceTwinId?: string | null;
  values?: RemoteControlValues | null;
  unitSystem?: UnitSystem | null;
};
export type RemoteControlValues = {
  slowMode?: SlowMode | null;
  pumpState?: PumpState | null;
  controlMode?: DDACControlMode | null;
  mlPerPulse?: number | null;
  manualDosingFlowValue?: number | null;
  maxCapacity?: number | null;
  dosingFlow?: number | null;
  pulseMemory?: boolean | null;
  autoDeaeration?: boolean | null;
  flowControl?: boolean | null;
  flowControlAutoFlowAdapt?: boolean | null;
  maxPressureValue?: number | null;
  minPressureValue?: number | null;
  minPressureAsAlarm?: boolean | null;
  flowControlDelay?: Delay | null;
  flowControlSensitivity?: Sensitivity | null;
  stopAfterPowerFailure?: boolean | null;
  analogDosingFlowHigher?: number | null;
  analogDosingFlowLower?: number | null;
  analogInputHigher?: number | null;
  analogInputLower?: number | null;
};
export type useSendRemoteControlCommandsMutation$variables = {
  input?: RemoteControlInput | null;
};
export type useSendRemoteControlCommandsMutation$data = {
  readonly remoteControl: {
    readonly result: ReadonlyArray<ReadonlyArray<string | null> | null>;
    readonly id: string;
    readonly fieldsWithErrors: ReadonlyArray<string>;
  } | null;
};
export type useSendRemoteControlCommandsMutation = {
  variables: useSendRemoteControlCommandsMutation$variables;
  response: useSendRemoteControlCommandsMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RemoteControlPayload",
    "kind": "LinkedField",
    "name": "remoteControl",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fieldsWithErrors",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useSendRemoteControlCommandsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSendRemoteControlCommandsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "aa397cabccd75b70bfe9f039f4a646d1",
    "id": null,
    "metadata": {},
    "name": "useSendRemoteControlCommandsMutation",
    "operationKind": "mutation",
    "text": "mutation useSendRemoteControlCommandsMutation(\n  $input: RemoteControlInput\n) {\n  remoteControl(input: $input) {\n    result\n    id\n    fieldsWithErrors\n  }\n}\n"
  }
};
})();

(node as any).hash = "efe7a0aa22bbb7d1d323c7f9879ea6c0";

export default node;
