import React from 'react';
import { IconProps } from './types';

const Alarm: React.FC<IconProps> = ({ color, size, styles, x, y }) => (
  <svg css={styles} x={x} y={y} width={size} height={size} viewBox="0 0 33 32" data-test-id="alarm-icon">
    <g id="Asset-Artboard-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Alarm-Active" transform="translate(0.628986, 0.000000)" fill={color} fillRule="nonzero">
        <g
          id="Group-9"
          transform="translate(15.907247, 16.000000) scale(1, -1) translate(-15.907247, -16.000000) translate(1.988406, 2.000000)"
        >
          <path
            d="M13.9188408,0 C21.6069634,0 27.8376815,6.26738175 27.8376815,14 C27.8376815,21.7326183 21.6069634,28 13.9188408,28 C6.23138075,28 0,21.7322847 0,14 C0,6.26771525 6.23138075,0 13.9188408,0 Z M14.9130437,12 L12.9246379,12 L11.9304349,22 L15.9072466,22 L14.9130437,12 Z M13.9188408,6 C12.8079847,6 11.9304349,6.872 11.9304349,7.984 C11.9304349,9.128 12.8079847,10 13.9188408,10 C15.0588601,10 15.9072466,9.128 15.9072466,7.984 C15.9072466,6.872 15.0588601,6 13.9188408,6 Z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Alarm;
