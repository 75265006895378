import { css, SerializedStyles, Theme } from '@emotion/react';

const accountSwitcherStyles = (): SerializedStyles => css`
  display: flex;
  flex-direction: column;
  h4 {
    font-size: 1rem;
  }
  button {
    all: unset;
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { accountSwitcherStyles };
