import React, { MouseEvent } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import sentryCaptureError from '../../../utils/sentry/sentry-capture-error';
import ErrorBoundary from '../../../components/ErrorBoundary/ErrorBoundary';
import useOffcanvas from '../../../components/Offcanvas/useOffcanvas';
import MainNavigation from './MainNavigation/MainNavigation';
import { mainNavigationStyles } from './styles';
import ErrorFallback from './SubNavigation/ErrorFallback/ErrorFallback';
import SubNavigation from './SubNavigation/SubNavigation';
import { AppNavigationProps } from './types';

const onClose =
  (setShowOffcanvas: React.Dispatch<React.SetStateAction<boolean>>) =>
  (event: MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    setShowOffcanvas(false);
  };

const AppNavigation: React.FC<AppNavigationProps> = ({ styles, ...props }) => {
  const { setShowOffcanvas, showOffcanvas, toggleIsOpen } = useOffcanvas();

  const data = useFragment(
    graphql`
      fragment AppNavigation_data on Query @argumentDefinitions(accountId: { type: "ID!" }) {
        accounts {
          ...MainNavigation_accounts
        }
        node(id: $accountId) {
          ...MainNavigation_account
        }
      }
    `,
    props?.data,
  );

  const { accounts, node: account } = data;

  return (
    <div css={styles}>
      <MainNavigation
        account={account}
        accounts={accounts}
        styles={mainNavigationStyles}
        toggleShowSubNavigation={toggleIsOpen}
      />
      <ErrorBoundary
        onError={(error): void => sentryCaptureError(error, 'AppNavigation')}
        // eslint-disable-next-line react/no-unstable-nested-components
        fallbackRender={(fallbackProps) => (
          <ErrorFallback
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...fallbackProps}
            isOpen={showOffcanvas}
            onClose={onClose(setShowOffcanvas)}
            toggleShowSubNavigation={toggleIsOpen}
          />
        )}
      >
        <SubNavigation
          isOpen={showOffcanvas}
          onClose={onClose(setShowOffcanvas)}
          toggleShowSubNavigation={toggleIsOpen}
        />
      </ErrorBoundary>
    </div>
  );
};

export default AppNavigation;
