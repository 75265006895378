import React from 'react';
import { summaryStyles, summaryValueStyles, summaryErrorStyles } from '../styles';

const TargetSummary = ({
  label,
  value,
  valueType,
  error,
}: {
  label: string;
  value: number;
  valueType: string;
  error: string;
}) => (
  <div css={summaryStyles}>
    <div>{label}</div>
    {value > 0.0015 ? (
      <div css={summaryValueStyles}>{`${value.toFixed(1)} ${valueType}`}</div>
    ) : (
      <div css={summaryErrorStyles}>{error}</div>
    )}
  </div>
);

export default TargetSummary;
