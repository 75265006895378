/**
 * @generated SignedSource<<d3f182718d9092c73b61fc19664708e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PumpVariant = "FCM" | "FC" | "AR";
export type RemoteControlQuery$variables = {
  id: string;
};
export type RemoteControlQuery$data = {
  readonly node: {
    readonly pumpInformation?: {
      readonly maxFlow: number;
      readonly pumpVariant: PumpVariant;
    } | null;
    readonly remoteControlLatestDeviceData?: {
      readonly dataPoints: ReadonlyArray<{
        readonly name: string | null;
        readonly value: number | null;
        readonly unit: string | null;
      } | null> | null;
    } | null;
  } | null;
};
export type RemoteControlQuery = {
  variables: RemoteControlQuery$variables;
  response: RemoteControlQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PumpInformation",
      "kind": "LinkedField",
      "name": "pumpInformation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxFlow",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pumpVariant",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "remoteControlLatestDeviceData",
      "args": null,
      "concreteType": "LatestDeviceData",
      "kind": "LinkedField",
      "name": "latestDeviceData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DeviceData",
          "kind": "LinkedField",
          "name": "dataPoints",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "unit",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoteControlQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoteControlQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3d15f8fee7e31177157bd9b294ace2d3",
    "id": null,
    "metadata": {},
    "name": "RemoteControlQuery",
    "operationKind": "query",
    "text": "query RemoteControlQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on Device {\n      pumpInformation {\n        maxFlow\n        pumpVariant\n      }\n      remoteControlLatestDeviceData: latestDeviceData {\n        dataPoints {\n          name\n          value\n          unit\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "2e0168c541036697a637c63b7848554c";

export default node;
