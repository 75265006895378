import { Global, ThemeProvider } from '@emotion/react';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Environment } from 'relay-runtime';
import { RelayEnvironmentProvider } from 'relay-hooks';
import { BrowserRouter } from 'react-router-dom';
import { SkeletonTheme } from 'react-loading-skeleton';
import { GDSProvider, dark } from '@gds-react/core';
import { MsalProvider } from '@azure/msal-react';
import BrowserSupport from '../BrowserSupport/BrowserSupport';
import environment from '../../relay/createRelayEnvironment';
import Routes from '../../routes/Routes';
import theme from '../../themes/dark';
import sentryCaptureError from '../../utils/sentry/sentry-capture-error';
import { ErrorFallback, Loader } from '..';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { globalStyles } from './styles';
import RootContext from './RootContext';
import setInitialDataLayer from '../../adobe-launch/set-initial-data-layer';
import LoginHandler from '../Login/LoginHandler';
import msalPublicClientApplication from '../../utils/auth/msalPublicClientApplication';

const Root: React.FC<{}> = () => {
  useEffect(() => {
    setInitialDataLayer();
  }, []);

  const [relayEnvironment, setRelayEnvironment] = useState<Environment>(environment());
  const context = useMemo(() => ({ relayEnvironment, setRelayEnvironment }), [relayEnvironment]);

  const { baseColor, highlightColor } = theme.colors.skeletonLoader;

  return (
    <RootContext.Provider value={context}>
      <GDSProvider tokens={dark}>
        <ThemeProvider theme={theme}>
          <Global styles={globalStyles} />
          <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor}>
            <BrowserSupport>
              <MsalProvider instance={msalPublicClientApplication}>
                <LoginHandler>
                  <BrowserRouter>
                    <RelayEnvironmentProvider environment={relayEnvironment}>
                      <ErrorBoundary
                        onError={(error): void => sentryCaptureError(error, 'Root')}
                        FallbackComponent={ErrorFallback}
                      >
                        <Suspense fallback={<Loader />}>
                          <Routes />
                        </Suspense>
                      </ErrorBoundary>
                    </RelayEnvironmentProvider>
                  </BrowserRouter>
                </LoginHandler>
              </MsalProvider>
            </BrowserSupport>
          </SkeletonTheme>
        </ThemeProvider>
      </GDSProvider>
    </RootContext.Provider>
  );
};

export default Root;
