/** @jsx jsx */
import { css, SerializedStyles, Theme } from '@emotion/react';
import grundfosthesansv2boldWoff2 from '../../assets/fonts/grundfosthesansv2bold-webfont.woff2';
import grundfosthesansv2boldWoff from '../../assets/fonts/grundfosthesansv2bold-webfont.woff';
import grundfosthesansv2bolditalicWoff2 from '../../assets/fonts/grundfosthesansv2bolditalic-webfont.woff2';
import grundfosthesansv2bolditalicWoff from '../../assets/fonts/grundfosthesansv2bolditalic-webfont.woff';
import grundfosthesansv2semilightWoff2 from '../../assets/fonts/grundfosthesansv2semilight-webfont.woff2';
import grundfosthesansv2semilightWoff from '../../assets/fonts/grundfosthesansv2semilight-webfont.woff';
import grundfosthesansv2semilightitalicWoff2 from '../../assets/fonts/grundfosthesansv2semilightitalic-webfont.woff2';
import grundfosthesansv2semilightitalicWoff from '../../assets/fonts/grundfosthesansv2semilightitalic-webfont.woff';

const globalStyles = (theme: Theme): SerializedStyles => css`
  @font-face {
    font-family: 'grundfos';
    src: url(${grundfosthesansv2boldWoff2}) format('woff2'), url(${grundfosthesansv2boldWoff}) format('woff');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'grundfos';
    src: url(${grundfosthesansv2bolditalicWoff2}) format('woff2'),
      url(${grundfosthesansv2bolditalicWoff}) format('woff');
    font-weight: bold;
    font-style: italic;
  }

  @font-face {
    font-family: 'grundfos';
    src: url(${grundfosthesansv2semilightWoff2}) format('woff2'), url(${grundfosthesansv2semilightWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'grundfos';
    src: url(${grundfosthesansv2semilightitalicWoff2}) format('woff2'),
      url(${grundfosthesansv2semilightitalicWoff}) format('woff');
    font-weight: normal;
    font-style: italic;
  }

  * {
    margin: 0;
    padding: 0;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html {
    color: ${theme.colors.primaryText};
    background-color: ${theme.colors.primaryBackground};
    box-sizing: border-box;
    font-family: 'grundfos', sans-serif;
    font-size: 16px;
  }

  html,
  body,
  #root,
  #app {
    height: 100%;
    width: 100%;
  }

  a {
    color: ${theme.colors.primaryText};
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${theme.colors.secondaryText};
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { globalStyles };
