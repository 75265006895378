/**
 * @generated SignedSource<<4060dfd25c553b46daa7a832a9cdece2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SubNavigationListItem_item$data = {
  readonly __typename: string;
  readonly id: string;
  readonly uuid?: string;
  readonly name?: string;
  readonly " $fragmentType": "SubNavigationListItem_item";
};
export type SubNavigationListItem_item$key = {
  readonly " $data"?: SubNavigationListItem_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"SubNavigationListItem_item">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "uuid",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubNavigationListItem_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "Section",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "Installation",
      "abstractKey": null
    }
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};
})();

(node as any).hash = "03f4830a63d99eb5ad2fa5a9d7f32dfa";

export default node;
