import React from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { EventVirtualDeviceItemProps } from './types';
import { useEventCodeTranslations } from '../../../utils/mappings/event-code-translations';
// eslint-disable-next-line camelcase
import { EventListItemVirtualDevice_item$key } from './__generated__/EventListItemVirtualDevice_item.graphql';
import { useAccount } from '../../../providers/AccountProvider';
import EventListItem from './EventListItem';

const EventListItemVirtualDevice: React.FC<EventVirtualDeviceItemProps> = ({ event }) => {
  // eslint-disable-next-line camelcase
  const eventItem = useFragment<EventListItemVirtualDevice_item$key>(
    graphql`
      fragment EventListItemVirtualDevice_item on Event {
        createdAt
        device {
          uuid
          deviceInfo {
            type # Expect VirtualSensor
          }
          location {
            ... on Installation {
              uuid
              installationName: name
              parentLocation {
                ... on Section {
                  customerName: name
                }
              }
              devices {
                edges {
                  node {
                    uuid
                    descendantDeviceInfo {
                      type # Expect child of gateway to be DDA / DDAC
                    }
                  }
                }
              }
            }
          }
        }
        type
        code
      }
    `,
    event,
  );
  const code = useEventCodeTranslations(eventItem?.code);
  const {
    account: { uuid: accountId },
  } = useAccount();

  if (!eventItem) return null;
  const { type, createdAt, device } = eventItem;

  if (device?.deviceInfo?.type !== 'VirtualSensor') throw new Error('Device is not a virtual sensor');

  // In asset service we have a hierachy for devices (e.g. virtual device, dda, communication device) and a hierarchy for assets (e.g. account, section, installation)
  // To find the communicationDeviceId we use for routing (url), we will look in the device hierachy.
  // We used the `... on Installation` in our graphql to filter on installations. We know that there is always only one installation,
  // therefore we can assume the first element in the array is our communication device
  const communicationDevice = (device?.location?.devices?.edges || [])[0]?.node;

  if (!communicationDevice) return null;

  const pumpIdentity = (device?.location?.devices?.edges || [])[0]?.node?.descendantDeviceInfo?.type;
  const redirectLink = `/account/${accountId}/gateways/${communicationDevice.uuid}/${pumpIdentity}`;
  return (
    <EventListItem
      type={type}
      createdAt={createdAt}
      customerName={device.location?.parentLocation?.customerName || ''}
      installationName={device.location?.installationName}
      initiatorTranslationKey="WP.ALERTS.GRID_ITEM_CALCULATED"
      redirectLink={redirectLink}
      alertCode={code}
    />
  );
};

export default EventListItemVirtualDevice;
