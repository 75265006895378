import React from 'react';
import { animated, useSpring, easings } from '@react-spring/web';
import { barLabelValueUnitContainer, barUpperLabel, unitLabelStyles, valueStyles, valueUnitContainer } from './styles';
import { heading05Styles, labelStyles } from '../../styles';
import { BarLabelValueUnitProps } from './types';

const BarLabelValueUnit = ({ maxValue, value, label, unit, testId }: BarLabelValueUnitProps) => {
  const positiveValue = value > 0 ? value : 0;

  const countDown = useSpring({
    config: { duration: 300 },
    delay: 350,
    from: { value: Number(maxValue) },
    to: { value: positiveValue },
    easing: easings.linear,
  });

  return (
    <div css={barLabelValueUnitContainer} data-testid={`${testId}-battery-bar-label-value-unit`}>
      <span css={[labelStyles, barUpperLabel]}>{label}</span>
      <div css={valueUnitContainer}>
        <span css={[heading05Styles, valueStyles]}>
          <animated.div>{countDown.value.to((x) => Math.floor(x))}</animated.div>
        </span>
        <span css={unitLabelStyles}>{unit}</span>
      </div>
    </div>
  );
};

export default BarLabelValueUnit;
