import React from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import Loader from '../components/Loader/Loader';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PrivateRoute: React.FC<{ children: JSX.Element }> = ({ children }): JSX.Element => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  if (isAuthenticated) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return children;
  }

  instance.loginRedirect();

  return <Loader />;
};

export default PrivateRoute;
