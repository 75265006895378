import { css, SerializedStyles, Theme } from '@emotion/react';

const tileBodyStyles = css`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const summaryContainerStyles = (theme: Theme): SerializedStyles => css`
  display: flex;
  flex-direction: row;
  padding-top: 0.8em;
  & > div:first-of-type {
    border-right: 0.1em solid ${theme.colors.white.primary};
    padding-right: 1em;
    margin-right: 1em;
  }
`;

const summaryStyles = css`
  flex-direction: column;
`;

const summaryValueStyles = (theme: Theme): SerializedStyles => css`
  color: ${theme.colors.white.primary};
  font-size: 1.5em;
  font-weight: 600;
  letter-spacing: 0.04em;
`;

const graphContainerStyles = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const lastConnectedDateStyles = css`
  font-weight: 400;
  font-size: 0.85em;
  padding-bottom: 2em;
`;

// eslint-disable-next-line import/prefer-default-export
export {
  tileBodyStyles,
  graphContainerStyles,
  summaryContainerStyles,
  summaryStyles,
  summaryValueStyles,
  lastConnectedDateStyles,
};
