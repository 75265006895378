import { useContext } from 'react';
import { AccountContext } from './AccountProvider';
import { ContextProps } from './types';

const useAccount = (): ContextProps => {
  const ctx = useContext(AccountContext);

  if (ctx === null) throw new Error('You forgot the provider');

  return ctx;
};

export default useAccount;
