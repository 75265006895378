import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoItem } from '../../../../../components';
import { isNotUndefinedOrNull } from '../../../utils';
import { gridPlacement } from '../styles';

const RemoteControlEnabled = ({ remoteControlEnabled }: { remoteControlEnabled: boolean | undefined | null }) => {
  const { t } = useTranslation();
  return (
    <InfoItem
      css={gridPlacement('remoteControlEnabled')}
      label={t('WP.COMMON.REMOTE_CONTROL')}
      testId="pump-status-remote-control-enabled"
      value={
        isNotUndefinedOrNull(remoteControlEnabled)
          ? t(`WP.COMMON.${remoteControlEnabled ? 'ON' : 'OFF'}`).toLocaleUpperCase()
          : t('WP.ERROR.NO_DATA')
      }
    />
  );
};

export default RemoteControlEnabled;
