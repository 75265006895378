import React from 'react';
import { useTranslation } from 'react-i18next';
import { BackLightColor, StoppedByState } from './types';
import { InfoItem } from '../../../../../components';
import { gridPlacement } from './styles';
import { StatusSquare } from '../../../../../components/Icons';

export const BackLight = ({ backlight }: { backlight: BackLightColor }) => {
  const { t } = useTranslation();
  return (
    <InfoItem
      css={gridPlacement('pumpOperation')}
      label={t('WP.OVERVIEW.PUMP_OPERATION')}
      Icon={<StatusSquare fill={backlight || 'transparent'} />}
      testId="pump-status-back-light"
    />
  );
};

export const StoppedBy = ({ stoppedBy }: { stoppedBy: StoppedByState }) => {
  const { t } = useTranslation();
  return (
    <InfoItem
      multiLine
      css={gridPlacement('stoppedBy')}
      label={t('WP.OVERVIEW.STOPPED_BY')}
      value={stoppedBy ? t(`WP.OVERVIEW.STOPPED_BY_${stoppedBy}`) : ''}
      testId="pump-status-stopped-by"
    />
  );
};
