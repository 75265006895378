import React from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useFragment } from 'relay-hooks';
import { NoDataAvailableMessage } from '../../../components';
import { InfoContainerDDADataProps } from './types';
import InfoContainer from '../Overview/OverviewChemPairing/InstallationInfo/InfoContainer';
// eslint-disable-next-line camelcase
import { InfoContainerDDAData_device$key } from './__generated__/InfoContainerDDAData_device.graphql';

const InfoContainerDDAData: React.FC<InfoContainerDDADataProps> = ({ onClose, device }) => {
  const { t } = useTranslation();
  // eslint-disable-next-line camelcase
  const data = useFragment<InfoContainerDDAData_device$key>(
    graphql`
      fragment InfoContainerDDAData_device on Device {
        ...useInfoContainerData_device
        descendantDeviceInfo {
          ... on DDA {
            productInfo {
              typeKey
              productNumber
              firmware
              serialNumber
            }
          }
        }
      }
    `,
    device.node,
  );

  if (!data) return <NoDataAvailableMessage message={t('WP.ERROR.NO_DATA')} />;
  const { productInfo } = data.descendantDeviceInfo ?? {};
  const { typeKey, productNumber, firmware, serialNumber } = productInfo ?? {};

  return (
    <InfoContainer
      productInfo={{
        typeKey: typeKey || '',
        productNumber: productNumber || '',
        serialNumber: serialNumber || '',
        firmware: firmware || '',
      }}
      device={device}
      onClose={onClose}
    />
  );
};

export default InfoContainerDDAData;
