import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router';
import { AccountProvider } from '../../providers/AccountProvider';
import PrivateRoute from '../../routes/PrivateRoute';
import useAuthenticatedUser from '../../hooks/useAuthenticatedUser/use-authenticated-user';
import { setUserPuid } from '../../adobe-launch';
import { LocaleProvider } from '../../providers/LocaleProvider/LocaleProvider';

const SignedInRoot: React.FC = () => {
  const user = useAuthenticatedUser();

  useEffect(() => {
    if (user) {
      const { company, signInName, puid, country } = user;

      if (puid) {
        setUserPuid(puid);

        Sentry.setUser({ id: puid });

        // Hotjar
        (window as any).hj('identify', puid, {
          emailDomain: signInName.split('@')[1],
          company,
          country,
        });
      }
    }
  }, [user]);

  return (
    <PrivateRoute>
      <LocaleProvider>
        <AccountProvider>
          <Outlet />
        </AccountProvider>
      </LocaleProvider>
    </PrivateRoute>
  );
};

export default SignedInRoot;
