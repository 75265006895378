import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { FormValues } from '../types';
import { PressureSliderFormProps } from './types';
import PressureSlider from '../components/PressureSlider';

const PressureSliderForm: React.FC<PressureSliderFormProps> = ({
  maxPressureUnit,
  minPressureUnit,
  pressureMaxRange,
  pressureMinRange,
  isDisabled = false,
  testId,
}) => {
  const { values: formValues, setFieldValue } = useFormikContext<FormValues>();
  const { maxPressureValue, minPressureValue } = formValues;

  const onChange = useCallback(
    ({ leftThumb, rightThumb }: { leftThumb?: number; rightThumb?: number }) => {
      if (leftThumb !== undefined) setFieldValue('minPressureValue', leftThumb);
      if (rightThumb !== undefined) setFieldValue('maxPressureValue', rightThumb);
    },
    [setFieldValue],
  );

  return (
    <PressureSlider
      pressureMaxRange={pressureMaxRange}
      pressureMinRange={pressureMinRange}
      maxPressureUnit={maxPressureUnit}
      minPressureUnit={minPressureUnit}
      minPressureValue={minPressureValue}
      maxPressureValue={maxPressureValue}
      isDisabled={isDisabled}
      onChange={onChange}
      testId={testId}
    />
  );
};

export default PressureSliderForm;
