import { css, SerializedStyles, Theme } from '@emotion/react';
import dsmMediaQuery from '../../../../utils/media-queries/media-queries';

const itemStyles = (theme: Theme): SerializedStyles => css`
  display: grid;
  grid-template-areas: 'type title datetime' 'type device .' 'type group .';
  grid-template-columns: max-content auto max-content;
  grid-column-gap: 1rem;
  grid-row-gap: 0.2rem;
  background: ${theme.colors.secondaryBackground};
  min-height: 7rem;
  padding: 1rem;
  justify-items: start;
  color: ${theme.colors.primaryText};
  margin-bottom: 0.5rem;
  text-decoration: none;
  :hover {
    background-color: ${theme.colors.tertiaryBackground};
    cursor: pointer;
  }
  ${dsmMediaQuery('large device')} {
    min-height: 4rem;
    grid-template-areas: 'type title device group datetime troubleshoot';
    grid-template-columns: max-content minmax(1rem, 12rem) minmax(1rem, 12rem) auto minmax(1rem, 10rem) max-content;
    align-items: center;
    :hover {
      button {
        background-color: ${theme.colors.secondaryText};
        border: none;
        color: #000;
      }
    }
  }
`;

const textStyles = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  ${dsmMediaQuery('large device')} {
    margin-left: 0.5rem;
  }
`;

const typeStyles = css`
  grid-area: type;
  align-self: center;
`;

const titleStyles = (theme: Theme): SerializedStyles => css`
  grid-area: title;
  font-size: 18px;
  color: ${theme.colors.secondaryText};
  word-break: break-all;
`;

const deviceStyles = css`
  grid-area: device;
`;

const inlineIconStyles = css`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1rem auto;
  align-items: center;
  grid-column-gap: 0.5rem;
`;

const groupStyles = css`
  grid-area: group;
`;

const datetimeStyles = css`
  grid-area: datetime;
  ${dsmMediaQuery('large device')} {
    justify-self: end;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1rem auto;
    align-items: center;
    grid-column-gap: 0.5rem;
  }
`;

const timestampStyles = css`
  vertical-align: middle;
`;

const troubleshootButtonStyles = (theme: Theme): SerializedStyles => css`
  display: none;
  ${dsmMediaQuery('large device')} {
    display: unset;
    button {
      cursor: pointer;
      height: 2rem;
      width: 8rem;
      border: 1px solid ${theme.colors.secondaryText};
      background-color: transparent;
      text-transform: uppercase;
      color: ${theme.colors.secondaryText};
      font-weight: 600;
      font-size: 0.75rem;
    }
  }
`;

export {
  itemStyles,
  typeStyles,
  datetimeStyles,
  titleStyles,
  deviceStyles,
  inlineIconStyles,
  groupStyles,
  troubleshootButtonStyles,
  textStyles,
  timestampStyles,
};
