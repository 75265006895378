import React from 'react';
import { t } from 'i18next';
import { VolumetricConcentrationProps } from './types';
import { graphContainerStyles } from './styles';
import VolumetricConcentrationGraph from './components/VolumetricConcentrationGraph/VolumetricConcentrationGraph';
import { LastConnected, VolumetricContainer, VolumetricSummary } from './components';
import NonProportionalDosingText from './components/NonProportionalDosingText';

const VolumetricConcentration: React.FC<VolumetricConcentrationProps> = ({
  targetVolume,
  buckets,
  isProportionalDosing,
  lastConnected,
  targetVolUnit,
  actualVolume,
  labels,
  graphHeight,
}) => (
  <VolumetricContainer>
    <VolumetricSummary
      isProportionalDosing={isProportionalDosing ?? false}
      valueActual={actualVolume ?? 0}
      valueTarget={targetVolume ?? 0}
      unit={targetVolUnit ?? ''}
    />
    <div css={graphContainerStyles}>
      {Boolean(lastConnected) && (
        <LastConnected
          lastConnected={lastConnected || 0}
          label={t('WP.VOLUMETRIC.CONCENTRATION.TILE_CONCENTRATION_CHANGE')}
        />
      )}
      {buckets && isProportionalDosing ? (
        <VolumetricConcentrationGraph
          height={graphHeight || 0}
          labels={labels}
          data={buckets}
          // Comment these bad bois in to test thresholds
          // thresholds={{
          //   thresholdMin: { minText: 'Min', categoryText: '40' },
          //   thresholdMax: { maxText: 'Max', categoryText: '70' },
          // }}
          // We need the target to be rounded here, for later conditional logic to draw the middle bucket label
          target={Number(targetVolume?.toFixed(1) ?? 0)}
        />
      ) : (
        !isProportionalDosing && (
          <NonProportionalDosingText infotext={t('WP.VOLUMETRIC.CONCENTRATION.TILE_NONPROPORTIONAL_INFO')} />
        )
      )}
    </div>
  </VolumetricContainer>
);
export default VolumetricConcentration;
