import { css } from '@emotion/react';
import { Formik, Form } from 'formik';
import React, { useRef } from 'react';
import * as Yup from 'yup';
import { TFunction, useTranslation } from 'react-i18next';
import { TextInputField } from '../../../../components';
import { formContentStyles, formStyles } from './styles';
import { InstallationLocationProps } from './types';

const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    installationName: Yup.string()
      .required(t('WP.ONBOARDING.INSTALLATION_NAME_ERROR'))
      .max(40, t('WP.ONBOARDING.INSTALLATION_LENGTH_ERROR')),
  });

const InstallationLocationForm: React.FC<InstallationLocationProps> = ({
  onChange,
  onSubmit,
  installationName = '',
  lastChanged = 0,
  formId,
}) => {
  const formRef = useRef<typeof Formik>();
  const { t } = useTranslation();

  const initialValues: { installationName: string; lastChanged: number } = {
    installationName,
    lastChanged,
  };

  return (
    <div css={formContentStyles}>
      <Formik
        ref={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema(t)}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values }) => {
          if (onChange) onChange(values);
          return (
            <Form id={formId} css={formStyles}>
              <TextInputField
                css={css`
                  grid-area: installationName;
                `}
                name="installationName"
                placeholder={t('WP.ONBOARDING.INSTALLATION_NAME_PLACEHOLDER')}
                label={t('WP.ONBOARDING.INSTALLATION_NAME_LABEL')}
                isVertical
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default InstallationLocationForm;
