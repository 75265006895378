import { css } from '@emotion/react';
import { Formik, Form } from 'formik';
import React, { useEffect, useState } from 'react';
import { commitLocalUpdate, graphql, useLazyLoadQuery, useMutation, useRelayEnvironment } from 'react-relay';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { toGlobalId } from 'graphql-relay';
import { useTranslation } from 'react-i18next';
import { usePageViewed } from '../../../../adobe-launch/hooks';
import { Button, DropDownSelect, Loader } from '../../../../components';
import { SelectAccountQuery } from './__generated__/SelectAccountQuery.graphql';
import { SelectAccountMutation } from './__generated__/SelectAccountMutation.graphql';
import { formStyles, contentStyles, dropDownStyle, linkStyles, nextButton } from './styles';
import countries, { getCountryCode } from '../../countries';
import { heading05Styles } from '../../../../styles';
import useAuthenticatedUser from '../../../../hooks/useAuthenticatedUser/use-authenticated-user';

const ExistingAccountSchema = (validationMessage: string) =>
  Yup.object().shape({
    account: Yup.string().required(validationMessage),
  });

const SelectAccount: React.FC = () => {
  usePageViewed('Onboarding:SelectAccount');
  const { state } = useLocation();
  const claimId = (state as { claimId: string })?.claimId;
  const { t } = useTranslation();
  const environment = useRelayEnvironment();
  const navigate = useNavigate();
  const user = useAuthenticatedUser();

  const data = useLazyLoadQuery<SelectAccountQuery>(
    graphql`
      query SelectAccountQuery($id: ID!) {
        clientState {
          onboarding {
            account {
              uuid
              address
              postalCode
              city
              country
            }
          }
        }
        onboardingAccounts {
          edges {
            node {
              uuid
              address
              postalCode
              city
              name
              country
              street
            }
          }
        }
        node(id: $id) {
          ... on UnclaimedDevice {
            connectState
          }
        }
      }
    `,
    { id: toGlobalId('UnclaimedDevice', claimId) },
  );

  const [commit, isInFlight] = useMutation<SelectAccountMutation>(graphql`
    mutation SelectAccountMutation($id: ID!, $input: OnboardingCreateUserAndAccountInput!) {
      onboardingCreateUserAndAccount(id: $id, input: $input) {
        id
        onboardingId
        account {
          uuid
          company
          address
          street
          postalCode
          grundfosCustomerID
          country
          city
        }
      }
    }
  `);

  const [isUserInfoLoaded, setIsUserInfoLoaded] = useState(false);
  const [companyFromToken, setCompanyFromToken] = useState('');
  const [countryFromToken, setCountryFromToken] = useState('');
  const { onboardingAccounts: accounts } = data || {};
  const [hasExistingAccounts] = useState(!!accounts?.edges?.length);
  const onboardingRecordID = 'client:clientState:onboarding';

  commitLocalUpdate(environment, (store) => {
    store
      .getRoot()
      .getLinkedRecord('clientState')
      ?.setLinkedRecord(store.get(onboardingRecordID) || store.create(onboardingRecordID, 'Onboarding'), 'onboarding');
  });

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (!user) return;

      setCompanyFromToken(user.company);
      setCountryFromToken(user.country);
      setIsUserInfoLoaded(true);
    };
    fetchUserInfo();
  }, [user]);

  if (isInFlight) return <Loader />;

  return isUserInfoLoaded ? (
    <div css={contentStyles}>
      <h1 css={heading05Styles}>{t('WP.ONBOARDING.CHOOSE_ACCOUNT_TITLE')}</h1>
      <Formik
        initialValues={{
          account: '',
          company: !accounts ? companyFromToken : '',
          address: '',
          postalCode: '',
          city: '',
          country: !accounts ? countryFromToken : '',
          grundfosCustomerID: '',
          acceptTermsAndConditions: false,
        }}
        validationSchema={ExistingAccountSchema(t('WP.ONBOARDING.CHOOSE_ACCOUNT_VALIDATION_MESSAGE'))}
        onSubmit={(values) => {
          const { account: existingAccount } = values;
          const existingAccounts = accounts?.edges;
          const selectedAccount = existingAccounts?.find((item) => item?.node?.name === existingAccount);
          commit({
            variables: {
              id: onboardingRecordID,
              input: {
                uuid: selectedAccount?.node?.uuid,
                address: selectedAccount?.node?.address,
                street: selectedAccount?.node?.street,
                postalCode: selectedAccount?.node?.postalCode,
                city: selectedAccount?.node?.city,
                company: selectedAccount?.node?.name || existingAccount,
                // if there is no country set on the account we set a default one
                country: getCountryCode(countries, selectedAccount?.node?.country) || 'DK',
                grundfosCustomerID: '',
              },
            },
            onCompleted: () => navigate('/onboarding/create-or-select-customer', { state: { claimId } }),
          });
        }}
      >
        <Form css={formStyles}>
          {hasExistingAccounts && (
            <DropDownSelect
              name="account"
              label={t('WP.ONBOARDING.CHOOSE_ACCOUNT_SELECTION')}
              alignment="start"
              className="accountSelect"
              css={dropDownStyle}
            >
              <option value="" disabled selected>
                {t('WP.ONBOARDING.CHOOSE_ACCOUNT_SELECT_TEXT')}
              </option>
              {accounts?.edges?.map((account) => (
                <option key={account?.node?.uuid} value={account?.node?.name}>
                  {account?.node?.name}
                </option>
              ))}
            </DropDownSelect>
          )}
          <p
            css={css`
              grid-area: description;
            `}
          >
            {t('WP.ONBOARDING.CHOOSE_ACCOUNT_NOT_AVAILABLE')}{' '}
            <a css={linkStyles} href={`mailto:${t('WP.COMMON.CSM_EMAIL_GENERAL')}`}>
              {t('WP.COMMON.CSM_EMAIL_GENERAL')}
            </a>
          </p>
          <Button variant="primary" type="submit" css={nextButton}>
            {t('WP.COMMON.NEXT')}
          </Button>
        </Form>
      </Formik>
    </div>
  ) : null;
};

export default SelectAccount;
