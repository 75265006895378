/**
 * @generated SignedSource<<bcda86fac9d4c2e1a07411033a18eb04>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GeniIdentity = {
  familyCode: number;
  unitType: number;
  unitVersion: number;
};
export type MoreDataQuery$variables = {
  id: string;
  endDate: number;
  startDate: number;
  geniIdentity: GeniIdentity;
};
export type MoreDataQuery$data = {
  readonly node: {
    readonly descendantDeviceInfo?: {
      readonly pumpSettings?: {
        readonly pulseRateSettings: {
          readonly meterType: string | null;
          readonly pulseRate: {
            readonly quantity: number | null;
            readonly unit: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly moreDataAppSettings?: ReadonlyArray<{
      readonly updatedAt: number | null;
      readonly updatedBy: string | null;
      readonly name: string | null;
      readonly value: string | null;
      readonly unit: string | null;
    } | null> | null;
    readonly moreDataLatestDeviceData?: {
      readonly dataPoints: ReadonlyArray<{
        readonly name: string | null;
        readonly unit: string | null;
        readonly value: number | null;
      } | null> | null;
    } | null;
    readonly moreDataTimeSeriesDatapoints?: ReadonlyArray<{
      readonly name: string;
      readonly unit: string | null;
      readonly latestDatapoint: {
        readonly valueRaw: number | null;
      };
      readonly datapoints: ReadonlyArray<{
        readonly createdAt: number;
        readonly valueRaw: number | null;
      }>;
    }>;
  } | null;
};
export type MoreDataQuery = {
  variables: MoreDataQuery$variables;
  response: MoreDataQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "geniIdentity"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit",
  "storageKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PumpSettingsDDA",
      "kind": "LinkedField",
      "name": "pumpSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PulseRateSettings",
          "kind": "LinkedField",
          "name": "pulseRateSettings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "meterType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "VolumeQuantity",
              "kind": "LinkedField",
              "name": "pulseRate",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "quantity",
                  "storageKey": null
                },
                (v5/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DDA",
  "abstractKey": null
},
v7 = {
  "kind": "Variable",
  "name": "endDate",
  "variableName": "endDate"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v10 = {
  "alias": "moreDataAppSettings",
  "args": [
    (v7/*: any*/),
    {
      "kind": "Literal",
      "name": "settingSelector",
      "value": [
        {
          "name": "sdcs:VOLUME_TOTAL_CHEM_REGISTRATION"
        },
        {
          "name": "sdcs:CHEMICAL_NAME"
        },
        {
          "name": "sdcs:VOLUME_CHEMICAL_WARNING_TRESHOLD"
        },
        {
          "name": "sdcs:CHEMICAL_CONNECTED"
        }
      ]
    }
  ],
  "concreteType": "AppSetting",
  "kind": "LinkedField",
  "name": "appSettings",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedBy",
      "storageKey": null
    },
    (v8/*: any*/),
    (v9/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v11 = {
  "alias": "moreDataLatestDeviceData",
  "args": null,
  "concreteType": "LatestDeviceData",
  "kind": "LinkedField",
  "name": "latestDeviceData",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceData",
      "kind": "LinkedField",
      "name": "dataPoints",
      "plural": true,
      "selections": [
        (v8/*: any*/),
        (v5/*: any*/),
        (v9/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "kind": "Variable",
  "name": "geniIdentity",
  "variableName": "geniIdentity"
},
v13 = {
  "kind": "Literal",
  "name": "raw",
  "value": true
},
v14 = [
  {
    "items": [
      {
        "fields": [
          (v12/*: any*/),
          {
            "kind": "Literal",
            "name": "name",
            "value": "sdcs:CALCULATED_CONTAINER_LEVEL"
          },
          (v13/*: any*/)
        ],
        "kind": "ObjectValue",
        "name": "datapointSelectors.0"
      },
      {
        "fields": [
          (v12/*: any*/),
          {
            "kind": "Literal",
            "name": "name",
            "value": "sdcs:DOSED_VOLUME_HOURLY"
          },
          (v13/*: any*/)
        ],
        "kind": "ObjectValue",
        "name": "datapointSelectors.1"
      },
      {
        "fields": [
          (v12/*: any*/),
          {
            "kind": "Literal",
            "name": "name",
            "value": "sdcs:DOSED_VOLUME_HOURLY_START"
          },
          (v13/*: any*/)
        ],
        "kind": "ObjectValue",
        "name": "datapointSelectors.2"
      },
      {
        "fields": [
          (v12/*: any*/),
          {
            "kind": "Literal",
            "name": "name",
            "value": "sdcs:PROPORTIONAL_DOSING_ACTUAL_CONCENTRATION"
          },
          (v13/*: any*/)
        ],
        "kind": "ObjectValue",
        "name": "datapointSelectors.3"
      },
      {
        "fields": [
          (v12/*: any*/),
          {
            "kind": "Literal",
            "name": "name",
            "value": "gfdm:RUNTIME"
          },
          (v13/*: any*/)
        ],
        "kind": "ObjectValue",
        "name": "datapointSelectors.4"
      },
      {
        "fields": [
          (v12/*: any*/),
          {
            "kind": "Literal",
            "name": "name",
            "value": "gfdm:VOLUME"
          },
          (v13/*: any*/)
        ],
        "kind": "ObjectValue",
        "name": "datapointSelectors.5"
      },
      {
        "fields": [
          (v12/*: any*/),
          {
            "kind": "Literal",
            "name": "name",
            "value": "sdcs:VOLUME_PROCESSED"
          },
          (v13/*: any*/)
        ],
        "kind": "ObjectValue",
        "name": "datapointSelectors.6"
      }
    ],
    "kind": "ListValue",
    "name": "datapointSelectors"
  },
  (v7/*: any*/),
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "startDate"
  }
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "valueRaw",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "Datapoint",
  "kind": "LinkedField",
  "name": "latestDatapoint",
  "plural": false,
  "selections": [
    (v15/*: any*/)
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "Datapoint",
  "kind": "LinkedField",
  "name": "datapoints",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    (v15/*: any*/)
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MoreDataQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "descendantDeviceInfo",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": "moreDataTimeSeriesDatapoints",
                "args": (v14/*: any*/),
                "concreteType": "TimeSeriesDatapoint",
                "kind": "LinkedField",
                "name": "timeSeriesDatapoints",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  (v5/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Device",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "MoreDataQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v18/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "descendantDeviceInfo",
                "plural": false,
                "selections": [
                  (v18/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": "moreDataTimeSeriesDatapoints",
                "args": (v14/*: any*/),
                "concreteType": "TimeSeriesDatapoint",
                "kind": "LinkedField",
                "name": "timeSeriesDatapoints",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  (v5/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v19/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Device",
            "abstractKey": null
          },
          (v19/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e7a0c4e21134ee275d4aefa93ae8103e",
    "id": null,
    "metadata": {},
    "name": "MoreDataQuery",
    "operationKind": "query",
    "text": "query MoreDataQuery(\n  $id: ID!\n  $endDate: Int!\n  $startDate: Int!\n  $geniIdentity: GeniIdentity!\n) {\n  node(id: $id) {\n    __typename\n    ... on Device {\n      descendantDeviceInfo {\n        __typename\n        ... on DDA {\n          pumpSettings {\n            pulseRateSettings {\n              meterType\n              pulseRate {\n                quantity\n                unit\n              }\n            }\n          }\n        }\n      }\n      moreDataAppSettings: appSettings(settingSelector: [{name: \"sdcs:VOLUME_TOTAL_CHEM_REGISTRATION\"}, {name: \"sdcs:CHEMICAL_NAME\"}, {name: \"sdcs:VOLUME_CHEMICAL_WARNING_TRESHOLD\"}, {name: \"sdcs:CHEMICAL_CONNECTED\"}], endDate: $endDate) {\n        updatedAt\n        updatedBy\n        name\n        value\n        unit\n      }\n      moreDataLatestDeviceData: latestDeviceData {\n        dataPoints {\n          name\n          unit\n          value\n        }\n      }\n      moreDataTimeSeriesDatapoints: timeSeriesDatapoints(datapointSelectors: [{name: \"sdcs:CALCULATED_CONTAINER_LEVEL\", raw: true, geniIdentity: $geniIdentity}, {name: \"sdcs:DOSED_VOLUME_HOURLY\", raw: true, geniIdentity: $geniIdentity}, {name: \"sdcs:DOSED_VOLUME_HOURLY_START\", raw: true, geniIdentity: $geniIdentity}, {name: \"sdcs:PROPORTIONAL_DOSING_ACTUAL_CONCENTRATION\", raw: true, geniIdentity: $geniIdentity}, {name: \"gfdm:RUNTIME\", raw: true, geniIdentity: $geniIdentity}, {name: \"gfdm:VOLUME\", raw: true, geniIdentity: $geniIdentity}, {name: \"sdcs:VOLUME_PROCESSED\", raw: true, geniIdentity: $geniIdentity}], startDate: $startDate, endDate: $endDate) {\n        name\n        unit\n        latestDatapoint {\n          valueRaw\n        }\n        datapoints {\n          createdAt\n          valueRaw\n        }\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "da64635d403c0ed8e868b972feb036cf";

export default node;
