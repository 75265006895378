import React from 'react';
import { t } from 'i18next';
import { lastConnectedDateStyles } from '../styles';
import { DateTimeFormatter } from '../../../../../../components';

const LastConnected = ({ label, lastConnected }: { label: string; lastConnected: number }) => (
  <div>
    <span>{label} - </span>
    <span css={lastConnectedDateStyles}>
      <DateTimeFormatter timestamp={lastConnected || 0} />
    </span>
  </div>
);

export default LastConnected;
