import React from 'react';

interface ArrowProps {
  color: string;
  size: string;
  rotateDeg?: number;
}

const Arrow: React.FC<ArrowProps> = ({ color, size, rotateDeg }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" transform={`rotate(${rotateDeg})`}>
    <g id="Icons/Navigation/Arrow-Right/24x24" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons/Navigation/Arrow-Right/32x32" fill={color}>
        <polygon id="Path" points="9.5325 3.9675 8.4675 5.0325 15.4425 12 8.4675 18.9675 9.5325 20.0325 17.5575 12" />
      </g>
    </g>
  </svg>
);

export default Arrow;
