import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoItem } from '../../../../../components';
import { gridPlacement } from '../styles';

const notUndefinedOrNull = (num: null | undefined | number | string) => num !== undefined && num !== null;

const MaxCapacity = ({ value, unit }: { value: number | undefined | null; unit: string | undefined | null }) => {
  const { t } = useTranslation();
  return (
    <InfoItem
      css={gridPlacement('maxCapacity')}
      label={t('WP.COMMON.MAX_CAPACITY')}
      value={notUndefinedOrNull(value) ? value?.toFixed(4) : t('WP.ERROR.NO_DATA')}
      unit={notUndefinedOrNull(unit) ? unit : ''}
      testId="pump-status-max-capacity"
    />
  );
};

export default MaxCapacity;
