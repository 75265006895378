import { css, SerializedStyles, Theme } from '@emotion/react';

const sectionListItemStyles = (theme: Theme): SerializedStyles => css`
  display: grid;
  grid-row-gap: 0.5rem;
  h2 {
    font-size: 1.1rem;
    font-weight: 400;
    color: ${theme.colors.secondaryText};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { sectionListItemStyles };
