import { SerializedStyles } from '@emotion/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tile, TileBody, TileHeader } from '../../../../../components';

const PumpStatusContainer = ({
  children,
  containerStyle,
}: {
  children: (JSX.Element | undefined)[] | JSX.Element;
  containerStyle: SerializedStyles;
}) => {
  const { t } = useTranslation();
  return (
    <Tile
      key="pumpStatus"
      Header={<TileHeader text={t('WP.OVERVIEW.PUMP_STATUS')} />}
      Body={<TileBody css={containerStyle}>{children}</TileBody>}
      testId="pump-status"
    />
  );
};

export default PumpStatusContainer;
