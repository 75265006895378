import { SVGRenderer, SVGElement, SVGPathArray } from 'highcharts';
import { RenderHelperOptions } from './types';
import warningIcon from '../../../../../../../assets/images/warning-triangle.svg';

// We use initRenderHelperMethods to help with all the context the render functions need.
// This reduces the number of params to the render functions
const initRenderHelperMethods = ({ marginTop, marginLeft, color, yAxisBottomLabelText }: RenderHelperOptions) => {
  // The y-axis is not a standard highcharts axis. We therefore must make a custom render function
  const renderYAxis = (ren: SVGRenderer, plotHeight: number, yAxisGroup: SVGElement) => {
    // startpoint (M), line endpoint  (L)

    const yAxisLineXValue = 0;
    const yAxisLineXvalueEnd = yAxisLineXValue + 8.5;
    // default x,y = 0,0 is in the top left corner of the render area
    // If the graph has no margins and the graph is 100 px height, then plotHeight should be 100, that is where y is zero from the graph's point of view
    // The vertical path starts from the graphs y=0 to the top of the render area minus the margin
    const verticalPath = ['M', yAxisLineXValue, plotHeight, 'L', yAxisLineXValue, marginTop];
    const upperEdgePath = ['M', yAxisLineXValue, marginTop, 'L', yAxisLineXvalueEnd, marginTop];
    const downEdgePath = ['M', yAxisLineXValue, plotHeight, 'L', yAxisLineXvalueEnd, plotHeight];
    const yAxisPath = [...verticalPath, ...downEdgePath, ...upperEdgePath] as unknown as SVGPathArray;
    // Draw yAxis
    ren
      .path(yAxisPath)
      .attr({
        'stroke-width': 1,
        stroke: color,
      })
      .add(yAxisGroup);

    // Draw yAxis labels
    // magic -5 value is to align with the yAxis line
    ren
      .label('100%', yAxisLineXValue - 4, marginTop - 25)
      .css({
        color,
      })
      .add(yAxisGroup);
    // magic -3 value is to align with the yAxis line
    ren
      .label(yAxisBottomLabelText, yAxisLineXValue - 3, plotHeight + 5)
      .css({
        color,
      })
      .add(yAxisGroup);
  };

  // E.g. the '<45' label
  const renderExtremesMin = (
    ren: SVGRenderer,
    plotHeight: number,
    extremesGroup: SVGElement,
    label: string,
    value: string,
  ) => {
    const offset = 65;
    // We use the margins, distance from the graph as base line
    // The offset makes the label come closer to graph
    ren
      .label(value, marginLeft - offset, plotHeight - 24)
      .css({
        color,
      })
      .add(extremesGroup);
    ren
      .label(label, marginLeft - offset, plotHeight + 5)
      .css({
        color,
      })
      .add(extremesGroup);
  };
  // E.g. the '>55' label
  const renderExtremesMax = (
    ren: SVGRenderer,
    plotHeight: number,
    plotWidth: number,
    extremesGroup: SVGElement,
    label: string,
    value: string,
  ) => {
    const offset = 35;
    // We use the plotWidth as baseline (it seems to include left margin)
    // The offset makes the label come further away from the graph (or closer to edge of the right margin)
    ren
      .label(value, plotWidth + offset - value.length, plotHeight - 24)
      .css({
        color,
      })
      .add(extremesGroup);

    const labelSVG = ren
      .label(label, plotWidth + offset + 10, plotHeight + 5)
      .css({
        color,
      })
      .add(extremesGroup);
    // This ugly bid of code is to make the text right aligned. Unfortunately the css prop doesnt work.
    labelSVG.attr({
      // TypeScript is not aware of all the props available
      // We use an offset with a magic number '22'. Beacuse it seems that the width of the element is a bit more than just the text
      // next we use the current position of the element and then subtract with the width.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      x: 22 + labelSVG.x - labelSVG.width,
    });
  };

  const renderMinWarn = (
    ren: SVGRenderer,
    plotHeight: number,
    minTrhesholdGroup: SVGElement,
    label: string,
    value: string,
  ) => {
    const offset = 85;
    const imageWidth = 25;
    const imageHeight = 25;
    // warning line low
    // The warning icon (rendered below) has transparent exclamation mark. We dont want to able to se the dashed line in the transparent area.
    // Therefore we use imageHeight to start the dashed line above the warnin icon
    ren
      .path([
        'M',
        marginLeft - 25 - offset,
        plotHeight - imageHeight,
        'L',
        marginLeft - 25 - offset,
        marginTop,
      ] as unknown as SVGPathArray)
      .attr({
        'stroke-width': 2,
        stroke: color,
        dashstyle: 'dash',
      })
      .add(minTrhesholdGroup);

    // warning label low
    ren
      // There is a bit of tinkering with values such as 36, 5, 40 and 25 to make the warning labels centered and positioned above / under the dashed line
      // Changing margins will not affect the centering of the warning labels, but changing font-size or the dashed line will mean we must redo the tinkering
      .label(value, marginLeft - 38 - offset, plotHeight + 5)
      .css({
        color,
        textAlign: 'center',
      })
      .add(minTrhesholdGroup);
    ren
      .label(label, marginLeft - 37 - offset, marginTop - 25)
      .css({
        color,
      })
      .add(minTrhesholdGroup);

    // Render warning icon
    const imageOptions = {
      // imageWidth / 2 to center with dashed line
      x: marginLeft - 25 - offset - imageWidth / 2,
      // imageHeight to position above x-axis and +2 to align with x-axis
      y: plotHeight - imageHeight + 2,
      width: imageWidth,
      height: imageHeight,
    };

    ren
      .image(warningIcon, imageOptions.x, imageOptions.y, imageOptions.height, imageOptions.width)
      .add(minTrhesholdGroup);
  };

  const renderMaxWarn = (
    ren: SVGRenderer,
    plotWidth: number,
    plotHeight: number,
    maxTrhesholdGroup: SVGElement,
    label: string,
    value: string,
  ) => {
    const offset = 85;
    const imageWidth = 25;
    const imageHeight = 25;
    // Vertical dashed line
    // The warning icon (rendered below) has transparent exclamation mark. We dont want to able to se the dashed line in the transparent area.
    // Therefore we use imageHeight to start the dashed line above the warnin icon
    ren
      .path([
        'M',
        plotWidth + 25 + offset,
        plotHeight - imageHeight,
        'L',
        plotWidth + 25 + offset,
        marginTop,
      ] as unknown as SVGPathArray)
      .attr({
        'stroke-width': 2,
        stroke: color,
        dashstyle: 'dash',
      })
      .add(maxTrhesholdGroup);

    // Label bottom
    ren
      .label(value, plotWidth + 10 + offset, plotHeight + 5)
      .css({
        color,
        textAlign: 'center',
      })
      .add(maxTrhesholdGroup);

    // Label top
    ren
      .label(label, plotWidth + 15 + offset, marginTop - 25)
      .css({
        color,
        textAlign: 'center',
      })
      .add(maxTrhesholdGroup);

    // Render warning icon
    const imageOptions = {
      x: plotWidth + 25 + offset - imageWidth / 2,
      y: plotHeight - imageHeight + 2,
      width: imageWidth,
      height: imageHeight,
    };

    ren
      .image(warningIcon, imageOptions.x, imageOptions.y, imageOptions.height, imageOptions.width)
      .add(maxTrhesholdGroup);
  };

  return {
    renderMaxWarn,
    renderMinWarn,
    renderExtremesMin,
    renderExtremesMax,
    renderYAxis,
  };
};

export default initRenderHelperMethods;
