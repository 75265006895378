/* eslint-disable camelcase */
import React from 'react';
import { css } from '@emotion/react';
import { graphql, useFragment } from 'relay-hooks';
import { useTranslation } from 'react-i18next';
import { Tile, TileHeader, TileFooter, TileBody } from '../../../../../components/Tiles';
import { NoDataAvailableMessage, TimeAgo } from '../../../../../components';
import { tileBodyStyles, chemicalConnectedTileFooter, countdownBarStyles } from './styles';
import { CalculatedContainerLevel_device$key } from './__generated__/CalculatedContainerLevel_device.graphql';
import { convertThresholdOrDefault, convertValueToNumber } from '../utils';
import CountDownBar from '../../../../../components/CountDownBar/CountDownBar';
import { AppSetting, CalculatedContainerLevelProps } from './types';

const CalculatedContainerLevel: React.FC<CalculatedContainerLevelProps> = ({ device }) => {
  const data = useFragment<CalculatedContainerLevel_device$key>(
    graphql`
      fragment CalculatedContainerLevel_device on Device @argumentDefinitions(endDate: { type: Int }) {
        latestSync {
          outOfSync
        }
        calcContainerAppSettings: appSettings(
          settingSelector: [
            { name: "sdcs:VOLUME_TOTAL_CHEM_REGISTRATION" }
            { name: "sdcs:CHEMICAL_NAME" }
            { name: "sdcs:VOLUME_CHEMICAL_WARNING_TRESHOLD" }
          ]
          endDate: $endDate
        ) {
          updatedAt
          name
          value
        }
        calcContainerTimeSeriesDatapoints: timeSeriesDatapoints(
          datapointSelectors: [{ name: "sdcs:CALCULATED_CONTAINER_LEVEL", raw: true, geniIdentity: $geniIdentity }]
          startDate: $startDate
          endDate: $endDate
        ) {
          name
          unit
          latestDatapoint {
            valueRaw
          }
        }
      }
    `,
    device.node,
  );
  const { t } = useTranslation();
  const getSetting = (name: string): AppSetting | undefined | null =>
    data?.calcContainerAppSettings?.find((setting) => setting?.name === name);

  const maxVolume = convertValueToNumber(getSetting('sdcs:VOLUME_TOTAL_CHEM_REGISTRATION')?.value);
  const lastConnected = getSetting('sdcs:VOLUME_TOTAL_CHEM_REGISTRATION')?.updatedAt;
  const connectedChemical = getSetting('sdcs:CHEMICAL_NAME')?.value;
  const threshold = convertThresholdOrDefault(getSetting('sdcs:VOLUME_CHEMICAL_WARNING_TRESHOLD')?.value, maxVolume);

  const calculateLevelDatapoint = data?.calcContainerTimeSeriesDatapoints?.find(
    (item) => item.name === 'CALCULATED_CONTAINER_LEVEL',
  );
  const calulatedLevel = convertValueToNumber(calculateLevelDatapoint?.latestDatapoint.valueRaw);

  const isOutOfSync = data?.latestSync?.outOfSync;

  if (isOutOfSync) {
    return (
      <Tile
        key="calculated container level"
        styles={css`
          grid-area: containerLevel;
        `}
        Header={<TileHeader text={t('WP.OVERVIEW.CALCULATED_CONTAINER_LEVEL')} />}
        Body={
          <TileBody css={tileBodyStyles}>
            <NoDataAvailableMessage message={t('WP.ERROR.NO_DATA')} size="text" />
          </TileBody>
        }
      />
    );
  }

  return (
    <Tile
      key="calculated container level"
      styles={css`
        grid-area: containerLevel;
      `}
      Header={<TileHeader text={t('WP.OVERVIEW.CALCULATED_CONTAINER_LEVEL')} />}
      Body={
        <TileBody css={tileBodyStyles}>
          {threshold !== undefined && maxVolume ? (
            <div
              css={css`
                width: 100%;
              `}
            >
              <CountDownBar maxValue={maxVolume} value={calulatedLevel || 0} threshold={{ value: threshold }}>
                <div css={countdownBarStyles}>
                  {/* I'm not sure if and how we should translate units, but this is a temporary solution to handle both EU and US units */}
                  <span>{calculateLevelDatapoint?.unit === 'gal' && 'Gallons'}</span>
                  <span>{calculateLevelDatapoint?.unit === 'l' && 'Liters'}</span>
                  <span>{t('WP.OVERVIEW.UNITS_AVAILABLE').toLowerCase()}</span>
                </div>
              </CountDownBar>
            </div>
          ) : (
            <NoDataAvailableMessage message={t('WP.ERROR.NO_DATA')} size="text" />
          )}
        </TileBody>
      }
      Footer={
        <TileFooter css={chemicalConnectedTileFooter}>
          {lastConnected && connectedChemical ? (
            <>
              <div>
                <span>{t('WP.OVERVIEW.CHEMICAL_CONNECTED')}: </span>
                <TimeAgo timestamp={lastConnected} />
              </div>
              <div>
                <span>{t('WP.OVERVIEW.CHEMICAL_NAME')}: </span>
                <span>{connectedChemical}</span>
              </div>
            </>
          ) : null}
        </TileFooter>
      }
    />
  );
};
export default CalculatedContainerLevel;
