import { css, Theme } from '@emotion/react';
import dsmMediaQuery from '../../../../utils/media-queries/media-queries';

const containerStyles = css`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  grid-template-areas:
    'left'
    'right';
  grid-template-rows: max-content;
  padding-bottom: 1rem;
  ${dsmMediaQuery('large device')} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 'left right';
  }
`;

const tileStyles = css`
  container-type: inline-size;
`;

const twoColumsTileStyles = css`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  @container (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const rightColumnStyles = css`
  grid-area: right;
`;

const leftColumnStyles = css`
  grid-area: left;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const infoItemStyles = css`
  padding-top: 0.6rem;
  padding-bottom: 0.5rem;
`;

const topPadding = css`
  padding-top: 1rem;
`;

const removeBorder = css`
  border-bottom: unset;
`;

const removeLastBottomBorder = css`
  div:last-child {
    ${removeBorder};
  }
`;

const dataBoxStyles = (theme: Theme) => css`
  border: 0.063rem solid ${theme.colors.border.subtle};
  padding: 0.5rem;
`;

const dataBoxTextContainerStyles = css`
  display: flex;
  gap: 0.3rem;
`;

const dataBoxInfoTextContainerStyles = (theme: Theme) => css`
  display: flex;
  padding: 0.325rem 0rem 0.325rem 0rem;
  font-size: 0.75rem;
  font-weight: 400;
  color: ${theme.colors.neutral.neutral100.hex};
`;

export {
  containerStyles,
  topPadding,
  infoItemStyles,
  leftColumnStyles,
  rightColumnStyles,
  twoColumsTileStyles,
  removeLastBottomBorder,
  removeBorder,
  dataBoxStyles,
  dataBoxTextContainerStyles,
  dataBoxInfoTextContainerStyles,
  tileStyles,
};
