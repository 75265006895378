/**
 * @generated SignedSource<<cb7164227504b171a364e38a03f7a43d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type OnboardingDeviceType = "UNKNOWN" | "DDA" | "DDAC";
export type OnboardingConfirmInput = {
  accountId?: string | null;
  onboardingId: string;
  claimId: string;
};
export type SummaryMutation$variables = {
  input: OnboardingConfirmInput;
};
export type SummaryMutation$data = {
  readonly onboardingConfirm: {
    readonly account: {
      readonly uuid: string;
      readonly name: string;
      readonly id: string;
    } | null;
    readonly device: {
      readonly deviceId: string | null;
      readonly subDevice: {
        readonly deviceType: OnboardingDeviceType | null;
      } | null;
    } | null;
  } | null;
};
export type SummaryMutation = {
  variables: SummaryMutation$variables;
  response: SummaryMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "OnboardingConfirmPayload",
    "kind": "LinkedField",
    "name": "onboardingConfirm",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uuid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "OnboardingDevice",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deviceId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OnboardingSubDevice",
            "kind": "LinkedField",
            "name": "subDevice",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deviceType",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SummaryMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SummaryMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fc340643b88c265cd2f793f048c8cc8d",
    "id": null,
    "metadata": {},
    "name": "SummaryMutation",
    "operationKind": "mutation",
    "text": "mutation SummaryMutation(\n  $input: OnboardingConfirmInput!\n) {\n  onboardingConfirm(input: $input) {\n    account {\n      uuid\n      name\n      id\n    }\n    device {\n      deviceId\n      subDevice {\n        deviceType\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5e0110a569d839d3b6384217386bdc85";

export default node;
