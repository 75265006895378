import { useEffect } from 'react';
import pageViewed from '../events/page-viewed';

const usePageViewed = (
  name = `${window.location.host + window.location.pathname}`,
  url = `${window.location.host + window.location.pathname}`,
): void => {
  useEffect(() => {
    pageViewed(name, name, url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default usePageViewed;
