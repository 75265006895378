import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoItem } from '../../../../../components';
import { ControlModeIcon } from '../../../../../components/Icons';
import { gridPlacement } from '../styles';
import { ControlModeProps } from '../types';

const ControlMode: React.FC<ControlModeProps> = ({ controlMode }) => {
  const { t } = useTranslation();

  switch (controlMode) {
    case 'PULSE':
      return (
        <InfoItem
          css={gridPlacement('controlMode')}
          Icon={<ControlModeIcon mode={controlMode} />}
          label={t('WP.OVERVIEW.CONTROL_MODE')}
          value={t(`WP.OVERVIEW.${controlMode}`)}
          testId="pump-status-control-mode-pulse"
        />
      );
    case 'MANUAL':
      return (
        <InfoItem
          css={gridPlacement('controlMode')}
          Icon={<ControlModeIcon mode={controlMode} />}
          label={t('WP.OVERVIEW.CONTROL_MODE')}
          value={t(`WP.OVERVIEW.${controlMode}`)}
          testId="pump-status-control-mode-manual"
        />
      );
    case 'ANALOG_0_20':
      return (
        <InfoItem
          css={gridPlacement('controlMode')}
          Icon={<ControlModeIcon mode={controlMode} />}
          label={t('WP.OVERVIEW.CONTROL_MODE')}
          value={`${t(`WP.OVERVIEW.ANALOG`)} 0-20 mA`}
          testId="pump-status-control-mode-analog_0_20"
        />
      );
    case 'ANALOG_4_20':
      return (
        <InfoItem
          css={gridPlacement('controlMode')}
          Icon={<ControlModeIcon mode={controlMode} />}
          label={t('WP.OVERVIEW.CONTROL_MODE')}
          value={`${t(`WP.OVERVIEW.ANALOG`)} 4-20 mA`}
          testId="pump-status-control-mode-analog_4_20"
        />
      );
    default:
      return (
        <InfoItem
          css={gridPlacement('controlMode')}
          label={t('WP.OVERVIEW.CONTROL_MODE')}
          value={`${t(`WP.OVERVIEW.${controlMode}`)} ${t(`WP.COMMON.NOT_SUPPORTED`).toLowerCase()}`}
          testId="pump-status-control-mode-not_supported"
        />
      );
  }
};

export default ControlMode;
