import React from 'react';
import { graphql, usePaginationFragment } from 'react-relay/hooks';
import { Disposable } from 'react-relay';
import { useTranslation } from 'react-i18next';
import { EventListProps } from './types';
import { Loader } from '../../../components';
import {
  showMoreButtonStyles,
  showMoreStyles,
  loadingMoreStyles,
  eventlistHeaderContainerStyles,
  eventListContainerStyles,
  eventListHeaderStyles,
} from './styles';
import { EventListQuery } from './__generated__/EventListQuery.graphql';
import { EventList_items$key as Key } from './__generated__/EventList_items.graphql';
import EventListItemGateway from './EventListItemGateway';
import { paragraphDimmedStyles } from '../../../styles';
import EventListItemVirtualDevice from './EventListItemVirtualDevice';

const EventList: React.FC<EventListProps> = ({ eventsData }) => {
  const { t } = useTranslation();

  const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment<EventListQuery, Key>(
    graphql`
      fragment EventList_items on Query
      @refetchable(queryName: "EventListQuery")
      @argumentDefinitions(
        exclude: { type: EventState, defaultValue: ACTIVE }
        count: { type: Int, defaultValue: 15 }
      ) {
        eventList: events(first: $count, after: $cursor, exclude: $exclude) @connection(key: "EventList_eventList") {
          edges {
            node {
              __typename
              ... on Event {
                id
                device {
                  deviceInfo {
                    type
                  }
                }
                ...EventListItemGateway_item
                ...EventListItemVirtualDevice_item
              }
            }
          }
        }
      }
    `,
    eventsData,
  );

  const edges = data?.eventList?.edges;

  return (
    <>
      <div css={eventListContainerStyles}>
        <div css={eventlistHeaderContainerStyles}>
          <span css={[eventListHeaderStyles, paragraphDimmedStyles]}>{t('WP.ALERTS.GRID_TITLE')}</span>
          <span css={[eventListHeaderStyles, paragraphDimmedStyles]}>{t('WP.ALERTS.GRID_CUSTOMER')}</span>
          <span css={[eventListHeaderStyles, paragraphDimmedStyles]}>{t('WP.ALERTS.GRID_INSTALLATION')}</span>
          <span css={[eventListHeaderStyles, paragraphDimmedStyles]}>{t('WP.ALERTS.GRID_INITIATOR')}</span>
          <span css={[eventListHeaderStyles, paragraphDimmedStyles]}>{t('WP.ALERTS.GRID_DATE')}</span>
          <span css={[eventListHeaderStyles, paragraphDimmedStyles]}>
            <span />
          </span>
        </div>
        {edges?.map((edge) => {
          switch (edge?.node?.device?.deviceInfo?.type) {
            case 'VirtualSensor':
              return <EventListItemVirtualDevice key={edge.node.id} event={edge.node} />;
            case 'Gateway':
              return <EventListItemGateway key={edge.node.id} event={edge.node} />;
            default:
              return null;
          }
        })}
      </div>
      {hasNext && !isLoadingNext && (
        <div css={showMoreStyles}>
          <button css={showMoreButtonStyles} type="button" onClick={(): Disposable => loadNext(15)}>
            {t('WP.COMMON.SHOW_MORE')}
          </button>
        </div>
      )}
      {isLoadingNext && (
        <div css={loadingMoreStyles}>
          <Loader />
        </div>
      )}
    </>
  );
};

export default EventList;
