import React from 'react';
import { useTranslation } from 'react-i18next';
import { stoppedLocally } from './styles';

const StoppedLocally: React.FC<{ isStoppedLocally: boolean }> = ({ isStoppedLocally }) => {
  const { t } = useTranslation();

  return isStoppedLocally ? (
    <div data-testid="stopped-locally" css={stoppedLocally}>
      {t('WP.REMOTE.DOSING_PUMP_STOPPED_LOCALLY_TOOLTIP')}
    </div>
  ) : null;
};

export default StoppedLocally;
