import { css } from '@emotion/react';

const mainNavigationStyles = css`
  position: absolute;
  z-index: 300;
  overflow: visible;
`;

// eslint-disable-next-line import/prefer-default-export
export { mainNavigationStyles };
