import { css } from '@emotion/react';
import React, { useState, useEffect } from 'react';

const loading = css`
  filter: blur(10px);
  clip-path: inset(0);
`;

const loaded = css`
  filter: blur(0px);
  transition: filter 0.2s linear;
`;

interface ProgressiveImageProps {
  placeholderSrc: string;
  src: string;
  className?: string;
}

const ProgressiveImage = ({
  placeholderSrc,
  src,
  className,
  ...props
}: ProgressiveImageProps & React.ImgHTMLAttributes<HTMLImageElement>) => {
  const [imgSrc, setImgSrc] = useState(placeholderSrc || src);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImgSrc(src);
    };
  }, [src]);

  return (
    <img
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...{ src: imgSrc, ...props }}
      alt={props.alt || ''}
      loading="lazy"
      css={css`
        ${imgSrc === placeholderSrc ? loading : loaded}
      `}
      className={className}
    />
  );
};

export default ProgressiveImage;
