/**
 * @generated SignedSource<<27dcdf653b3e27aea1c498a6beae135f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProportionalDosingRuleViolations = "ZeroToTwentyLowerInputCurrent" | "FourToTwentyLowerInputCurrent" | "LowerInputCurrentFlow" | "UpperInputCurrent";
import { FragmentRefs } from "relay-runtime";
export type VolumetricConcentrationData$data = {
  readonly latestSync: {
    readonly outOfSync: boolean | null;
  } | null;
  readonly descendantDeviceInfo: {
    readonly pumpSettings?: {
      readonly dosingSettings: {
        readonly controlModes: {
          readonly analog: {
            readonly proportionalValidation: {
              readonly ruleViolations: ReadonlyArray<ProportionalDosingRuleViolations>;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly volumetricConcentrationAppSettings: ReadonlyArray<{
    readonly updatedAt: number | null;
    readonly name: string | null;
  } | null> | null;
  readonly latestDeviceData: {
    readonly latestSync: number | null;
    readonly dataPoints: ReadonlyArray<{
      readonly value: number | null;
      readonly name: string | null;
      readonly unit: string | null;
    } | null> | null;
    readonly buckets: ReadonlyArray<{
      readonly bucket: number;
      readonly value: number;
      readonly unit: string;
    }>;
  } | null;
  readonly volumetricConcentrationTimeSeriesDatapoints: ReadonlyArray<{
    readonly name: string;
    readonly unit: string | null;
    readonly datapoints: ReadonlyArray<{
      readonly valueRaw: number | null;
    }>;
  }>;
  readonly " $fragmentType": "VolumetricConcentrationData";
};
export type VolumetricConcentrationData$key = {
  readonly " $data"?: VolumetricConcentrationData$data;
  readonly " $fragmentSpreads": FragmentRefs<"VolumetricConcentrationData">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "endDate",
  "variableName": "endDate"
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endDate"
    },
    {
      "kind": "RootArgument",
      "name": "geniIdentity"
    },
    {
      "kind": "RootArgument",
      "name": "startDate"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "VolumetricConcentrationData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LatestSync",
      "kind": "LinkedField",
      "name": "latestSync",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "outOfSync",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "descendantDeviceInfo",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PumpSettingsDDAC",
              "kind": "LinkedField",
              "name": "pumpSettings",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DosingSettings",
                  "kind": "LinkedField",
                  "name": "dosingSettings",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ControlModes",
                      "kind": "LinkedField",
                      "name": "controlModes",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AnalogDosingSettings",
                          "kind": "LinkedField",
                          "name": "analog",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ProportionalValidation",
                              "kind": "LinkedField",
                              "name": "proportionalValidation",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "ruleViolations",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "DDAC",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "volumetricConcentrationAppSettings",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "settingSelector",
          "value": [
            {
              "name": "sdcs:VOLUMETIC_CONCENTRATION_TARGET"
            }
          ]
        }
      ],
      "concreteType": "AppSetting",
      "kind": "LinkedField",
      "name": "appSettings",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "updatedAt",
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LatestDeviceData",
      "kind": "LinkedField",
      "name": "latestDeviceData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latestSync",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DeviceData",
          "kind": "LinkedField",
          "name": "dataPoints",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            (v1/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BucketData",
          "kind": "LinkedField",
          "name": "buckets",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bucket",
              "storageKey": null
            },
            (v2/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "volumetricConcentrationTimeSeriesDatapoints",
      "args": [
        {
          "items": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "geniIdentity",
                  "variableName": "geniIdentity"
                },
                {
                  "kind": "Literal",
                  "name": "name",
                  "value": "sdcs:PROPORTIONAL_DOSING_ACTUAL_CONCENTRATION"
                },
                {
                  "kind": "Literal",
                  "name": "raw",
                  "value": true
                }
              ],
              "kind": "ObjectValue",
              "name": "datapointSelectors.0"
            }
          ],
          "kind": "ListValue",
          "name": "datapointSelectors"
        },
        (v0/*: any*/),
        {
          "kind": "Variable",
          "name": "startDate",
          "variableName": "startDate"
        }
      ],
      "concreteType": "TimeSeriesDatapoint",
      "kind": "LinkedField",
      "name": "timeSeriesDatapoints",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Datapoint",
          "kind": "LinkedField",
          "name": "datapoints",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "valueRaw",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};
})();

(node as any).hash = "9d1246ba4d2968a087066d2f2fd74871";

export default node;
