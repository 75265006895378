import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import Root from './components/Root/Root';
import 'react-loading-skeleton/dist/skeleton.css';
import '@reach/menu-button/styles.css';
import './i18n';

// We see a lot of noise from SE and E2E tests in LogRocket, so we disable it for those environments in order for the results to be useful and keep down costs.
if (process.env.NODE_ENV === 'production') {
  LogRocket.init('grsmbs/sdcs');
  setupLogRocketReact(LogRocket);
}

Sentry.init({
  dsn: process.env.GIC_UI_SENTRY_DSN,
  maxBreadcrumbs: 10,
  environment: process.env.GIC_UI_ENVIRONMENT,
  beforeSend: (event) => {
    if (LogRocket.sessionURL) {
      // eslint-disable-next-line no-param-reassign
      event.extra = { ...event.extra, LogRocket: LogRocket.sessionURL };
    }
    return event;
  },
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(React.createElement(React.StrictMode, null, React.createElement(Root)));
