import React, { FunctionComponent } from 'react';
import { TooltipProps } from './types';
import useContentFlowMeasurement from './useContentFlowMeasurement';
import BaseTooltip from './BaseTooltip';

const Tooltip: FunctionComponent<React.PropsWithChildren<TooltipProps>> = ({
  children,
  content,
  size = 'small',
  placement = 'left',
  centeredText = false,
  initAsOpen = false,
  trigger = 'mouse-enter',
  onClose,
  onOpen,
  visible = true,
}) => {
  const { ref, contentFlow } = useContentFlowMeasurement();
  return (
    <BaseTooltip
      content={content}
      initAsOpen={initAsOpen}
      size={size}
      placement={placement}
      trigger={trigger}
      contentFlow={contentFlow}
      toolTipRef={ref}
      centeredText={centeredText}
      onClose={onClose}
      onOpen={onOpen}
      visible={visible}
    >
      {children}
    </BaseTooltip>
  );
};

export default Tooltip;
