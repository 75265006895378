/**
 * @generated SignedSource<<1ca9c9a053d3712a446287ddf810a455>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useInfoContainerData_device$data = {
  readonly gatewayIMEI: string | null;
  readonly name: string;
  readonly customerName: string | null;
  readonly qrCode: string | null;
  readonly location: {
    readonly name: string | null;
    readonly street: string | null;
    readonly country: string | null;
    readonly postalCode: string | null;
    readonly city: string | null;
    readonly geoLocation?: string | null;
    readonly commissioningData?: {
      readonly commissioningDate: string | null;
      readonly commissioningEngineer: string | null;
      readonly commissioningNote: string | null;
    };
  } | null;
  readonly " $fragmentType": "useInfoContainerData_device";
};
export type useInfoContainerData_device$key = {
  readonly " $data"?: useInfoContainerData_device$data;
  readonly " $fragmentSpreads": FragmentRefs<"useInfoContainerData_device">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useInfoContainerData_device",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gatewayIMEI",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "qrCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "location",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "street",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "country",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "postalCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "geoLocation",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CommissioningData",
              "kind": "LinkedField",
              "name": "commissioningData",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "commissioningDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "commissioningEngineer",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "commissioningNote",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Installation",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};
})();

(node as any).hash = "71d733f6e1e22290e00f6631d2a62ad9";

export default node;
