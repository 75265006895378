import React from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { notEmpty } from '../../../../utils/type-guards/array';
import { SectionList_sections$key } from './__generated__/SectionList_sections.graphql';
import SectionListItem from './SectionListItem/SectionListItem';
import { SectionListProps } from './types';

const SectionList: React.FC<SectionListProps> = (props) => {
  const sections = useFragment<SectionList_sections$key>(
    graphql`
      fragment SectionList_sections on LocationConnection {
        edges {
          node {
            uuid
            ...SectionListItem_section
          }
        }
      }
    `,
    // eslint-disable-next-line react/destructuring-assignment
    props.sections,
  );

  const nodes = sections?.edges
    ?.filter(notEmpty)
    .map((edge) => edge.node)
    .filter(notEmpty);

  if (nodes?.length === 0) return null;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {nodes?.map((node) => (
        <SectionListItem key={`section-list-item-${node.uuid}`} section={node} />
      ))}
    </>
  );
};

export default SectionList;
