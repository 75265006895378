import { css, SerializedStyles, Theme } from '@emotion/react';
import dsmMediaQuery from '../../../utils/media-queries/media-queries';
import { paragraphBaseStyles } from '../../../styles';

const showMoreButtonStyles = (theme: Theme): SerializedStyles => css`
  border: none;
  background: initial;
  color: ${theme.colors.tertiaryText};
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;
  cursor: pointer;

  :focus {
    outline: none;
  }
`;

const showMoreStyles = css`
  margin-top: 1.7rem;
  text-align: center;

  ${dsmMediaQuery('medium device')} {
    text-align: left;
  }
`;

const loadingMoreStyles = css`
  padding-top: 2rem;
`;

const eventlistHeaderContainerStyles = css`
  display: contents;
`;

const eventListHeaderStyles = (theme: Theme) => css`
  background: ${theme.colors.neutral.neutral800.hex};
  padding: 0.25rem 1rem;
  height: 1.75rem;
  display: flex;
  align-items: center;
`;

const eventListContainerStyles = css`
  display: grid;
  grid-template-columns: max-content repeat(4, 1fr) max-content;
  grid-row-gap: 0.5em;
  grid-template-rows: 1.75rem;
  grid-auto-rows: 3rem;
  overflow:auto;
}
`;

const linkStyles = (theme: Theme) => css`
  ${paragraphBaseStyles}
  color:${theme.colors.tertiaryText};
  font-weight: 700;
  line-height: 1.25rem;
  text-transform: uppercase;
  :hover {
    padding-bottom: 0;
    border-bottom: 0.05rem solid ${theme.colors.tertiaryText};
  }
`;

const itemStyles = css`
  display: flex;
  align-items: center;
  curser: default;
  justify-items: space-between;
  height: 3rem;
  padding: 0.5rem 1rem;
`;

const alertItemHoverStyles = (isHovered: boolean, theme: Theme) => css`
  background-color: ${isHovered ? '#2F3336' : theme?.colors?.neutral.neutral1000.hex};
`;

const warningAndAlarmStyles = css`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const lastItemStyles = css`
  display: flex;
  justify-content: end;
`;

export {
  showMoreButtonStyles,
  showMoreStyles,
  loadingMoreStyles,
  eventlistHeaderContainerStyles,
  eventListHeaderStyles,
  eventListContainerStyles,
  linkStyles,
  itemStyles,
  alertItemHoverStyles,
  warningAndAlarmStyles,
  lastItemStyles,
};
