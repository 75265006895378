import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Waves, ErrorBoundary, ErrorFallback } from '../../components';
import Onboarding from './Onboarding';
import sentryCaptureError from '../../utils/sentry/sentry-capture-error';
import CorrelatedError from '../../utils/errors/CorrelatedError';
import NotFoundErrorFallback from './Errors/NotFoundErrorFallback';
import { onboardingContainerStyles, wavesStyles } from './styles';

const OnboardingWrapper: React.FC<{ claimId: string }> = ({ claimId }) => (
  <div css={onboardingContainerStyles}>
    <ErrorBoundary
      onError={(error): void => sentryCaptureError(error, 'Onboarding')}
      // eslint-disable-next-line react/no-unstable-nested-components
      FallbackComponent={({ error, resetErrorBoundary }) => {
        const err = (error as CorrelatedError).errors[0];
        switch (err.extensions.code) {
          case 'NOT_FOUND':
            return <NotFoundErrorFallback error={error as CorrelatedError} resetErrorBoundary={resetErrorBoundary} />;
          default:
            return <ErrorFallback error={error} resetErrorBoundary={resetErrorBoundary} />;
        }
      }}
    >
      <Onboarding claimId={claimId} />
    </ErrorBoundary>
    <Waves styles={wavesStyles} />
  </div>
);

const CanOnboard: React.FC = () => {
  const { state } = useLocation();
  const claimIdState = (state as { claimId: string })?.claimId;
  if (!claimIdState) {
    return <Navigate replace to="/" />;
  }

  return <OnboardingWrapper claimId={claimIdState as string} />;
};

export default CanOnboard;
