/**
 * @generated SignedSource<<64dbeada3524d09f6426b28a58839687>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TimeToService_device$data = {
  readonly latestSync: {
    readonly outOfSync: boolean | null;
  } | null;
  readonly staticdata: ReadonlyArray<{
    readonly code: string | null;
    readonly value: string | null;
  } | null> | null;
  readonly timeToServiceDataPoint: ReadonlyArray<{
    readonly name: string;
    readonly latestDatapoint: {
      readonly valueRaw: number | null;
    };
  }>;
  readonly " $fragmentType": "TimeToService_device";
};
export type TimeToService_device$key = {
  readonly " $data"?: TimeToService_device$data;
  readonly " $fragmentSpreads": FragmentRefs<"TimeToService_device">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "endDate"
    },
    {
      "kind": "RootArgument",
      "name": "geniIdentity"
    },
    {
      "kind": "RootArgument",
      "name": "startDate"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "TimeToService_device",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LatestSync",
      "kind": "LinkedField",
      "name": "latestSync",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "outOfSync",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Metadata",
      "kind": "LinkedField",
      "name": "staticdata",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "timeToServiceDataPoint",
      "args": [
        {
          "items": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "geniIdentity",
                  "variableName": "geniIdentity"
                },
                {
                  "kind": "Literal",
                  "name": "name",
                  "value": "sdcs:TIME_NEXT_SERVICE"
                },
                {
                  "kind": "Literal",
                  "name": "raw",
                  "value": true
                }
              ],
              "kind": "ObjectValue",
              "name": "datapointSelectors.0"
            }
          ],
          "kind": "ListValue",
          "name": "datapointSelectors"
        },
        {
          "kind": "Variable",
          "name": "endDate",
          "variableName": "endDate"
        },
        {
          "kind": "Variable",
          "name": "startDate",
          "variableName": "startDate"
        }
      ],
      "concreteType": "TimeSeriesDatapoint",
      "kind": "LinkedField",
      "name": "timeSeriesDatapoints",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Datapoint",
          "kind": "LinkedField",
          "name": "latestDatapoint",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "valueRaw",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};

(node as any).hash = "3f763dff44c54078236ae33ac9b1692d";

export default node;
