import React from 'react';
import { useTranslation } from 'react-i18next';
import { getFormValuesKey } from '../types';
import { TextInputField } from '../../../../../components';
import { textInputStyles } from './styles';

const MaxCapacity: React.FC<{ unit: string | null }> = ({ unit }) => {
  const { t } = useTranslation();
  return (
    <TextInputField
      testId="maximum-capacity"
      css={[textInputStyles]}
      type="number"
      step="any"
      name={getFormValuesKey('maxCapacity')}
      label={`${t('WP.REMOTE.MAXIMUM_CAPACITY')} ${unit && `(${unit})`}`}
      skipTouchedCheck
    />
  );
};

export default MaxCapacity;
