import React from 'react';
import { BackLight, StoppedBy } from '../../Overview/OverviewChemPairing/PumpStatus/Fields';
import ControlMode from './Fields/ControlMode';
import { PumpStatusProps } from './types';
import PumpStatusContainer from '../../Overview/OverviewChemPairing/PumpStatus/PumpStatusContainer';
import NoPumpStatusData from '../../Overview/OverviewChemPairing/PumpStatus/NoPumpStatusData';
import { PumpRunning, RemoteControlStatus, PumpDisplayStatus } from './Fields';

const PumpStatus: React.FC<PumpStatusProps> = ({
  backlight,
  pumpStatus,
  geniBusControl,
  stoppedBy,
  pumpState,
  controlMode,
  containerStyle,
}) => {
  if (controlMode === undefined || pumpStatus === undefined) {
    return <NoPumpStatusData />;
  }
  return (
    <PumpStatusContainer containerStyle={containerStyle}>
      <ControlMode controlMode={controlMode} />
      <BackLight backlight={backlight || 'transparent'} />
      <PumpDisplayStatus pumpStatus={pumpStatus} />
      <PumpRunning pumpState={pumpState} />
      <StoppedBy stoppedBy={stoppedBy} />
      <RemoteControlStatus geniBusControl={geniBusControl} />
    </PumpStatusContainer>
  );
};

export default PumpStatus;
