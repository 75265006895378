import React, { PropsWithChildren, useEffect } from 'react';
import { ErrorBoundary, ErrorBoundaryProps } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';

type Listener = (location: unknown, action: unknown) => void;

interface History {
  listen: (cb: Listener) => void;
}

const useHistory = (): History => {
  const listeners: Listener[] = [];
  const result: History = {
    listen: (cb) => listeners.push(cb),
  };

  const location = useLocation();

  useEffect(() => {
    listeners.forEach((cb) => cb.call(null, location, undefined));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return result;
};

const withHistory = (Component: typeof ErrorBoundary): React.FC<PropsWithChildren<ErrorBoundaryProps>> => {
  const WrappedComponent: React.FC<PropsWithChildren<any>> = (props) => {
    const history = useHistory();

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...props} history={history} />;
  };

  return WrappedComponent;
};

// Extending React class components using inheritance is a BIG anti-pattern, but in this case
// we need it to be able to extend the ErrorBoundary component from react-error-boundary
// Alternatively we do our own implementaion or improve the existing one
class ReactRouterErrorBoundary extends ErrorBoundary {
  componentDidMount(): void {
    (this.props as any).history.listen((location: unknown, action: unknown) => {
      if ((this.state as any).error) {
        (this as any).resetErrorBoundary();
      }
    });
  }
}

export default withHistory(ReactRouterErrorBoundary);
