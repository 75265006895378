import { css, SerializedStyles, Theme } from '@emotion/react';
import dsmMediaQuery from '../../../../utils/media-queries/media-queries';

const pageContentStyles = css`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content auto;
`;

const tilesContainerStyles = css`
  grid-column: 1/-1;
  grid-row: 2/2;
  display: grid;
  grid-template-rows: max-content max-content 1fr;
  padding: 1.2rem 0;

  ${dsmMediaQuery('medium device')} {
    grid-row: unset;
  }
`;

const overviewTileStyles = css`
  min-height: unset;

  ${dsmMediaQuery('medium device')} {
    grid-column: 2 / span 1;
    grid-row: 1 / span 1;
  }

  ${dsmMediaQuery('large device')} {
    display: flex;
    grid-template-rows: 1fr;
    padding: 0;
    grid-column: 2 / span 2;
    grid-row: 1 / span 1;
  }
`;

const overviewTileContent = css`
  ${dsmMediaQuery('large device')} {
    width: 100%;
    padding: 1.2rem;
    display: grid;
    grid-template-rows: max-content;
  }
`;

const overviewTileBody = css`
  grid-row-gap: 0.8rem;
  margin-top: 1.5rem;
  > div {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    :not(:last-child) {
      border-bottom: 2px solid #454647;
      padding-bottom: 0.8rem;
    }
  }
`;

const overviewTileBodyTextStyles = css`
  text-align: end;
  color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
  font-weight: 600;
`;

const overviewEventStyles = css`
  border-bottom: unset;
  margin-top: unset;
  display: grid;
  justify-items: end;
`;

const overviewEventSpanStyles = css`
  display: grid;
  align-items: center;
  grid-template-columns: 1rem max-content;
  grid-gap: 0.25rem;
`;

const overviewTileImgStyles = css`
  display: none;

  ${dsmMediaQuery('medium device')} {
    width: 100%;
    display: grid;
  }
`;

const hideDeviceImgStyles = css`
  grid-column: 1/-1;
  grid-row: 1/1;
  margin-left: -1.5rem;
  margin-right: -1.5rem;

  ${dsmMediaQuery('medium device')} {
    display: none;
  }
`;

const imgTileStyles = css`
  padding: unset;
  background-color: unset;
  min-height: unset;
  grid-template-rows: 1fr;
`;

const navStyles = css`
  padding: 2rem 0;
  border-top: 0.125rem solid #454647;
`;

const navItemStyles = (theme: Theme): SerializedStyles => css`
  text-decoration: none;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  margin-right: 2.2rem;
  font-weight: 600;
  &:hover,
  &.active {
    padding-bottom: 0.2rem;
    color: ${theme.colors.secondaryText};
    border-bottom: 0.2rem solid #0068b4;
  }
`;

const pageHeaderStyles = css`
  grid-column: 1/-1;
  grid-row: 1/1;
  z-index: 5;
  padding: 1.5rem 0;

  ${dsmMediaQuery('medium device')} {
    padding-top: 2.2rem;
  }
`;

export {
  pageContentStyles,
  navStyles,
  hideDeviceImgStyles,
  navItemStyles,
  overviewTileBodyTextStyles,
  overviewEventStyles,
  overviewEventSpanStyles,
  overviewTileBody,
  overviewTileImgStyles,
  overviewTileContent,
  overviewTileStyles,
  tilesContainerStyles,
  imgTileStyles,
  pageHeaderStyles,
};
