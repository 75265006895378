import React from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { notEmpty } from '../../../../utils/type-guards/array';
import { DeviceList_devices$key } from './__generated__/DeviceList_devices.graphql';
import DeviceListItem from './DeviceListItem/DeviceListItem';
import { DeviceListProps } from './types';

const DeviceList: React.FC<DeviceListProps> = (props) => {
  const devices = useFragment<DeviceList_devices$key>(
    graphql`
      fragment DeviceList_devices on DeviceConnection {
        edges {
          node {
            uuid
            ...DeviceListItem_device
          }
        }
      }
    `,
    // eslint-disable-next-line react/destructuring-assignment
    props.devices,
  );

  const nodes = devices?.edges
    ?.filter(notEmpty)
    .map((edge) => edge.node)
    .filter(notEmpty);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {nodes?.map((node) => (
        <DeviceListItem key={`installation-asset-list-item-${node.uuid}`} device={node} />
      ))}
    </>
  );
};

export default DeviceList;
