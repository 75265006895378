import React, { useState } from 'react';
import { Button } from '../../components';
import { fetchCsvReport } from './csvService';
import { PumpType } from '../types';

type DownloadButtonProps = {
  fileName: string;
  report: 'EVENTS' | 'TRENDS';
  twinId?: string | null;
  deviceId?: string | null;
  accountId: string;
  Idle: JSX.Element[] | JSX.Element | string;
  Downloading: JSX.Element[] | JSX.Element | string;
  pumpType: PumpType;
};

const CsvDownloadButton: React.FC<DownloadButtonProps> = ({
  fileName,
  report,
  twinId,
  deviceId,
  accountId,
  Idle,
  Downloading,
  pumpType,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownload = () => {
    if (isLoading) return;
    setIsLoading(true);
    fetchCsvReport(report, accountId, pumpType, twinId, deviceId)
      .then((blob) => {
        // Create a URL for the file blob
        const downloadUrl = URL.createObjectURL(blob);

        // Create an a element with the download URL
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        // Dispatch a click event on the link element
        link.click();
        // Remove the link element from the document
        document.body.removeChild(link);
        // Release the URL
        URL.revokeObjectURL(downloadUrl);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Button variant="primary" type="button" onClick={handleDownload} disabled={isLoading}>
      {isLoading ? Downloading : Idle}
    </Button>
  );
};

export default CsvDownloadButton;
