/**
 * @generated SignedSource<<758d87b234ee1ac2525b961cdd1f6f86>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MainNavigation_account$data = {
  readonly locations: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: string;
        readonly uuid: string;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "MainNavigation_account";
};
export type MainNavigation_account$key = {
  readonly " $data"?: MainNavigation_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"MainNavigation_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MainNavigation_account",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "typeCodes",
          "value": [
            "INSTALLATION",
            "SECTION"
          ]
        }
      ],
      "concreteType": "LocationConnection",
      "kind": "LinkedField",
      "name": "locations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LocationEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "uuid",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "locations(typeCodes:[\"INSTALLATION\",\"SECTION\"])"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "843a2910dcdce52607bd6df19c01a3cc";

export default node;
