export interface ProfileFormValues {
  unitSystem: string;
  iso6391: string;
}

export interface ProfileProps {
  firstName: string;
  lastName: string;
  unitSystemName: string;
  isLoading: boolean;
  onSubmit: (values: ProfileFormValues) => void;
  languageIso6391: string;
}

// eslint-disable-next-line no-shadow
export enum UnitSystem {
  EU = 'EU',
  US = 'US',
}
