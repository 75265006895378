import { AuthError, AuthenticationResult, InteractionRequiredAuthError } from '@azure/msal-browser';
import { jwtDecode } from 'jwt-decode';
import msalPublicClientApplication from './msalPublicClientApplication';
import { RefreshScopes } from '../../config/oidc-settings';

const REFRESH_TOKEN_BUFFER = 300; // 5 minutes in seconds

const getAuthenticationResult = async (): Promise<AuthenticationResult | undefined> => {
  const account = msalPublicClientApplication.getAllAccounts()[0];
  const scopes = RefreshScopes;

  try {
    return await msalPublicClientApplication.acquireTokenSilent({ scopes, account });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.debug(`could not get auth token.`);
    await msalPublicClientApplication.acquireTokenRedirect({ scopes });
  }
};

const refreshTokenSilently = async (): Promise<void> => {
  const account = msalPublicClientApplication.getAllAccounts()[0];
  const scopes = RefreshScopes;

  if (!account) {
    // eslint-disable-next-line no-console
    console.debug('redirecting, no account found');
    return msalPublicClientApplication.acquireTokenRedirect({ scopes });
  }

  try {
    await msalPublicClientApplication.acquireTokenSilent({
      scopes,
      account,
      forceRefresh: true,
    });
  } catch (err) {
    if (err instanceof AuthError) {
      // eslint-disable-next-line no-console
      console.debug(`silent refresh error occurred`, { err });
      if (err instanceof InteractionRequiredAuthError) {
        // eslint-disable-next-line no-console
        console.debug(`silent refresh error instanceof InteractionRequiredAuthError, redirecting`);
        // fallback to interaction when silent call fails
        return msalPublicClientApplication.acquireTokenRedirect({ scopes });
      }
    }
    // eslint-disable-next-line no-console
    console.debug(`silent refresh error WAS NOT instanceof AuthError`, err);
  }

  return Promise.resolve();
};

const acquireAccessToken = async (): Promise<string> => (await getAuthenticationResult())?.accessToken ?? '';

const accessTokenIsExpired = async (): Promise<boolean> => {
  const currentTime = Date.now();
  const tokenExpiration = jwtDecode<{ exp: number }>(await acquireAccessToken()).exp;
  const diffInSeconds = tokenExpiration - Math.floor(currentTime / 1000);

  return diffInSeconds <= REFRESH_TOKEN_BUFFER;
};

const getAccessToken = async (): Promise<string> => {
  // Fix for "Cannot update a component (MsalProvider)" - https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/5796#issuecomment-1763461620
  await Promise.resolve();

  if (await accessTokenIsExpired()) {
    await refreshTokenSilently();
  }

  return acquireAccessToken();
};

export default getAccessToken;
