import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Divider, InfoItem } from '../../../../../components';
import { AnalogSettings, FormValues } from '../types';

interface AnalogProportionalFormProps {
  analogSettings: AnalogSettings;
}

const AnalogProportionalForm: React.FC<AnalogProportionalFormProps> = ({ analogSettings }) => {
  const { t } = useTranslation();
  const { values: formValues } = useFormikContext<FormValues>();
  const { higherInput, lowerInput } = analogSettings;
  return (
    <>
      <InfoItem
        noBorder
        className="info-item"
        testId="analog-input-lower"
        label={t('WP.REMOTE.ANALOG_DDA_LOWER_MA')}
        value={formValues.analogInputLower}
        unit={lowerInput.electricCurrent.unit}
      />
      <Divider marginLeft="small" />
      <InfoItem
        noBorder
        className="info-item"
        testId="analog-dosing-lower"
        label={t('WP.REMOTE.ANALOG_DDA_LOWER_DOSING_FLOW')}
        value={formValues.analogDosingFlowLower}
        unit={lowerInput.dosingVolume.unit}
      />
      <Divider marginLeft="small" />
      <InfoItem
        noBorder
        className="info-item"
        testId="analog-input-higher"
        label={t('WP.REMOTE.ANALOG_DDA_HIGHER_MA')}
        value={formValues.analogInputHigher}
        unit={higherInput.electricCurrent.unit}
      />
      <Divider marginLeft="small" />
    </>
  );
};
export default AnalogProportionalForm;
