import React from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { EventItemProps } from './types';
import { useEventCodeTranslations } from '../../../utils/mappings/event-code-translations';
// eslint-disable-next-line camelcase
import { EventListItemGateway_item$key } from './__generated__/EventListItemGateway_item.graphql';
import { useAccount } from '../../../providers/AccountProvider';
import EventListItem from './EventListItem';

const EventListItemGateway: React.FC<EventItemProps> = ({ event }) => {
  // eslint-disable-next-line camelcase
  const eventItem = useFragment<EventListItemGateway_item$key>(
    graphql`
      fragment EventListItemGateway_item on Event {
        createdAt
        device {
          uuid
          location {
            ... on Installation {
              installationName: name
              parentLocation {
                ... on Section {
                  customerName: name
                }
              }
            }
          }
          deviceInfo {
            type # Expect Gateway
          }
          descendantDeviceInfo {
            type # Expect DDA / DDAC
          }
        }
        type
        code
      }
    `,
    event,
  );
  const code = useEventCodeTranslations(eventItem?.code);
  const {
    account: { uuid: accountId },
  } = useAccount();

  if (!eventItem) return null;
  const { type, createdAt, device } = eventItem;

  if (device?.deviceInfo?.type !== 'Gateway') throw Error('Device is not a gateway / communication device');

  const redirectLink = `/account/${accountId}/gateways/${device?.uuid}/${device?.descendantDeviceInfo?.type}`;
  return (
    <EventListItem
      type={type}
      createdAt={createdAt}
      customerName={device?.location?.parentLocation?.customerName || ''}
      installationName={device?.location?.installationName}
      initiatorTranslationKey="WP.ALERTS.GRID_ITEM_DOSING_PUMP"
      redirectLink={redirectLink}
      alertCode={code}
    />
  );
};

export default EventListItemGateway;
