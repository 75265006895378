import React from 'react';
import { dataBoxStyles, dataBoxTextContainerStyles, topPadding } from '../styles';
import { heading05Styles, labelStyles } from '../../../../../styles';

const DataBox = ({ label, value, unit }: { label: string; value?: number | string | null; unit?: string | null }) => (
  <div css={topPadding}>
    <div css={dataBoxStyles}>
      <p css={labelStyles}>{label}</p>
      <div css={[heading05Styles, dataBoxTextContainerStyles]}>
        <span>{value}</span>
        <span>{unit}</span>
      </div>
    </div>
  </div>
);

export default DataBox;
