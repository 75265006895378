import { useTheme } from '@emotion/react';
import React from 'react';
import { inactiveLabelStyles, paragraphBaseStyles, paragraphBrightStyles } from '../../styles';
import {
  containerStyles,
  iconValueUnitGroupStyles,
  labelMultiLineSupport,
  multiLineSupport,
  textTruncateStyles,
} from './styles';

interface InfoItemProps {
  label: string | JSX.Element;
  Icon?: JSX.Element;
  value?: string | number | null | JSX.Element;
  unit?: string | null;
  className?: string;
  noBorder?: boolean;
  multiLine?: boolean;
  inActive?: boolean;
  testId?: string;
  labelMultiline?: boolean;
}

export const InfoItemIcon = ({ iconSrc }: { iconSrc: string }) => <img height="16" src={iconSrc} alt="icon" />;

const InfoItem = ({
  label,
  value,
  Icon,
  unit,
  className,
  inActive = false,
  noBorder = false,
  testId,
  multiLine = false,
  labelMultiline = false,
}: InfoItemProps) => {
  const theme = useTheme();
  return (
    <div
      data-testid={testId}
      className={className}
      css={[containerStyles(theme, noBorder), inActive ? inactiveLabelStyles : paragraphBrightStyles]}
    >
      <div
        className={`${className}-label`}
        data-testid={`${testId}-label`}
        css={[labelMultiline ? labelMultiLineSupport : textTruncateStyles, paragraphBaseStyles]}
      >
        {label}
      </div>
      <div css={[iconValueUnitGroupStyles(!!value), inActive ? inactiveLabelStyles : paragraphBrightStyles]}>
        {Icon}
        <div
          className={`${className}-value`}
          data-testid={`${testId}-value`}
          css={[multiLine ? multiLineSupport : undefined, paragraphBaseStyles]}
        >
          {value}
        </div>
        {unit && <div data-testid={`${testId}-unit`}>{unit}</div>}
      </div>
    </div>
  );
};

export default InfoItem;
