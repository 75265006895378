/**
 * @generated SignedSource<<57b9855062acb4b7cbaf0a03b35d14a3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type DeviceInfoType = "DDA" | "DDAC" | "Gateway" | "VirtualSensor";
export type EventCode = "CAVITATION" | "LOW_PRESSURE" | "AIR_BUBBLES" | "OVERPRESSURE" | "PREASSURE_VALVE_LEAKAGE" | "SUCTION_VALVE_LEAKAGE" | "SERVICE_NOW" | "SERVICE_SOON" | "UNDERDOSING" | "DIAPHRAGM_BREAK" | "BLOCKED_MOTOR" | "PREEMPTY_CONTAINER_FLOATER_SWITCH" | "PREEMPTY_CONTAINER_CALCULATED" | "EMPTY_CONTAINER" | "PRESSURE_SENSOR_OR_CABLE_BREAK" | "ANALOGUE_INPUT_OR_CABLE_BREAK" | "MAIN_NETWORK_COMMUNICATION_FAULT" | "CIM_HEARTBEAT_TIMEOUT" | "POWER_IN_FAIL";
export type EventType = "ALARM" | "WARNING";
import { FragmentRefs } from "relay-runtime";
export type DeviceEvents_device$data = {
  readonly deviceTwinId: string | null;
  readonly uuid: string;
  readonly eventsTab: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: string;
        readonly id: string;
        readonly alertCorrelationId: string;
        readonly createdAt: number;
        readonly type: EventType;
        readonly code: EventCode;
        readonly device: {
          readonly deviceInfo: {
            readonly type: DeviceInfoType;
          } | null;
        };
      } | null;
    } | null> | null;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "DeviceEvents_device";
};
export type DeviceEvents_device$key = {
  readonly " $data"?: DeviceEvents_device$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceEvents_device">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "eventsTab"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "count"
    },
    {
      "kind": "RootArgument",
      "name": "cursor"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./DeviceEventsPaginationQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "DeviceEvents_device",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deviceTwinId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": "eventsTab",
      "args": null,
      "concreteType": "EventConnection",
      "kind": "LinkedField",
      "name": "__PaginationQuery_eventsTab_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EventEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Event",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "alertCorrelationId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdAt",
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "code",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Device",
                  "kind": "LinkedField",
                  "name": "device",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "deviceInfo",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/)
  ],
  "type": "Device",
  "abstractKey": null
};
})();

(node as any).hash = "e2c58723c44a92358d6d44eb2ac49593";

export default node;
