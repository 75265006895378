import { css, SerializedStyles, Theme } from '@emotion/react';

const tileBodyStyles = css`
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
`;

const linkButtonStyle = (theme: Theme): SerializedStyles => css`
  color: ${theme.colors.secondaryText};
  text-decoration: none;
  text-transform: none;
  font-size: 0.9rem;
  font-weight: 400;
  &:hover {
    text-decoration: underline;
  }
`;

const warningTileStyles = () => css`
  display: grid;
  justify-content: center;
`;

const warningMotorTextStyles = (theme: Theme): SerializedStyles => css`
  color: ${theme.colors.secondaryText};
  padding-top: 1rem;
  font-size: 1rem;
  font-weight: 600;
`;

const skeletonContainer = css`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .timeToServiceSkeleton {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const timeToServiceProgressStyles = (theme: Theme) => css`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  color: ${theme.colors.neutral.neutral400.hex};
`;

export {
  tileBodyStyles,
  linkButtonStyle,
  warningMotorTextStyles,
  skeletonContainer,
  warningTileStyles,
  timeToServiceProgressStyles,
};
