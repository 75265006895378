import React from 'react';
import { useTranslation } from 'react-i18next';
import { convertToSuperScript } from '../../../utils';
import { PROPORTIONAL_CONCENTRATION_TARGET } from '../../../../../../translations-keys';
import DataBox from './Databox';
import { ProportionalConcentrationTargetProps } from './types';
import DataBoxInfoText from './DataboxInfoText';

const ProportionalConcentrationTarget: React.FC<ProportionalConcentrationTargetProps> = ({
  targetProportionalConcentration,
  proportionalRuleValidation,
}) => {
  const { t } = useTranslation();
  const hasProportionalRuleViolation = (proportionalRuleValidation?.ruleViolations?.length || 0) > 0;

  if (hasProportionalRuleViolation)
    return (
      <DataBoxInfoText
        label={t(PROPORTIONAL_CONCENTRATION_TARGET)}
        value={t('WP.MORE.DATA.PROPORTIONAL_RULE_VIOLATION')}
      />
    );

  return (
    <DataBox
      label={t(PROPORTIONAL_CONCENTRATION_TARGET)}
      value={targetProportionalConcentration?.quantity?.toFixed(1)}
      unit={convertToSuperScript(targetProportionalConcentration?.unit || '')}
    />
  );
};

export default ProportionalConcentrationTarget;
