import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReadOnlyInfoItem } from '../components';
import { AnalogReadFieldsProps } from './types';
import { formFieldsStyles } from './styles';
import { Divider } from '../../../../../components';
import { AnalogRuleInfoToolTip } from '../../components';

const AnalogReadFields: React.FC<AnalogReadFieldsProps> = ({ analog }) => {
  const { t } = useTranslation();
  const { lowerInput, higherInput, proportionalValidation } = analog;

  return (
    <div css={formFieldsStyles}>
      <ReadOnlyInfoItem
        className="info-item"
        hideDivider
        label={t('WP.REMOTE.ANALOG_DDA_LOWER_MA')}
        value={
          <AnalogRuleInfoToolTip
            proportionalRuleValidation={proportionalValidation}
            macthRuleViolations={['ZeroToTwentyLowerInputCurrent', 'FourToTwentyLowerInputCurrent']}
          >
            {lowerInput.electricCurrent.quantity?.toFixed(2)}
          </AnalogRuleInfoToolTip>
        }
        unit={lowerInput.electricCurrent.unit}
        testId="analog-dosing-read-lower-ma"
      />
      <Divider marginLeft="small" />
      <ReadOnlyInfoItem
        className="info-item"
        hideDivider
        label={t('WP.REMOTE.ANALOG_DDA_LOWER_DOSING_FLOW')}
        value={
          <AnalogRuleInfoToolTip
            proportionalRuleValidation={proportionalValidation}
            macthRuleViolations={['LowerInputCurrentFlow']}
          >
            {lowerInput.dosingVolume.quantity?.toFixed(4)}
          </AnalogRuleInfoToolTip>
        }
        unit={lowerInput.dosingVolume.unit}
        testId="analog-dosing-read-lower-flow"
      />
      <Divider marginLeft="small" />
      <ReadOnlyInfoItem
        className="info-item"
        hideDivider
        label={t('WP.REMOTE.ANALOG_DDA_HIGHER_MA')}
        value={
          <AnalogRuleInfoToolTip
            proportionalRuleValidation={proportionalValidation}
            macthRuleViolations={['UpperInputCurrent']}
          >
            {higherInput.electricCurrent.quantity?.toFixed(2)}
          </AnalogRuleInfoToolTip>
        }
        unit={higherInput.electricCurrent.unit}
        testId="analog-dosing-read-higher-ma"
      />
      <Divider marginLeft="small" />
      <ReadOnlyInfoItem
        className="info-item"
        hideDivider
        label={t('WP.REMOTE.ANALOG_DDA_HIGHER_DOSING_FLOW')}
        value={higherInput.dosingVolume.quantity?.toFixed(4)}
        unit={higherInput.dosingVolume.unit}
        testId="analog-dosing-read-higher-ma"
      />
      <Divider marginLeft="small" />
    </div>
  );
};

export default AnalogReadFields;
