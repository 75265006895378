import React, { useContext } from 'react';
import { format, fromUnixTime } from 'date-fns';
import { DateTimeFormatterProps } from './types';
import { LocaleContext } from '../../providers/LocaleProvider/LocaleProvider';

const DateTimeFormatter: React.FC<DateTimeFormatterProps> = ({ timestamp }) => {
  const { shortDateTimeFormat } = useContext(LocaleContext);
  if (!timestamp) return null;

  if (typeof timestamp === 'string') {
    try {
      const date = new Date(timestamp);
      return <>{format(date, shortDateTimeFormat.dateFnsDateTimeFormat)}</>;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      return null;
    }
  }
  if (typeof timestamp === 'number') {
    return <>{format(fromUnixTime(timestamp), shortDateTimeFormat.dateFnsDateTimeFormat)}</>;
  }
  return null;
};

export default DateTimeFormatter;
