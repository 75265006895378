import React from 'react';
import { useTranslation } from 'react-i18next';
import { getFormValuesKey } from '../types';
import { Divider, TextInputField } from '../../../../../components';
import { formFieldsStyles, textInputStyles } from './styles';

const ManualDosing: React.FC<{ manualDosingFlowUnit: string | null }> = ({ manualDosingFlowUnit }) => {
  const { t } = useTranslation();
  return (
    <div data-testid="manual-dosing-form" css={formFieldsStyles}>
      <TextInputField
        testId="manual-dosing-flow"
        css={[textInputStyles]}
        type="number"
        step="any"
        name={getFormValuesKey('manualDosingFlowValue')}
        label={`${t('WP.REMOTE.MANUAL_DOSING_FLOW')} ${manualDosingFlowUnit && `(${manualDosingFlowUnit})`}`}
        skipTouchedCheck
      />
      <Divider marginLeft="small" />
    </div>
  );
};

export default ManualDosing;
