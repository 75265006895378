import React, { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyLoadQuery, graphql } from 'react-relay/hooks';
import { toGlobalId } from 'graphql-relay';
import { Loader } from '../../../components';
import usePageViewed from '../../../adobe-launch/hooks/usePageViewed';
import { PageContent } from '../components';
import { SectionQuery } from './__generated__/SectionQuery.graphql';
import { childrenRoutesContainer } from './styles';
import Assets from './Assets/SectionAssets';

const Section: React.FC<{}> = () => {
  usePageViewed('Section');

  const { id } = useParams<{ id: string }>();

  const { node } = useLazyLoadQuery<SectionQuery>(
    graphql`
      query SectionQuery($id: ID!) {
        node(id: $id) {
          ... on Section {
            ...PageContent_location
            ...SectionAssets_section
          }
        }
      }
    `,
    { id: toGlobalId('Section', id ?? '') },
  );

  return (
    <PageContent location={node}>
      <div css={childrenRoutesContainer}>
        <Suspense fallback={<Loader />}>
          <Assets section={node!} />
        </Suspense>
      </div>
    </PageContent>
  );
};

export default Section;
