import React from 'react';
import { useTranslation } from 'react-i18next';
import { gridPlacement } from '../styles';
import { InfoItem, InfoItemIcon } from '../../../../../components';
import operationUpdate from '../../../../../assets/images/operationUpdate.svg';

const PumpRunning = ({ pumpState }: { pumpState: 'YES' | 'NO' | undefined | null }) => {
  const { t } = useTranslation();
  return (
    <InfoItem
      css={gridPlacement('pumpRunning')}
      label={t('WP.OVERVIEW.PUMP_RUNNING')}
      value={pumpState ? t(`WP.COMMON.${pumpState}`) : ''}
      Icon={pumpState === 'YES' ? <InfoItemIcon iconSrc={operationUpdate} /> : undefined}
    />
  );
};

export default PumpRunning;
