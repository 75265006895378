/**
 * @generated SignedSource<<09b3a19c30ca2bc577e5f983c8498dfa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InfoContainerDDAData_device$data = {
  readonly descendantDeviceInfo: {
    readonly productInfo?: {
      readonly typeKey: string | null;
      readonly productNumber: string | null;
      readonly firmware: string | null;
      readonly serialNumber: string | null;
    } | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"useInfoContainerData_device">;
  readonly " $fragmentType": "InfoContainerDDAData_device";
};
export type InfoContainerDDAData_device$key = {
  readonly " $data"?: InfoContainerDDAData_device$data;
  readonly " $fragmentSpreads": FragmentRefs<"InfoContainerDDAData_device">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InfoContainerDDAData_device",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useInfoContainerData_device"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "descendantDeviceInfo",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DDAProductInfo",
              "kind": "LinkedField",
              "name": "productInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "typeKey",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "productNumber",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firmware",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "serialNumber",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "DDA",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};

(node as any).hash = "2fa68fd0532ecbbf073c4942e144516e";

export default node;
