import { css, Theme } from '@emotion/react';

const childrenRoutesContainer = css`
  position: relative;
  display: grid;
  grid-column: 1/-1;
`;

// eslint-disable-next-line import/prefer-default-export
export { childrenRoutesContainer };
