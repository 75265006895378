import { css, Theme } from '@emotion/react';
import dsmMediaQuery from '../../utils/media-queries/media-queries';

const onboardingContainerStyles = css`
  min-height: 100%;
  position: relative;
  display: grid;
  grid-template-areas: 'main';

  ${dsmMediaQuery('medium device')} {
    padding-top: 4rem;
    grid-template-areas: 'progress' 'main';
    grid-template-rows: max-content 1fr;
    grid-gap: 3rem;
    justify-content: center;
  }
`;

const progressContainerStyles = css`
  display: none;
  ${dsmMediaQuery('medium device')} {
    grid-area: progress;
    display: flex;
    justify-content: center;
  }
`;

const progressIndicatorStyles = css`
  max-width: 90%;
`;

const stepLabelStyles = css`
  font-size: 0.875rem;
`;

const mainContainerStyles = css`
  grid-area: main;
  display: flex;
  justify-content: center;
  align-items: center;
  ${dsmMediaQuery('medium device')} {
    align-self: start;
`;

const wavesStyles = css`
  display: none;
  z-index: -1000;
  ${dsmMediaQuery('medium device')} {
    display: unset;
    position: absolute;
    bottom: 0;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export {
  onboardingContainerStyles,
  progressContainerStyles,
  progressIndicatorStyles,
  stepLabelStyles,
  mainContainerStyles,
  wavesStyles,
};
