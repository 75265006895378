const setUserPuid = (puid: string): void => {
  const state = window.digitalData[window.digitalData.length - 1];

  window.digitalData.push({
    ...state,
    data: {
      ...state?.data,
      user: {
        ...state?.data?.user,
        puid,
      },
    },
  });
};

export default setUserPuid;
