import React from 'react';
import { nonproportionalgraph } from '../styles';
import { paragraphBaseStyles } from '../../../../../../styles';
import { alertsContainer } from '../../AlertsTile/styles';

const NonProportionalDosingText = ({ infotext }: { infotext: string }) => (
  <div css={alertsContainer}>
    <div css={[paragraphBaseStyles, nonproportionalgraph]}>{infotext}</div>
  </div>
);

export default NonProportionalDosingText;
