import { css, Theme } from '@emotion/react';

const radioButtonStyles = (theme: Theme) => css`
  appearance: none;
  margin: 0;
  font: inherit;
  width: 1.125rem;
  height: 1.125rem;
  border: 0.06rem solid ${theme.colors.neutral.neutral500.hex};
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  &:before {
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 0.85em 0.85em ${theme.colors.blue.blue3.baseColor};
  }
  &:checked {
    border: 0.15em solid ${theme.colors.blue.blue3.baseColor};
  }
  &:checked::before {
    transform: scale(1);
  }
  &:focus {
    outline-offset: max(2px, 0.15rem);
  }
  &:disabled {
    cursor: not-allowed;
    border: 0.15rem solid
    ${theme.colors.blue.blue3.baseColor}
  }
  &:disabled::before {
    box-shadow: inset 1rem 1rem
    ${theme.colors.blue.blue3.baseColor}
    cursor: not-allowed;
  }
`;

const labelStyles = css`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export { labelStyles, radioButtonStyles };
