import React from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import { AccountEventsErrorProps } from './types';
import { AccountEventsError_events$key } from './__generated__/AccountEventsError_events.graphql';
import { notifBarStyles } from './styles';

const AccountEventsError: React.FC<AccountEventsErrorProps> = (props) => {
  const events = useFragment<AccountEventsError_events$key>(
    graphql`
      fragment AccountEventsError_events on EventConnection {
        errorCount
      }
    `,
    // eslint-disable-next-line react/destructuring-assignment
    props.events,
  );

  const count = events?.errorCount;

  return count ? (
    <div css={notifBarStyles}>
      <span>
        Something went wrong while loading {count} {count > 1 ? 'events' : 'event'}
      </span>
    </div>
  ) : null;
};

export default AccountEventsError;
