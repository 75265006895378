import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { DropDownSelect, InfoItem, TogglerFormik, Divider, Indent } from '../../../../../components';
import { FlowDelayOptions, FlowSensitivtyOptions, FormValues, PumpVariant, getFormValuesKey } from '../types';

export const FieldMode: React.FC<{
  isHidden: boolean;
  isRead: boolean;
  readOnlyComponent: JSX.Element;
  children: JSX.Element;
}> = ({ isHidden, children, readOnlyComponent, isRead }) => {
  if (isHidden) return null;
  if (isRead) return readOnlyComponent;
  return children;
};

const FlowControl: React.FC<{
  pumpVariant: PumpVariant | undefined;
  PressureSlider: JSX.Element;
}> = ({ pumpVariant, PressureSlider }) => {
  const { t } = useTranslation();
  const { values, initialValues, resetForm } = useFormikContext<FormValues>();
  const isReadMode = values.flowControl === false;
  if (pumpVariant === 'AR') return null;
  return (
    <div data-testid="flow-control-form">
      <div>
        <TogglerFormik
          testId="flow-control"
          label={<div>{t('WP.REMOTE.FLOW_CONTROL_ON_OFF')}</div>}
          name={getFormValuesKey('flowControl')}
          onToggle={(flowControlIsOn) => {
            if (!flowControlIsOn) {
              // Reset form needs all values for next state
              resetForm({
                values: {
                  ...values,
                  flowControlDelay: initialValues.flowControlDelay,
                  flowControlSensitivity: initialValues.flowControlSensitivity,
                  flowControlAutoFlowAdapt: initialValues.flowControlAutoFlowAdapt,
                  minPressureAsAlarm: initialValues.minPressureAsAlarm,
                  minPressureValue: initialValues.minPressureValue,
                  maxPressureValue: initialValues.maxPressureValue,
                },
              });
            }
          }}
          isToggled={initialValues.flowControl}
        />
      </div>
      <Divider />
      <div>
        <FieldMode
          isHidden={false}
          isRead={isReadMode}
          readOnlyComponent={
            <InfoItem
              testId="flow-control-delay-read-item"
              noBorder
              label={<Indent>{t('WP.REMOTE.FLOW_CONTROL_DELAY_LABEL')}</Indent>}
              inActive
              value={t('WP.REMOTE.FLOW_CONTROL_NOT_ACTIVE')}
            />
          }
        >
          <DropDownSelect
            name={getFormValuesKey('flowControlDelay')}
            label={<Indent>{t('WP.REMOTE.FLOW_CONTROL_DELAY_LABEL')}</Indent>}
            testId="flow-control-delay"
          >
            {Object.keys(FlowDelayOptions).map((option) => (
              <option key={option} value={option} data-testid={`flow-control-delay-selected-item-${option}`}>
                {t(`WP.REMOTE.FLOW_DELAY_OPTION_${option}`)}
              </option>
            ))}
          </DropDownSelect>
        </FieldMode>
        <Divider marginLeft="large" />
        <FieldMode
          isHidden={false}
          isRead={isReadMode}
          readOnlyComponent={
            <InfoItem
              testId="flow-control-sensitivity-read-item"
              noBorder
              label={<Indent>{t('WP.REMOTE.FLOW_CONTROL_SENSIVITY_LABEL')}</Indent>}
              inActive
              value={t('WP.REMOTE.FLOW_CONTROL_NOT_ACTIVE')}
            />
          }
        >
          <DropDownSelect
            name={getFormValuesKey('flowControlSensitivity')}
            label={<Indent>{t('WP.REMOTE.FLOW_CONTROL_SENSIVITY_LABEL')}</Indent>}
            testId="flow-control-sensitivity"
          >
            {Object.keys(FlowSensitivtyOptions).map((option) => (
              <option key={option} value={option} data-testid={`flow-control-sensitivity-selected-item-${option}`}>
                {t(`WP.REMOTE.REMOTE_FLOW_SENS_OPTION_${option}`)}
              </option>
            ))}
          </DropDownSelect>
        </FieldMode>
        <Divider marginLeft="large" />
        <FieldMode
          isHidden={pumpVariant !== 'FCM'}
          isRead={isReadMode}
          readOnlyComponent={
            <InfoItem
              testId="flow-control-auto-flow-adapt-read-item"
              noBorder
              label={<Indent>{t('WP.REMOTE.FLOW_CONTROL_AUTO_FLOW_ADAPT_LABEL')}</Indent>}
              inActive
              value={t('WP.REMOTE.FLOW_CONTROL_NOT_ACTIVE')}
            />
          }
        >
          <TogglerFormik
            testId="flow-control-auto-flow-adapt"
            label={<Indent>{t('WP.REMOTE.FLOW_CONTROL_AUTO_FLOW_ADAPT_LABEL')}</Indent>}
            name={getFormValuesKey('flowControlAutoFlowAdapt')}
            isToggled
          />
        </FieldMode>

        {pumpVariant === 'FCM' && <Divider marginLeft="large" />}
        <FieldMode
          isHidden={pumpVariant !== 'FCM'}
          isRead={isReadMode}
          readOnlyComponent={
            <>
              <InfoItem
                testId="flow-control-pressure-read-item"
                noBorder
                label={<Indent>{t('WP.REMOTE.FLOW_CONTROL_PRESSURE_TITLE')}</Indent>}
                inActive
                value={t('WP.REMOTE.FLOW_CONTROL_NOT_ACTIVE')}
              />
              <Divider marginLeft="large" />
            </>
          }
        >
          <>
            <InfoItem noBorder label={<Indent>{t('WP.REMOTE.FLOW_CONTROL_PRESSURE_TITLE')}</Indent>} />
            <Indent>{PressureSlider}</Indent>
          </>
        </FieldMode>
        <FieldMode
          isHidden={false}
          isRead={isReadMode}
          readOnlyComponent={
            <InfoItem
              testId="flow-control-min-pressure-read-item"
              noBorder
              label={<Indent>{t('WP.REMOTE.FLOW_CONTROL_MIN_PRESSURE_LABEL')}</Indent>}
              inActive
              value={t('WP.REMOTE.FLOW_CONTROL_NOT_ACTIVE')}
            />
          }
        >
          <TogglerFormik
            testId="flow-control-min-pressure"
            label={<Indent>{t('WP.REMOTE.FLOW_CONTROL_MIN_PRESSURE_LABEL')}</Indent>}
            name={getFormValuesKey('minPressureAsAlarm')}
            isToggled
          />
        </FieldMode>
        <Divider marginLeft="large" />
      </div>
    </div>
  );
};

export default FlowControl;
