import { css } from '@emotion/react';

const pumpStatusContainerStyles = css`
  display: grid;
  grid-template-columns: 1.5rem 1fr;
  grid-template-areas:
    'controlMode controlMode'
    'maxCapacity maxCapacity'
    'pumpOperation pumpOperation'
    '. operatingStatus'
    '. isPumping'
    '. stoppedBy'
    'remoteControlEnabled remoteControlEnabled';
  padding-top: 1rem;
  gap: 0.5rem;
`;

const gridPlacement = (placement: string) => css`
  grid-area: ${placement};
`;

export { pumpStatusContainerStyles, gridPlacement };
