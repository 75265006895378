import * as React from 'react';
import { IconProps } from './types';

const CheckCircleOutline: React.FC<IconProps> = ({ color, size, styles, x, y }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" css={styles} x={x} y={y} height={size} width={size}>
    <path
      fill={color}
      className="check-circle_outline_svg__check-mark"
      d="M11.93 15.07l-1.47 1.36L15 21l7-7v-2.83l-6.98 6.99-3.09-3.09z"
    />
    <path
      fill={color}
      className="check-circle_outline_svg__check-circle"
      d="M16 2a14 14 0 1014 14A14 14 0 0016 2zm0 26a12 12 0 1112-12 12 12 0 01-12 12z"
    />
  </svg>
);

export default CheckCircleOutline;
