import { useEffect, useState } from 'react';
import { TokenClaims } from '@azure/msal-common';
import { useMsal } from '@azure/msal-react';
import { AuthenticatedUser } from './types';

const createAuthenticatedUserFromOidcTokenClaims = (
  tokenClaims:
    | (TokenClaims & {
        [key: string]: string | number | string[] | object | undefined | unknown;
      })
    | undefined,
): AuthenticatedUser =>
  ({
    company: tokenClaims?.Company,
    country: tokenClaims?.country,
    puid: tokenClaims?.puid,
    signInName: tokenClaims?.signInName,
  } as AuthenticatedUser);

const useAuthenticatedUser = (): AuthenticatedUser | undefined => {
  const { accounts } = useMsal();
  const [authenticatedUser, setAuthenticatedUser] = useState<AuthenticatedUser | undefined>(undefined);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        const account = accounts[0];

        if (account) {
          setAuthenticatedUser(createAuthenticatedUserFromOidcTokenClaims(account.idTokenClaims));
        } else {
          // eslint-disable-next-line no-console
          console.warn('Failed to get user!');
        }
      } catch (error) {
        // We catch this specific error so it doesn't pollute Sentry
        if (error instanceof Error && error.message === 'No state in response') {
          // eslint-disable-next-line no-console
          console.info('Unauthenticated user detected. No action required');
        } else {
          throw error;
        }
      }
    })();
  }, [accounts]);
  return authenticatedUser;
};

export default useAuthenticatedUser;
