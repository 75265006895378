import React from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import { assetsListStyles, sectionListStyles } from './styles';
import { SectionAssetsProps } from './types';
import { SectionAssets_section$key } from './__generated__/SectionAssets_section.graphql';
import { DeviceList, SectionList } from '../../components';

const SectionAssets: React.FC<SectionAssetsProps> = (props) => {
  const section = useFragment<SectionAssets_section$key>(
    graphql`
      fragment SectionAssets_section on Section {
        locations(typeCodes: [SECTION]) {
          totalCount
          ...SectionList_sections
        }
        devices {
          ...DeviceList_devices
        }
      }
    `,
    // eslint-disable-next-line react/destructuring-assignment
    props.section,
  );

  const totalCount = section.locations?.totalCount;

  return (
    <div css={totalCount ? [assetsListStyles, sectionListStyles] : assetsListStyles}>
      {totalCount ? <SectionList sections={section.locations} /> : <DeviceList devices={section.devices} />}
    </div>
  );
};

export default SectionAssets;
