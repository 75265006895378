import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Button } from '../../../../../components';
import { setToDefaultContainerStyles, setToDefaultTextStyles } from './styles';
import { FormValues } from '../types';

/*
  For now the analog default values are hardcoded. It's
  not optimal but the simplest solution for now since
  the values will not change.
 */

const ANALOG_INPUT_LOWER__0_20_DEFAULT = 0;
const ANALOG_INPUT_LOWER__4_20_DEFAULT = 4;
const ANALOG_DOSING_FLOW_LOWER_DEFAULT = 0;
const ANALOG_INPUT_HIGHER_DEFAULT = 20;

interface SetToDefaultProps {
  hasBeenSetToDefault: boolean;
  setToDefault: (setToDefault: boolean) => void;
}

const SetToDefault: React.FC<SetToDefaultProps> = ({ setToDefault, hasBeenSetToDefault }) => {
  const { t } = useTranslation();
  const { values: formValues, setFieldValue } = useFormikContext<FormValues>();

  const setDefaultValues = (controlMode: FormValues['controlMode']) => {
    if (controlMode === 'ANALOG_0_20') setFieldValue('analogInputLower', ANALOG_INPUT_LOWER__0_20_DEFAULT.toFixed(2));
    if (controlMode === 'ANALOG_4_20') setFieldValue('analogInputLower', ANALOG_INPUT_LOWER__4_20_DEFAULT.toFixed(2));
    setFieldValue('analogDosingFlowLower', ANALOG_DOSING_FLOW_LOWER_DEFAULT.toFixed(4));
    setFieldValue('analogInputHigher', ANALOG_INPUT_HIGHER_DEFAULT.toFixed(2));
    setToDefault(true);
  };

  return (
    <div css={setToDefaultContainerStyles} data-testid="set-to-default">
      <p css={setToDefaultTextStyles}>{t('WP.REMOTE.ANALOG_SET_TO_DEFAULT_TXT')}</p>
      <Button
        variant="secondary"
        type="button"
        disabled={hasBeenSetToDefault}
        onClick={() => {
          setDefaultValues(formValues.controlMode);
        }}
      >
        {t('WP.REMOTE.ANALOG_SET_TO_DEFAULT_BTN')}
      </Button>
    </div>
  );
};

export default SetToDefault;
