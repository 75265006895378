import React from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { useNavigate } from 'react-router-dom';
import { useAccount } from '../../../providers/AccountProvider';
import { AccountSwitcherQuery } from './__generated__/AccountSwitcherQuery.graphql';
import { accountSwitcherStyles } from './styles';

const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
  e.nativeEvent.stopImmediatePropagation();
};

const onTouchStart = (e: React.TouchEvent<HTMLDivElement>): void => {
  e.nativeEvent.stopImmediatePropagation();
};

type AccountSwitcherProps = { onClick?: () => void };

const AccountSwitcher: React.FC<AccountSwitcherProps> = ({ onClick }) => {
  const navigate = useNavigate();

  const {
    account: { id: accountId },
    setAccount,
  } = useAccount();

  const data = useLazyLoadQuery<AccountSwitcherQuery>(
    graphql`
      query AccountSwitcherQuery {
        accounts {
          edges {
            node {
              id
              uuid
              name
            }
          }
        }
      }
    `,
    {},
  );

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div css={accountSwitcherStyles} onMouseDown={onMouseDown} onTouchStart={onTouchStart}>
      <h4>Switch to Account</h4>
      {data.accounts?.edges
        ?.filter(Boolean)
        .map((edge) => edge?.node)
        .filter(Boolean)
        .filter((node) => node?.id !== accountId)
        .map(
          (node) =>
            node?.id && (
              <button
                key={node.id}
                onClick={(e): void => {
                  e.nativeEvent.stopImmediatePropagation();

                  if (onClick) onClick();

                  if (node.id) {
                    setAccount(node);
                    navigate('/');
                  }
                }}
                type="button"
              >
                {node.name}
              </button>
            ),
        )}
    </div>
  );
};

export default AccountSwitcher;
