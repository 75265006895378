import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { errorFallbackStyles } from './styles';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ErrorFallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => (
  <div css={errorFallbackStyles}>
    <div>
      There was an error in loading this component. &nbsp;
      <button type="button" onClick={resetErrorBoundary}>
        Try again
      </button>
    </div>
  </div>
);

export default ErrorFallback;
