import React from 'react';
import { useTranslation } from 'react-i18next';
import { summaryContainerStyles } from '../styles';
import LastHourSummary from './LastHourSummary';
import TargetSummary from './TargetSummary';
import NonProportionalDosing from './NonProportionalDosing';

const VolumetricSummary = ({
  isProportionalDosing,
  valueActual,
  valueTarget,
  unit,
}: {
  isProportionalDosing: boolean;
  valueTarget: number;
  valueActual: number;
  unit: string;
}) => {
  const { t } = useTranslation();
  return (
    <div css={summaryContainerStyles}>
      <LastHourSummary value={valueActual} label={t('WP.VOLUMETRIC.CONCENTRATION.TILE_LAST_HOUR')} valueType={unit} />
      {isProportionalDosing ? (
        <TargetSummary
          value={valueTarget}
          label={t('WP.VOLUMETRIC.CONCENTRATION.TILE_TARGET')}
          valueType={unit}
          error={t('WP.VOLUMETRIC.CONCENTRATION.TILE_ERROR')}
        />
      ) : (
        <NonProportionalDosing
          label={t('WP.VOLUMETRIC.CONCENTRATION.TILE_TARGET')}
          tooltiptext={t('WP.VOLUMETRIC.CONCENTRATION.TILE_NONPROPORTIONAL_TOOLTIP')}
          alarmtext={t('WP.VOLUMETRIC.CONCENTRATION.TILE_NONPROPORTIONAL_ALARM')}
        />
      )}
    </div>
  );
};

export default VolumetricSummary;
