import * as React from 'react';
import { IconProps } from './types';

const InfoCircleOutline: React.FC<IconProps> = ({ color, size, styles, x, y }) => (
  <svg xmlns="http://www.w3.org/2000/svg" css={styles} x={x} y={y} height={size} width={size} viewBox="0 0 32 32">
    <path fill={color} d="M16 2a14 14 0 1014 14A14 14 0 0016 2zm0 26a12 12 0 1112-12 12 12 0 01-12 12z" />
    <path fill={color} d="M16 8a1.5 1.5 0 101.5 1.49A1.48 1.48 0 0016 8zM15 13h2v10h-2z" />
  </svg>
);

export default InfoCircleOutline;
