import React, { Suspense, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import {
  closeButtonStyles,
  containerStyles,
  displayStyles,
  headerContainerStyles,
  modalStyles,
  footerButtons,
  sectionStyles,
  mainContentContainerStyles,
  installationNoteStyles,
  noteTextStyles,
} from './styles';
import {
  headingSmallBrightStyles,
  paragraphBaseStyles,
  paragraphBoldStyles,
  textLinkStyles,
} from '../../../../../styles';
import { Button, InfoItem, Loader, NoDataAvailableMessage } from '../../../../../components';
import close from '../../../../../assets/images/close.svg';
import { InfoContainerProps } from './types';
import EditInformation from './EditInformation';
import { LocaleContext } from '../../../../../providers/LocaleProvider/LocaleProvider';
import { formatDate } from './utils';
import useInfoContainerData from './useInfoContainerData';

const Header = ({ title, onClick }: { title: string; onClick: () => void }) => (
  <div css={headerContainerStyles}>
    <p css={headingSmallBrightStyles}>{title}</p>
    <Button type="button" variant="text link" onClick={onClick} css={closeButtonStyles}>
      <img src={close} alt="" height="24" width="24" />
    </Button>
  </div>
);

const InfoSection = ({
  children,
  title,
  className,
}: {
  children: JSX.Element[] | JSX.Element;
  title: string;
  className?: string;
}) => (
  <div css={className}>
    <p css={[paragraphBoldStyles, sectionStyles]}>{title}</p>
    {children}
  </div>
);

const Column = ({ children, className }: { children: JSX.Element[] | JSX.Element; className?: string }) => (
  <div css={className}>{children}</div>
);

const formId = 'edit-installation-info';

const InfoContainer: React.FC<InfoContainerProps> = ({
  onClose,
  productInfo: { typeKey, productNumber, serialNumber, firmware, pumpName },
  device,
}) => {
  const data = useInfoContainerData(device);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { shortDateTimeFormat } = useContext(LocaleContext);

  if (!data) return <NoDataAvailableMessage message={t('WP.ERROR.NO_DATA')} />;

  const {
    installationName,
    location,
    gatewayIMEI,
    customerName,
    commissioningDate,
    commissioningEngineer,
    commissioningNote,
    qrCode,
  } = data;
  const { street, postalCode, country, city, geoLocation } = location;
  // we sometimes experience double spacing in values(or empty values) from asset service
  const address = `${street?.trim() ? `${street}, ` : ''}${postalCode?.trim() || ''} ${city?.trim() || ''}${
    country?.trim() ? `, ${country}` : ''
  }`;

  return (
    <div css={[modalStyles, displayStyles]}>
      <div css={containerStyles}>
        <Header title={t('WP.OVERVIEW.MODAL.INSTALLATION_INFO')} onClick={onClose} />
        <div css={mainContentContainerStyles}>
          <Column
            css={css`
              display: flex;
              flex-direction: column;
              gap: 2rem;
            `}
          >
            <InfoSection title={t('WP.OVERVIEW.MODAL.GENERAL_INFORMATION')}>
              <InfoItem label={t('WP.OVERVIEW.MODAL.CUSTOMER_NAME')} value={customerName} />
              <InfoItem label={t('WP.OVERVIEW.MODAL.APPLICATION')} value={installationName} />
              <InfoItem noBorder label={t('WP.OVERVIEW.MODAL.ADDRESS')} value={address} />
            </InfoSection>
            <Suspense fallback={<Loader />}>
              <InfoSection title="">
                <EditInformation loadingCallback={(loading) => setIsLoading(loading)} formId={formId} />
              </InfoSection>
            </Suspense>
            <InfoSection title={t('WP.OVERVIEW.MODAL.DOSING_PUMP_INFO')}>
              <InfoItem label={t('WP.OVERVIEW.MODAL.TYPE_KEY')} value={typeKey} />
              <InfoItem
                label={t('WP.OVERVIEW.MODAL.DDA_PUMP_NAME')}
                // eslint-disable-next-line no-nested-ternary
                value={pumpName ? (pumpName === 'Unknown' ? '-' : pumpName) : '-'}
              />
              <InfoItem label={t('WP.OVERVIEW.MODAL.DDA_PRODUCT_NUMBER')} value={productNumber} />
              <InfoItem label={t('WP.OVERVIEW.MODAL.DDA_SERIAL_NUMBER')} value={serialNumber} />
              <InfoItem noBorder label={t('WP.OVERVIEW.MODAL.DDA_FIRMWARE_NUMBER')} value={firmware} />
            </InfoSection>
          </Column>
          <Column>
            <InfoSection title={t('WP.OVERVIEW.MODAL.COMMISSIONING_INFORMATION')}>
              <InfoItem
                label={t('WP.OVERVIEW.MODAL.COMMISSIONING_DATE')}
                value={formatDate(commissioningDate, shortDateTimeFormat.dateFnsDateTimeFormat)}
              />
              <InfoItem label={t('WP.OVERVIEW.MODAL.COMMISSIONING_ENGINEER')} value={commissioningEngineer} />
              {/* TODO: we don't have this info yet - add when we do */}
              {/* <InfoItem label={t('WP.MORE.DATA.FIRST_SYS_STARTUP')} value="-" /> */}
              <InfoItem
                label={t('WP.OVERVIEW.MODAL.GEO_LOCATION')}
                value={
                  geoLocation && (
                    <a css={textLinkStyles} href={geoLocation} target="_blank" rel="noreferrer">
                      {t('WP.OVERVIEW.MODAL.SEE_MAP')}
                    </a>
                  )
                }
              />
              <InfoItem noBorder label={t('WP.OVERVIEW.MODAL.COMMISSIONING_NOTE')} css={installationNoteStyles} />
              <div css={[noteTextStyles, paragraphBaseStyles]}>{commissioningNote}</div>
              <InfoItem label={t('WP.OVERVIEW.MODAL.GATEWAY_ID')} value={gatewayIMEI} />
              <InfoItem
                label={t('WP.OVERVIEW.MODAL.GATEWAY_QR')}
                noBorder
                css={css`
                  align-items: start;
                `}
                value={qrCode && <img width="100" height="100" src={qrCode} alt="QR code" />}
              />
            </InfoSection>
          </Column>
        </div>
        <div css={footerButtons}>
          <Button type="button" variant="secondary" onClick={onClose}>
            {t('WP.COMMON.CANCEL')}
          </Button>
          <Button disabled={isLoading} type="submit" variant="primary" formId={formId}>
            {t('WP.OVERVIEW.MODAL.INSTALLATION_SAVE_BUTTON')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InfoContainer;
