import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tile, TileBody, TileHeader } from '../../../../../components';
import { tileStyles, topPadding, twoColumsTileStyles } from '../styles';

const ProportionalDosingData = ({ children }: { children: JSX.Element[] | JSX.Element }) => {
  const { t } = useTranslation();
  return (
    <Tile
      styles={tileStyles}
      Header={<TileHeader text={t('WP.MORE.DATA.VOLUMETRIC_CONCENTRATION_TILE')} />}
      Body={
        <TileBody css={[topPadding]}>
          <div css={twoColumsTileStyles}>{children}</div>
        </TileBody>
      }
    />
  );
};

export default ProportionalDosingData;
