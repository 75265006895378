import { css, Theme } from '@emotion/react';
import dsmMediaQuery from '../../utils/media-queries/media-queries';

const profileStyles = css`
  padding-top: 2.2rem;
  padding-bottom: 2.2rem;
  gap: 1rem;
  height: 100%;
`;

const formStyles = css`
  grid-column: 1/-1;
  display: grid;
  gap: 1rem;
  grid-template-rows: max-content 1fr max-content;
  grid-template-areas:
    'header'
    'tiles'
    'buttons';
  ${dsmMediaQuery('medium device')} {
    grid-template-areas:
      'header header'
      'tiles tiles'
      'buttons buttons';
  }
  ${dsmMediaQuery('large device')} {
    grid-template-areas:
      'header header'
      'tiles tiles'
      'buttons buttons';
  }
  .regional-settings-divider {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

const buttonStyles = css`
  grid-area: buttons;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  ${dsmMediaQuery('medium device')} {
    flex-direction: unset;
    justify-content: end;
  }
  .button {
    min-width: 20rem;
  }
`;

const dropDownStyles = css`
  padding-top: 1rem;
  font-size: 0.875rem;
  select {
    padding: 0.5rem;
    max-width: 12.25rem;
  }
`;

const headerStyles = css`
  grid-area: header;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1rem;
  h1 {
    font-size: 2rem;
    font-weight: 400;
  }
`;

const spinnerModalStyles = (theme: Theme) => css`
  z-index: 100;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: ${theme.colors.primaryBackgroundTransparent};
`;

const profileContainer = css`
  padding: 1.5rem 2rem 4rem 2rem;
`;

const regionalSettingsTile = css`
  padding-left: 2rem;
  padding-right: 2rem;
`;

export {
  profileStyles,
  formStyles,
  buttonStyles,
  dropDownStyles,
  headerStyles,
  spinnerModalStyles,
  profileContainer,
  regionalSettingsTile,
};
