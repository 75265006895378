import { css, Theme } from '@emotion/react';

const errorFallbackStyles = css`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 1rem 2rem;
  text-align: center;
  width: 100%;
`;

// eslint-disable-next-line import/prefer-default-export
export { errorFallbackStyles };
