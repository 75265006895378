import { css, SerializedStyles, Theme } from '@emotion/react';
import dsmMediaQuery from '../../utils/media-queries/media-queries';
import { TileHeaderProps, TileBodyProps, TileFooterProps } from './types';

const tilesStyles = css`
  display: grid;
  grid-row-gap: 1.2rem;
  grid-column-gap: 1.2rem;
  margin-bottom: 2rem;
  ${dsmMediaQuery('medium device')} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${dsmMediaQuery('large device')} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const tileStyles = (
  theme: Theme,
  body?: React.ReactElement<TileBodyProps> | boolean | null,
  footer?: React.ReactElement<TileFooterProps> | string | number | boolean | null,
  header?: React.ReactElement<TileHeaderProps> | boolean | null,
): SerializedStyles => {
  let gridTemplateRows = '';

  if (header) gridTemplateRows += 'max-content ';
  if (body) gridTemplateRows += 'auto ';
  if (footer) gridTemplateRows += 'max-content';

  gridTemplateRows = gridTemplateRows.trim();

  return css`
    display: grid;
    ${gridTemplateRows && gridTemplateRows !== '' && `grid-template-rows: ${gridTemplateRows}`};
    padding: 1rem;
    background-color: #222527;
    color: ${theme.colors.primaryText};
    small {
      text-align: center;
    }
  `;
};

const tileHeaderStyles = css`
  color: #c6c7c7;
  font-weight: 700;
  display: grid;
  grid-template-areas: 'icon text status';
  grid-template-columns: max-content;
  grid-column-gap: 0.7rem;
  align-items: center;
  img {
    grid-area: icon;
  }
  span {
    grid-area: text;
  }
`;

const noIconStyles = css`
  grid-template-areas: 'text status';
`;

const tileHeaderStatusStyles = css`
  grid-area: status;
  justify-self: end;
`;

const tileFooterStyles = css`
  text-align: center;
`;

export { tileFooterStyles, tileHeaderStyles, tileHeaderStatusStyles, tileStyles, tilesStyles, noIconStyles };
