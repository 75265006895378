import { Reducer } from 'react';
import { AccountProviderAction, AccountProviderState } from './types';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const initialState: AccountProviderState = { account: undefined!, hasLoaded: false };

const reducer: Reducer<AccountProviderState, AccountProviderAction> = (
  state,
  action: AccountProviderAction,
): AccountProviderState => {
  switch (action.type) {
    case 'SET_ACCOUNT':
      return {
        ...state,
        account: { ...action.account },
      };
    case 'SET_HAS_LOADED':
      return {
        ...state,
        hasLoaded: true,
      };
    default:
      throw new Error(`Unhandled action type`);
  }
};

export { reducer, initialState };
