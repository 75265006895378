import React from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useFragment } from 'react-relay/hooks';
import { pageStyles, paragraphBaseStyles } from '../../../styles';
import { Button } from '../../../components';
import {
  buttonStyles,
  containerStyles,
  paragraphStyles,
  textBoxStyles,
  textContainerStyles,
  titleStyles,
} from './styles';
import { NotSupportedFragment$key } from './__generated__/NotSupportedFragment.graphql';

interface NotSupportedProps {
  device: NotSupportedFragment$key;
  Header: JSX.Element;
}

const SUPPORT_EMAIL = 'ind_services@digitalsupport.grundfos.com';

const NotSupported = ({ device, Header }: NotSupportedProps) => {
  const data = useFragment(
    graphql`
      fragment NotSupportedFragment on Device {
        ... on Device {
          gatewayIMEI
        }
      }
    `,
    device,
  );
  const { t } = useTranslation();
  const { gatewayIMEI } = data;

  const lineBreak = '%0A';
  const bulletItem = '%E2%80%A2';

  const emailSubjectText = `${t('WP.DDA.NOT_SUPPORTED_EMAIL_SUBJECT')} ${gatewayIMEI} (${t(
    'WP.COMMON.DDA_NOT_SUPPORTED',
  )})`;

  const emailBodyText = `${t('WP.DDA.NOT_SUPPORTED_EMAIL_BODY')}
  ${lineBreak}  ${bulletItem} ${t('WP.COMMON.PHONE')}:
  ${lineBreak}  ${bulletItem} ${t('WP.COMMON.EMAIL')}:`;

  const openMailApplication = () => {
    window.open(`mailto:${SUPPORT_EMAIL}?subject=${emailSubjectText}&body=${emailBodyText}`);
  };

  return (
    <div css={[pageStyles, containerStyles]}>
      {Header}
      <div css={textContainerStyles}>
        <div css={textBoxStyles}>
          <h1 css={titleStyles}>{t('WP.DDA.NOT_SUPPORTED_HEADLINE')}</h1>
          <p css={[paragraphBaseStyles, paragraphStyles]}>{`${t('WP.DDA.NOT_SUPPORTED_TEXT')} ${gatewayIMEI}.`}</p>
          <Button type="button" variant="primary" onClick={openMailApplication} css={buttonStyles}>
            {t('WP.COMMON.CONTACT_SUPPORT')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NotSupported;
