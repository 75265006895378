import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary, ErrorFallback, Loader } from '../../../components';
import { pageStyles } from '../../../styles';
import sentryCaptureError from '../../../utils/sentry/sentry-capture-error';
import { childrenRoutesContainer, deviceStyles, navStyles, tabContainerStyles } from '../styles';
import DevicePageHeader from './DevicePageHeader';
import DeviceNavLink from './DeviceNavLink';

const DevicePage: React.FC<{
  customerName: string;
  installationName: string;
  LastSync: JSX.Element;
  currentTab: string;
  setCurrentTab: (tab: string) => void;
  tabs: { [tab: string]: { analyticsName: string; linkLabel: string; page: JSX.Element | null } };
}> = ({ tabs, LastSync, customerName, installationName, currentTab, setCurrentTab }) => {
  const { t } = useTranslation();
  return (
    <div css={[pageStyles, deviceStyles]}>
      <DevicePageHeader
        customerName={customerName}
        installationName={installationName}
        label={t('WP.OVERVIEW.LAST_SYNC')}
        LastSync={LastSync}
      />
      <div css={tabContainerStyles}>
        <nav css={navStyles}>
          {Object.entries(tabs).map(([tab, { analyticsName, linkLabel }]) => (
            <DeviceNavLink
              key={tab}
              isActive={currentTab === tab}
              onClick={() => setCurrentTab(tab)}
              analyticsEvent={analyticsName}
            >
              {linkLabel}
            </DeviceNavLink>
          ))}
        </nav>
        <div css={childrenRoutesContainer}>
          <ErrorBoundary
            onError={(error): void => sentryCaptureError(error, 'Device')}
            FallbackComponent={ErrorFallback}
          >
            <Suspense fallback={<Loader />}>{tabs[currentTab].page}</Suspense>
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
};

export default DevicePage;
