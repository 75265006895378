import React, { Suspense } from 'react';
import { css } from '@emotion/react';
import { graphql, useFragment } from 'relay-hooks';
import { useTranslation } from 'react-i18next';
import {
  ErrorBoundary,
  ErrorFallback,
  NoDataAvailableMessage,
  Tile,
  TileHeader,
  TileBody,
  BarLabelValueUnit,
  BatteryBar,
} from '../../../components/index';
import sentryCaptureError from '../../../utils/sentry/sentry-capture-error';
import { OverviewChemPairingDDACQuery$data } from './__generated__/OverviewChemPairingDDACQuery.graphql';
import { TimeToServiceDDAC_device$key } from './__generated__/TimeToServiceDDAC_device.graphql';
import { timeToServiceTileStyles } from './styles';
import { isNotUndefinedOrNull } from '../utils';

export interface TimeToServiceDDACProps {
  device: OverviewChemPairingDDACQuery$data;
}

const TimeToServiceDDAC = ({ device }: TimeToServiceDDACProps) => {
  const { t } = useTranslation();

  // eslint-disable-next-line camelcase
  const data = useFragment<TimeToServiceDDAC_device$key>(
    graphql`
      fragment TimeToServiceDDAC_device on Device {
        latestSync {
          outOfSync
        }
        descendantDeviceInfo {
          ... on DDAC {
            timeToService {
              hoursToNextService
              monthsToNextService
              hoursThreshold
              hoursMax
              monthsThreshold
              monthsMax
            }
          }
        }
      }
    `,
    device.node,
  );

  const { hoursToNextService, monthsToNextService, hoursThreshold, hoursMax, monthsThreshold, monthsMax } =
    data?.descendantDeviceInfo?.timeToService || {};

  const hasData = isNotUndefinedOrNull(hoursToNextService) && isNotUndefinedOrNull(monthsToNextService);
  const isOutOfSync = data?.latestSync?.outOfSync;

  return (
    <Tile
      key="timeToService"
      styles={css`
        grid-area: timeToService;
      `}
      Header={<TileHeader text={t('WP.OVERVIEW.TIME_TO_SERVICE')} />}
      Body={
        <TileBody css={timeToServiceTileStyles}>
          <Suspense>
            <ErrorBoundary
              onError={(error): void => sentryCaptureError(error, 'TimeToServiceTile')}
              // eslint-disable-next-line react/no-unstable-nested-components
              fallbackRender={(fallbackProps) => (
                <ErrorFallback
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...fallbackProps}
                />
              )}
            >
              {hasData && !isOutOfSync ? (
                <>
                  <BatteryBar
                    value={hoursToNextService}
                    maxValue={hoursMax || 8000}
                    threshold={hoursThreshold || 500}
                    testId="hours"
                  >
                    <BarLabelValueUnit
                      value={hoursToNextService}
                      maxValue={hoursMax || 8000}
                      label={t('WP.OVERVIEW.MOTOR_OPERATING_HOURS_LEFT')}
                      unit={t('WP.COMMON.HOURS')}
                      testId="hours"
                    />
                  </BatteryBar>
                  <BatteryBar
                    value={monthsToNextService}
                    maxValue={monthsMax || 24}
                    threshold={monthsThreshold || 1}
                    testId="months"
                  >
                    <BarLabelValueUnit
                      value={monthsToNextService}
                      maxValue={monthsMax || 24}
                      label={t('WP.OVERVIEW.MONTHS_LEFT')}
                      unit={t('WP.COMMON.MONTHS')}
                      testId="months"
                    />
                  </BatteryBar>
                </>
              ) : (
                <NoDataAvailableMessage message={t('WP.ERROR.NO_DATA')} size="text" />
              )}
            </ErrorBoundary>
          </Suspense>
        </TileBody>
      }
    />
  );
};

export default TimeToServiceDDAC;
