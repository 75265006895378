import React from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { useTranslation } from 'react-i18next';
import { Offcanvas } from '../../../../components';
import CloseIcon from '../../../../assets/images/close-grey.svg';
import { useAccount } from '../../../../providers/AccountProvider';
import { SubNavigationQuery } from './__generated__/SubNavigationQuery.graphql';
import {
  subNavigationBackgroundStyles,
  subNavigationButtonStyles,
  subNavigationHeaderStyles,
  offcanvasWrapperStyles,
} from './styles';
import SubNavigationList from './SubNavigationList/SubNavigationList';
import { SubNavigationProps } from './types';
import { heading05Styles } from '../../../../styles';

const SubNavigation: React.FC<SubNavigationProps> = ({ dataTestId, isOpen, onClose, toggleShowSubNavigation }) => {
  const {
    account: { id: accountId },
  } = useAccount();
  const { t } = useTranslation();

  const data = useLazyLoadQuery<SubNavigationQuery>(
    graphql`
      query SubNavigationQuery($accountId: ID!) {
        node(id: $accountId) {
          ... on Account {
            name
            ...SubNavigationList_data
          }
        }
      }
    `,
    { accountId },
  );

  const currentAccount = data?.node;

  return (
    <Offcanvas
      isOpen={isOpen}
      onClose={onClose}
      styles={subNavigationBackgroundStyles}
      stylesWrapper={offcanvasWrapperStyles(isOpen)}
      toggleIsOpen={toggleShowSubNavigation}
    >
      <nav data-test-id={`${dataTestId ?? 'sub-navigation'}`}>
        <div css={subNavigationHeaderStyles}>
          <h2 css={heading05Styles}>{t('WP.CUSTOMERS_LIST_NAV')}</h2>
          <button css={subNavigationButtonStyles} onClick={onClose} type="button">
            <img width="20" height="20" src={CloseIcon} alt="close-subnavigation" />
          </button>
        </div>
        <SubNavigationList data={currentAccount} toggleShowSubNavigation={toggleShowSubNavigation} />
      </nav>
    </Offcanvas>
  );
};

export default SubNavigation;
