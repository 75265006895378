import React from 'react';
import { StatusSquareProps } from './types';

const StatusSquare: React.FC<StatusSquareProps> = ({ fill = 'transparent', size = '16px' }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <g id="g-1" fill={fill}>
      <rect width={size} height={size} />
    </g>
  </svg>
);

export default StatusSquare;
