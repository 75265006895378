import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Divider, InfoItem } from '../../../../../components';
import { AnalogSettings, FormValues } from '../types';
import { AnalogRuleInfoToolTip, DisplayIcon, DisplayValues } from '../../components';
import { ProportionalRule } from '../../types';
import SetToDefault from './SetToDefault';

interface AnalogUnproportionalFormProps {
  analogSettings: AnalogSettings;
  lowerInputRuleViolation: ProportionalRule;
}

const AnalogUnproportionalForm: React.FC<AnalogUnproportionalFormProps> = ({
  analogSettings,
  lowerInputRuleViolation,
}) => {
  const { t } = useTranslation();
  const { values: formValues, initialValues } = useFormikContext<FormValues>();
  const [hasBeenSetToDefault, setHasBeenSetToDefault] = useState(false);
  const { higherInput, lowerInput, proportionalValidation } = analogSettings;

  return (
    <>
      <InfoItem
        noBorder
        className="info-item"
        testId="analog-input-lower"
        label={t('WP.REMOTE.ANALOG_DDA_LOWER_MA')}
        Icon={
          <DisplayIcon displayIcon={!hasBeenSetToDefault}>
            <AnalogRuleInfoToolTip
              proportionalRuleValidation={proportionalValidation}
              macthRuleViolations={[lowerInputRuleViolation]}
            />
          </DisplayIcon>
        }
        value={
          <DisplayValues
            testId="analog-dosing-form-lower-ma"
            formValue={formValues.analogInputLower}
            setToDefault={hasBeenSetToDefault}
            initialFormValue={initialValues.analogInputLower}
          />
        }
        unit={lowerInput.electricCurrent.unit}
      />
      <Divider marginLeft="small" />
      <InfoItem
        noBorder
        className="info-item"
        testId="analog-dosing-lower"
        label={t('WP.REMOTE.ANALOG_DDA_LOWER_DOSING_FLOW')}
        Icon={
          <DisplayIcon displayIcon={!hasBeenSetToDefault}>
            <AnalogRuleInfoToolTip
              proportionalRuleValidation={proportionalValidation}
              macthRuleViolations={['LowerInputCurrentFlow']}
            />
          </DisplayIcon>
        }
        value={
          <DisplayValues
            formValue={formValues.analogDosingFlowLower}
            initialFormValue={initialValues.analogDosingFlowLower}
            setToDefault={hasBeenSetToDefault}
            testId="analog-dosing-form-lower-flow"
          />
        }
        unit={lowerInput.dosingVolume.unit}
      />
      <Divider marginLeft="small" />
      <InfoItem
        noBorder
        className="info-item"
        testId="analog-input-higher"
        label={t('WP.REMOTE.ANALOG_DDA_HIGHER_MA')}
        Icon={
          <DisplayIcon displayIcon={!hasBeenSetToDefault}>
            <AnalogRuleInfoToolTip
              proportionalRuleValidation={proportionalValidation}
              macthRuleViolations={['UpperInputCurrent']}
            />
          </DisplayIcon>
        }
        value={
          <DisplayValues
            testId="analog-dosing-form-higher-ma"
            formValue={formValues.analogInputHigher}
            initialFormValue={initialValues.analogInputHigher}
            setToDefault={hasBeenSetToDefault}
          />
        }
        unit={higherInput.electricCurrent.unit}
      />
      <Divider marginLeft="small" />
      <SetToDefault hasBeenSetToDefault={hasBeenSetToDefault} setToDefault={setHasBeenSetToDefault} />
    </>
  );
};

export default AnalogUnproportionalForm;
