/**
 * @generated SignedSource<<6806aad9b837935059382491eec1b94d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceInfoType = "DDA" | "DDAC" | "Gateway" | "VirtualSensor";
import { FragmentRefs } from "relay-runtime";
export type DeviceListItem_device$data = {
  readonly uuid: string;
  readonly name: string;
  readonly descendantDeviceInfo: {
    readonly type: DeviceInfoType;
  } | null;
  readonly " $fragmentType": "DeviceListItem_device";
};
export type DeviceListItem_device$key = {
  readonly " $data"?: DeviceListItem_device$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceListItem_device">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeviceListItem_device",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "descendantDeviceInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};

(node as any).hash = "b3524b6a26484b3d1dc0d86d633a7d3e";

export default node;
