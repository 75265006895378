import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Environment } from 'relay-runtime';

const RootContext = React.createContext<{
  relayEnvironment: Environment;
  setRelayEnvironment: (env: Environment) => void;
}>(undefined!);

export default RootContext;
