import React from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import { LocationListItemProps } from './types';
import { SectionListItem_section$key } from './__generated__/SectionListItem_section.graphql';
import DeviceList from '../../DeviceList/DeviceList';
import { sectionListItemStyles } from './styles';

const SectionListItem: React.FC<LocationListItemProps> = (props) => {
  const section = useFragment<SectionListItem_section$key>(
    graphql`
      fragment SectionListItem_section on Section {
        name
        devices {
          ...DeviceList_devices
        }
      }
    `,
    // eslint-disable-next-line react/destructuring-assignment
    props.section,
  );

  return (
    <div css={sectionListItemStyles}>
      <h2>{section.name}</h2>
      <DeviceList devices={section.devices} />
    </div>
  );
};

export default SectionListItem;
