import { css, SerializedStyles, Theme } from '@emotion/react';
import dsmMediaQuery from '../../../../utils/media-queries/media-queries';

const assetsListStyles = css`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  ${dsmMediaQuery('medium device')} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0.5rem;
  }
  ${dsmMediaQuery('large device')} {
    grid-template-columns: 1fr;
  }
`;

const sectionListStyles = css`
  grid-row-gap: 2rem;
  ${dsmMediaQuery('medium device')} {
    grid-row-gap: 1rem;
  }
`;

const assetGroupHeader = css`
  display: grid;
  align-items: center;
  grid-template-columns: 1.5rem max-content;
  grid-gap: 0.5rem;
  padding: 1rem 0 1rem 0;
`;

const textStyles = (theme: Theme): SerializedStyles => css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  vertical-align: middle;
  font-size: 1.1rem;
  color: ${theme.colors.secondaryText};
  font-weight: 600;
  ${dsmMediaQuery('large device')} {
    align-self: center;
  }
`;

export { assetGroupHeader, assetsListStyles, textStyles, sectionListStyles };
