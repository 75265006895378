import React, { PropsWithChildren } from 'react';
import { tileFooterStyles } from './styles';
import { TileFooterProps } from './types';

const TileFooter: React.FC<PropsWithChildren<TileFooterProps>> = ({ className, children }) => (
  <div className={className} css={[tileFooterStyles]}>
    {children}
  </div>
);

export default TileFooter;
