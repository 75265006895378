import React, { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useLazyLoadQuery } from 'relay-hooks';
import sentryCaptureError from '../../utils/sentry/sentry-capture-error';
import { Loader, ErrorFallback, ErrorBoundary, SystemOkCheckmark } from '../../components';
import { headingLargeStyles, headingSmallDimmedStyles, pageStyles } from '../../styles';
import { eventPageStyles, subTitleStyles, tabContentContainer, titleStyles } from './styles';
import usePageViewed from '../../adobe-launch/hooks/usePageViewed';
import { EventsQuery } from './__generated__/EventsQuery.graphql';
import EventList from './EventList/EventList';

const Events: React.FC = () => {
  const { t } = useTranslation();
  usePageViewed('Events');

  const { data, retry } = useLazyLoadQuery<EventsQuery>(
    graphql`
      query EventsQuery($exclude: EventState, $cursor: String, $count: Int) {
        events(exclude: $exclude, first: $count, after: $cursor) {
          totalCount
        }
        ...EventList_items @arguments(exclude: $exclude, count: $count)
      }
    `,
    { exclude: 'CLEARED', count: 30 },
  );

  useEffect(() => {
    retry({}, { fetchPolicy: 'store-and-network' });
  }, [retry]);

  return (
    <div css={[pageStyles, eventPageStyles]}>
      <h1 css={[titleStyles, headingLargeStyles]}>{t('WP.COMMON.SDCS_TITLE')}</h1>
      <h4 css={[headingSmallDimmedStyles, subTitleStyles]}>{t('WP.ENTRY.SUB_TITLE')}</h4>
      <div css={tabContentContainer}>
        <ErrorBoundary
          onError={(error): void => sentryCaptureError(error, 'tabContentContainer')}
          FallbackComponent={ErrorFallback}
        >
          <Suspense fallback={<Loader />}>
            {data?.events?.totalCount ? (
              <EventList eventsData={data} />
            ) : (
              <SystemOkCheckmark
                actionText={t('WP.ALERTS.SYSTEM_OK_NO_ACTION')}
                infoText={t('WP.ENTRY.NO_ACTIVE_EVENTS')}
              />
            )}
          </Suspense>
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default Events;
