import React from 'react';
import { useTheme, SerializedStyles } from '@emotion/react';

const Waves: React.FC<{ styles?: SerializedStyles }> = ({ styles }) => {
  const { colors } = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" css={styles}>
      <path
        fill={colors.tertiaryText}
        fillOpacity="0.5"
        d="M0,192L30,170.7C60,149,120,107,180,106.7C240,107,300,149,360,181.3C420,213,480,235,540,218.7C600,203,660,149,720,138.7C780,128,840,160,900,181.3C960,203,1020,213,1080,202.7C1140,192,1200,160,1260,154.7C1320,149,1380,171,1410,181.3L1440,192L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"
      />
      <path
        fill={colors.tertiaryText}
        fillOpacity="0.5"
        d="M0,160L48,176C96,192,192,224,288,245.3C384,267,480,277,576,277.3C672,277,768,267,864,229.3C960,192,1056,128,1152,112C1248,96,1344,128,1392,144L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
      />
      <path
        fill={colors.tertiaryText}
        fillOpacity="0.5"
        d="M0,224L48,218.7C96,213,192,203,288,202.7C384,203,480,213,576,213.3C672,213,768,203,864,208C960,213,1056,235,1152,234.7C1248,235,1344,213,1392,202.7L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
      />
    </svg>
  );
};

export default Waves;
