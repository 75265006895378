interface Step {
  path: string;
  key: string;
}

const base: Step[] = [
  { path: '/onboarding/create-or-select-customer', key: 'WP.ONBOARDING.STEP_CUSTOMER_SELECT' },
  { path: '/onboarding/customer-summary', key: 'WP.ONBOARDING.STEP_CUSTOMER_SUMMARY' },
  { path: '/onboarding/installation', key: 'WP.ONBOARDING.STEP_INSTALLATION' },
  { path: '/onboarding/summary', key: 'WP.ONBOARDING.STEP_SUMMARY' },
];

const getSteps = (hasMoreAccounts: boolean): Step[] => {
  let steps = base;

  const accountStep: Step = { path: '/onboarding/create-account', key: 'WP.ONBOARDING.STEP_ACCOUNT' };

  if (hasMoreAccounts) {
    steps = [accountStep, ...steps];
  }

  return steps;
};

export default getSteps;
