import { useFormikContext } from 'formik';
import React from 'react';
import { AnalogSettings, FormValues } from '../types';
import ManualDosing from './ManualDosingForm';
import PulseDosing from './PulseDosingForm';
import AnalogDosing from './AnalogDosingForm';

const DosinControlForm: React.FC<{
  manualDosingFlowUnit: string | null;
  analogSettings: AnalogSettings;
}> = ({ manualDosingFlowUnit, analogSettings }) => {
  const { values } = useFormikContext<FormValues>();

  switch (values.controlMode) {
    case 'MANUAL':
      return <ManualDosing manualDosingFlowUnit={manualDosingFlowUnit} />;
    case 'PULSE':
      return <PulseDosing />;
    case 'ANALOG_0_20':
      return (
        <AnalogDosing
          key={values.controlMode}
          analogSettings={analogSettings}
          lowerInputRuleViolation="ZeroToTwentyLowerInputCurrent"
        />
      );
    case 'ANALOG_4_20':
      return (
        <AnalogDosing
          key={values.controlMode}
          analogSettings={analogSettings}
          lowerInputRuleViolation="FourToTwentyLowerInputCurrent"
        />
      );
    default:
      return null;
  }
};

export default DosinControlForm;
