import React from 'react';
import { graphql, useFragment } from 'relay-hooks';
import { OverviewChemPairingDDAQuery$data } from '../../../DDA/__generated__/OverviewChemPairingDDAQuery.graphql';
// eslint-disable-next-line camelcase
import { AlertsTileRelay_device$key, EventType } from './__generated__/AlertsTileRelay_device.graphql';
import { AlertsTile } from './AlertsTile';
import { AlertType } from './types';
import { OverviewChemPairingDDACQuery$data } from '../../../DDAC/__generated__/OverviewChemPairingDDACQuery.graphql';

const applyAlert = (type?: EventType | undefined): AlertType => {
  switch (type) {
    case 'WARNING':
      return 'warning';
    case 'ALARM':
      return 'alarm';
    default:
      return 'ok';
  }
};

export interface DeviceProps {
  device: OverviewChemPairingDDAQuery$data | OverviewChemPairingDDACQuery$data;
  goToEvents: () => void;
}

export const AlertsTileRelay: React.FC<DeviceProps> = ({ device, goToEvents }) => {
  // eslint-disable-next-line camelcase
  const data = useFragment<AlertsTileRelay_device$key>(
    graphql`
      fragment AlertsTileRelay_device on Device {
        latestSync {
          outOfSync
        }
        newestAlert {
          code
          type
        }
      }
    `,
    device.node,
  );

  const newestAlertCode = data?.newestAlert?.code || '';
  const alertStatus = applyAlert(data?.newestAlert?.type);

  if (data?.latestSync?.outOfSync) {
    // We set alertStatus to undefined to trigger no data view
    return <AlertsTile newestAlertCode={newestAlertCode} alertStatus={undefined} goToEvents={goToEvents} />;
  }

  return <AlertsTile newestAlertCode={newestAlertCode} alertStatus={alertStatus} goToEvents={goToEvents} />;
};

export default AlertsTileRelay;
