/* eslint-disable jsx-a11y/anchor-is-valid */
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { Tile, TileBody, TileHeader } from '../../../../../components/Tiles';
import warningIcon from '../../../../../assets/images/alerts/warning.svg';
import okIcon from '../../../../../assets/images/alerts/ok.svg';
import alarmIcon from '../../../../../assets/images/alerts/alarm.svg';
import { AlertType } from './types';
import {
  alertsText,
  alertsImg,
  alertsContainer,
  tileBodyStyles,
  scrollContentWrapperStyles,
  allEventsContainer,
  alertsDescriptionText,
  buttonStyles,
} from './styles';
import { Button, NoDataAvailableMessage } from '../../../../../components';
import { useEventCodeTranslations } from '../../../../../utils/mappings/event-code-translations';

const AlertComponent = ({ alertStatus, newestAlertCode }: { alertStatus?: AlertType; newestAlertCode: string }) => {
  const { t } = useTranslation();
  const code = useEventCodeTranslations(newestAlertCode);

  switch (alertStatus) {
    case 'warning':
      return (
        <div css={alertsContainer}>
          <img src={warningIcon} css={alertsImg} alt="warning icon" />
          <p css={alertsText} data-test-id="warning-msg">
            {t('WP.ALERTS.WARNING')}
          </p>
          <p css={alertsDescriptionText}>{code}</p>
        </div>
      );
    case 'alarm':
      return (
        <div css={alertsContainer}>
          <img src={alarmIcon} css={alertsImg} alt="alarm icon" />
          <p css={alertsText} data-test-id="alarm-msg">
            {t('WP.ALERTS.ALARM')}
          </p>
          <p css={alertsDescriptionText}>{code}</p>
        </div>
      );
    case 'ok':
      return (
        <div css={alertsContainer}>
          <img src={okIcon} css={alertsImg} alt="ok icon" />
          <p css={alertsText} data-test-id="warning-msg">
            {t('WP.ALERTS.SYSTEM_OK')}
          </p>
          <p css={alertsDescriptionText}>{t('WP.ALERTS.SYSTEM_OK_NO_ACTION')}</p>
        </div>
      );
    default:
      return (
        <div css={alertsContainer}>
          <NoDataAvailableMessage size="text" message={t('WP.ERROR.NO_DATA')} />
        </div>
      );
  }
};

export interface ConditionMonitorProps {
  alertStatus?: AlertType;
  goToEvents: () => void;
  newestAlertCode: string;
}

export const AlertsTile: React.FC<ConditionMonitorProps> = ({ alertStatus, newestAlertCode, goToEvents }) => {
  const { t } = useTranslation();

  return (
    <Tile
      key="condMonitor"
      styles={css`
        grid-area: conditionMonitor;
      `}
      Header={
        <TileHeader
          styles={css`
            padding-bottom: 1rem;
          `}
          text={t('WP.OVERVIEW.ALARMS_AND_WARNINGS')}
        />
      }
      Body={
        <div css={scrollContentWrapperStyles}>
          <TileBody css={tileBodyStyles}>
            <AlertComponent alertStatus={alertStatus} newestAlertCode={newestAlertCode} />
          </TileBody>
        </div>
      }
      Footer={
        Boolean(alertStatus) && (
          <div css={allEventsContainer}>
            <Button css={buttonStyles} type="button" variant="text link" onClick={goToEvents}>
              {t('WP.ALERTS.SEE_ALL_EVENTS')}
            </Button>
          </div>
        )
      }
    />
  );
};
