import { css, Theme } from '@emotion/react';
import dsmMediaQuery from '../../../../utils/media-queries/media-queries';

const containerStyles = css`
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-areas:
    'remote'
    'button';
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content;
  gap: 1rem;
  ${dsmMediaQuery('medium device')} {
    grid-template-areas:
      'remote remote'
      '.  button';
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: max-content 1fr;
    gap: 1rem;
  }
`;

const remoteContainerStyles = (theme: Theme) => css`
  grid-area: remote;
  padding: 1rem 1rem 2.5rem 1rem;
  background-color: ${theme.colors.neutral.neutral1000.hex};
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  grid-template-rows: max-content 1fr 1fr;
  ${dsmMediaQuery('medium device')} {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: max-content 1fr;
  }
`;

const borderStyles = (theme: Theme) =>
  css`
    border-bottom: 0.06rem solid ${theme.colors.border.subtle};
    padding-bottom: 0.5rem;
  `;

const textInputStyles = css`
  grid-template-areas: 'label input' '. error';
  padding-bottom: unset;
`;

const formFieldsStyles = css`
  display: flex;
  flex-direction: column;
  .info-item-label {
    padding-left: 1rem;
  }
  .toggler-label {
    padding-left: 1rem;
  }
  label {
    padding-left: 1rem;
  }
`;

const buttonContainerStyles = css`
  grid-area: button;
  display: flex;
  gap: 1rem;
  justify-content: end;
  padding-bottom: 2rem;
  flex-direction: column;
  ${dsmMediaQuery('medium device')} {
    flex-direction: row;
  }
`;

const getProgressContainerStyles = css`
  grid-row: 1;
  grid-column: 1;
`;

const spinnerModalStyles = (theme: Theme) => css`
  height: 100%;
  width: 100%;
  z-index: 100;
  position: relative;
  background: ${theme.colors.primaryBackgroundTransparent};
`;

const paragraphStyles = css`
  padding-bottom: 1rem;
`;

const remoteContainerContentStyles = css`
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-template-columns: 1fr;
  padding-right: 1rem;
`;

const sectionStyles = css`
  display: flex;
  flex-direction: column;
`;

const flowControlContainer = css`
  display: grid;
  grid-column: 1;
  grid-row: 3;
  grid-template-columns: 1fr;
  padding-right: 1rem;
  ${dsmMediaQuery('medium device')} {
    grid-row: 2;
    grid-column-start: 2;
    grid-column-end: 3;
  }
`;

const tooltipIcon = (theme: Theme) => css`
  height: 1.1rem;
  width: 1.1rem;
  fill: ${theme.colors.neutral.neutral0.hex};
  padding-bottom: 0.1rem;
`;

const icon = (theme: Theme) => css`
  height: 1.1rem;
  width: 1.1rem;
  fill: ${theme.colors.neutral.neutral0.hex};
  margin-right: 0.5rem;
`;

const messageContainer = css`
  flex: 1;
  display: flex;
  align-self: stretch;
  margin-bottom: 2rem;
  align-items: center;
`;

const remoteHeader = css`
  grid-column-start: 1;
  grid-column-end: 4;
`;

export {
  containerStyles,
  remoteContainerStyles,
  buttonContainerStyles,
  spinnerModalStyles,
  paragraphStyles,
  remoteContainerContentStyles,
  borderStyles,
  messageContainer,
  textInputStyles,
  formFieldsStyles,
  sectionStyles,
  getProgressContainerStyles,
  flowControlContainer,
  tooltipIcon,
  remoteHeader,
  icon,
};
