import React from 'react';
import { useTranslation } from 'react-i18next';
import { CurrentDisplayMode } from '../types';
import { InfoItem } from '../../../../../components';
import { gridPlacement } from '../styles';
import { PumpStatusIcon } from '../../../../../components/Icons';

const PumpDisplayStatus = ({ pumpStatus }: { pumpStatus: CurrentDisplayMode }) => {
  const { t } = useTranslation();
  return (
    <InfoItem
      css={gridPlacement('pumpStatus')}
      label={t('WP.OVERVIEW.PUMP_OPERATING_STATE')}
      value={t(`WP.OVERVIEW.${pumpStatus.toLocaleUpperCase()}`)}
      Icon={<PumpStatusIcon status={pumpStatus} />}
    />
  );
};

export default PumpDisplayStatus;
