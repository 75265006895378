import React from 'react';
import { IconProps } from './types';

const Warning: React.FC<IconProps> = ({ color, size, styles, x, y }) => (
  <svg css={styles} x={x} y={y} width={size} height={size} viewBox="0 0 16 16" data-test-id="warning-icon">
    <g id="Asset-Artboard-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Alarm-Active-Copy-2-Warning-Inactive" transform="translate(0.568069, 0.000000)" fill={color}>
        <path
          d="M8,1 L15,15 L1,15 L8,1 Z M8,12 C7.42666667,12 7,12.436 7,13.008 C7,13.564 7.42666667,14 8,14 C8.55866667,14 9,13.564 9,13.008 C9,12.436 8.55866667,12 8,12 Z M9,6 L7,6 L7.5,11 L8.5,11 L9,6 Z"
          id="Combined-Shape-Copy"
        />
      </g>
    </g>
  </svg>
);

export default Warning;
