import { css, Theme } from '@emotion/react';
import { headingSmallStyles } from '../../styles';

const checkmarkContainer = css`
  display: grid;
  justify-content: center;
  align-content: center;
`;

const checkmarkContent = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
`;

const checkmarkText = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const checkmarkWhiteText = (theme: Theme) => css`
  ${headingSmallStyles}
  color:${theme.colors.neutral.neutral100.hex};
  line-height: 1.25rem;
`;

const actionTextStyles = css`
  font-weight: 400;
  line-height: 1rem;
`;

export { checkmarkContainer, checkmarkContent, checkmarkText, checkmarkWhiteText, actionTextStyles };
