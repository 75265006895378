/**
 * @generated SignedSource<<b7b3b47ee36678fe77e67a2ae01317c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type InstallationDetailsQuery$variables = {
  id: string;
};
export type InstallationDetailsQuery$data = {
  readonly clientState: {
    readonly onboarding: {
      readonly onboardingId: string | null;
      readonly installationName: string | null;
      readonly section: {
        readonly address: string | null;
        readonly postalCode: string | null;
        readonly city: string | null;
        readonly country: string | null;
        readonly state: string | null;
      } | null;
    } | null;
  } | null;
  readonly __typename: string;
  readonly node: {
    readonly installationNote?: string;
  } | null;
};
export type InstallationDetailsQuery = {
  variables: InstallationDetailsQuery$variables;
  response: InstallationDetailsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "installationNote",
      "storageKey": null
    }
  ],
  "type": "UnclaimedDevice",
  "abstractKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "onboardingId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "installationName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "OnboardingSection",
  "kind": "LinkedField",
  "name": "section",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postalCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "country",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InstallationDetailsQuery",
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "kind": "ClientExtension",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientState",
            "kind": "LinkedField",
            "name": "clientState",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Onboarding",
                "kind": "LinkedField",
                "name": "onboarding",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InstallationDetailsQuery",
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v3/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      {
        "kind": "ClientExtension",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientState",
            "kind": "LinkedField",
            "name": "clientState",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Onboarding",
                "kind": "LinkedField",
                "name": "onboarding",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "519b099a23cca3a1790a285c4547c3eb",
    "id": null,
    "metadata": {},
    "name": "InstallationDetailsQuery",
    "operationKind": "query",
    "text": "query InstallationDetailsQuery(\n  $id: ID!\n) {\n  __typename\n  node(id: $id) {\n    __typename\n    ... on UnclaimedDevice {\n      installationNote\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "3f3183bbed34e5b81f1d940f532c354f";

export default node;
