import React from 'react';
import { useTheme } from '@emotion/react';
import { animated, useSpring } from '@react-spring/web';
import { batteryBarContainer, cellStyles, labelMax, labels, labelThreshold, container } from './styles';
import { BatteryBarProps } from './types';
import { labelStyles } from '../../styles';
import { colorMe, createCells } from './utils';

const Cell = ({ color, first }: { color: string; first: boolean }) => {
  const theme = useTheme();

  const fade = useSpring({
    from: { backgroundColor: first ? `${theme.colors.event.warning}` : `${theme.colors.event.none}` },
    to: { backgroundColor: color },
    config: { duration: 300 },
  });

  return <animated.div data-testid="battery-cell" css={cellStyles} style={fade} />;
};

const BatteryBar = ({ value, maxValue, threshold, testId, children }: BatteryBarProps) => {
  const thresholdPercentage = threshold / maxValue;
  const cells = createCells(maxValue, threshold);
  const theme = useTheme();

  return (
    <div css={container} data-testid={`${testId}-battery-bar`}>
      {children}
      <div css={batteryBarContainer}>
        {cells.map((cell, index) => {
          const color = colorMe(value, threshold, cell.value, {
            green: `${theme.colors.event.none}`,
            yellow: `${theme.colors.event.warning}`,
            empty: `${theme.colors.neutral.neutral800.hex}`,
          });

          return <Cell key={cell.value} color={color} first={index === 0} />;
        })}
      </div>
      <div css={[labelStyles, labels]}>
        {threshold < maxValue ? <div css={labelThreshold(thresholdPercentage)}>{threshold}</div> : null}
        <div css={labelMax}>{maxValue}</div>
      </div>
    </div>
  );
};

export default BatteryBar;
