import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql } from 'react-relay/hooks';
import { useLazyLoadQuery } from 'relay-hooks';
import { toGlobalId } from 'graphql-relay';
import { useParams } from 'react-router-dom';
import { NoDataAvailableMessage } from '../../../../components';
import dataPointsGrouping from '../../Overview/OverviewChemPairing/DosedVolume/dataPointsGrouping';
import { MoreDataDDACQuery } from './__generated__/MoreDataDDACQuery.graphql';
import { GeniIdentity } from '../../types';
import MoreDataTabManual from './MoreDataTabManual';
import MoreDataTabPulse from './MoreDataTabPulse';
import MoreDataTabAnalog from './MoreDataTabAnalog';
import MoreDataTabUnsupportedControlMode from './MoreDataTabUnsupportedControlMode';

const MoreDataDDAC = ({
  endDate,
  startDate,
  geniIdentity,
}: {
  endDate: number;
  startDate: number;
  geniIdentity: GeniIdentity;
}) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const moreDataTabQuery = graphql`
    query MoreDataDDACQuery($id: ID!, $endDate: Int!, $startDate: Int!, $geniIdentity: GeniIdentity!) {
      node(id: $id) {
        ... on Device {
          descendantDeviceInfo {
            ... on DDAC {
              dosedChemical {
                actualProportionalConcentration {
                  unit
                  quantity
                }
                targetProportionalConcentration {
                  unit
                  quantity
                }
                total {
                  quantity
                  unit
                }
              }
              chemicalContainer {
                connectedChemical
                chemicalConnectedBy
                connectedTotalVolume {
                  quantity
                  unit
                }
                chemicalConnectionDate
                calculatedContainerVolume {
                  quantity
                  unit
                }
                thresholdValueForCalculatedPremptyAlert {
                  quantity
                  unit
                }
              }
              deviceOperation {
                totalOperatingHours
                motorOperatingHours
              }
              pumpSettings {
                controlMode
                dosingSettings {
                  controlModes {
                    manual {
                      quantity
                      unit
                    }
                    pulse {
                      mlPerPulse
                      pulseRate {
                        quantity
                        unit
                      }
                      meterType
                    }
                    analog {
                      proportionalValidation {
                        remedy
                        ruleViolations
                      }
                      pump {
                        lowerInput {
                          electricCurrent {
                            quantity
                            unit
                          }
                          dosingVolume {
                            quantity
                            unit
                          }
                        }
                        higherInput {
                          electricCurrent {
                            quantity
                            unit
                          }
                          dosingVolume {
                            quantity
                            unit
                          }
                        }
                      }
                      process {
                        lowerInput {
                          electricCurrent {
                            quantity
                            unit
                          }
                          dosingVolume {
                            quantity
                            unit
                          }
                        }
                        higherInput {
                          electricCurrent {
                            quantity
                            unit
                          }
                          dosingVolume {
                            quantity
                            unit
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          moreDataTabTimeSeriesDatapoints: timeSeriesDatapoints(
            datapointSelectors: [
              { name: "sdcs:DOSED_VOLUME_HOURLY", raw: true, geniIdentity: $geniIdentity }
              { name: "sdcs:DOSED_VOLUME_HOURLY_START", raw: true, geniIdentity: $geniIdentity }
              { name: "sdcs:VOLUME_PROCESSED", raw: true, geniIdentity: $geniIdentity }
              { name: "sdcs:VOLUME_PROCESSED_HOURLY", raw: true, geniIdentity: $geniIdentity }
            ]
            startDate: $startDate
            endDate: $endDate
          ) {
            name
            unit
            latestDatapoint {
              valueRaw
            }
            datapoints {
              createdAt
              valueRaw
            }
          }
        }
      }
    }
  `;

  const { data, retry } = useLazyLoadQuery<MoreDataDDACQuery>(moreDataTabQuery, {
    id: toGlobalId('Device', id || ''),
    endDate,
    startDate,
    geniIdentity,
  });

  useEffect(() => {
    retry({}, { fetchPolicy: 'store-and-network' });
  }, [retry]);

  if (!data?.node) return <NoDataAvailableMessage message={t('WP.ERROR.NO_DATA')} />;

  const { descendantDeviceInfo } = data?.node || {};
  const { dosedChemical } = descendantDeviceInfo || {};
  const { controlMode, dosingSettings } = descendantDeviceInfo?.pumpSettings || {};
  const { controlModes } = dosingSettings || {};
  // PULSE DOSING TILE
  const { mlPerPulse } = controlModes?.pulse || {};
  const { actualProportionalConcentration, targetProportionalConcentration, total } =
    descendantDeviceInfo?.dosedChemical || {};
  const { pulse, analog } = controlModes || {};
  const { pulseRate, meterType } = pulse || {};
  const pumpLowerInput = analog?.pump?.lowerInput;
  const pumpHigherInput = analog?.pump?.higherInput;
  const settingsLowerInput = analog?.process?.lowerInput;
  const settingsHigherInput = analog?.process?.higherInput;

  const dosedProcessVolLastHour = data.node?.moreDataTabTimeSeriesDatapoints?.find(
    (dp) => dp?.name === 'VOLUME_PROCESSED_HOURLY',
  );

  // Dosed volume tile (MANUAL)
  const dosedVolLastHour = data.node?.moreDataTabTimeSeriesDatapoints?.find((dp) => dp.name === 'DOSED_VOLUME_HOURLY');
  const dosedVolLastHourStart = data.node?.moreDataTabTimeSeriesDatapoints?.find(
    (dp) => dp.name === 'DOSED_VOLUME_HOURLY_START',
  );

  // We use the dataPointsGrouping here to align with the way we do on the manual dosing part
  // In future we should get the data needed from our graphql instead.
  // We are overfetching here and sometimes this data will not be relevant (e.g. for prorppostional dosing)
  const { volumeLastHour } = dataPointsGrouping(
    dosedVolLastHour?.datapoints || [],
    dosedVolLastHourStart?.datapoints || [],
    // We only need data from the last hour
    1,
  );

  // DOSING COUNTER TILE
  // only proportional
  const totalVolumeProcessLiquid = data.node?.moreDataTabTimeSeriesDatapoints?.find(
    (dp) => dp.name === 'VOLUME_PROCESSED',
  );
  const { totalOperatingHours, motorOperatingHours } = descendantDeviceInfo?.deviceOperation || {};

  const chemicalContainer = descendantDeviceInfo?.chemicalContainer;

  switch (controlMode) {
    case 'MANUAL':
      return (
        <MoreDataTabManual
          chemicalContainer={chemicalContainer}
          totalDosedChemical={total}
          motorOperatingHours={motorOperatingHours}
          totalOperatingHours={totalOperatingHours}
          dosedChecmicalLastHour={volumeLastHour}
          dosedChecmicalLastHourUnit={dosedVolLastHour?.unit}
          manualDosingFlow={controlModes?.manual}
        />
      );
    case 'PULSE':
      return (
        <MoreDataTabPulse
          chemicalContainer={chemicalContainer}
          totalDosedChemical={total}
          motorOperatingHours={motorOperatingHours}
          totalOperatingHours={totalOperatingHours}
          dosedChecmicalLastHour={volumeLastHour}
          dosedChecmicalLastHourUnit={dosedVolLastHour?.unit}
          meterType={meterType}
          pulseRate={pulseRate}
          mlPerPulse={mlPerPulse}
          dosedProcessVolLastHour={{
            quantity: dosedProcessVolLastHour?.latestDatapoint.valueRaw,
            unit: dosedProcessVolLastHour?.unit,
          }}
          actualProportionalConcentration={actualProportionalConcentration}
          targetProportionalConcentration={targetProportionalConcentration}
          totalVolumeProcessLiquid={{
            quantity: totalVolumeProcessLiquid?.latestDatapoint.valueRaw,
            unit: totalVolumeProcessLiquid?.unit,
          }}
        />
      );
    case 'ANALOG_0_20':
    case 'ANALOG_4_20':
      return (
        <MoreDataTabAnalog
          chemicalContainer={chemicalContainer}
          totalDosedChemical={dosedChemical?.total}
          motorOperatingHours={motorOperatingHours}
          totalOperatingHours={totalOperatingHours}
          dosedChecmicalLastHour={volumeLastHour}
          dosedChecmicalLastHourUnit={dosedVolLastHour?.unit}
          dosedProcessVolLastHour={{
            quantity: dosedProcessVolLastHour?.latestDatapoint.valueRaw,
            unit: dosedProcessVolLastHour?.unit,
          }}
          actualProportionalConcentration={actualProportionalConcentration}
          targetProportionalConcentration={targetProportionalConcentration}
          totalVolumeProcessLiquid={{
            quantity: totalVolumeProcessLiquid?.latestDatapoint.valueRaw,
            unit: totalVolumeProcessLiquid?.unit,
          }}
          pumpHigherInput={pumpHigherInput}
          pumpLowerInput={pumpLowerInput}
          settingsHigherInput={settingsHigherInput}
          settingsLowerInput={settingsLowerInput}
          proportionalRuleValidation={analog?.proportionalValidation}
        />
      );
    default:
      return (
        <MoreDataTabUnsupportedControlMode
          chemicalContainer={chemicalContainer}
          totalDosedChemical={dosedChemical?.total}
          motorOperatingHours={motorOperatingHours}
          totalOperatingHours={totalOperatingHours}
        />
      );
  }
};

export default MoreDataDDAC;
