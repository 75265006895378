import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoItem } from '../../../../../components';
import { infoItemStyles, removeBorder } from '../styles';
import { convertToSuperScript } from '../../../utils';
import DataBox from './Databox';

import {
  PROPORTIONAL_DOSED_VOLUME_LAST_HOUR,
  PROPORTIONAL_DOSED_VOL_LAST_HOUR,
} from '../../../../../../translations-keys';
import { OptionalNumber, OptionalQuantityUnit, OptionalString } from '../../types';

const ProportionalLastHour: React.FC<{
  dosedChecmicalLastHour: OptionalNumber;
  dosedChecmicalLastHourUnit: OptionalString;
  dosedProcessVolLastHour: OptionalQuantityUnit;
  actualProportionalConcentration: OptionalQuantityUnit;
}> = ({
  actualProportionalConcentration,
  dosedChecmicalLastHour,
  dosedChecmicalLastHourUnit,
  dosedProcessVolLastHour,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <DataBox
        // We use the same translation key here as on the tile. We have seen issues where it is important that they align
        label={t('WP.VOLUMETRIC.CONCENTRATION.TILE_LAST_HOUR')}
        value={actualProportionalConcentration?.quantity?.toFixed(1)}
        unit={convertToSuperScript(actualProportionalConcentration?.unit || '')}
      />
      <InfoItem
        css={infoItemStyles}
        label={t(PROPORTIONAL_DOSED_VOL_LAST_HOUR)}
        value={dosedChecmicalLastHour?.toFixed(4)}
        unit={dosedChecmicalLastHourUnit}
      />
      <InfoItem
        css={[infoItemStyles, removeBorder]}
        label={t(PROPORTIONAL_DOSED_VOLUME_LAST_HOUR)}
        value={dosedProcessVolLastHour?.quantity?.toFixed(4)}
        unit={dosedProcessVolLastHour?.unit}
      />
    </>
  );
};

export default ProportionalLastHour;
