import { css, Theme } from '@emotion/react';

const labelStyles = (isToggled: boolean, isDisabled: boolean, theme: Theme) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${isDisabled ? 'auto' : 'pointer'};
  width: 3rem;
  height: 1.5rem;
  background: ${isDisabled ? theme.colors.neutral.neutral600.hex : theme.colors.neutral.neutral400.hex};
  border-radius: 6.25rem;
  position: relative;
  transition: background-color 0.2s;
  ${isToggled && !isDisabled && `background:${theme.colors.blue.blue3.baseColor};`};
  ${isToggled && isDisabled && `background: #0068B47F;`};
`;

const checkboxStyles = css`
  position: absolute;
  height: 0;
  width: 0;
  visibility: hidden;
`;

const togglerStyles = (isToggled: boolean, isDisabled: boolean, theme: Theme) => css`
  content: '';
  position: absolute;
  top: 0.125rem;
  left: 0.125rem;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 2.813rem;
  transition: 0.2s;
  background: ${isDisabled ? theme.colors.neutral.neutral400.hex : theme.colors.neutral.neutral0.hex};
  box-shadow: 0 0 0.063rem 0 rgba(0, 0, 0, 0.16);
  &:active {
    width: 1.875rem;
  }
  ${isToggled &&
  `left: calc(100% - 0.125rem);
  transform: translateX(-100%);`}
`;

const containerStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0rem;
`;

export { checkboxStyles, labelStyles, togglerStyles, containerStyles };
