import { css, Theme } from '@emotion/react';
import { paragraphBaseStyles } from '../../../../../styles';

const borderStyles = (theme: Theme) =>
  css`
    border-bottom: 0.06rem solid ${theme.colors.border.subtle};
    padding-bottom: 0.5rem;
  `;

const getProgressContainerStyles = css`
  grid-row: 1;
  grid-column: 1;
`;

const paragraphStyles = css`
  padding-bottom: 1rem;
`;

const sectionStyles = css`
  display: flex;
  flex-direction: column;
`;

const tooltipIcon = (theme: Theme) => css`
  height: 1.1rem;
  width: 1.1rem;
  fill: ${theme.colors.neutral.neutral0.hex};
  padding-bottom: 0.1rem;
`;

const icon = (theme: Theme) => css`
  height: 1.1rem;
  width: 1.1rem;
  fill: ${theme.colors.neutral.neutral0.hex};
  margin-right: 0.5rem;
`;

const messageContainer = css`
  flex: 1;
  display: flex;
  align-self: stretch;
  margin-bottom: 2rem;
  align-items: center;
`;

const stoppedLocally = (theme: Theme) => css`
  ${paragraphBaseStyles}
  color: ${theme.colors.neutral.neutral600.hex}
`;

export {
  paragraphStyles,
  borderStyles,
  messageContainer,
  sectionStyles,
  getProgressContainerStyles,
  tooltipIcon,
  icon,
  stoppedLocally,
};
