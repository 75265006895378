import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { InfoItem, Toggler } from '../../../../../components';
import { mapSlowMode } from '../types';
import {
  RemoteLayout,
  Header as RemoteHeader,
  Section as RemoteSection,
  ReadField,
  ShowActiveReadField,
  PressureSlider,
  ReadOnlyInfoItem,
} from '../components';
import { formFieldsStyles } from './styles';
import { ReadOnlyFormProps } from './types';
import AnalogReadFields from './AnalogReadFields';

const ReadOnlyForm: React.FC<ReadOnlyFormProps> = ({ settings, data, Message }) => {
  const { t } = useTranslation();
  const {
    manualDosingFlowUnit,
    pumpVariant,
    maxCapacityUnit,
    maxPressureUnit,
    minPressureUnit,
    pressureMinRange,
    pressureMaxRange,
    analog,
  } = settings;
  const {
    controlMode,
    pumpState,
    mlPerPulse,
    pulseMemory,
    manualDosingFlowValue,
    autoDeaeration,
    slowMode,
    flowControl,
    flowControlAutoFlowAdapt,
    maxPressureValue,
    flowControlDelay,
    flowControlSensitivity,
    minPressureAsAlarm,
    maxCapacity,
    stopAfterPowerFailure,
    minPressureValue,
  } = data;

  const isArPump = pumpVariant === 'AR';
  const flowControlIsOn = flowControl === true;

  return (
    <RemoteLayout
      testId="read-only-form"
      Message={Message}
      Header={
        <RemoteHeader
          title={t('WP.REMOTE.REMOTE')}
          controlMode={controlMode !== undefined ? t(`WP.REMOTE.${controlMode}`) : t(`WP.REMOTE.CONTROL_MODE_UNKNOWN`)}
        />
      }
      OperationModeSection={
        <RemoteSection
          css={css`
            padding-bottom: 2rem;
          `}
          title={t('WP.REMOTE.OPERATION_MODE')}
        >
          <ReadOnlyInfoItem
            label={t('WP.REMOTE.DOSING_PUMP')}
            hasError={!pumpState}
            value={t(`WP.REMOTE.${pumpState}`)}
            testId="remote-dosing-pump-read"
          />
        </RemoteSection>
      }
      ControlModeSection={
        <RemoteSection title={t('WP.REMOTE.CONTROL_MODE_SETTINGS')}>
          <ReadOnlyInfoItem
            value={maxCapacity}
            label={`${t('WP.REMOTE.MAXIMUM_CAPACITY')} ${maxCapacityUnit && `(${maxCapacityUnit})`}`}
            testId="remote-maximum-capacity-read"
          />
          <ReadOnlyInfoItem
            // We need to use hasError when the value is always truthy like here: value={t(`WP.REMOTE.${controlMode}`)}
            hasError={!controlMode}
            label={t('WP.REMOTE.CONTROL_MODE')}
            value={t(`WP.REMOTE.${controlMode}`)}
            testId="remote-control-mode-read"
          />
          <div>
            {controlMode === 'PULSE' && (
              <div css={formFieldsStyles}>
                <ReadOnlyInfoItem
                  label={t('WP.REMOTE.PULSE_DOSING_VOL')}
                  className="info-item"
                  value={mlPerPulse}
                  testId="pulse-dosing-mlPerPulse-read"
                />
                <ReadField
                  isHidden={false}
                  value={pulseMemory}
                  noValueLabel={t('WP.REMOTE.PULSE_MEMORY')}
                  hideDivider={false}
                >
                  <Toggler
                    label={t('WP.REMOTE.PULSE_MEMORY')}
                    isDisabled
                    isToggled={pulseMemory}
                    id="pulseMemory"
                    testId="pulse-dosing-mlPerPulse-read"
                  />
                </ReadField>
              </div>
            )}
            {(controlMode === 'ANALOG_0_20' || controlMode === 'ANALOG_4_20') && <AnalogReadFields analog={analog} />}
            {controlMode === 'MANUAL' && (
              <div css={formFieldsStyles}>
                <ReadOnlyInfoItem
                  label={`${t('WP.REMOTE.MANUAL_DOSING_FLOW')} ${manualDosingFlowUnit && `(${manualDosingFlowUnit})`}`}
                  className="info-item"
                  value={manualDosingFlowValue}
                  testId="manual-dosing-flow-read"
                />
              </div>
            )}
          </div>
        </RemoteSection>
      }
      FlowControlSection={
        <RemoteSection title={t('WP.REMOTE.FLOW_CONTROL_TITLE')}>
          <ReadField isHidden={false} value={autoDeaeration} noValueLabel={t('WP.REMOTE.AUTO_DEAERATION')}>
            <Toggler
              label={t('WP.REMOTE.AUTO_DEAERATION')}
              id="autoDeaeration"
              isToggled={Boolean(autoDeaeration)}
              isDisabled
            />
          </ReadField>
          <ReadOnlyInfoItem
            testId="remote-slow-mode-read"
            label={t('WP.REMOTE.SLOW_MODE')}
            value={t(`WP.REMOTE.SLOW_MODEL_OPTION_${`${mapSlowMode(slowMode) || undefined}`}`)}
          />
          <ReadField
            isHidden={false}
            value={stopAfterPowerFailure}
            noValueLabel={t('WP.REMOTE.STOP_AFTER_POWER_FAILURE')}
          >
            <Toggler
              label={t('WP.REMOTE.STOP_AFTER_POWER_FAILURE')}
              id="stopAfterPowerFailure"
              isToggled={Boolean(stopAfterPowerFailure)}
              isDisabled
              testId="stop-after-power-failure-read"
            />
          </ReadField>
          <ReadField isHidden={isArPump} value={flowControl} noValueLabel={t('WP.REMOTE.FLOW_CONTROL_ON_OFF')}>
            <Toggler
              label={<div>{t('WP.REMOTE.FLOW_CONTROL_ON_OFF')}</div>}
              id="flowControl"
              isDisabled
              isToggled={flowControlIsOn}
            />
          </ReadField>
          <div
            css={css`
              padding-left: 1rem;
            `}
          >
            <ShowActiveReadField
              label={t('WP.REMOTE.FLOW_CONTROL_DELAY_LABEL')}
              showActive={flowControlIsOn}
              value={t('WP.REMOTE.FLOW_CONTROL_NOT_ACTIVE')}
              isHidden={isArPump}
            >
              <InfoItem
                noBorder
                label={t('WP.REMOTE.FLOW_CONTROL_DELAY_LABEL')}
                value={t(`WP.REMOTE.FLOW_DELAY_OPTION_${flowControlDelay}`)}
              />
            </ShowActiveReadField>
            <ShowActiveReadField
              label={t('WP.REMOTE.FLOW_CONTROL_SENSIVITY_LABEL')}
              showActive={flowControlIsOn}
              value={t('WP.REMOTE.FLOW_CONTROL_NOT_ACTIVE')}
              isHidden={isArPump}
            >
              <InfoItem
                noBorder
                label={t('WP.REMOTE.FLOW_CONTROL_SENSIVITY_LABEL')}
                value={t(`WP.REMOTE.REMOTE_FLOW_SENS_OPTION_${flowControlSensitivity}`)}
              />
            </ShowActiveReadField>
            <ShowActiveReadField
              label={t('WP.REMOTE.FLOW_CONTROL_AUTO_FLOW_ADAPT_LABEL')}
              showActive={flowControlIsOn}
              value={t('WP.REMOTE.FLOW_CONTROL_NOT_ACTIVE')}
              isHidden={isArPump}
            >
              <Toggler
                label={t('WP.REMOTE.FLOW_CONTROL_AUTO_FLOW_ADAPT_LABEL')}
                id="autoFlowAdapt"
                isToggled={flowControlAutoFlowAdapt}
                isDisabled
              />
            </ShowActiveReadField>
            <ShowActiveReadField
              testId="flow-control-pressure-read"
              label={t('WP.REMOTE.FLOW_CONTROL_PRESSURE_TITLE')}
              showActive={flowControlIsOn}
              value={t('WP.REMOTE.FLOW_CONTROL_NOT_ACTIVE')}
              isHidden={isArPump}
            >
              <>
                <InfoItem noBorder label={t('WP.REMOTE.FLOW_CONTROL_PRESSURE_TITLE')} />
                <PressureSlider
                  pressureMaxRange={pressureMaxRange}
                  pressureMinRange={pressureMinRange}
                  maxPressureUnit={maxPressureUnit}
                  minPressureUnit={minPressureUnit}
                  minPressureValue={minPressureValue}
                  maxPressureValue={maxPressureValue}
                  isDisabled
                  testId="flow-control-pressure-read"
                />
              </>
            </ShowActiveReadField>
            <ShowActiveReadField
              label={t('WP.REMOTE.FLOW_CONTROL_MIN_PRESSURE_LABEL')}
              showActive={flowControlIsOn}
              value={t('WP.REMOTE.FLOW_CONTROL_NOT_ACTIVE')}
              isHidden={isArPump}
            >
              <Toggler
                label={t('WP.REMOTE.FLOW_CONTROL_MIN_PRESSURE_LABEL')}
                id="minPressureAsAlarm"
                isToggled={minPressureAsAlarm}
                isDisabled
              />
            </ShowActiveReadField>
          </div>
        </RemoteSection>
      }
    />
  );
};

export default ReadOnlyForm;
