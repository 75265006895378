import { css, SerializedStyles, Theme } from '@emotion/react';

const subNavigationItemArrowStyles = css`
  margin-bottom: -0.25rem;
`;

const subNavigationItemImageOverlayContentStyles = css`
  width: 100%;
  height: 100%;
`;

const subNavigationItemImageOverlayStyles = css`
  padding: 1rem;
  color: black;

  width: 100%;
  height: 100%;
`;

// Needed Outer and Inner wrapper to make border line to not extend the padding
const subNavigationItemStylesOuter = (theme: Theme): SerializedStyles => css`
  padding: 1rem 1rem 0rem 1rem;
  display: flex;
  :hover {
    background-color: ${theme.colors.navigation.hover};
  }
`;

const subNavigationItemStylesInner = (theme: Theme): SerializedStyles => css`
  border-bottom: 0.063rem solid ${theme.colors.border.subtle};
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
`;

const subNavigationItemTextStyles = (theme: Theme): SerializedStyles => css`
  color: ${theme.colors.secondaryText};
  font-size: 1.1rem;
`;

export {
  subNavigationItemImageOverlayContentStyles,
  subNavigationItemImageOverlayStyles,
  subNavigationItemArrowStyles,
  subNavigationItemStylesOuter,
  subNavigationItemTextStyles,
  subNavigationItemStylesInner,
};
