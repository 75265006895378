import React from 'react';
import { useTranslation } from 'react-i18next';
import operationUpdate from '../../../../../assets/images/operationUpdate.svg';
import { InfoItem, InfoItemIcon } from '../../../../../components';
import { isNotUndefinedOrNull } from '../../../utils';
import { gridPlacement } from '../styles';

const IsPumping = ({ isPumping }: { isPumping: boolean | undefined }) => {
  const { t } = useTranslation();
  return (
    <InfoItem
      css={gridPlacement('isPumping')}
      label={t('WP.OVERVIEW.PUMP_RUNNING')}
      value={isNotUndefinedOrNull(isPumping) ? t(`WP.COMMON.${isPumping ? 'YES' : 'NO'}`) : t('WP.ERROR.NO_DATA')}
      Icon={isPumping ? <InfoItemIcon iconSrc={operationUpdate} /> : undefined}
      testId="pump-status-is-pumping"
    />
  );
};

export default IsPumping;
