/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { css } from '@emotion/react';
import { Formik, Form } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { TFunction, useTranslation } from 'react-i18next';
import { Button, TextInputField, DropDownSelect, InfoItem } from '../../../../components';
import dsmMediaQuery from '../../../../utils/media-queries/media-queries';
import { contentStyles, dropDownStyle, formStyles, note, noteLabel, noteText } from './styles';
import { FormValues } from './types';
import countries from '../../countries';
import { usStates } from '../../states';

import { heading05Styles, paragraphBaseStyles, paragraphBrightStyles } from '../../../../styles';

const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    name: Yup.string().required(t('WP.ONBOARDING.CUSTOMER_DETAILS_NAME_ERROR')),
    address: Yup.string(),
    postalCode: Yup.string(),
    city: Yup.string(),
    country: Yup.string(),
  });

interface CustomerSummaryFormProps {
  initialValues: FormValues;
  sendFormData: (formValues: FormValues) => void;
  claimId: string;
  onBack: () => void;
  installationNote: string;
}

const CustomerSummaryForm: React.FC<CustomerSummaryFormProps> = ({
  initialValues,
  sendFormData,
  claimId,
  onBack,
  installationNote,
}) => {

  const { t, i18n } = useTranslation();
  const selectedLanguage = i18n.language;
  const regionName = new Intl.DisplayNames([selectedLanguage], { type: 'region' });

  return (
    <div css={contentStyles}>
      <h1 css={heading05Styles}>{t('WP.ONBOARDING.CUSTOMER_DETAILS_TITLE')}</h1>
      <br />
      <label htmlFor="installationNote" css={note}>
        <div css={[paragraphBaseStyles, noteLabel]}>{t('WP.ONBOARDING.INSTALLATION_NOTE_LABEL')}</div>
        <div css={[paragraphBrightStyles, noteText]}>{installationNote}</div>
      </label>
      <InfoItem
        css={css`
          grid-area: claim;
        `}
        label={t('WP.ONBOARDING.CUSTOMER_CLAIM_CODE_LABEL')}
        value={claimId}
        noBorder
      />
      <Formik initialValues={initialValues} validationSchema={validationSchema(t)} onSubmit={sendFormData}>
        {({ values }) => (
          <Form css={formStyles((values.country || '').toLocaleLowerCase().includes('us'))}>
            <TextInputField
              isVertical
              css={css`
                grid-area: name;
              `}
              name="name"
              placeholder={t('WP.ONBOARDING.CUSTOMER_DETAILS_NAME_PLACEHOLDER')}
              label={t('WP.ONBOARDING.CUSTOMER_DETAILS_NAME_LABEL')}
            />
            <TextInputField
              isVertical
              css={css`
                grid-area: address;
              `}
              name="address"
              placeholder={t('WP.ONBOARDING.CUSTOMER_DETAILS_ADDRESS_PLACEHOLDER')}
              label={t('WP.ONBOARDING.CUSTOMER_DETAILS_ADDRESS_LABEL')}
            />
            <TextInputField
              isVertical
              css={css`
                grid-area: postalCode;
              `}
              name="postalCode"
              placeholder={t('WP.ONBOARDING.CUSTOMER_DETAILS_POSTAL_PLACEHOLDER')}
              label={t('WP.ONBOARDING.CUSTOMER_DETAILS_POSTAL_LABEL')}
            />
            <TextInputField
              isVertical
              css={css`
                grid-area: city;
              `}
              name="city"
              placeholder={t('WP.ONBOARDING.CUSTOMER_DETAILS_CITY_PLACEHOLDER')}
              label={t('WP.ONBOARDING.CUSTOMER_DETAILS_CITY_LABEL')}
            />
            <DropDownSelect
              name="country"
              label={t('WP.ONBOARDING.CUSTOMER_DETAILS_COUNTRY_LABEL')}
              css={dropDownStyle('country')}
            >
              {countries.map(({ id, countryCode }) => (
                <option key={id} value={countryCode}>
                  {regionName.of(countryCode)}
                </option>
              ))}
            </DropDownSelect>
            {(values.country || '').toLocaleLowerCase().includes('us') && (
              <DropDownSelect
                name="state"
                label={t('WP.ONBOARDING.CUSTOMER_DETAILS_STATE_LABEL')}
                css={dropDownStyle('state')}
              >
                {usStates.map(({ name, code }) => (
                  <option key={code} value={name}>
                    {name}
                  </option>
                ))}
              </DropDownSelect>
            )}
            <div
              css={css`
                grid-area: back;
                padding-top: 2rem;
                ${dsmMediaQuery('medium device')} {
                  padding-top: 2rem;
                }
              `}
            >
              <Button type="button" variant="secondary" onClick={onBack}>
                {t('WP.COMMON.BACK')}
              </Button>
            </div>
            <div
              css={css`
                grid-area: next;
                ${dsmMediaQuery('medium device')} {
                  justify-self: right;
                  padding-top: 0rem;
                  align-self: end;
                }
              `}
            >
              <Button type="submit" variant="primary">
                {t('WP.COMMON.NEXT')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CustomerSummaryForm;
