import * as React from 'react';
import { IconProps } from './types';

const CloseOutline: React.FC<IconProps> = ({ color, size, styles, x, y }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" css={styles} x={x} y={y} height={size} width={size}>
    <path
      fill={color}
      d="M24.64 8.71l-1.42-1.42-7.29 7.3-7.29-7.3-1.42 1.42 7.3 7.29-7.3 7.29 1.42 1.42 7.29-7.3 7.29 7.3 1.42-1.42-7.3-7.29 7.3-7.29z"
    />
  </svg>
);

export default CloseOutline;
