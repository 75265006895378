import { Theme, css } from '@emotion/react';
import dsmMediaQuery from '../../../../../utils/media-queries/media-queries';
import { paragraphBaseStyles } from '../../../../../styles';

const tileBodyStyles = css`
  display: grid;
  grid-gap: 1rem;
  padding-top: 1rem;
  height: 100%;
  padding-bottom: 1rem;
  justify-items: flex-start;
`;

const buttonStyles = css`
  ${paragraphBaseStyles}
  height: unset;
  width: unset;
  min-width: unset;
  padding: 0;
  text-transform: unset;
`;

const containerStyles = (theme: Theme) => css`
  grid-area: overview;
  display: grid;
  background: ${theme.colors.neutral.neutral1000.hex};
  ${dsmMediaQuery('medium device')} {
    grid-template-columns: 1fr max-content 1fr;
  }
  ${dsmMediaQuery('large device')} {
    grid-template-columns: 1fr max-content 2fr;
  }
  ${dsmMediaQuery('extra large device')} {
    grid-template-columns: 1fr max-content 1fr;
  }
`;

const tileStyles = css`
  grid-column: 1/4;
  ${dsmMediaQuery('medium device')} {
    grid-column: 3/4;
  }
`;

const imageStyles = (theme: Theme) => css`
  display: none;
  ${dsmMediaQuery('medium device')} {
    background: ${theme.colors.neutral.neutral1000.hex};
    display: unset;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`;

const contactPersonText = css`
  padding-bottom: 0.5rem;
`;

const addressStyles = css`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(2, max-content);
`;

const verticalLineStyles = (theme: Theme) => css`
  display: none;
  ${dsmMediaQuery('medium device')} {
    display: unset;
    border-right: 0.0625rem solid ${theme.colors.border.subtle};
    height: calc(100% - 2rem);
    margin: 1rem 0;
`;

export {
  buttonStyles,
  tileBodyStyles,
  containerStyles,
  imageStyles,
  addressStyles,
  contactPersonText,
  tileStyles,
  verticalLineStyles,
};
