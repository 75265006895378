import React from 'react';
import { headingSmallDimmedStyles } from '../../../../styles';

const RemoteHeader = ({ title, controlMode }: { title: string; controlMode?: string }) => (
  <span css={[headingSmallDimmedStyles]}>
    {title} / {controlMode}
  </span>
);

export default RemoteHeader;
