import { css, SerializedStyles, Theme } from '@emotion/react';

const batteryBarContainer = css`
  display: flex;
  gap: 0.125rem;
`;

const container = css`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const labels = css`
  position: relative;
  font-weight: unset;
`;

const labelMax = css`
  position: absolute;
  right: 0;
`;

const labelThreshold = (threshold: number): SerializedStyles => css`
  position: absolute;
  left: ${threshold * 100}%;
  /* we translate it 50 percent back to center the element relative to the bar */
  transform: translate(-50%, 0);
`;

const barLabelValueUnitContainer = css`
  display: inline-grid;
  grid-template-columns: minmax(0, 1fr) max-content;
  gap: 1rem;
  justify-content: space-between;
  align-items: baseline;
`;

const barUpperLabel = css`
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const valueUnitContainer = css`
  display: inline-grid;
  grid-template-columns: repeat(2, max-content);
  gap: 0.25rem;
  align-items: baseline;
  justify-content: end;
`;

const valueStyles = css`
  font-weight: unset;
`;

const unitLabelStyles = (theme: Theme) => css`
  font-size: 0.813rem;
  color: ${theme.colors.neutral.neutral100.hex};
  line-height: 1rem;
`;

const cellStyles = css`
  height: 0.25rem;
  flex-grow: 1;
`;

export {
  container,
  batteryBarContainer,
  labels,
  labelMax,
  labelThreshold,
  barUpperLabel,
  barLabelValueUnitContainer,
  valueUnitContainer,
  valueStyles,
  unitLabelStyles,
  cellStyles,
};
