import React, { useState } from 'react';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Alarm, Warning } from '../../../components/Icons';
import { useEventCodeTranslations } from '../../../utils/mappings/event-code-translations';
import { DateTimeFormatter } from '../../../components';
import { Alert } from './types';
import { paragraphDimmedStyles } from '../../../styles';
import { rowContainer, alertItem, alertIconCenter, alertIconText } from './styles';

const AlertsListItem = ({ alert }: { alert: Alert }) => {
  const alertName = useEventCodeTranslations(alert.code);
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState(false);
  const { t } = useTranslation();

  return (
    <div css={rowContainer} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <div css={alertItem({ theme, isHovered })}>
        {alert.type === 'ALARM' && (
          <div css={alertIconCenter}>
            <Alarm color={theme.colors.event.alarm} size="1.7rem" />
            <span css={alertIconText}>{t('WP.ALERTS.ALARM')}</span>
          </div>
        )}
        {alert.type === 'WARNING' && (
          <div css={alertIconCenter}>
            <Warning color={theme.colors.event.warning} size="1.7rem" />
            <span css={alertIconText}>{t('WP.ALERTS.WARNING')}</span>
          </div>
        )}
      </div>
      <div css={alertItem({ theme, isHovered })}>{alertName}</div>
      <div css={[alertItem({ theme, isHovered }), paragraphDimmedStyles]}>
        {alert.initiator || t('WP.ALERTS.GRID_ITEM_DOSING_PUMP')}
      </div>
      <div css={[alertItem({ theme, isHovered }), paragraphDimmedStyles]}>
        <DateTimeFormatter timestamp={alert.createdAt} />
      </div>
    </div>
  );
};
export default AlertsListItem;
