import { css, SerializedStyles, Theme } from '@emotion/react';
import dsmMediaQuery from '../../../../utils/media-queries/media-queries';

const overviewChemPairingStyles = css`
  ${dsmMediaQuery('small device')} {
    grid-template-rows: repeat(6, 19.813rem);
    grid-template-areas:
      'overview'
      'conditionMonitor'
      'containerLevel'
      'timeToService'
      'volumetric'
      'pumpStatus';
  }
  ${dsmMediaQuery('medium device')} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, minmax(19.813rem, 1fr));
    grid-template-areas:
      'overview overview'
      'conditionMonitor containerLevel'
      'timeToService pumpStatus'
      'volumetric volumetric';
  }
  ${dsmMediaQuery('extra large device')} {
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-areas:
      'overview conditionMonitor containerLevel'
      'volumetric pumpStatus timeToService';
  }
`;

const longTileStyles = css`
  ${dsmMediaQuery('large device')} {
    grid-column: span 1;
  }
`;

const tileBodyStyles = css`
  display: grid;
  align-content: center;
  justify-items: center;
`;

const checkmarkContainerTileStyles = css`
  div {
    grid-template-columns: 1fr;
  }
  span {
    text-align: center;
    line-height: 1.8rem;
    p:first-child {
      font-size: 1.2rem;
    }
  }
`;

const scrollContentWrapperStyles = css`
  padding: 0 1rem;
`;

const simpleBarStyles = {
  maxHeight: '14.5rem',
};

const chemicalConnectedTileFooter = css`
  text-align: unset;
  justify-items: start;
  display: grid;
  grid-template-rows: max-content max-content;
  grid-gap: 0.5rem;
`;

const footerBoldText = (theme: Theme): SerializedStyles => css`
  color: ${theme.colors.white.primary};
  font-weight: 600;
`;

const noDataStyles = css`
  ${dsmMediaQuery('medium device')} {
    font-size: 1rem;
    margin: unset;
  }
`;

export {
  overviewChemPairingStyles,
  longTileStyles,
  tileBodyStyles,
  checkmarkContainerTileStyles,
  scrollContentWrapperStyles,
  simpleBarStyles,
  chemicalConnectedTileFooter,
  footerBoldText,
  noDataStyles,
};
