import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';

const Portal: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const portalRoot = document.getElementById('portal-root');

  const container = useMemo(() => document.createElement('div'), []);

  useEffect(() => {
    if (portalRoot) portalRoot.appendChild(container);

    return (): void => {
      container.remove();
    };
  });

  return portalRoot ? createPortal(children, portalRoot) : null;
};

export default Portal;
