import React from 'react';

const GFLogo: React.FC<{}> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.602 37.796" width="28" height="26">
    <g fill="#FEFEFE">
      <path d="M13.954 37.796H3.807l14.15-14.16s.857.518 2.694 2.336c1.864 1.843 2.415 2.704 2.415 2.704l-9.112 9.12z" />
      <path d="M9.275 26.798C3.903 20.254 2.407 17.4 1.041 14.001c-.87-2.166-1.949-6.406.277-8.633 1.969-1.968 5.847-1.288 9.753.463 4.476 2.003 9.64 5.66 14.495 10.201L41.602 0v10.127l-14.668 14.68s-1.591-1.659-2.407-2.462C15.912 13.869 7.916 9.759 5.924 11.717c-2.666 2.624 5.559 12.169 5.845 12.593l-2.494 2.488zM41.602 37.796h-5.71s-.661-1.883-2.67-5.025c-1.98-3.098-3.787-4.865-3.787-4.865l3.849-3.846c1.554 1.862 3.204 4.04 4.727 6.477 1.413 2.262 2.724 4.689 3.591 7.259z" />
    </g>
  </svg>
);

export default GFLogo;
