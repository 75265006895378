import React, { useCallback } from 'react';
import { toGlobalId } from 'graphql-relay';
import { useTranslation } from 'react-i18next';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { NoDataAvailableMessage, ProgressiveImage, Tiles } from '../../../components';
import CalculatedContainerLevel from '../Overview/OverviewChemPairing/CalculatedContainerLevel/CalculatedContainerLevel';
import DosedVolume from '../Overview/OverviewChemPairing/DosedVolume/DosedVolume';
import PumpStatusData from './PumpStatus/PumpStatusData';
import TimeToService from './TimeToService/TimeToService';
import { overviewChemPairingStyles } from '../Overview/OverviewChemPairing/styles';
import { AlertsTileRelay } from '../Overview/OverviewChemPairing/AlertsTile/AlertsTileRelay';
import DeviceQuery, { OverviewChemPairingDDAQuery } from './__generated__/OverviewChemPairingDDAQuery.graphql';
import InfoContainerDDAData from './InfoContainerDDAData';
import useRelayRefresh from '../useRelayRefresh';
import OverviewTile from '../Overview/OverviewChemPairing/OverviewTile/OverviewTile';
import dda from '../../../assets/images/dda.png';
import ddaPlaceholder from '../../../assets/images/dda_tiny.png';
import VolumetricConcentrationData from '../Overview/OverviewChemPairing/VolumetricConcentration/components/VolumetricConcentrationData';

interface OverviewChemPairingProps {
  startDate: number;
  endDate: number;
  id: string;
  goToEvents: () => void;
}

const defaultDDAGeniIdentity = { familyCode: 30, unitType: 1, unitVersion: 1 };

const OverviewChemPairingDDA: React.FC<OverviewChemPairingProps> = ({ startDate, endDate, id, goToEvents }) => {
  const { t } = useTranslation();
  const queryOptions = useRelayRefresh(startDate, endDate, id, DeviceQuery, defaultDDAGeniIdentity);
  const data = useLazyLoadQuery<OverviewChemPairingDDAQuery>(
    graphql`
      query OverviewChemPairingDDAQuery($id: ID!, $startDate: Int!, $endDate: Int!, $geniIdentity: GeniIdentity!) {
        node(id: $id) {
          ... on Device {
            latestDeviceData {
              dataPoints {
                value
                name
              }
            }
            ...useOverviewData_device
            ...InfoContainerDDAData_device
            ...useInfoContainerData_device
            ...TimeToService_device
            ...VolumetricConcentrationData @arguments(endDate: $endDate)
            ...DosedVolume_device @arguments(startDate: $startDate, endDate: $endDate)
            ...CalculatedContainerLevel_device @arguments(endDate: $endDate)
            ...PumpStatusDataDDA_device
            ...AlertsTileRelay_device
          }
        }
      }
    `,
    {
      id: toGlobalId('Device', id),
      startDate,
      endDate,
      geniIdentity: defaultDDAGeniIdentity,
    },
    queryOptions,
  );

  // InfoContainerDDAData types are more restrict than OverviewTile device.
  // OverviewTile can both handle OverviewChemPairingDDAQuery and OverviewChemPairingDDACQuery device
  // We therefore wrap the InfoContainerDDAData indside the modal here with the specific device it needs
  const Modal = useCallback(
    // eslint-disable-next-line react/no-unused-prop-types
    ({ onClose }: { onClose: () => void }) => <InfoContainerDDAData onClose={onClose} device={data} />,
    [data],
  );

  if (!data.node) return <NoDataAvailableMessage message={t('WP.ERROR.NO_DATA')} />;

  const controlMode = data?.node?.latestDeviceData?.dataPoints?.find((dp) => dp?.name === 'gfdm:CONTROL_MODE');

  return (
    <Tiles styles={overviewChemPairingStyles}>
      <OverviewTile
        device={data}
        ModalComponent={Modal}
        Image={<ProgressiveImage placeholderSrc={ddaPlaceholder} src={dda} alt="dda pump" />}
      />
      <AlertsTileRelay device={data} goToEvents={goToEvents} />
      <CalculatedContainerLevel device={data} />
      {/* controlMode.value = 1 is pulse mode and 0 is manual, 2, 3 and 4 is not supported yet */}
      {controlMode?.value === 1 ? (
        <VolumetricConcentrationData device={data} controlMode="PULSE" />
      ) : (
        <DosedVolume device={data} />
      )}
      <PumpStatusData device={data} />
      <TimeToService device={data} />
    </Tiles>
  );
};

export default OverviewChemPairingDDA;
