import { useCallback, useEffect, useRef, useState } from 'react';
import { toGlobalId } from 'graphql-relay';
import { FetchPolicy, GraphQLTaggedNode, fetchQuery, useRelayEnvironment } from 'react-relay/hooks';

const UPDATE_INTERVAL = 10000;

// Refresh a lazyload query essentially requires the fetchKey to be updated: https://relay.dev/docs/next/guided-tour/refetching/refreshing-queries/#when-using-uselazyloadquery
// But refreshing a lazyload query without triggering Suspense requires manual fetchQuery request: https://relay.dev/docs/next/guided-tour/refetching/refreshing-queries/#if-you-need-to-avoid-suspense-1
const useRelayRefresh = <DeviceQuery extends GraphQLTaggedNode>(
  startDate: number,
  endDate: number,
  id: string,
  deviceQuery: DeviceQuery,
  defaultDDACGeniIdentity: { familyCode: number; unitType: number; unitVersion: number },
) => {
  const [refreshedQueryOptions, setRefreshedQueryOptions] = useState<
    | {
        fetchKey: number;
        fetchPolicy: FetchPolicy;
      }
    | undefined
  >();
  const isRefreshing = useRef(false);
  const environment = useRelayEnvironment();
  const refresh = useCallback(() => {
    if (isRefreshing.current) {
      return;
    }
    isRefreshing.current = true;
    // fetchQuery will fetch the query and write
    // the data to the Relay store. This will ensure
    // that when we re-render, the data is already
    // cached and we don't suspend
    fetchQuery(environment, deviceQuery, {
      id: toGlobalId('Device', id),
      startDate,
      endDate,
      geniIdentity: defaultDDACGeniIdentity,
    }).subscribe({
      complete: () => {
        isRefreshing.current = false;

        // *After* the query has been fetched, we update
        // our state to re-render with the new fetchKey
        // and fetchPolicy.
        // At this point the data for the query should
        // be cached, so we use the 'store-only'
        // fetchPolicy to avoid suspending.
        setRefreshedQueryOptions((prev) => ({
          fetchKey: (prev?.fetchKey ?? 0) + 1,
          fetchPolicy: 'store-only',
        }));
      },
      error: () => {
        isRefreshing.current = false;
      },
    });
  }, [defaultDDACGeniIdentity, deviceQuery, endDate, environment, id, startDate]);

  useEffect(() => {
    // Initiate refresh immidiatately
    refresh();
    // Refresh after 10 seconds, and then follow the interval
    const interval = setInterval(refresh, UPDATE_INTERVAL);
    return () => clearInterval(interval);
  }, [refresh]);

  return refreshedQueryOptions;
};

export default useRelayRefresh;
