import React from 'react';
import { SerializedStyles, Theme } from '@emotion/react';
import { navigationStyles, stickyNavigationStyles } from './styles';
import { NavigationProps } from './types';

const Navigation: React.FC<NavigationProps> = ({ className, Items, Logo, styles }) => (
  <nav
    className={className}
    css={(theme: Theme): SerializedStyles[] =>
      styles
        ? [navigationStyles(theme), stickyNavigationStyles, styles]
        : [navigationStyles(theme), stickyNavigationStyles]
    }
  >
    {Logo}
    {Items}
  </nav>
);

export default Navigation;
