import React from 'react';
import { icon, messageContainer } from './styles';
import tooltip from '../../../../../assets/images/tooltip.svg';
import { paragraphBrightStyles } from '../../../../../styles';

const Message = ({ message }: { message?: string | JSX.Element }) =>
  message ? (
    <div css={[messageContainer, paragraphBrightStyles]}>
      <img css={[icon, paragraphBrightStyles]} src={tooltip} alt="tooltip" />
      {message}
    </div>
  ) : null;

export default Message;
