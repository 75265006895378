import { toGlobalId } from 'graphql-relay';
import { useParams } from 'react-router';
import { useCallback } from 'react';
import { PreloadFetchPolicy, useQueryLoader } from 'react-relay';
import { useDisplayRemoteControlDataQuery as RemoteControlDataQuery } from './__generated__/useDisplayRemoteControlDataQuery.graphql';
import { remoteControlDataQuery } from './useDisplayRemoteControlData';

// Used for retrieving all data related to remote control. It can be used for first render and subsequent calls, e.g. in a polling function.
const useFetchRemoteControlData = () => {
  const { id } = useParams<{ id: string }>();
  const nodeId = toGlobalId('Device', id || '');
  const [fetchRemoteControlDataRef, loadQuery] = useQueryLoader<RemoteControlDataQuery>(remoteControlDataQuery);

  const fetchRemoteControlData = useCallback(
    (fetchPolicy: PreloadFetchPolicy = 'store-and-network') =>
      loadQuery(
        {
          id: nodeId,
        },
        { fetchPolicy },
      ),
    [loadQuery, nodeId],
  );

  return { fetchRemoteControlData, fetchRemoteControlDataRef };
};

export default useFetchRemoteControlData;
