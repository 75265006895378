import React from 'react';
import { useTheme } from '@emotion/react';
import { dividerStyles } from './styles';
import { DividerProps } from './types';

const Divider: React.FC<DividerProps> = ({ marginRight, marginLeft, className }) => {
  const theme = useTheme();

  return <hr css={[dividerStyles(theme, marginRight, marginLeft)]} className={className} />;
};

export default Divider;
