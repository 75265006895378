import { css, SerializedStyles, Theme } from '@emotion/react';
import dsmMediaQuery from '../../utils/media-queries/media-queries';

const supportContainerStyles = css`
  position: fixed;
  z-index: 2002;
  top: 1.5rem;
  right: 1.5rem;

  ${dsmMediaQuery('medium device')} {
    position: absolute;
    bottom: 5.5rem;
    right: 2.5rem;
    top: unset;
  }

  ${dsmMediaQuery('large device')} {
    bottom: 2rem;
  }
`;

const supportBtnStyles = (theme: Theme, isTooltipOpen: boolean): SerializedStyles => css`
  position: relative;
  height: 3rem;
  width: 3rem;
  background-color: ${theme.colors.primary};
  border-radius: 50%;
  cursor: pointer;
  border: none;
  outline: none;
  user-select: none;
  transition: all 0.3s ease-in-out;

  ${isTooltipOpen &&
  `
    transform: scale(0.8);
    img {
      -webkit-animation: rotation 0.5s;
      animation: rotation 0.5s;
    }
    `}

  @-webkit-keyframes rotation {
    0% {
      @-webkit-keyframes-transform: rotate(0deg);
    }
    100% {
      @-webkit-keyframes-transform: rotate(180deg);
    }
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }
`;

const popupContentStyles = (theme: Theme): SerializedStyles => css`
  position: absolute;
  top: 0;
  right: 3.5rem;
  display: grid;
  grid-row-gap: 0.3rem;
  font-size: 0.9rem;
  background-color: ${theme.colors.tertiaryBackground};
  color: ${theme.colors.secondaryText};
  padding: 0.9rem;
  border-radius: 0.3rem;
  ::after {
    content: '';
    position: absolute;
    bottom: 4rem;
    right: -1rem;
    margin-left: 1rem;
    border-width: 0.5rem;
    border-style: solid;
    border-color: transparent transparent transparent ${theme.colors.tertiaryBackground};
  }

  ${dsmMediaQuery('medium device')} {
    bottom: 3.5rem;
    right: 0.5rem;
    top: unset;
    ::after {
      content: '';
      position: absolute;
      bottom: -0.9rem;
      right: 1rem;
      margin-left: 1rem;
      border-width: 0.5rem;
      border-style: solid;
      border-color: ${theme.colors.tertiaryBackground} transparent transparent transparent;
    }
  }

  ${dsmMediaQuery('large device')} {
    a[href*='tel:'] {
      pointer-events: none;
    }
  }
`;

export { supportContainerStyles, supportBtnStyles, popupContentStyles };
