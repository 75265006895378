/**
 * @generated SignedSource<<ae1692259168c529bb182e8ce6d105bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EventCode = "CAVITATION" | "LOW_PRESSURE" | "AIR_BUBBLES" | "OVERPRESSURE" | "PREASSURE_VALVE_LEAKAGE" | "SUCTION_VALVE_LEAKAGE" | "SERVICE_NOW" | "SERVICE_SOON" | "UNDERDOSING" | "DIAPHRAGM_BREAK" | "BLOCKED_MOTOR" | "PREEMPTY_CONTAINER_FLOATER_SWITCH" | "PREEMPTY_CONTAINER_CALCULATED" | "EMPTY_CONTAINER" | "PRESSURE_SENSOR_OR_CABLE_BREAK" | "ANALOGUE_INPUT_OR_CABLE_BREAK" | "MAIN_NETWORK_COMMUNICATION_FAULT" | "CIM_HEARTBEAT_TIMEOUT" | "POWER_IN_FAIL";
export type EventType = "ALARM" | "WARNING";
import { FragmentRefs } from "relay-runtime";
export type LocationEventListItem_event$data = {
  readonly id: string;
  readonly createdAt: number;
  readonly device: {
    readonly name: string;
    readonly location: {
      readonly name: string | null;
    } | null;
  };
  readonly type: EventType;
  readonly code: EventCode;
  readonly " $fragmentType": "LocationEventListItem_event";
};
export type LocationEventListItem_event$key = {
  readonly " $data"?: LocationEventListItem_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"LocationEventListItem_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LocationEventListItem_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Device",
      "kind": "LinkedField",
      "name": "device",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "location",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};
})();

(node as any).hash = "f4247d5c4bdd671f896b7fa61c088ac0";

export default node;
