export interface Label {
  label: string;
  value: string;
}

export interface RemoteControlProps {
  deviceTwinId?: string | null;
}

export interface StatusMessage {
  progressmessage: string | null;
  messagetype: string | null;
}

export type StatusMessages = ReadonlyArray<StatusMessage | null> | null | undefined;

// eslint-disable-next-line no-shadow
export enum SupportedGeniValueName {
  START = 'gfdm:START',
  STOP = 'gfdm:STOP',
  PULSE = 'gfdm:PULSE_DOSING',
  MANUAL = 'gfdm:MANUAL_DOSING',
  PULSE_DOSING_VOLUME = 'gfdm:PULSE_DOSING_VOLUME',
  MANUAL_DOSING_VOLUME = 'gfdm:BUS_CTR_DOSING_CAPACITY',
  PULSE_MEMORY = 'gfdm:PULSE_MEMORY_ED',
  SLOW_MODE = 'gfdm:ANTI_CAVITATION_SETUP',
  AUTO_DEAERATION = 'gfdm:AUTO_DEAERATING_ED',
  MAX_PRESSURE_ALARM = 'gfdm:FLOW_MONITORING_PRESSURE_MAX',
  FLOW_CONTROL = 'gfdm:FLOW_MONITORING_ED',
  FLOW_CONTROL_SETUP = 'gfdm:FLOW_MONITORING_SETUP',
}

// eslint-disable-next-line no-shadow
export enum SlowModeValues {
  OFF = 'OFF',
  QUARTER = '25',
  HALF = '50',
}

// eslint-disable-next-line no-shadow
export enum DelayValues {
  SHORT = '0', // 0b00 // 00
  MEDIUM = '1', // 0b01 // 01
  LONG = '2', // 0b10 // 10
}

// eslint-disable-next-line no-shadow
export enum FlowSensitivityValues {
  LOW = '0',
  MEDIUM = '1',
  HIGH = '2',
}

export interface FormValues {
  autoDeaeration: boolean;
  operationMode: string;
  slowMode: string;
  controlMode: string;
  pulseDosingVolume: string;
  manualDosingVolume: string;
  pulseMemory: boolean;
  flowControl: boolean;
  delay: string;
  sensitivity: string;
  autoFlowAdapt: boolean;
  maxPressureAlarm: string;
  minPressureAsAlarm: boolean;
}
export interface GeniValue {
  name: string;
  value: string;
  unit: string;
}

export type PumpVariant = 'FCM' | 'FC' | 'AR';
