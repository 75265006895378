import { BreakPoints, BreakPointKey } from './types';

/**
 * For more info:
 * https://grundfos.invisionapp.com/dsm/grundfos/1-1-responsive-web/nav/5fa7cb018c0120001835479c/folder/5ed9fca9f3764c476d2b86ac?version=5fd0dd1d212dbe390c03b982&mode=preview
 *
 * Small devices: 320px and up
 * Medium devices:  768px and up
 * Large devices: 1024px and up
 * (Large devices w/ expanded navigation bar): 1200px and up
 * Extra large devices: 1440px and up
 */
export const breakPoints: BreakPoints = {
  'small device': 320,
  'medium device': 768,
  'large device': 1024,
  'extended navigation': 1200,
  'extra large device': 1440,
};

export const dsmMediaMinWidthSelector = (n: BreakPointKey): string => `(min-width: ${breakPoints[n]}px)`;
const dsmMediaQuery = (n: BreakPointKey): string => `@media ${dsmMediaMinWidthSelector(n)}`;

export default dsmMediaQuery;
