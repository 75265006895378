import { usePreloadedQuery } from 'react-relay';
import { graphql } from 'relay-hooks';
import { FormValues, RemoteControlSettings, RemoteQueryRef } from '../types';
import { useDisplayRemoteControlDataQuery as RemoteControlDataQuery } from './__generated__/useDisplayRemoteControlDataQuery.graphql';
import { RemoteControlDataQueryHook } from './types';

export const remoteControlDataQuery = graphql`
  query useDisplayRemoteControlDataQuery($id: ID!) {
    node(id: $id) {
      ... on Device {
        descendantDeviceInfo {
          ... on DDAC {
            pumpVariant
            pumpSettings {
              controlMode
              remoteControlEnabled
              stoppedBy
              isPumping
              slowMode
              autoDeaeration
              stopAfterPowerFailure
              flowControl {
                enabled
                autoFlowAdapt
                delay
                maxPressure {
                  unit
                  maxPressureValue
                  maxRange
                  minRange
                }
                minPressure {
                  unit
                  minPressureAsAlarm
                  minPressureValue
                  minRange
                  maxRange
                }
                sensitivity
              }
              dosingSettings {
                maxCapacity {
                  quantity
                  unit
                }
                capacity {
                  min
                  max
                  unit
                }
                controlModes {
                  pulse {
                    mlPerPulse
                    pulseMemory
                    dosingVolumeRange {
                      min
                      max
                    }
                  }
                  manual {
                    quantity
                    unit
                  }
                  analog {
                    proportionalValidation {
                      remedy
                      ruleViolations
                    }
                    pump {
                      lowerInput {
                        electricCurrent {
                          quantity
                          unit
                        }
                        dosingVolume {
                          quantity
                          unit
                        }
                      }
                      higherInput {
                        electricCurrent {
                          quantity
                          unit
                        }
                        dosingVolume {
                          quantity
                          unit
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

// Only responsible for retrieving data from our relay store in a way that we can render
// All data fetching should  have been done, that is also why we need a queryRef
const useDisplayRemoteControlData = (queryRef: RemoteQueryRef): RemoteControlDataQueryHook => {
  const data = usePreloadedQuery<RemoteControlDataQuery>(remoteControlDataQuery, queryRef);
  const pumpSettings = data?.node?.descendantDeviceInfo?.pumpSettings;
  const pumpVariant = data?.node?.descendantDeviceInfo?.pumpVariant;

  if (!pumpSettings || !pumpVariant) return {};

  const {
    controlMode,
    flowControl,
    isPumping,
    stoppedBy,
    slowMode,
    autoDeaeration,
    dosingSettings,
    remoteControlEnabled,
    stopAfterPowerFailure,
  } = pumpSettings;

  if (!dosingSettings || !flowControl) return {};

  const { enabled, autoFlowAdapt, delay, maxPressure, minPressure, sensitivity } = flowControl;

  const { pulse, manual, analog } = dosingSettings?.controlModes || {};
  const { unit: maxCapacityUnit, quantity: maxCapacityQuantity } = dosingSettings.maxCapacity || {};
  const { min, max, unit: capacityUnit } = dosingSettings.capacity || {};
  const { unit: maxPressureUnit, maxPressureValue, maxRange: pressureMaxRange } = maxPressure || {};
  const { unit: minPressureUnit, minPressureAsAlarm, minPressureValue, minRange: pressureMinRange } = minPressure || {};
  const pumpLowerInput = analog?.pump?.lowerInput;
  const pumpHigherInput = analog?.pump?.higherInput;

  if (!pulse || !manual) return {};
  const { min: minPulseDosing, max: maxPulseDosing } = pulse?.dosingVolumeRange || {};

  // Remote settings are values that are important to how and what we should render
  // They also provide utility to our label components, when we need to show units etc.
  // Also note that remoteControlSettings are values we should not send to our graphql using a mutation. Only formValues are meant to be send to the graphql
  const remoteControlSettings: RemoteControlSettings = {
    maxCapacityUnit: maxCapacityUnit !== null && maxCapacityUnit !== undefined ? maxCapacityUnit : null,
    pumpCapacity: {
      min,
      max,
      unit: capacityUnit,
    },
    pumpVariant,
    manualDosingFlowUnit: manual.unit,
    remoteControlEnabled,
    stoppedBy,
    maxPressureUnit: maxPressureUnit === undefined ? null : maxPressureUnit,
    minPressureUnit: minPressureUnit === undefined ? null : minPressureUnit,
    pressureMinRange,
    pressureMaxRange,
    pulseMinMax: { min: minPulseDosing, max: maxPulseDosing },
    analog: {
      proportionalValidation: analog?.proportionalValidation,
      lowerInput: {
        electricCurrent: {
          quantity: pumpLowerInput?.electricCurrent?.quantity,
          unit: pumpLowerInput?.electricCurrent?.unit,
        },
        dosingVolume: {
          quantity: pumpLowerInput?.dosingVolume?.quantity,
          unit: pumpLowerInput?.dosingVolume?.unit,
        },
      },
      higherInput: {
        electricCurrent: {
          quantity: pumpHigherInput?.electricCurrent?.quantity,
          unit: pumpHigherInput?.electricCurrent?.unit,
        },
        dosingVolume: {
          quantity: pumpHigherInput?.dosingVolume?.quantity,
          unit: pumpHigherInput?.dosingVolume?.unit,
        },
      },
    },
  };
  // FormValues are values that will be changed on the form and values we are able to send to the graphql to do remote commands
  const formValues: FormValues = {
    manualDosingFlowValue: manual.quantity !== null ? manual.quantity.toFixed(4) : null,
    maxCapacity:
      maxCapacityQuantity !== null && maxCapacityQuantity !== undefined ? maxCapacityQuantity.toFixed(4) : null,
    controlMode,
    pumpState: isPumping ? 'START' : 'STOP',
    flowControl: enabled,
    autoDeaeration,
    flowControlAutoFlowAdapt: autoFlowAdapt,
    slowMode,
    flowControlDelay: delay,
    maxPressureValue: maxPressureValue === undefined ? null : maxPressureValue,
    minPressureValue: minPressureValue === undefined ? null : minPressureValue,
    minPressureAsAlarm: minPressureAsAlarm === undefined ? null : minPressureAsAlarm,
    flowControlSensitivity: sensitivity,
    mlPerPulse: pulse.mlPerPulse !== null ? pulse.mlPerPulse.toFixed(4) : null,
    pulseMemory: pulse !== null ? pulse.pulseMemory : null,
    stopAfterPowerFailure,
    analogInputLower:
      pumpLowerInput?.electricCurrent?.quantity !== null && pumpLowerInput?.electricCurrent?.quantity !== undefined
        ? pumpLowerInput?.electricCurrent?.quantity?.toFixed(2)
        : null,
    analogInputHigher:
      pumpHigherInput?.electricCurrent?.quantity !== null && pumpHigherInput?.electricCurrent?.quantity !== undefined
        ? pumpHigherInput?.electricCurrent?.quantity?.toFixed(2)
        : null,
    analogDosingFlowLower:
      pumpLowerInput?.dosingVolume?.quantity !== null && pumpLowerInput?.dosingVolume?.quantity !== undefined
        ? pumpLowerInput?.dosingVolume?.quantity?.toFixed(4)
        : null,
    analogDosingFlowHigher:
      pumpHigherInput?.dosingVolume?.quantity !== null && pumpHigherInput?.dosingVolume?.quantity !== undefined
        ? pumpHigherInput?.dosingVolume?.quantity?.toFixed(4)
        : null,
  };

  return { remoteControlSettings, formValues };
};

export default useDisplayRemoteControlData;
