import { differenceInHours } from 'date-fns';
import { useContext } from 'react';
import { LocaleContext } from '../../../providers/LocaleProvider/LocaleProvider';

const useGetTimeFormatString = (currentTime: Date, latestSync: Date): string => {
  const { hourMinuteFormat, shortDateTimeFormat } = useContext(LocaleContext);
  if (differenceInHours(currentTime, latestSync) <= 24) return hourMinuteFormat.dateFnsTimeFormat;
  return shortDateTimeFormat.dateFnsDateTimeFormat;
};

export default useGetTimeFormatString;
