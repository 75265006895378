import { css, SerializedStyles, Theme } from '@emotion/react';
import dsmMediaQuery from '../../../utils/media-queries/media-queries';

const notFoundErrorFallbackStyles = css`
  padding: 4rem 2rem;
  ${dsmMediaQuery('medium device')} {
    padding: 0;
  }
  ${dsmMediaQuery('large device')} {
    padding: 4rem 0;
  }
`;

const subHeaderStyles = (theme: Theme): SerializedStyles => css`
  color: ${theme.colors.white.secondary};
`;

const breadTextStyles = (theme: Theme): SerializedStyles => css`
  margin: 1.5rem 0;
  max-width: 42rem;
  color: ${theme.colors.primaryText};
`;

const container = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const heading = css`
  padding-top: 4rem;
`;
const subHeading = css`
  padding-top: 2rem;
`;
const text = css`
  padding-top: 1rem;
`;

const linkStyles = ({ colors }: Theme): SerializedStyles => css`
  color: ${colors.tertiaryText};

  &:hover {
    text-decoration: underline;
  }
`;

export {
  breadTextStyles,
  notFoundErrorFallbackStyles,
  subHeaderStyles,
  container,
  heading,
  subHeading,
  text,
  linkStyles,
};
