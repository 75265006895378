/**
 * @generated SignedSource<<08a2d8571c86b9e6e65696605daeedcb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotSupportedFragment$data = {
  readonly gatewayIMEI: string | null;
  readonly " $fragmentType": "NotSupportedFragment";
};
export type NotSupportedFragment$key = {
  readonly " $data"?: NotSupportedFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotSupportedFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotSupportedFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gatewayIMEI",
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};

(node as any).hash = "8078c54c623a0c474b9aa11585fa7ac1";

export default node;
