import { css, Theme } from '@emotion/react';

const snackbarContainer = (theme: Theme) => css`
  z-index: 200;
  position: fixed;
  display: flex;
  left: 50%;
  top: 2.5rem;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  width: calc(100% - 2rem);
  max-width: 26.5rem;
  background-color: ${theme.colors.neutral.neutral800.hex};
`;

const informationAndActionContainer = css`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-right: 0.75rem;
`;

const closeIconContainer = css`
  display: flex;
  height: fit-content;
  cursor: pointer;
`;

const snackbarMessage = (color: string) => css`
  font-size: 1rem;
  color: ${color};
`;

const iconWrapper = (color: string) => css`
  background-color: ${color};
  display: flex;
  align-self: stretch;
  align-items: center;
`;

const svgStyles = css`
  box-sizing: unset;
  padding: 1rem;
`;

export {
  snackbarContainer,
  informationAndActionContainer,
  closeIconContainer,
  snackbarMessage,
  svgStyles,
  iconWrapper,
};
