import { useMemo } from 'react';
import { RemoteControlSettings, FormValues } from '../types';

const requiredFields: (keyof RemoteControlSettings | keyof FormValues)[] = [
  'autoDeaeration',
  'controlMode',
  'flowControl',
  'flowControlAutoFlowAdapt',
  'flowControlDelay',
  'flowControlSensitivity',
  'maxPressureValue',
  'minPressureValue',
  'minPressureUnit',
  'maxPressureUnit',
  'minPressureAsAlarm',
  'pumpState',
  'pumpVariant',
  'maxCapacity',
  'maxCapacityUnit',
  'pumpCapacity',
  // 'stoppedBy',
];

// eslint-disable-next-line import/prefer-default-export
export const verifyData = (state: RemoteControlSettings & FormValues): boolean => {
  const hasIncompleteState = requiredFields.some(
    (requiredField) => state[requiredField] === null || state[requiredField] === undefined,
  );
  if (hasIncompleteState) console.log('Incomplete state detected:', state);
  return hasIncompleteState;
};

const useRemoteFieldsVerification = (state: RemoteControlSettings & FormValues) => {
  const memoized = useMemo(() => verifyData(state), [state]);
  return { hasFieldsErrors: memoized };
};

export default useRemoteFieldsVerification;
