import { css, Theme } from '@emotion/react';
import { Margin } from './types';

const getMargin = (margin: Margin | undefined) => {
  switch (margin) {
    case 'small':
      return '1rem';
    case 'large':
      return '2rem';
    default:
      return '0rem';
  }
};

const dividerStyles = (theme: Theme, marginRight?: Margin, marginLeft?: Margin) => css`
  border: unset;
  border-top: 0.06rem solid ${theme.colors.border.subtle};
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: ${getMargin(marginLeft)};
  margin-right: -${getMargin(marginRight)};
`;

// eslint-disable-next-line import/prefer-default-export
export { dividerStyles };
