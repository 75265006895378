import { css, SerializedStyles, Theme } from '@emotion/react';

const labelThreshold = (threshold: number): SerializedStyles => css`
  position: absolute;
  font-size: 0.75rem;
  text-align: center;
  left: ${threshold * 100}%;
  /* we translate it 50 percent back to center the element relative to the bar */
  transform: translate(-50%, 0);
`;

const main = (theme: Theme): SerializedStyles => css`
  position: relative;
  background-color: grey;
  align-self: stretch;
  height: 1.2em;
  border-top: 0.05em solid ${theme.colors.white.secondary};
  border-bottom: 0.05em solid ${theme.colors.white.secondary};
  overflow: hidden;
`;

const barContainer = css`
  position: relative;
  padding-top: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
`;

const countDownBarContainer = (theme: Theme): SerializedStyles => css`
  flex-direction: column;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.secondaryBackground};
`;

const fill = (theme: Theme): SerializedStyles => css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-right: 0.05em solid ${theme.colors.white.secondary};
`;

const content = (theme: Theme): SerializedStyles => css`
  font-weight: 400;
  color: ${theme.colors.white.primary};
  font-size: 3rem;
`;

const labels = (theme: Theme): SerializedStyles => css`
  font-size: 1em;
  padding-bottom: 1.4em;
  background-color: ${theme.colors.secondaryBackground};
`;

const labelMax = css`
  position: absolute;
  font-size: 0.75rem;
  right: 0;
`;

export { main, countDownBarContainer, barContainer, fill, content, labels, labelMax, labelThreshold };
