import { css, SerializedStyles, Theme } from '@emotion/react';
import dsmMediaQuery from '../../../utils/media-queries/media-queries';
import { paragraphBoldStyles, paragraphBrightStyles } from '../../../styles';
import { AlertItemProps } from './types';

const eventsContainer = css`
  padding-bottom: 2rem;
`;

const itemStyles = (theme: Theme): SerializedStyles => css`
  display: grid;
  background: ${theme.colors.secondaryBackground};
  min-height: auto;
  padding: 1rem;
  justify-items: left;
  margin-bottom: 0.5rem;
  text-decoration: none;
  overflow: hidden;
`;

const troubleshootButtonStyles = (theme: Theme): SerializedStyles => css`
  display: none;

  ${dsmMediaQuery('medium device')} {
    display: unset;
    button {
      cursor: pointer;
      height: 2rem;
      width: 8rem;
      border: 0.0625rem solid ${theme.colors.secondaryText};
      background-color: transparent;
      text-transform: uppercase;
      color: ${theme.colors.secondaryText};
      font-weight: 600;
      font-size: 0.75rem;
    }
  }
`;

const iconStyles = css`
  vertical-align: middle;
`;

const timestampStyles = css`
  vertical-align: middle;
  margin-left: 0.3rem;
  font-size: 0.875rem;
`;

const showMoreStyles = css`
  margin-top: 1.7rem;
  text-align: center;

  ${dsmMediaQuery('medium device')} {
    text-align: left;
  }
`;

const showMoreButtonStyles = (theme: Theme): SerializedStyles => css`
  border: none;
  background: initial;
  color: ${theme.colors.tertiaryText};
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;
  cursor: pointer;

  :focus {
    outline: none;
  }
`;

const loadingMoreStyles = css`
  position: relative;
  height: 6rem;
`;

const item = css`
  display: flex;
  align-items: center;
  curser: default;
  justify-items: space-between;
`;

const grid = (theme: Theme): SerializedStyles => css`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 0.25fr);
  justify-items: space-between;
  grid-row-gap: 0.5em;
  position: relative;
  ${paragraphBrightStyles(theme)}
`;

const headerContainer = (theme: Theme): SerializedStyles => css`
  display: contents;
  ${paragraphBoldStyles(theme)}
`;

const rowContainer = css`
  display: contents;
`;

const deleteItemStyles = css`
  display: flex;
  justify-content: end;
`;

const togglerContainerStyles = css`
  gap: 0.4rem;
`;

const deleteButtonStyles = (canBeDeleted: boolean) => css`
  height: unset;
  min-width: unset;
  padding-left: unset;
  padding-right: unset;
  min-width: unset;
  ${!canBeDeleted && `cursor:not-allowed;`}
  ${dsmMediaQuery('medium device')} {
    min-width: unset;
  }
`;

const alertHeader = css`
  ${item}
`;
const alertIconCenter = css`
  display: flex;
  align-items: center;
`;
const alertIconText = css`
  padding-left: 0.5em;
`;

// We are using an isHovered props.
// The reason is that rowContainer has to have  display: contents;
// This means background-color has no effect on rowContainer
// If we use a normal :hover selector we will just hover the item, not the row.
// Either we need some smart CSS with siblings selectors, or we can do as here
// use javascript
const alertItem = (props: AlertItemProps): SerializedStyles => css`
  ${item}
  background-color: ${props.isHovered
    ? props.theme.colors.white.secondary
    : props.theme?.colors?.neutral.neutral1000.hex};
  color: ${props.isHovered ? props.theme.colors.neutral.neutralDark.hex : props.theme.colors.secondaryText};
  padding: 0.5em 1rem;
`;

const headerItem = (theme: Theme): SerializedStyles => css`
  background: ${theme.colors.neutral.neutral800.hex};
  padding: 0.5em 1rem;
`;

export {
  eventsContainer,
  iconStyles,
  itemStyles,
  troubleshootButtonStyles,
  timestampStyles,
  showMoreStyles,
  showMoreButtonStyles,
  loadingMoreStyles,
  grid,
  headerContainer,
  item,
  rowContainer,
  alertHeader,
  alertItem,
  alertIconCenter,
  alertIconText,
  deleteItemStyles,
  deleteButtonStyles,
  togglerContainerStyles,
  headerItem,
};
