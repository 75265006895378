import React, { PropsWithChildren } from 'react';
import { itemStyles } from './styles';
import { ItemProps } from './types';

const Item: React.FC<PropsWithChildren<ItemProps>> = ({ children, autoMargin }) => (
  <div css={itemStyles(autoMargin)} data-test-id="nav-item">
    {children}
  </div>
);

export default Item;
