import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DateTimeFormatter,
  InfoItem,
  NoDataAvailableMessage,
  Tile,
  TileBody,
  TileHeader,
} from '../../../../../components';
import { infoItemStyles, removeLastBottomBorder, topPadding } from '../styles';
import { ChemicalContainerProps } from './types';

const ChemicalContainer = ({ chemicalContainer }: ChemicalContainerProps) => {
  const { t } = useTranslation();

  if (!chemicalContainer) return <NoDataAvailableMessage message={t('WP.ERROR.NO_DATA')} />;

  const {
    connectedChemical,
    thresholdValueForCalculatedPremptyAlert,
    chemicalConnectedBy,
    connectedTotalVolume,
    chemicalConnectionDate,
    calculatedContainerVolume,
  } = chemicalContainer;

  return (
    <Tile
      Header={<TileHeader text={t('WP.MORE.DATA.CHEMICAL_INFO_TILE')} />}
      Body={
        <TileBody css={[topPadding, removeLastBottomBorder]}>
          <InfoItem css={infoItemStyles} label={t('WP.MORE.DATA.CONNECTED_CHEMICAL')} value={connectedChemical} />
          <InfoItem
            css={infoItemStyles}
            label={t('WP.MORE.DATA.CHEMICAL_CONNECTION_DATE')}
            value={chemicalConnectionDate && <DateTimeFormatter timestamp={chemicalConnectionDate} />}
          />
          <InfoItem css={infoItemStyles} label={t('WP.MORE.DATA.CONNECTED_BY')} value={chemicalConnectedBy} />
          <InfoItem
            css={infoItemStyles}
            label={t('WP.MORE.DATA.CONNECTED_VOL')}
            value={connectedTotalVolume?.quantity ? `${connectedTotalVolume.quantity.toFixed(1)}` : ''}
            unit={connectedTotalVolume?.quantity ? `${connectedTotalVolume?.unit}` : ''}
          />
          <InfoItem
            css={infoItemStyles}
            label={t('WP.MORE.DATA.ACTUAL_VOL')}
            // we don't want to display negative values
            value={
              calculatedContainerVolume?.quantity && calculatedContainerVolume.quantity > 0
                ? `${calculatedContainerVolume.quantity.toFixed(1)}`
                : 0
            }
            unit={calculatedContainerVolume?.quantity ? `${calculatedContainerVolume?.unit}` : ''}
          />
          <InfoItem
            css={infoItemStyles}
            label={t('WP.MORE.DATA.PRE_EMPTY_TRESHOLD')}
            value={
              thresholdValueForCalculatedPremptyAlert?.quantity
                ? `${thresholdValueForCalculatedPremptyAlert.quantity.toFixed(1)}`
                : ''
            }
            unit={
              thresholdValueForCalculatedPremptyAlert?.quantity ? `${thresholdValueForCalculatedPremptyAlert.unit}` : ''
            }
          />
        </TileBody>
      }
    />
  );
};

export default ChemicalContainer;
