import { PumpInput } from './types';

const formatPair = (quantity?: number | null, unit?: string | null, decimals?: number): string =>
  quantity != null && unit != null ? `${quantity.toFixed(decimals ?? 2)} ${unit}` : '-';

// eslint-disable-next-line import/prefer-default-export
export const formatPumpInput = (
  pumpInput: PumpInput | undefined | null,
  electricCurrentDecimals = 2,
  dosingVolumeDecimals = 2,
): string => {
  if (!pumpInput) return '- / -';
  const electricCurrentFormatted = formatPair(
    pumpInput.electricCurrent?.quantity,
    pumpInput.electricCurrent?.unit,
    electricCurrentDecimals,
  );
  const dosingVolumeFormatted = formatPair(
    pumpInput.dosingVolume?.quantity,
    pumpInput.dosingVolume?.unit,
    dosingVolumeDecimals,
  );

  return `${electricCurrentFormatted} / ${dosingVolumeFormatted}`;
};
