/**
 * @generated SignedSource<<d2f41eeffceae5104c3d7c625d8939ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountEventsError_events$data = {
  readonly errorCount: number;
  readonly " $fragmentType": "AccountEventsError_events";
};
export type AccountEventsError_events$key = {
  readonly " $data"?: AccountEventsError_events$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountEventsError_events">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountEventsError_events",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "errorCount",
      "storageKey": null
    }
  ],
  "type": "EventConnection",
  "abstractKey": null
};

(node as any).hash = "94f08b019b32fc2d6f8ffcadb1e87b67";

export default node;
