import { css } from '@emotion/react';
import dsmMediaQuery from '../../../utils/media-queries/media-queries';

const containerStyles = css`
  grid-column: 1/-1;
  grid-row: 1/1;
  padding-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${dsmMediaQuery('large device')} {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const lastSyncStyles = css`
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;
`;

const accountInstallationStyles = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${dsmMediaQuery('medium device')} {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }
`;

export { containerStyles, lastSyncStyles, accountInstallationStyles };
