import React, { useCallback } from 'react';
import { toGlobalId } from 'graphql-relay';
import { useTranslation } from 'react-i18next';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { NoDataAvailableMessage, ProgressiveImage, Tiles } from '../../../components';
import CalculatedContainerLevel from '../Overview/OverviewChemPairing/CalculatedContainerLevel/CalculatedContainerLevel';
import PumpStatusData from './PumpStatus/PumpStatusData';
import TimeToServiceDDAC from './TimeToServiceDDAC';
import { overviewChemPairingStyles } from '../Overview/OverviewChemPairing/styles';
import { AlertsTileRelay } from '../Overview/OverviewChemPairing/AlertsTile/AlertsTileRelay';
import DeviceQuery, { OverviewChemPairingDDACQuery } from './__generated__/OverviewChemPairingDDACQuery.graphql';
import useRelayRefresh from '../useRelayRefresh';
import InfoContainerDDACData from './InfoContainerDDACData';
import OverviewTile from '../Overview/OverviewChemPairing/OverviewTile/OverviewTile';
import ddac from '../../../assets/images/ddacPump.png';
import ddacPlaceholder from '../../../assets/images/ddacPump_tiny.png';
import { imgPadding } from './styles';
import GraphDosingComponent from './GraphDosingComponent';

interface OverviewChemPairingProps {
  startDate: number;
  endDate: number;
  id: string;
  goToEvents: () => void;
}

const defaultDDACGeniIdentity = { familyCode: 30, unitType: 4, unitVersion: 1 };

const OverviewChemPairingDDAC: React.FC<OverviewChemPairingProps> = ({ startDate, endDate, id, goToEvents }) => {
  const { t } = useTranslation();
  const queryOptions = useRelayRefresh(startDate, endDate, id, DeviceQuery, defaultDDACGeniIdentity);
  const data = useLazyLoadQuery<OverviewChemPairingDDACQuery>(
    graphql`
      query OverviewChemPairingDDACQuery($id: ID!, $startDate: Int!, $endDate: Int!, $geniIdentity: GeniIdentity!) {
        node(id: $id) {
          ... on Device {
            descendantDeviceInfo {
              ... on DDAC {
                pumpSettings {
                  controlMode
                }
              }
            }
            ...useOverviewData_device
            ...InfoContainerDDACData_device
            ...useInfoContainerData_device
            ...TimeToServiceDDAC_device
            ...VolumetricConcentrationData @arguments(endDate: $endDate)
            ...DosedVolume_device @arguments(startDate: $startDate, endDate: $endDate)
            ...CalculatedContainerLevel_device @arguments(endDate: $endDate)
            ...PumpStatusDataDDAC_device
            ...AlertsTileRelay_device
          }
        }
      }
    `,
    {
      id: toGlobalId('Device', id),
      startDate,
      endDate,
      geniIdentity: defaultDDACGeniIdentity,
    },
    queryOptions,
  );

  const Modal = useCallback(
    // eslint-disable-next-line react/no-unused-prop-types
    ({ onClose }: { onClose: () => void }) => <InfoContainerDDACData onClose={onClose} device={data} />,
    [data],
  );

  if (!data.node) return <NoDataAvailableMessage message={t('WP.ERROR.NO_DATA')} />;
  const controlMode = data?.node?.descendantDeviceInfo?.pumpSettings?.controlMode;

  return (
    <Tiles styles={overviewChemPairingStyles}>
      <OverviewTile
        device={data}
        ModalComponent={Modal}
        Image={<ProgressiveImage css={imgPadding} placeholderSrc={ddacPlaceholder} src={ddac} alt="ddac pump" />}
      />
      <AlertsTileRelay device={data} goToEvents={goToEvents} />
      <CalculatedContainerLevel device={data} />
      <GraphDosingComponent controlMode={controlMode} data={data} />
      <PumpStatusData device={data} />
      <TimeToServiceDDAC device={data} />
    </Tiles>
  );
};

export default OverviewChemPairingDDAC;
