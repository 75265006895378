import React from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../../components';
import { paragraphBrightStyles, textLinkStyles } from '../../../../styles';
import Mesage from './components/Message';
import { DosingControlForm, EditForm, FlowControlForm, PressureSliderForm, ReadOnlyForm } from './forms';
import useRemoteFieldsVerification from './hooks/useRemoteFieldsVerification';
import useSchemaValidation from './hooks/useSchemaValidation';
import { spinnerModalStyles } from './styles';
import { RemoteControlProps } from './types';

const Loading: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
  if (!isLoading) return null;
  return (
    <div css={spinnerModalStyles}>
      <Loader size="large" />
    </div>
  );
};

const LimitedVariantMessage = () => {
  const { t } = useTranslation();
  return (
    <Mesage
      message={
        <div css={paragraphBrightStyles}>
          {`${t('WP.REMOTE.READ_MODE_LIMITED_DDA_VARIANT')} `}
          <a
            css={textLinkStyles}
            target="_blank"
            href="https://product-selection.grundfos.com/products/dosing-pumps-digital/dda?tab=explore"
            rel="noreferrer"
          >
            {t('WP.REMOTE.READ_MODE_LIMITED_DDA_VARIANT_READ_MORE')}
          </a>
        </div>
      }
    />
  );
};

const BusOffMessage = () => {
  const { t } = useTranslation();
  return <Mesage message={t('WP.REMOTE.READ_MODE_BUS_OFFLINE')} />;
};

const ValuesNotReceived = () => {
  const { t } = useTranslation();
  return <Mesage message={t('WP.REMOTE.READ_MODE_VALUES_NOT_RECEIVED')} />;
};

const RemoteControl: React.FC<RemoteControlProps> = ({ data, isLoading, remoteControlSettings, onFormSubmit }) => {
  const {
    pumpCapacity,
    maxPressureUnit,
    minPressureUnit,
    manualDosingFlowUnit,
    stoppedBy,
    maxCapacityUnit,
    pulseMinMax,
    pressureMinRange,
    pressureMaxRange,
    analog,
  } = remoteControlSettings;

  const { hasFieldsErrors } = useRemoteFieldsVerification({ ...data, ...remoteControlSettings });

  const validationSchema = useSchemaValidation({
    pulseMinMax,
    pumpCapacity,
  });

  if (hasFieldsErrors) {
    return <ReadOnlyForm data={data} settings={remoteControlSettings} Message={<ValuesNotReceived />} />;
  }

  if (!remoteControlSettings.remoteControlEnabled) {
    return <ReadOnlyForm data={data} settings={remoteControlSettings} Message={<BusOffMessage />} />;
  }

  const EditFormMessage = remoteControlSettings.pumpVariant === 'AR' ? <LimitedVariantMessage /> : null;

  const FlowControlsComponent =
    remoteControlSettings.pumpVariant === 'FCM' ? (
      <FlowControlForm
        pumpVariant={remoteControlSettings.pumpVariant}
        PressureSlider={
          <PressureSliderForm
            maxPressureUnit={maxPressureUnit}
            minPressureUnit={minPressureUnit}
            pressureMinRange={pressureMinRange}
            pressureMaxRange={pressureMaxRange}
            testId="flow-control-pressure"
          />
        }
      />
    ) : null;

  // This is related to server state and not a change we should send when doing form submit
  const isStoppedByOtherThanRemote = stoppedBy !== 'REMOTE' && stoppedBy !== null;

  return (
    <>
      <Loading isLoading={isLoading} />
      <EditForm
        data={data}
        isStoppedByOtherThanRemote={isStoppedByOtherThanRemote}
        FlowControlForm={FlowControlsComponent}
        validationSchema={validationSchema}
        Message={EditFormMessage}
        disabled={isLoading}
        handleFormSubmit={onFormSubmit}
        DosingControlForm={<DosingControlForm manualDosingFlowUnit={manualDosingFlowUnit} analogSettings={analog} />}
        maxCapacityUnit={maxCapacityUnit || ''}
      />
    </>
  );
};

export default RemoteControl;
