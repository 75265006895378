import React from 'react';
import { InfoItem } from '../../../../../components';
import { infoItemStyles } from '../styles';
import { formatPumpInput } from '../utils';
import { AnalogRuleInfoItemProps } from './types';
import { AnalogRuleInfoToolTip } from '../../components';

const AnalogRuleInfoItemLower: React.FC<AnalogRuleInfoItemProps> = ({
  pumpInput,
  label,
  dosingVolumeDecimals,
  proportionalRuleValidation,
}) => {
  const pumpInputText = formatPumpInput(pumpInput, 2, dosingVolumeDecimals);

  return (
    <InfoItem
      css={infoItemStyles}
      label={label}
      value={
        <AnalogRuleInfoToolTip
          proportionalRuleValidation={proportionalRuleValidation}
          macthRuleViolations={[
            'FourToTwentyLowerInputCurrent',
            'ZeroToTwentyLowerInputCurrent',
            'LowerInputCurrentFlow',
          ]}
        >
          {pumpInputText}
        </AnalogRuleInfoToolTip>
      }
      labelMultiline
    />
  );
};

export default AnalogRuleInfoItemLower;
