import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { FormValues, getFormValuesKey } from '../types';
import { Divider, TextInputField, TogglerFormik } from '../../../../../components';
import { textInputStyles } from '../../../DDA/RemoteControl/styles';
import { formFieldsStyles } from './styles';

const PulseDosing: React.FC = () => {
  const { values } = useFormikContext<FormValues>();
  const { t } = useTranslation();
  return (
    <div data-testid="pulse-dosing-form" css={formFieldsStyles}>
      <TextInputField
        css={[textInputStyles]}
        testId="pulse-dosing-mlPerPulse"
        type="number"
        step="any"
        name={getFormValuesKey('mlPerPulse')}
        label={t('WP.REMOTE.PULSE_DOSING_VOL')}
        skipTouchedCheck
      />
      <Divider marginLeft="small" />
      <TogglerFormik
        testId="pulse-dosing-pulseMemory-toggler"
        className="toggler"
        label={t('WP.REMOTE.PULSE_MEMORY')}
        isToggled={values.pulseMemory}
        name={getFormValuesKey('pulseMemory')}
      />
      <Divider marginLeft="small" />
    </div>
  );
};

export default PulseDosing;
