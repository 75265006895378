import React, { useEffect, useState } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { LocaleProviderQuery } from './__generated__/LocaleProviderQuery.graphql';
import { ContextProps, ProviderProps, TimeFormat, UnitSystemName } from './types';
import {
  defaultShortDateFormat,
  hourMinuteFormat,
  languageIsSupported,
  shortDateFormatMap,
  shortDateTimeFormat,
  update18NextLanguage,
} from './utils';

const initialValue: ContextProps = {
  shortDateFormat: defaultShortDateFormat,
  shortDateTimeFormat: shortDateTimeFormat(),
  hourMinuteFormat: hourMinuteFormat(),
  timeFormat: '24-hour',
  unitSystemName: 'European Unit',
};

const Context = React.createContext<ContextProps>(initialValue);

const Provider = ({ children }: ProviderProps): JSX.Element => {
  const [locale, setLocale] = useState(initialValue);

  const { me } = useLazyLoadQuery<LocaleProviderQuery>(
    graphql`
      query LocaleProviderQuery {
        me {
          countryName
          languageIso6391
          timeFormatName
          shortDateFormatName
          unitSystemName
        }
      }
    `,
    {},
  );
  // Main purpose of this Provider
  // 1. Udate the i18next to the language set from asset service
  // 2. Provide dateformatting string for dateFns and Highcharts that matches the user settings in asset service
  // Besides this it also give the locale dateFns need. This is important when we do distance functions, so they are in the correct language and region
  useEffect(() => {
    if (me?.countryName) {
      const language = me?.languageIso6391 && languageIsSupported(me?.languageIso6391) ? me?.languageIso6391 : 'en';
      update18NextLanguage(language);

      setLocale((l) => {
        const timeformat = (me.timeFormatName as TimeFormat) || l.timeFormat;
        const shortDateFormat = shortDateFormatMap.get(me.shortDateFormatName || '') || l.shortDateFormat;
        const unitSystemName = (me.unitSystemName as UnitSystemName) || l.unitSystemName;
        return {
          shortDateFormat,
          shortDateTimeFormat: shortDateTimeFormat(shortDateFormat, timeformat),
          hourMinuteFormat: hourMinuteFormat(timeformat),
          timeFormat: timeformat,
          unitSystemName,
        };
      });
    }
  }, [me]);

  return <Context.Provider value={locale}>{children}</Context.Provider>;
};

export { Context as LocaleContext, Provider as LocaleProvider };
