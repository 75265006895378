/* eslint-disable import/prefer-default-export */
export const dateTimeFormatter = (locale: string): Highcharts.AxisDateTimeLabelFormatsOptions => ({
  millisecond: '%H:%M:%S.%L',
  second: '%H:%M:%S',
  minute: `${locale === 'en-US' ? '%I:%M %p' : '%H:%M'}`,
  hour: `${locale === 'en-US' ? '%I:%M %p' : '%H:%M'}`,
  day: `${locale === 'en-US' ? '%b %e' : ' %e.%b'}`,
  week: '%e. %b',
  month: "%b '%y",
  year: '%Y',
});

export const convertToSuperScript = (value: string) => {
  if (value.includes('m3')) {
    return value.replaceAll('m3', 'm³');
  }
  return value;
};

export const isNotUndefinedOrNull = <T>(value: T | undefined | null): value is T =>
  value !== undefined && value !== null;

// different amount of decimals depending on meter type
export const formatPulseRateValue = (pulseRate: number, meterType?: string | null) => {
  if (meterType === 'WaterMeter') return pulseRate.toFixed(3);
  if (meterType === 'FlowTurbine') return pulseRate.toFixed(0);
  // in case of existing pumps with no meterType set yet
  return pulseRate.toFixed(4);
};

export const flowMeterLabel = (
  flowTurbineLabel: string,
  waterMeterLabel: string,
  defaultLabel: string,
  meterType?: string | null,
) => {
  if (meterType === 'WaterMeter') return waterMeterLabel;
  if (meterType === 'FlowTurbine') return flowTurbineLabel;
  // in case of existing pumps with no meterType set yet
  return defaultLabel;
};
