import { css, SerializedStyles, Theme } from '@emotion/react';
import dsmMediaQuery from '../../../../utils/media-queries/media-queries';
import { heading05Styles } from '../../../../styles';

const formStyles = css`
  label {
    padding-top: 0.5rem;
  }

  ${dsmMediaQuery('medium device')} {
    min-width: 25rem;
    display: grid;
    grid-template-areas:
      'installationName'
    gap: 1rem;

    grid-template-areas:
      'installationName'
  }
`;
const formContentStyles = ({ colors }: Theme): SerializedStyles => css`
  grid-area: form;
  background-color: ${colors.secondaryBackground};
  padding: 1rem 0rem;
`;

const contentStyles = ({ colors }: Theme): SerializedStyles => css`
  display: grid;
  width: 100%;
  grid-template-areas:
  'header'
  'note'
  'apply'
  'form'
  'back'
  'next';
  background-color: ${colors.secondaryBackground};
  padding: 1rem;
  padding-bottom: 0rem;
  margin: 1rem;

  ${dsmMediaQuery('medium device')} {
    padding: 1.5rem;
    width: none;
    padding-bottom: 0rem;
    margin: 1.5rem;
    max-width: 30rem;
    grid-template-areas:
    'header header'
    'note note'
    'apply apply'
    'form form'
    'back next';
`;

const nextBtnWrapperStyles = css`
  grid-area: next;

  ${dsmMediaQuery('medium device')} {
    justify-self: right;
    padding-top: 1rem;
  }
`;
const linkStyles = css`
  text-decoration: underline;
`;

const backBtnWrapperStyles = css`
  grid-area: back;
  padding-top: 2rem;
  padding-bottom: 1rem;

  ${dsmMediaQuery('medium device')} {
    padding-top: 1rem;
    align-self: center;
    padding-bottom: 0rem;
  }
`;

const header = css`
  grid-area: header;
  padding-bottom: 1rem;
`;

const note = css`
  grid-area: note;
`;

const noteLabel = css`
  padding: 0.5rem 0rem;
`;

const noteText = ({ colors }: Theme): SerializedStyles => css`
  min-height: 4rem;
  border: 0.063rem solid ${colors.border.subtle};
  overflow-wrap: break-word;
  max-width: 30rem;
  padding: 0.5rem;
`;

const applyContainer = css`
  grid-area: apply;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const applyButton = css`
  margin-right: -0.5rem;
  padding-top: 0.3rem;
  padding-right: 0.5rem;
  min-width: unset;
  ${dsmMediaQuery('medium device')} {
    padding-right: 0rem;
    margin-right: -1rem;
  }
`;

export {
  applyButton,
  applyContainer,
  header,
  note,
  noteLabel,
  noteText,
  contentStyles,
  nextBtnWrapperStyles,
  backBtnWrapperStyles,
  formStyles,
  linkStyles,
  formContentStyles,
};
