import { css } from '@emotion/react';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { skeletonContainer } from './styles';

const LoadingBodySkeleton = () => (
  <div css={skeletonContainer}>
    <Skeleton
      height="4rem"
      containerClassName="timeToServiceSkeleton"
      css={css`
        width: 40%;
      `}
    />
    <Skeleton
      containerClassName="timeToServiceSkeleton"
      height="1.5rem"
      css={css`
        width: 60%;
        margin-top: 1em;
      `}
    />
    <Skeleton
      containerClassName="timeToServiceSkeleton"
      height="2rem"
      css={css`
        margin-top: 1em;
      `}
    />
  </div>
);

export default LoadingBodySkeleton;
