import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, DropDownSelect } from '../../../components';
import { dropDownStyles } from '../styles';
import unitSystems from '../unitSystems';

const UnitSystemSelect = ({ unitSystemName }: { unitSystemName: string }) => {
  const { t } = useTranslation();
  return (
    <>
      <DropDownSelect name="unitSystem" label={t('WP.PROFILE.UNIT_SYSTEM')} css={dropDownStyles}>
        {/* SI UNIT will only show up if the customer success manager makes a mistake when creating the user */}
        {unitSystemName === 'SI Unit' && (
          <option key="SI" disabled value="SI Unit">
            {t('WP.PROFILE.SI_UNIT')}
          </option>
        )}
        {unitSystems.map(({ val, displayText }) => (
          <option key={val} value={val}>
            {t(displayText)}
          </option>
        ))}
      </DropDownSelect>
      <Divider />
    </>
  );
};

export default UnitSystemSelect;
