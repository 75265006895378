/**
 * @generated SignedSource<<e04949932711709ba5ab4a2741a4e18d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type OnboardingClaimDeviceInput = {
  onboardingId: string;
  claimId: string;
  applicationAbbreviation?: string | null;
  digitalOfferingName?: string | null;
  installationName?: string | null;
  installationInformation?: InstallationInformationInput | null;
};
export type InstallationInformationInput = {
  installationStreet?: string | null;
  installationCity?: string | null;
  installationState?: string | null;
  installationPostalCode?: string | null;
  installationCountryCode?: string | null;
};
export type InstallationDetailsMutation$variables = {
  id: string;
  input: OnboardingClaimDeviceInput;
};
export type InstallationDetailsMutation$data = {
  readonly onboardingClaimDevice: {
    readonly id: string;
    readonly onboardingId: string | null;
    readonly device: {
      readonly claimId: string | null;
    } | null;
    readonly installationName: string | null;
    readonly installationInformation: {
      readonly installationCity: string | null;
      readonly installationStreet: string | null;
      readonly installationState: string | null;
      readonly installationPostalCode: string | null;
      readonly installationCountryCode: string | null;
    } | null;
  } | null;
};
export type InstallationDetailsMutation = {
  variables: InstallationDetailsMutation$variables;
  response: InstallationDetailsMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "OnboardingClaimDevicePayload",
    "kind": "LinkedField",
    "name": "onboardingClaimDevice",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "onboardingId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "OnboardingDevice",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "claimId",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "installationName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "InstallationInformation",
        "kind": "LinkedField",
        "name": "installationInformation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "installationCity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "installationStreet",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "installationState",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "installationPostalCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "installationCountryCode",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InstallationDetailsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InstallationDetailsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4ef15c961e597658df8ec98090940329",
    "id": null,
    "metadata": {},
    "name": "InstallationDetailsMutation",
    "operationKind": "mutation",
    "text": "mutation InstallationDetailsMutation(\n  $id: ID!\n  $input: OnboardingClaimDeviceInput!\n) {\n  onboardingClaimDevice(id: $id, input: $input) {\n    id\n    onboardingId\n    device {\n      claimId\n    }\n    installationName\n    installationInformation {\n      installationCity\n      installationStreet\n      installationState\n      installationPostalCode\n      installationCountryCode\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "342c12f531cf1d02e22a7d65c5a1944d";

export default node;
