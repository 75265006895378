import { css, SerializedStyles, Theme } from '@emotion/react';
import dsmMediaQuery from '../../../../utils/media-queries/media-queries';

const contentStyles = ({ colors }: Theme): SerializedStyles => css`
display: flex;
flex: 1;
flex-direction: column;
background-color: ${colors.secondaryBackground};
padding: 1rem;
margin: 1rem;

${dsmMediaQuery('medium device')} {
  max-width: 30rem;
  position: relative;
  z-index: 1000;
  opacity: 0.9;
  margin-bottom: 2rem;
`;

const dropDownStyle = (gridArea: string) => css`
  grid-area: ${gridArea};
  display: flex;

  flex-direction: column;
  align-items: start;
  select {
    width: 100%;
  }
  label {
    text-align: start;
    padding-bottom: 0rem;
  }
`;

const formStyles = (state: boolean) => css`
  display: grid;
  row-gap: 1rem;
  grid-template-areas:
    'installation-note-label'
    'installation-note-field'
    'claim'
    'name'
    'address'
    'postalCode'
    'city'
    'country'
    ${state ? `'state'` : ``}
    'back'
    'next';

  ${dsmMediaQuery('medium device')} {
    min-width: 24rem;
    grid-template-areas:
      'installation-note-label installation-note-label'
      'installation-note-field installation-note-field'
      'claim claim'
      'name name'
      'address address'
      'postalCode postalCode'
      'city city'
      'country country'
      ${state ? `'state state'` : ``}
      'back next';
  }
`;

const summaryStyles = (state: boolean) => css`
  display: grid;
  grid-template-areas:
    'installation-note-label'
    'installation-note-field'
    'note'
    'claim'
    'name'
    'address'
    'postal'
    ${state ? `'state'` : ``}
    'city'
    'country'
    'back'
    'next';

  ${dsmMediaQuery('medium device')} {
    min-width: 24rem;

    grid-template-areas:
      'installation-note-label installation-note-label'
      'installation-note-field installation-note-field'
      'note note'
      'claim claim'
      'name name'
      'address address'
      'postal postal'
      ${state ? `'state state'` : ``}
      'city city'
      'country country'
      'back next';
  }
`;

const note = css`
  grid-area: installation-note-field;
`;

const noteLabel = css`
  grid-area: installation-note-label;
  padding: 0.5rem 0rem;
`;

const noteText = ({ colors }: Theme): SerializedStyles => css`
  min-height: 4rem;
  border: 0.063rem solid ${colors.border.subtle};
  overflow-wrap: break-word;
  max-width: 30rem;
  padding: 0.5rem;
`;

export { note, noteLabel, noteText, contentStyles, formStyles, summaryStyles, dropDownStyle };
