/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useField, FieldHookConfig } from 'formik';
import { useTheme } from '@emotion/react';
import { labelStyles, errorStyles, textInputStyles, containerStyles, hideDefaultArrowsStyles } from './styles';

interface TextInputFieldProps {
  label: string | JSX.Element;
  className?: string;
  isVertical?: boolean;
  step?: string;
  testId?: string;
  skipTouchedCheck?: boolean;
  placeholder?: string;
}

const TextInputField: React.FC<FieldHookConfig<string> & TextInputFieldProps> = (props) => {
  const [field, meta] = useField(props);
  const {
    name,
    placeholder,
    label,
    className,
    type,
    step = 'any',
    isVertical = false,
    testId,
    skipTouchedCheck,
  } = props;
  const isTouched = meta.touched || skipTouchedCheck;
  const theme = useTheme();
  return (
    <div className={className} css={containerStyles(isVertical)} data-testid={testId}>
      <label data-testid={`${testId}-label`} css={labelStyles(theme, isVertical)} htmlFor={name}>
        {label}
      </label>
      <input
        {...field}
        data-testid={`${testId}-input`}
        placeholder={placeholder}
        type={type}
        step={step}
        css={[textInputStyles, hideDefaultArrowsStyles]}
      />
      {isTouched && meta.error ? (
        <div data-testid={`${testId}-input-error`} css={errorStyles}>
          {meta.error}
        </div>
      ) : null}
    </div>
  );
};

export default TextInputField;
