import React from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { css } from '@emotion/react';
import { t } from 'i18next';
import { DosedVolumeProps } from './types';
import { Tile, TileBody, TileHeader } from '../../../../../components/Tiles';
import { NoDataAvailableMessage } from '../../../../../components';
// eslint-disable-next-line camelcase
import { DosedVolume_device$key } from './__generated__/DosedVolume_device.graphql';
import {
  tileBodyStyles,
  summaryContainerStyles,
  summaryStyles,
  graphContainerStyles,
  summaryValueStyles,
} from './styles';
import dataPointsGrouping from './dataPointsGrouping';
import DosedVolumeGraph from './DosedVolumeGraph/DosedVolumeGraph';

const height = 200;

const DataSummary = ({ label, value, valueType }: { label: string; value: string; valueType: string }) => (
  <div css={summaryStyles}>
    <div>{label}</div>
    <div css={summaryValueStyles}>{`${value} ${valueType}`}</div>
  </div>
);

const DosedVolumeSummary = ({
  labelTotal,
  labelSelected,
  valueActual,
  valueTarget,
  unitTarget,
  unitActual,
}: {
  labelTotal: string;
  labelSelected: string;
  valueTarget: string | undefined | null;
  valueActual: string;
  unitTarget: string;
  unitActual: string;
}) => (
  <div css={summaryContainerStyles}>
    <DataSummary value={valueActual} label={labelTotal} valueType={unitActual} />
    {valueTarget !== undefined && valueTarget !== null && (
      <DataSummary value={valueTarget} label={labelSelected} valueType={unitTarget} />
    )}
  </div>
);

const DosedVolumeContainer = ({
  TileTitle,
  children,
}: {
  TileTitle: JSX.Element;
  children: JSX.Element[] | JSX.Element;
}) => (
  <Tile
    key="volumetric"
    styles={css`
      grid-area: volumetric;
    `}
    Header={TileTitle}
    Body={<TileBody css={tileBodyStyles}>{children}</TileBody>}
  />
);

const DosedVolume: React.FC<DosedVolumeProps> = ({ device }) => {
  // eslint-disable-next-line camelcase
  const data = useFragment<DosedVolume_device$key>(
    graphql`
      fragment DosedVolume_device on Device @argumentDefinitions(endDate: { type: Int }, startDate: { type: Int }) {
        latestSync {
          outOfSync
        }
        latestDeviceData {
          latestSync
          dataPoints {
            value
            name
            unit
          }
        }
        dosedVlumeTimeSeriesDatapoints: timeSeriesDatapoints(
          datapointSelectors: [
            { name: "sdcs:DOSED_VOLUME_HOURLY", raw: true, geniIdentity: $geniIdentity }
            { name: "sdcs:DOSED_VOLUME_HOURLY_START", raw: true, geniIdentity: $geniIdentity }
          ]
          startDate: $startDate
          endDate: $endDate
        ) {
          name
          unit
          datapoints {
            createdAt
            valueRaw
          }
        }
      }
    `,
    device.node,
  );

  const volumetricData = data?.dosedVlumeTimeSeriesDatapoints?.find((item) => item.name === 'DOSED_VOLUME_HOURLY');
  const volumetricDataStart = data?.dosedVlumeTimeSeriesDatapoints?.find(
    (item) => item.name === 'DOSED_VOLUME_HOURLY_START',
  );
  const selctedVolume = data?.latestDeviceData?.dataPoints?.find(
    (item) => item?.name === 'gfdm:MANUAL_DOSING_CAPACITY',
  );
  const isOutOfSync = data?.latestSync?.outOfSync;

  if (!volumetricData || selctedVolume === undefined || isOutOfSync)
    return (
      <DosedVolumeContainer TileTitle={<TileHeader text={t('WP.OVERVIEW.DOSED_VOLUME')} />}>
        <NoDataAvailableMessage size="text" message={t('WP.ERROR.NO_DATA')} />
      </DosedVolumeContainer>
    );

  const { volumeLastHour, hourValues } = dataPointsGrouping(
    volumetricData?.datapoints || [],
    volumetricDataStart?.datapoints || [],
  );
  return (
    <DosedVolumeContainer TileTitle={<TileHeader text={t('WP.OVERVIEW.DOSED_VOLUME')} />}>
      <DosedVolumeSummary
        labelTotal={t('WP.OVERVIEW.TOTAL_DOSED_VOLUME_DURING_THE_LAST_HOUR')}
        labelSelected={t('WP.OVERVIEW.SELECTED_DOSING_VOLUME')}
        valueActual={volumeLastHour.toFixed(4)}
        valueTarget={selctedVolume?.value?.toFixed(4)}
        unitTarget={selctedVolume?.unit || ''}
        unitActual={volumetricData.unit || ''}
      />
      <div css={graphContainerStyles}>
        {hourValues && hourValues.length > 0 ? (
          <DosedVolumeGraph
            height={height}
            data={hourValues}
            labels={{
              postFix: selctedVolume?.unit || '',
              yAxis: {
                legend: selctedVolume?.unit || '',
              },
              dosedVolume: t('WP.OVERVIEW.DOSED.VOLUME_GRAPH_DOSED_VOLUME_LABEL'),
            }}
          />
        ) : (
          // If there are no hourly values it means that the `fragment DosedVolume_device` did not have any values
          // dataPointsGrouping uses the most recent data (the most recent could be 48 hours old) and goes 24 hours back (but still limited by the startDate of the fragment)
          // This message is meant as a simplification because the above will be too complex a message for the user
          <NoDataAvailableMessage size="text" message={t('WP.ERROR.NO_DOSED_VOLUME_REG_LAST_24')} />
        )}
      </div>
    </DosedVolumeContainer>
  );
};

export default DosedVolume;
