import { css, SerializedStyles, Theme } from '@emotion/react';

const stepStyles = (handleClick: boolean, lineColor: string): SerializedStyles => css`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  align-items: center;
  justify-items: center;
  position: relative;
  cursor: ${handleClick ? 'pointer' : 'default'};
  transition: background-color 500ms;
  &:not(:last-child) {
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 0.13rem;
      top: 25%;
      background-color: ${lineColor};
      translate: transform(-50%);
      left: 50%;
    }
  }
`;

const bulletStyles = (
  status: 'active' | 'completed' | 'default',
  lineColor: string,
  circleColor: string,
  handleClick: boolean,
  theme: Theme,
): SerializedStyles => css`
  width: 1.5rem;
  height: 1.5rem;
  transform: ${status === 'active' ? 'scale(1.5, 1.5)' : 'scale(1, 1)'};
  transition: transform 0.5s ease-in-out;
  border: 0.13rem solid ${lineColor};
  background-color: ${circleColor};
  border-radius: 50%;
  z-index: 10;
  &:hover {
    background-color: ${handleClick ? theme.colors.blue.blue3.hoverColor : circleColor};
    transition: background-color 150ms;
  }
`;

const labelStyles = css`
  padding: 0.5rem;
  text-align: center;
`;

export { stepStyles, bulletStyles, labelStyles };
