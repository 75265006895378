import React, { useCallback, useMemo } from 'react';
import { AdjustableSliderProps } from './types';
import DualRangeSlider from '../DualRangeSlider/DualRangeSlider';
import { containerStyles, label, labels, slider } from './styles';

const AdjustableSlider: React.FC<AdjustableSliderProps> = ({
  maxUnit,
  minUnit,
  maxRange,
  minRange,
  leftThumb,
  rightThumb,
  isDisabled,
  decimalPlaces,
  overlapThreshold,
  step,
  onChange,
  testId = 'adjustable-slider',
}) => {
  const { maxValue, minValue } = useMemo(() => {
    let minValueNext = leftThumb;
    let maxValueNext = rightThumb;

    if (leftThumb < minRange) {
      // eslint-disable-next-line no-console
      console.error(`Minimum value/left thumb adjusted from '${minValueNext}' to '${minRange}'`);
      minValueNext = minRange;
    }

    if (rightThumb > maxRange) {
      // eslint-disable-next-line no-console
      console.error(`Maximum value/right thumb adjusted from '${maxValueNext}' to '${maxRange}'`);
      maxValueNext = maxRange;
    }

    return {
      minValue: minValueNext,
      maxValue: maxValueNext,
    };
  }, [leftThumb, maxRange, minRange, rightThumb]);

  const onValuesChange = useCallback(
    (values: { leftThumb: number; rightThumb: number }) => {
      if (onChange) onChange(values);
    },
    [onChange],
  );

  return (
    <div data-testid={`${testId}`} css={containerStyles}>
      <div css={labels}>
        <div css={label} data-testid={`${testId}-label-min`}>{`${minValue.toFixed(decimalPlaces)} ${minUnit}`}</div>
        <div css={label} data-testid={`${testId}-label-max`}>{`${maxValue.toFixed(decimalPlaces)} ${maxUnit}`}</div>
      </div>
      <div css={slider}>
        <DualRangeSlider
          step={step}
          overlapThreshold={overlapThreshold}
          min={minRange}
          max={maxRange}
          leftThumb={minValue}
          rightThumb={maxValue}
          setThumbValues={onValuesChange}
          testId={testId}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
};

export default AdjustableSlider;
