import { css, SerializedStyles, Theme } from '@emotion/react';
import dsmMediaQuery from '../../../../utils/media-queries/media-queries';

const cursorNotAllowedStyles = css`
  opacity: 0.2;
  cursor: not-allowed;
`;

const logoutButtonStyles = css`
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
`;

const logoutWrapperStyles = css`
  display: flex;
  padding: 1rem;
  flex: 1;
  margin-bottom: 0.4375rem;
`;

const navLink = css`
  display: flex;
  flex: 1;
`;

const activeLink = (theme: Theme): SerializedStyles => css`
  display: flex;
  padding: 1rem;
  flex: 1;
  justify-content: center;
  align-items: center;
  transition-property: background-color;
  transition-duration: 0.33s;
  transition-timing-function: ease-out;
  &.active {
    animation: activeElementBorder 0.33s ease-out;
    background-color: ${theme.colors.navigation.mainNavigationSelected};
    border-bottom: 0.1875rem solid ${theme.colors.blue.blue4.baseColor};
    padding-bottom: 0.8125rem;
    ${dsmMediaQuery('large device')} {
      animation: activeElementBorder 0.33s ease-out;
      background-color: ${theme.colors.navigation.mainNavigationSelected};
      border-left: 0.1875rem solid ${theme.colors.blue.blue4.baseColor};
      padding-left: 0.8125rem;
      padding-bottom: 1rem;
      border-bottom: 0;
    }
  }
  @keyframes activeElementBorder {
    0% {
      border-color: transparent;
    }
    100% {
      border-color: ${theme.colors.blue.blue4.baseColor};
    }
  }
`;

const accountSwitcher = css`
  display: flex;
  padding: 1rem;
  flex: 1;
`;

export { accountSwitcher, cursorNotAllowedStyles, logoutButtonStyles, logoutWrapperStyles, navLink, activeLink };
