import React from 'react';
import {
  containerStyles,
  flowControlContainer,
  remoteContainerContentStyles,
  remoteContainerStyles,
  remoteHeader,
} from '../styles';

const DefaultContainer = ({ children }: { children: JSX.Element | JSX.Element[] }) => <div>{children}</div>;

const RemoteLayout = ({
  Header,
  OperationModeSection,
  ControlModeSection,
  FlowControlSection,
  Container,
  Message,
  testId,
}: {
  Container?: ({ children }: { children: JSX.Element | JSX.Element[] }) => JSX.Element;
  Message?: JSX.Element;
  Header: JSX.Element;
  OperationModeSection: JSX.Element;
  ControlModeSection: JSX.Element;
  FlowControlSection: JSX.Element | false | null;
  testId?: string;
}) => {
  const SelectedContainer = Container || DefaultContainer;
  return (
    <SelectedContainer css={containerStyles}>
      <div data-testid={testId} css={remoteContainerStyles}>
        <div css={remoteHeader}>
          {Boolean(Message) && Message}
          {Header}
        </div>
        <div css={remoteContainerContentStyles}>
          <div>
            {OperationModeSection}
            {ControlModeSection}
          </div>
        </div>
        <div css={flowControlContainer}>{FlowControlSection}</div>
      </div>
    </SelectedContainer>
  );
};

export default RemoteLayout;
