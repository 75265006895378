import { useEffect, useState } from 'react';
import { getUnixTime, subDays, subHours, addYears } from 'date-fns';

const useUnixStartEndDate = () => {
  const [startDate, setStartDate] = useState<number>();
  const [endDate, setEndDate] = useState<number>();
  useEffect(() => {
    setStartDate(getUnixTime(subHours(subDays(new Date(), 1), 12)));
    // Hack: We set the enddate to 5 years in the future in order to make sure we always get the latest data when fetch data for the overview every 10 sec.
    setEndDate(getUnixTime(addYears(new Date(), 5)));
  }, []);

  return { startDate, endDate };
};

export default useUnixStartEndDate;
