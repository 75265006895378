import React from 'react';
import { Loader } from '../../components';
import { spinnerModalStyles } from './styles';

const Loading: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
  if (!isLoading) return null;
  return (
    <div css={spinnerModalStyles}>
      <Loader size="large" />
    </div>
  );
};

export default Loading;
