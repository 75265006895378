const setCustomerAccount = (name: string): void => {
  const state = window.digitalData[window.digitalData.length - 1];
  window.digitalData.push({
    ...state,
    data: {
      ...state?.data,
      user: {
        ...state?.data?.user,
        accountName: name,
      },
    },
  });
};

export default setCustomerAccount;
