import React from 'react';
import { Link } from 'react-router-dom';
import { useFragment, graphql } from 'react-relay/hooks';
import { useTranslation } from 'react-i18next';
import deviceIcon from '../../../../../assets/images/pump-dde.svg';
import { DeviceListItemProps } from './types';
import { itemStyles, deviceStyles, iconStyles, linkStyles } from './styles';
import { DeviceListItem_device$key } from './__generated__/DeviceListItem_device.graphql';
import { paragraphBrightStyles } from '../../../../../styles';
import { useAccount } from '../../../../../providers/AccountProvider';

const DeviceListItem: React.FC<DeviceListItemProps> = (props) => {
  const { t } = useTranslation();
  const {
    account: { uuid: accountId },
  } = useAccount();

  const device = useFragment<DeviceListItem_device$key>(
    graphql`
      fragment DeviceListItem_device on Device {
        uuid
        name
        descendantDeviceInfo {
          type
        }
      }
    `,
    // eslint-disable-next-line react/destructuring-assignment
    props.device,
  );

  const { name, uuid } = device;
  const pumpIdentity = device.descendantDeviceInfo?.type;
  const redirectLink = `/account/${accountId}/gateways/${uuid}`;
  return (
    <div css={itemStyles}>
      <img src={deviceIcon} height="20" width="20" alt="device-icon" css={iconStyles} />
      <div css={[paragraphBrightStyles, deviceStyles]}>{name}</div>
      <Link to={`${redirectLink}/${pumpIdentity}`} css={linkStyles}>
        {t('WP.COMMON.SEE_DASHBOARD')}
      </Link>
    </div>
  );
};

export default DeviceListItem;
