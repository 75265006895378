import React from 'react';
import stop from '../../../assets/images/stopFilled.svg';
import play from '../../../assets/images/playFilled.svg';
import pause from '../../../assets/images/pauseFilled.svg';
import deaerating from '../../../assets/images/deaeratingIcon.svg';

const PumpStatusIcon = ({ status }: { status: string }) => {
  if (status === 'ACTIVE') return <img height="16" src={play} alt="play" />;
  if (status === 'STOPPED') return <img height="16" src={stop} alt="stop" />;
  if (status === 'STANDBY') return <img height="16" src={pause} alt="pause" />;
  if (status === 'DEAERATING') return <img height="16" src={deaerating} alt="deaerating" />;

  return null;
};

export default PumpStatusIcon;
