import { css, SerializedStyles, Theme } from '@emotion/react';
import dsmMediaQuery from '../../../../../utils/media-queries/media-queries';

const alertsImg = css`
  width: 8rem;
  padding-bottom: 1rem;
`;

const tileBodyStyles = css`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-items: center;
`;

const alertsContainer = css`
  padding: 2rem;
  min-width: 16rem;
  min-height: 18rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const alertsText = (theme: Theme): SerializedStyles => css`
  color: ${theme.colors.secondaryText};
  font-size: 1rem;
  font-weight: 700;
`;

const alertsDescriptionText = (theme: Theme): SerializedStyles => css`
  color: ${theme.colors.primaryText};
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
`;

const allEventsContainer = css`
  display: flex;
  justify-content: flex-end;
`;

const allEventsText = (theme: Theme): SerializedStyles => css`
  color: ${theme.colors.tertiaryText};
  text-decoration: none;
  text-transform: none;
  font-size: 0.9rem;
  font-weight: 400;
  &:hover {
    text-decoration: underline;
  }
`;

const scrollContentWrapperStyles = css`
  padding: 0 1rem;
`;

const buttonStyles = css`
  height: unset;
  width: unset;
  min-width: unset;
  padding: 0;
  text-transform: unset;
  font-size: 0.9rem;
`;

export {
  allEventsContainer,
  alertsDescriptionText,
  allEventsText,
  alertsText,
  alertsImg,
  alertsContainer,
  tileBodyStyles,
  scrollContentWrapperStyles,
  buttonStyles,
};
