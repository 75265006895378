import React, { useContext } from 'react';

import { AdjustableSlider } from '../../../../../components';
import { PressureSliderProps } from './types';
import { LocaleContext } from '../../../../../providers/LocaleProvider/LocaleProvider';

const useSliderSttings = () => {
  const { unitSystemName } = useContext(LocaleContext);
  switch (unitSystemName) {
    case 'European Unit':
      return {
        step: 0.1,
        decimalPlaces: 2,
        overlapThreshold: 1,
      };
    case 'US Unit':
      return {
        step: 1,
        decimalPlaces: 0,
        overlapThreshold: 15,
      };
    default:
      break;
  }
};

const PressureSlider: React.FC<PressureSliderProps> = ({
  maxPressureUnit,
  minPressureUnit,
  pressureMaxRange,
  pressureMinRange,
  minPressureValue,
  maxPressureValue,
  onChange,
  isDisabled = false,
  testId,
}) => {
  const { step, decimalPlaces, overlapThreshold } = useSliderSttings() || {};

  if (
    minPressureUnit === null ||
    maxPressureUnit === null ||
    minPressureValue === null ||
    maxPressureValue === null ||
    pressureMinRange === null ||
    pressureMinRange === undefined ||
    pressureMaxRange === undefined ||
    pressureMaxRange === null ||
    step === undefined ||
    decimalPlaces === undefined ||
    overlapThreshold === undefined
  ) {
    // eslint-disable-next-line no-console
    console.warn('[PressureSlider] Missing one or more required values', {
      maxPressureUnit,
      minPressureUnit,
      pressureMaxRange,
      pressureMinRange,
      minPressureValue,
      maxPressureValue,
      step,
      decimalPlaces,
      overlapThreshold,
    });
    return null;
  }

  return (
    <AdjustableSlider
      leftThumb={minPressureValue}
      rightThumb={maxPressureValue}
      minRange={pressureMinRange}
      maxRange={pressureMaxRange}
      isDisabled={isDisabled}
      maxUnit={maxPressureUnit}
      minUnit={minPressureUnit}
      testId={testId}
      step={step}
      decimalPlaces={decimalPlaces}
      overlapThreshold={overlapThreshold}
      onChange={onChange}
    />
  );
};

export default PressureSlider;
