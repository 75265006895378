import { v4 } from 'uuid';
import { subMonths, getUnixTime } from 'date-fns';
import { PumpType } from '../types';
import getAccessToken from '../../utils/auth/accessTokenUtils';

const getDataPoints = (pumpType: PumpType) => {
  switch (pumpType) {
    case 'DDA':
      return `
      sdcs:PROPORTIONAL_DOSING_ACTUAL_CONCENTRATION,
      sdcs:CALCULATED_CONTAINER_LEVEL,
      gfdm:VOLUME,
      sdcs:VOLUME_PROCESSED,
      gfdm:TOTAL_POWERED_TIME,
      gfdm:RUNTIME,
      sdcs:TIME_NEXT_SERVICE
     `.trim();
    case 'DDA-C':
      return `
      sdcs:PROPORTIONAL_DOSING_ACTUAL_CONCENTRATION,
      sdcs:CALCULATED_CONTAINER_LEVEL,
      gfdm:VOLUME_ENDLESS_NANOL,
      sdcs:VOLUME_PROCESSED,
      gfdm:TOTAL_POWERED_TIME,
      gfdm:RUNTIME,
      sdcs:TIME_NEXT_SERVICE
     `.trim();
    default:
      return '';
  }
};

// eslint-disable-next-line import/prefer-default-export
export const fetchCsvReport = async (
  reportType: 'EVENTS' | 'TRENDS',
  accountId: string,
  pumpType: PumpType,
  twinId?: string | null,
  deviceId?: string | null,
) => {
  const correlationId = v4();
  const baseDate = new Date().getTime();
  const endDate = getUnixTime(baseDate).toString();
  const startDate = getUnixTime(subMonths(baseDate, 3).getTime()).toString();
  try {
    let url;
    let queryParams;
    if (reportType === 'TRENDS') {
      url = new URL(`${process.env.GIC_UI_CSV_URL as string}/trends/${twinId}/${deviceId}/pumpType/${pumpType}`);
      queryParams = {
        // Dates need to be in seconds, therefore we use getUnixTime
        endDate,
        startDate,
        datapoints: getDataPoints(pumpType),
      };
    }
    if (reportType === 'EVENTS') {
      url = new URL(`${process.env.GIC_UI_CSV_URL as string}/alerts/${twinId}/${deviceId}/pumpType/${pumpType}`);
      queryParams = {
        endDate,
        startDate,
        accountId,
      };
    }
    if (!url) throw Error('no url provided');
    url.search = new URLSearchParams(queryParams).toString();

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        // 'Content-Type': 'text/csv',
        'x-correlation-id': correlationId,
        'x-caller-id': 'sdcs-client',
        'x-account-id': accountId,
      },
    });

    if (response.status !== 200) throw Error(`Fetching report failed: '${await response.text()}'`);

    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.log('Error', error);
    throw error;
  }
};
