import { InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react';
import React, { FC, PropsWithChildren, useEffect } from 'react';
import Loader from '../Loader/Loader';

const LoginHandler: FC<PropsWithChildren> = ({ children }) => {
  const { login, error } = useMsalAuthentication(InteractionType.Redirect);

  useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
      login(InteractionType.Redirect);
    }
  }, [error, login]);

  return (
    <>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Loader />
      </UnauthenticatedTemplate>
    </>
  );
};

export default LoginHandler;
