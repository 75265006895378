const tabClicked = (label: string, subLabel?: string): void => {
  const state = window.digitalData[window.digitalData.length - 1];
  window.digitalData.push({
    ...state,
    event: 'Click',
    data: {
      ...state?.data,
      eventName: 'Tab changed',
      tabName: label,
      subTabName: subLabel,
    },
  });
};

export default tabClicked;
