import React from 'react';
import { useTheme } from '@emotion/react';
import { stepStyles, bulletStyles, labelStyles } from './styles';
import { StepProps } from './types';

const Step: React.FC<StepProps> = ({ activeStep, index, children, handleClick, className }) => {
  const theme = useTheme();
  const { colors } = useTheme();

  const getStatus = (stepIndex: number, currentStep: number): 'active' | 'completed' | 'default' => {
    if (currentStep === stepIndex) return 'active';
    if (currentStep > stepIndex) return 'completed';
    if (currentStep < stepIndex) return 'default';
    return 'default';
  };

  const colorCircle = (state: string): string => {
    if (state === 'default') return colors.primaryBackground;
    if (state === 'active') return colors.blue.blue3.hoverColor;
    if (state === 'completed') return colors.blue.coporateBlue.pressedColor;
    return colors.primaryBackground;
  };

  const colorLine = (state: string): string => {
    if (state === 'default') return colors.blue.blue3.baseColor;
    if (state === 'active') return colors.blue.blue3.hoverColor;
    if (state === 'completed') return colors.blue.coporateBlue.pressedColor;
    return colors.primaryBackground;
  };

  return (
    <div
      onClick={handleClick}
      role="button"
      onKeyDown={handleClick}
      tabIndex={0}
      className={className}
      css={stepStyles(!!handleClick, colorLine(getStatus(index, activeStep)))}
    >
      <div
        css={bulletStyles(
          getStatus(index, activeStep),
          colorLine(getStatus(index, activeStep)),
          colorCircle(getStatus(index, activeStep)),
          !!handleClick,
          theme,
        )}
      />
      <div css={labelStyles}>{children}</div>
    </div>
  );
};

export default Step;
