import React, { useState } from 'react';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { EventListItemProps } from './types';
// eslint-disable-next-line camelcase
import { DateTimeFormatter } from '../../../components';
import { Alarm, Warning } from '../../../components/Icons';
import { paragraphBrightStyles, paragraphDimmedStyles } from '../../../styles';
import { alertItemContainerStyles } from '../styles';
import { linkStyles, itemStyles, alertItemHoverStyles, warningAndAlarmStyles, lastItemStyles } from './styles';

const EventListItem: React.FC<EventListItemProps> = ({
  type,
  alertCode,
  customerName,
  initiatorTranslationKey,
  installationName,
  createdAt,
  redirectLink,
}) => {
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState(false);
  const { t } = useTranslation();
  return (
    <div
      css={alertItemContainerStyles}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div css={[itemStyles, alertItemHoverStyles(isHovered, theme)]}>
        {type === 'ALARM' && (
          <div css={warningAndAlarmStyles}>
            <Alarm color={theme.colors.event.alarm} size="1.5rem" />
            <span css={[paragraphBrightStyles]}>{alertCode}</span>
          </div>
        )}
        {type === 'WARNING' && (
          <div css={warningAndAlarmStyles}>
            <Warning color={theme.colors.event.warning} size="1.5rem" />
            <span css={[paragraphBrightStyles]}>{alertCode}</span>
          </div>
        )}
      </div>
      <div css={[itemStyles, alertItemHoverStyles(isHovered, theme), paragraphBrightStyles]}>{customerName}</div>
      <div css={[itemStyles, alertItemHoverStyles(isHovered, theme), paragraphDimmedStyles]}>{installationName}</div>
      <div css={[itemStyles, alertItemHoverStyles(isHovered, theme), paragraphDimmedStyles]}>
        {t(initiatorTranslationKey)}
      </div>
      <div css={[itemStyles, alertItemHoverStyles(isHovered, theme), paragraphDimmedStyles]}>
        <DateTimeFormatter timestamp={createdAt} />
      </div>
      <div css={[itemStyles, alertItemHoverStyles(isHovered, theme), paragraphDimmedStyles, lastItemStyles]}>
        <Link to={`${redirectLink}`} css={linkStyles}>
          {t('WP.COMMON.SEE_DASHBOARD')}
        </Link>
      </div>
    </div>
  );
};

export default EventListItem;
