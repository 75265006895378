import { css, Theme } from '@emotion/react';
import dsmMediaQuery from '../../../../../utils/media-queries/media-queries';

const tileBodyStyles = css`
  display: grid;
  align-content: center;
  justify-items: center;
`;

const countdownBarStyles = (theme: Theme) => css`
  display: flex;
  gap: 0.2rem;
  font-size: 0.75rem;
  color: ${theme.colors.neutral.neutral400.hex};
`;

const chemicalConnectedTileFooter = (theme: Theme) => css`
  font-size: 0.75rem;
  color: ${theme.colors.neutral.neutral400.hex};
  text-align: unset;
  justify-items: start;
  display: grid;
  grid-template-rows: max-content max-content;
  grid-gap: 0.5rem;
  font-size: 0.75rem;
`;

const noDataStyles = css`
  ${dsmMediaQuery('medium device')} {
    font-size: 2rem;
  }
`;

export { tileBodyStyles, chemicalConnectedTileFooter, noDataStyles, countdownBarStyles };
