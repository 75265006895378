import { useState } from 'react';
import { UseOffcanvasProps } from './types';

const useOffcanvas = (show = false): UseOffcanvasProps => {
  const [showOffcanvas, setShowOffcanvas] = useState(show);

  const toggleIsOpen = (): void => setShowOffcanvas(!showOffcanvas);

  return { setShowOffcanvas, showOffcanvas, toggleIsOpen };
};

export default useOffcanvas;
