export const colorMe = (
  currentVal: number,
  threshold: number,
  cellVal: number,
  colors: { yellow: string; green: string; empty: string },
): string =>
  // eslint-disable-next-line no-nested-ternary
  cellVal < threshold ? `${colors.yellow}` : cellVal >= currentVal ? `${colors.empty}` : `${colors.green}`;

// max/min(threshold) => the total number of cells e.g. 8000/500 => 16, 24/1 => 24 (these are the two tested and supported scenarios)
export const createCells = (max: number, min: number) => {
  if (!Number.isInteger(max) || !Number.isInteger(min)) throw new Error('min or max input is not an integer');
  if (max <= min) throw new Error('max input is less than or equal to min input');
  if (max === 0 || min === 0) throw new Error('min or max is 0');
  if (max % min !== 0) throw new Error('max/min does not result in a whole number');

  const cells = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < max / min; i++) {
    cells.push({ value: i * min });
  }
  return cells;
};
