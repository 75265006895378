/**
 * @generated SignedSource<<f6be462466c3120fe6e3d063446c244e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SelectOrCreateCustomerQuery$variables = {};
export type SelectOrCreateCustomerQuery$data = {
  readonly clientState: {
    readonly onboarding: {
      readonly onboardingId: string | null;
      readonly account: {
        readonly company: string;
      } | null;
    } | null;
  } | null;
  readonly onboardingAccounts: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string;
        readonly locations: {
          readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly name: string | null;
            readonly address: string | null;
            readonly postalCode: string | null;
            readonly city: string | null;
            readonly country: string | null;
          } | null> | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};
export type SelectOrCreateCustomerQuery = {
  variables: SelectOrCreateCustomerQuery$variables;
  response: SelectOrCreateCustomerQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "typeCodes",
    "value": [
      "SECTION"
    ]
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalCode",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "country",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "onboardingId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "OnboardingAccount",
  "kind": "LinkedField",
  "name": "account",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "company",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectOrCreateCustomerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountConnection",
        "kind": "LinkedField",
        "name": "onboardingAccounts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": (v1/*: any*/),
                    "concreteType": "LocationConnection",
                    "kind": "LinkedField",
                    "name": "locations",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v0/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "locations(typeCodes:[\"SECTION\"])"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "kind": "ClientExtension",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientState",
            "kind": "LinkedField",
            "name": "clientState",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Onboarding",
                "kind": "LinkedField",
                "name": "onboarding",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SelectOrCreateCustomerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountConnection",
        "kind": "LinkedField",
        "name": "onboardingAccounts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": (v1/*: any*/),
                    "concreteType": "LocationConnection",
                    "kind": "LinkedField",
                    "name": "locations",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          (v0/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "locations(typeCodes:[\"SECTION\"])"
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "kind": "ClientExtension",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientState",
            "kind": "LinkedField",
            "name": "clientState",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Onboarding",
                "kind": "LinkedField",
                "name": "onboarding",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "607bd7b656c69adf483e950ad6ca99c9",
    "id": null,
    "metadata": {},
    "name": "SelectOrCreateCustomerQuery",
    "operationKind": "query",
    "text": "query SelectOrCreateCustomerQuery {\n  onboardingAccounts {\n    edges {\n      node {\n        name\n        locations(typeCodes: [SECTION]) {\n          nodes {\n            __typename\n            id\n            name\n            address\n            postalCode\n            city\n            country\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "48c4a265753e05f7ea39803e71636157";

export default node;
