import { css, Theme } from '@emotion/react';
import { paragraphBrightStyles } from '../../styles';

const containerStyles = css`
  display: flex;
  flex-direction: column;
  margin: 1rem 0rem 1rem 0rem;
`;

const labels = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0rem 0.8rem 0.1rem 0.8rem;
`;

const slider = css`
  display: block;
  padding: 0.5rem 0.8rem 0rem 0.8rem;
`;

const label = (theme: Theme) => css`
  ${paragraphBrightStyles(theme)}
`;

export { containerStyles, labels, slider, label };
