import React, { useCallback } from 'react';
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toGlobalId } from 'graphql-relay';
import { usePageViewed } from '../../../../adobe-launch/hooks';
import { Button, Spinner } from '../../../../components';
import {
  applyButton,
  applyContainer,
  backBtnWrapperStyles,
  contentStyles,
  header,
  nextBtnWrapperStyles,
  note,
  noteLabel,
  noteText,
} from './styles';

import InstallationLocationForm from './InstallationLocationForm';

import { InstallationDetailsMutation } from './__generated__/InstallationDetailsMutation.graphql';
import { InstallationDetailsQuery } from './__generated__/InstallationDetailsQuery.graphql';
import { heading05Styles, paragraphBaseStyles, paragraphBrightStyles } from '../../../../styles';

const onboardingRecordID = 'client:clientState:onboarding';
const formId = 'claimForm';
const InstallationDetails: React.FC = () => {
  usePageViewed('Onboarding:InstallationDetails');
  const { state } = useLocation();
  const claimId = (state as { claimId: string })?.claimId;
  const { t } = useTranslation();
  // Last changed is used as a way to make formik form realize there has been a change.
  // It is not important that number represent a date, just that the number is changed when we want formik to update its starte
  const [lastChanged, setLastChanged] = React.useState<number>(0);
  const navigate = useNavigate();
  const { clientState, node } = useLazyLoadQuery<InstallationDetailsQuery>(
    graphql`
      query InstallationDetailsQuery($id: ID!) {
        clientState {
          onboarding {
            onboardingId
            installationName
            section {
              address
              postalCode
              city
              country
              state
            }
          }
        }
        ... on Query {
          __typename
        }
        node(id: $id) {
          ... on UnclaimedDevice {
            installationNote
          }
        }
      }
    `,
    { id: toGlobalId('UnclaimedDevice', claimId) },
  );
  const installationNote = node?.installationNote || '';

  const { city, address, postalCode, country, state: stateRegion } = clientState?.onboarding?.section || {};

  const clientInstallationName = clientState?.onboarding?.installationName;

  const [installationName, setInstallationName] = React.useState(clientInstallationName);

  const [commit, isInFlight] = useMutation<InstallationDetailsMutation>(graphql`
    mutation InstallationDetailsMutation($id: ID!, $input: OnboardingClaimDeviceInput!) {
      onboardingClaimDevice(id: $id, input: $input) {
        id
        onboardingId
        device {
          claimId
        }
        installationName
        installationInformation {
          installationCity
          installationStreet
          installationState
          installationPostalCode
          installationCountryCode
        }
      }
    }
  `);
  const onConfirm = useCallback(
    ({ installationName: onConfirmInstallationName }: { installationName: string }) => {
      const onboardingId = clientState?.onboarding?.onboardingId;
      if (onboardingId) {
        commit({
          variables: {
            id: onboardingRecordID,
            input: {
              onboardingId,
              claimId,
              installationName: onConfirmInstallationName,
              installationInformation: {
                installationCity: city,
                installationCountryCode: country,
                installationPostalCode: postalCode,
                installationStreet: address,
                installationState: stateRegion,
              },
            },
          },
          onCompleted: () => navigate('/onboarding/summary', { state: { claimId } }),
        });
      }
    },
    [address, city, claimId, clientState?.onboarding?.onboardingId, commit, country, navigate, postalCode, stateRegion],
  );

  if (isInFlight) return <Spinner />;

  return (
    <div css={contentStyles}>
      <h1 css={[header, heading05Styles]}>{t('WP.ONBOARDING.INSTALLATION_DETAILS_TITLE')}</h1>
      <br />
      <label htmlFor="installationNote" css={note}>
        <div css={[paragraphBaseStyles, noteLabel]}>{t('WP.ONBOARDING.INSTALLATION_NOTE_LABEL')}</div>
        <div css={[paragraphBrightStyles, noteText]}>{installationNote}</div>
      </label>
      <div css={applyContainer}>
        <div css={paragraphBaseStyles}>{t('WP.ONBOARDING.INSTALLATION_NOTE_APPLY_TEXT')}</div>
        <Button
          onClick={() => {
            setInstallationName(installationNote);
            setLastChanged(new Date().getTime());
          }}
          variant="text link"
          type="button"
          css={applyButton}
        >
          {t('WP.ONBOARDING.INSTALLATION_NOTE_APPLY_BTN')}
        </Button>
      </div>
      <InstallationLocationForm
        formId={formId}
        onSubmit={onConfirm}
        installationName={installationName || ''}
        lastChanged={lastChanged}
      />

      <div css={backBtnWrapperStyles}>
        <Button
          type="button"
          variant="secondary"
          onClick={() => navigate('/onboarding/customer-summary', { state: { claimId } })}
        >
          {t('WP.COMMON.BACK')}
        </Button>
      </div>
      <div css={nextBtnWrapperStyles}>
        <Button type="submit" variant="primary" formId={formId}>
          {t('WP.COMMON.NEXT')}
        </Button>
      </div>
    </div>
  );
};

export default InstallationDetails;
