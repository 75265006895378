import React from 'react';
import { useTranslation } from 'react-i18next';
import { gridPlacement } from '../styles';
import { InfoItem } from '../../../../../components';
import { isNotUndefinedOrNull } from '../../../utils';

const RemoteControlStatus = ({ geniBusControl }: { geniBusControl: 'ON' | 'OFF' | undefined }) => {
  const { t } = useTranslation();
  return (
    <InfoItem
      css={gridPlacement('remoteControl')}
      label={t('WP.COMMON.REMOTE_CONTROL')}
      value={
        isNotUndefinedOrNull(geniBusControl) ? t(`WP.COMMON.${geniBusControl}`).toUpperCase() : t('WP.ERROR.NO_DATA')
      }
    />
  );
};

export default RemoteControlStatus;
