import React from 'react';
import { tileHeaderStyles, tileHeaderStatusStyles, noIconStyles } from './styles';
import { TileHeaderProps } from './types';

const TileHeader: React.FC<TileHeaderProps> = ({ status, icon, styles, text }) => (
  <div css={[tileHeaderStyles, icon ? undefined : noIconStyles, styles]}>
    {icon ? <img src={icon} height="16" alt="tileHeaderIcon" /> : null}
    <span data-test-id="tile-title">{text}</span>
    <div css={tileHeaderStatusStyles}>{status}</div>
  </div>
);

export default TileHeader;
