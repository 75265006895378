import { graphql, useFragment } from 'react-relay/hooks';
import React from 'react';
// eslint-disable-next-line camelcase
import { PumpStatusDataDDAC_device$key } from './__generated__/PumpStatusDataDDAC_device.graphql';
import { OverviewChemPairingDDACQuery$data } from '../__generated__/OverviewChemPairingDDACQuery.graphql';
import { pumpStatusContainerStyles } from './styles';
import PumpStatus from './PumpStatus';
import NoPumpStatusData from '../../Overview/OverviewChemPairing/PumpStatus/NoPumpStatusData';

const PumpStatusData: React.FC<{ device: OverviewChemPairingDDACQuery$data }> = ({ device }) => {
  // eslint-disable-next-line camelcase
  const data = useFragment<PumpStatusDataDDAC_device$key>(
    graphql`
      fragment PumpStatusDataDDAC_device on Device {
        latestSync {
          outOfSync
        }
        descendantDeviceInfo {
          ... on DDAC {
            pumpSettings {
              controlMode
              displayColor {
                color
              }
              dosingSettings {
                maxCapacity {
                  quantity
                  unit
                }
              }
              remoteControlEnabled
              isPumping
              operatingMode
              stoppedBy
            }
          }
        }
      }
    `,
    device.node,
  );
  const { controlMode, displayColor, operatingMode, stoppedBy, dosingSettings, isPumping, remoteControlEnabled } =
    data?.descendantDeviceInfo?.pumpSettings || {};

  const { quantity, unit } = dosingSettings?.maxCapacity || {};

  const isOutOfSync = data?.latestSync?.outOfSync;
  if (isOutOfSync) return <NoPumpStatusData />;

  return (
    <PumpStatus
      backlight={displayColor?.color}
      maxCapacity={{ value: quantity, unit }}
      remoteControlEnabled={remoteControlEnabled}
      operatingMode={operatingMode || undefined}
      stoppedBy={stoppedBy || undefined}
      isPumping={isPumping ?? undefined}
      controlMode={controlMode || undefined}
      containerStyle={pumpStatusContainerStyles}
    />
  );
};

export default PumpStatusData;
