import { graphql, useFragment } from 'react-relay/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { VolumetricConcentrationData$key } from './__generated__/VolumetricConcentrationData.graphql';
import { AppSetting } from '../types';
import { convertToSuperScript } from '../../../../utils';
import rawBucketsToBucketData from './rawBucketsToBucketData';
import VolumetricConcentration from '../VolumetricConcentration';
import { Labels } from './VolumetricConcentrationGraph/types';
import VolumetricContainer from './VolumetricContainer';
import { NoDataAvailableMessage } from '../../../../../../components';
import { OverviewChemPairingDDACQuery$data } from '../../../../DDAC/__generated__/OverviewChemPairingDDACQuery.graphql';
import { OverviewChemPairingDDAQuery$data } from '../../../../DDA/__generated__/OverviewChemPairingDDAQuery.graphql';

const VolumetricConcentrationData: React.FC<{
  device: OverviewChemPairingDDACQuery$data | OverviewChemPairingDDAQuery$data;
  controlMode: string;
}> = ({ device, controlMode }) => {
  const data = useFragment<VolumetricConcentrationData$key>(
    graphql`
      fragment VolumetricConcentrationData on Device @argumentDefinitions(endDate: { type: Int }) {
        latestSync {
          outOfSync
        }
        descendantDeviceInfo {
          ... on DDAC {
            pumpSettings {
              dosingSettings {
                controlModes {
                  analog {
                    proportionalValidation {
                      ruleViolations
                    }
                  }
                }
              }
            }
          }
        }
        volumetricConcentrationAppSettings: appSettings(
          settingSelector: [{ name: "sdcs:VOLUMETIC_CONCENTRATION_TARGET" }]
          endDate: $endDate
        ) {
          updatedAt
          name
        }
        latestDeviceData {
          latestSync
          dataPoints {
            value
            name
            unit
          }
          buckets {
            bucket
            value
            unit
          }
        }
        volumetricConcentrationTimeSeriesDatapoints: timeSeriesDatapoints(
          datapointSelectors: [
            { name: "sdcs:PROPORTIONAL_DOSING_ACTUAL_CONCENTRATION", raw: true, geniIdentity: $geniIdentity }
          ]
          startDate: $startDate
          endDate: $endDate
        ) {
          name
          unit
          datapoints {
            valueRaw
          }
        }
      }
    `,
    device.node,
  );

  const getSetting = (name: string): AppSetting | undefined | null =>
    data?.volumetricConcentrationAppSettings?.find((setting) => setting?.name === name);

  const proportionalDosingRuleViolations =
    data?.descendantDeviceInfo?.pumpSettings?.dosingSettings?.controlModes?.analog?.proportionalValidation
      ?.ruleViolations;
  const isProportionalDosing = !(
    proportionalDosingRuleViolations &&
    proportionalDosingRuleViolations.length > 0 &&
    controlMode !== 'PULSE'
  );

  const lastConnected = getSetting('sdcs:VOLUMETIC_CONCENTRATION_TARGET')?.updatedAt ?? null;
  // targetVolume is a calculated value which is why we don't fetch it directly from settings service
  const targetVolume =
    data?.latestDeviceData?.dataPoints?.find((dp) => dp?.name === 'sdcs:PROPORTIONAL_DOSING_TARGET')?.value ?? 0;
  const targetVolUnit = convertToSuperScript(
    data?.latestDeviceData?.dataPoints?.find((dp) => dp?.name === 'sdcs:PROPORTIONAL_DOSING_TARGET')?.unit || '',
  );
  const volumetricDataPoints =
    data?.volumetricConcentrationTimeSeriesDatapoints?.find(
      (item) => item.name === 'PROPORTIONAL_DOSING_ACTUAL_CONCENTRATION',
    )?.datapoints || [];

  const buckets = rawBucketsToBucketData(data?.latestDeviceData?.buckets);
  const isOutOfSync = data?.latestSync?.outOfSync;

  const actualVolume = volumetricDataPoints?.[volumetricDataPoints.length - 1]?.valueRaw ?? 0;

  const { t } = useTranslation();
  const graphHeight = 150;
  const labels: Labels = {
    postFixY: '%',
    yAxis: {
      legend: `${t('WP.VOLUMETRIC.CONCENTRATION.TILE_GRAPH_YAXSIS_LEGEND_1')} </br>${t(
        'WP.VOLUMETRIC.CONCENTRATION.TILE_GRAPH_YAXIS_LEGEND_2',
      )}`,
      to: '100',
    },
    xAxis: {
      legend: targetVolUnit,
    },
    target: t('WP.VOLUMETRIC.CONCENTRATION.TILE_TARGET'),
  };

  if (!targetVolume || !buckets || isOutOfSync || !volumetricDataPoints) {
    return (
      <VolumetricContainer>
        <NoDataAvailableMessage message={t('WP.ERROR.NO_DATA')} size="text" />
      </VolumetricContainer>
    );
  }
  return (
    <VolumetricConcentration
      targetVolume={targetVolume}
      buckets={buckets}
      isProportionalDosing={isProportionalDosing}
      lastConnected={lastConnected}
      targetVolUnit={targetVolUnit}
      actualVolume={actualVolume}
      graphHeight={graphHeight}
      labels={labels}
    />
  );
};

export default VolumetricConcentrationData;
