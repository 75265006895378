import { css, SerializedStyles, Theme } from '@emotion/react';
import dsmMediaQuery from '../../../../utils/media-queries/media-queries';

const contentStyles = ({ colors }: Theme): SerializedStyles => css`
  display: flex;
  flex-direction: column;
  background-color: ${colors.secondaryBackground};
  padding: 1rem;
  margin: 1rem;
  position: relative;
  z-index: 1000;
  opacity: 0.9;

  ${dsmMediaQuery('medium device')} {
    width: 30rem;
    margin-bottom: 2rem;
    padding: 1.5rem;
    margin: 1.5rem;
  }
`;

const fieldsStyles = css`
  display: grid;
  grid-template-areas:
    'header'
    'installation-header'
    'installation-name'
    'installation-note-label'
    'installation-note-field'
    'customer-header'
    'customer-name'
    'customer-address'
    'customer-postal-code'
    'customer-state'
    'customer-city'
    'customer-country'
    'back'
    'next'
    'error';
  gap: 0.1rem;
  ${dsmMediaQuery('medium device')} {
    grid-template-areas:
      'header header'
      'installation-header installation-header'
      'installation-name installation-name'
      'installation-note-label .'
      'installation-note-field installation-note-field'
      'customer-header customer-header'
      'customer-name customer-name'
      'customer-address customer-address'
      'customer-postal-code customer-postal-code'
      'customer-state customer-state'
      'customer-city customer-city'
      'customer-country customer-country'
      'back next'
      'error error';
  }
`;

const titleStyles = css`
  grid-area: header;
  justify-self: center;
  padding-bottom: 1rem;
`;

const headerStyles = (gridArea: string): SerializedStyles => css`
  grid-area: ${gridArea};
`;

const labelStyles = (gridArea: string): SerializedStyles => css`
  grid-area: ${gridArea};
`;

const valueStyles = (gridArea: string): SerializedStyles => css`
  grid-area: ${gridArea};
  justify-self: end;
`;

const submitStyles = css`
  grid-area: next;
  padding-top: 1rem;

  ${dsmMediaQuery('medium device')} {
    justify-self: right;
    padding-top: 2rem;
  }
`;

const backStyles = css`
  grid-area: back;
  padding-top: 2rem;

  ${dsmMediaQuery('medium device')} {
    padding-top: 2.2rem;
  }
`;

const errorStyles = css`
  grid-area: error;
  padding-top: 1rem;
  color: red;
  justify-self: center;
`;

const disclaimerStyles = ({ colors }: Theme): SerializedStyles => css`
  grid-area: disclaimer;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 1rem;
  align-items: center;
  background-color: ${colors.tertiaryBackground};
  color: ${colors.white.primary};
  padding: 1rem;
  margin-top: 1.5rem;
`;

const note = css`
  grid-area: installation-note-field;
  padding-bottom: 2rem;
`;

const noteLabel = css`
  grid-area: installation-note-label;
  padding: 0.5rem 0rem;
`;

const noteText = ({ colors }: Theme): SerializedStyles => css`
  min-height: 4rem;
  border: 0.063rem solid ${colors.border.subtle};
  overflow-wrap: break-word;
  max-width: 30rem;
  padding: 0.5rem;
`;

export {
  note,
  noteLabel,
  noteText,
  contentStyles,
  fieldsStyles,
  titleStyles,
  headerStyles,
  labelStyles,
  valueStyles,
  submitStyles,
  backStyles,
  errorStyles,
  disclaimerStyles,
};
