import { css, Theme } from '@emotion/react';

const containerStyles = (theme: Theme, noBorder = false) => css`
  display: grid;
  grid-template-columns: 1fr auto;
  align-content: center;
  gap: 0.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  border-bottom: ${noBorder ? '0' : `0.063rem solid${theme.colors.border.subtle}`};
`;

const textTruncateStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 0.5rem;
`;

const iconValueUnitGroupStyles = (addGap: boolean) => css`
  display: flex;
  align-items: center;
  gap: ${addGap && '0.25rem'};
`;

const multiLineSupport = css`
  max-width: 8rem;
  overflow-wrap: break-word;
  text-align: right;
`;

const labelMultiLineSupport = css`
  overflow-wrap: break-word;
`;

export { containerStyles, textTruncateStyles, iconValueUnitGroupStyles, multiLineSupport, labelMultiLineSupport };
