import React from 'react';
import { useTranslation } from 'react-i18next';
import { paragraphBoldStyles } from '../../../../styles';
import { Tile, TileHeader, TileBody } from '../../../../components';
import {
  containerStyles,
  twoColumsTileStyles,
  leftColumnStyles,
  removeLastBottomBorder,
  rightColumnStyles,
  topPadding,
  tileStyles,
} from './styles';
import { OperatingInfo, ChemicalContainer, TotalDosedChemical } from './components';
import { MoreDataTabUnsupportedControlModeProps } from './types';

const MoreDataTabUnsupportedControlMode: React.FC<MoreDataTabUnsupportedControlModeProps> = ({
  chemicalContainer,
  totalDosedChemical,
  motorOperatingHours,
  totalOperatingHours,
}) => {
  const { t } = useTranslation();

  return (
    <div css={containerStyles}>
      <div css={leftColumnStyles}>
        <ChemicalContainer chemicalContainer={chemicalContainer} />
      </div>
      <div css={rightColumnStyles}>
        <Tile
          styles={tileStyles}
          Header={<TileHeader text={t('WP.MORE.DATA.DOSING_COUNTER_TILE')} />}
          Body={
            <TileBody css={[topPadding, twoColumsTileStyles]}>
              <div css={removeLastBottomBorder}>
                <p css={paragraphBoldStyles}>{t('WP.MORE.DATA.VOLUME')}</p>
                <TotalDosedChemical totalDosedChemical={totalDosedChemical} />
              </div>
              <OperatingInfo motorOperatingHours={motorOperatingHours} totalOperatingHours={totalOperatingHours} />
            </TileBody>
          }
        />
      </div>
    </div>
  );
};

export default MoreDataTabUnsupportedControlMode;
