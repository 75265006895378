import React from 'react';
import { summaryStyles, summaryValueStyles } from '../styles';

const LastHourSummary = ({ label, value, valueType }: { label: string; value: number; valueType: string }) => (
  <div css={summaryStyles}>
    <div>{label}</div>
    <div css={summaryValueStyles}>{`${value.toFixed(1)} ${valueType}`}</div>
  </div>
);

export default LastHourSummary;
