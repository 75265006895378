import { css, Theme } from '@emotion/react';
import dsmMediaQuery from '../../../utils/media-queries/media-queries';

const containerStyles = css`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: max-content 1fr max-content;
  gap: 1rem;
  grid-template-areas:
    'box box'
    '.  . '
    'button button';
  ${dsmMediaQuery('medium device')} {
    grid-template-areas:
      'box box'
      '.  . '
      '. button';
  }
`;

const boxStyles = (theme: Theme) =>
  css`
    background-color: ${theme.colors.neutral.neutral1000.hex};
    padding: 1rem;
    grid-area: box;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  `;

const footerStyles = css`
  grid-area: button;
  display: flex;
  justify-content: end;
  padding-bottom: 2rem;
`;

const contentStyles = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1.5rem;
`;

export { containerStyles, boxStyles, footerStyles, contentStyles };
