import React from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { Link } from 'react-router-dom';
import { subNavigationItemArrowStyles, subNavigationItemStylesInner, subNavigationItemStylesOuter } from './styles';
import { SubNavigationListItemProps } from './types';
import { Arrow } from '../../../../../../components/Icons';
import { headingSmallBrightStyles } from '../../../../../../styles';

const SubNavigationListItem: React.FC<SubNavigationListItemProps> = (props) => {
  const item = useFragment(
    graphql`
      fragment SubNavigationListItem_item on Node {
        __typename
        id
        ... on Section {
          uuid
          name
        }
        ... on Installation {
          uuid
          name
        }
      }
    `,
    // eslint-disable-next-line react/destructuring-assignment
    props.item,
  );

  return (
    <Link
      key={item?.id}
      to={`/${item?.__typename?.toLowerCase()}s/${item?.uuid}`}
      onClick={(): void => {
        props.toggleShowSubNavigation();
      }}
      css={subNavigationItemStylesOuter}
      data-test-id="sub-nav-item"
    >
      <div css={subNavigationItemStylesInner}>
        <span css={headingSmallBrightStyles}>{item?.name}</span>
        <span css={subNavigationItemArrowStyles}>
          <Arrow color="#fff" size="1rem" />
        </span>
      </div>
    </Link>
  );
};

export default SubNavigationListItem;
