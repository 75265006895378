import React from 'react';
import noDataStyles from './styles';
import { NoDataAvailableMessageProps } from './types';

const NoDataAvailableMessage: React.FC<NoDataAvailableMessageProps> = ({ message, className, size }) => (
  <div className={className} css={noDataStyles(size || 'title')}>
    {message}
  </div>
);

export default NoDataAvailableMessage;
