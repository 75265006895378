import React from 'react';

import { BackLight, StoppedBy } from '../../Overview/OverviewChemPairing/PumpStatus/Fields';
import NoPumpStatusData from '../../Overview/OverviewChemPairing/PumpStatus/NoPumpStatusData';
import PumpStatusContainer from '../../Overview/OverviewChemPairing/PumpStatus/PumpStatusContainer';
import { ControlMode, IsPumping, MaxCapacity, PumpOperatingStatus, RemoteControlEnabled } from './Fields';
import { PumpStatusProps } from './types';

const PumpStatus: React.FC<PumpStatusProps> = ({
  backlight,
  operatingMode,
  remoteControlEnabled,
  stoppedBy,
  isPumping,
  controlMode,
  containerStyle,
  maxCapacity,
}) => {
  if (controlMode === undefined || operatingMode === undefined) {
    return <NoPumpStatusData />;
  }
  return (
    <PumpStatusContainer containerStyle={containerStyle}>
      <ControlMode controlMode={controlMode} />
      <MaxCapacity value={maxCapacity?.value} unit={maxCapacity?.unit} />
      <BackLight backlight={backlight || 'transparent'} />
      <PumpOperatingStatus pumpStatus={operatingMode} />
      <IsPumping isPumping={isPumping} />
      <StoppedBy stoppedBy={stoppedBy} />
      <RemoteControlEnabled remoteControlEnabled={remoteControlEnabled} />
    </PumpStatusContainer>
  );
};

export default PumpStatus;
