import { css, SerializedStyles, Theme } from '@emotion/react';
import dsmMediaQuery from '../../../../../utils/media-queries/media-queries';
import { paragraphBaseStyles } from '../../../../../styles';

const itemStyles = (theme: Theme): SerializedStyles => css`
  display: grid;
  grid-template-areas: 'icon device';
  grid-template-columns: max-content auto;
  grid-column-gap: 1.5rem;
  background: ${theme.colors.secondaryBackground};
  padding: 1rem;
  color: ${theme.colors.primaryText};
  :hover {
    background-color: ${theme.colors.tertiaryBackground};
  }
  margin-bottom: 0.5rem;
  ${dsmMediaQuery('large device')} {
    min-height: 4rem;
    grid-template-areas: 'icon device arrow';
    grid-template-columns: max-content auto max-content;
    grid-column-gap: 1rem;
    align-items: center;
  }
`;

const itemWithEventClmStyles = css`
  grid-template-areas:
    'icon device .'
    'icon event .';
  grid-template-columns: max-content auto max-content;
  ${dsmMediaQuery('large device')} {
    grid-template-areas: 'icon device event arrow';
    grid-template-columns: max-content minmax(1rem, 12rem) auto max-content;
  }
`;

const deviceStyles = css`
  grid-area: device;
`;

const iconStyles = css`
  grid-area: icon;
  align-self: center;
`;

const arrowStyles = css`
  display: none;
  ${dsmMediaQuery('large device')} {
    display: unset;
    grid-area: arrow;
  }
`;
const textStyles = (theme: Theme): SerializedStyles => css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-self: center;
  font-size: 1.1rem;
  font-weight: 400;
  color: ${theme.colors.secondaryText};
  ${dsmMediaQuery('large device')} {
    align-self: center;
  }
`;

const linkStyles = (theme: Theme) => css`
  ${paragraphBaseStyles}
  color:${theme.colors.tertiaryText};
  font-weight: 700;
  text-transform: uppercase;
  align-self: center;
  :hover {
    padding-bottom: 0;
    border-bottom: 0.05rem solid ${theme.colors.tertiaryText};
  }
`;

export { itemStyles, deviceStyles, iconStyles, arrowStyles, linkStyles, textStyles, itemWithEventClmStyles };
