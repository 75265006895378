const unitSystems = [
  {
    val: 'European Unit',
    displayText: 'WP.PROFILE.UNIT_EU',
  },
  {
    val: 'US Unit',
    displayText: 'WP.PROFILE.UNIT_US',
  },
];

export default unitSystems;
