import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { useFormikContext } from 'formik';
import { Divider, TextInputField } from '../../../../../components';
import { textInputStyles } from '../styles';
import { AnalogSettings, FormValues, getFormValuesKey } from '../types';
import { formFieldsStyles } from './styles';
import { ProportionalRule } from '../../types';
import useAnalogRuleViolations from '../../hooks/useAnalogRuleValidations';
import AnalogProportionalForm from './AnalogProportionalForm';
import AnalogUnproportionalForm from './AnalogUnproportionalForm';

interface AnalogWrapperProps {
  isProportional: boolean;
  Propertional: JSX.Element;
  Unproportional: JSX.Element;
}

const AnalogWrapper: React.FC<AnalogWrapperProps> = ({ isProportional, Propertional, Unproportional }) =>
  isProportional ? Propertional : Unproportional;

interface AnalogDosingProps {
  analogSettings: AnalogSettings;
  lowerInputRuleViolation: ProportionalRule;
}

const AnalogDosing: React.FC<AnalogDosingProps> = ({ analogSettings, lowerInputRuleViolation }) => {
  const { higherInput, proportionalValidation } = analogSettings;
  const { status } = useFormikContext<FormValues>();
  const { t } = useTranslation();
  const { hasRuleViolations } = useAnalogRuleViolations(proportionalValidation, [
    lowerInputRuleViolation,
    'LowerInputCurrentFlow',
    'UpperInputCurrent',
  ]);

  const setToDefaultSuccess: boolean = status?.setToDefaultSuccess || false;
  const isProportional: boolean = !hasRuleViolations || setToDefaultSuccess;

  return (
    <div data-testid="analog-dosing-form" css={formFieldsStyles}>
      <AnalogWrapper
        isProportional={isProportional}
        Unproportional={
          <AnalogUnproportionalForm analogSettings={analogSettings} lowerInputRuleViolation={lowerInputRuleViolation} />
        }
        Propertional={<AnalogProportionalForm analogSettings={analogSettings} />}
      />
      <TextInputField
        disabled
        testId="analog-dosing-higher-flow"
        css={[
          textInputStyles,
          css`
            padding-top: 0.375rem;
            input[type='number'] {
            }
          `,
        ]}
        type="number"
        step="any"
        name={getFormValuesKey('analogDosingFlowHigher')}
        // In figma spec the unit is inside the field. But we don't support that yet.
        label={`${t('WP.REMOTE.ANALOG_DDA_HIGHER_DOSING_FLOW')} ${
          higherInput.dosingVolume.unit && `(${higherInput.dosingVolume.unit})`
        }`}
        skipTouchedCheck
      />
      <Divider marginLeft="small" />
    </div>
  );
};

export default AnalogDosing;
