import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoItem } from '../../../../../components';
import { infoItemStyles } from '../styles';
import { TotalVolumeProcessedProps } from './types';

const TotalVolumeProcessed: React.FC<TotalVolumeProcessedProps> = ({ totalVolumeProcessLiquid }) => {
  const { t } = useTranslation();
  return (
    <InfoItem
      css={infoItemStyles}
      label={t('WP.MORE.DATA.TOTAL_PROCESS_LIQUID')}
      // For now we don't show any decimals
      value={totalVolumeProcessLiquid?.quantity?.toFixed(0)}
      unit={totalVolumeProcessLiquid?.unit}
    />
  );
};

export default TotalVolumeProcessed;
