import { css, SerializedStyles, Theme } from '@emotion/react';
import dsmMediaQuery from '../../utils/media-queries/media-queries';
import { Directions } from './types';

const logoStyles = css`
  display: none;

  ${dsmMediaQuery('large device')} {
    display: unset;
    grid-area: logo;
    text-align: center;
    padding: 1rem;
    border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.2);
  }
`;

const stickyNavigationStyles = css`
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 100;
  height: 100%;

  ${dsmMediaQuery('large device')} {
    position: fixed;
    width: 4rem;
  }
`;

const navigationStyles = (theme: Theme): SerializedStyles => css`
  background-color: ${theme.colors.primary};
  padding: 0 0.5rem;
  overflow: auto;

  ${dsmMediaQuery('large device')} {
    display: grid;
    grid-template-rows: max-content auto;
    grid-template-columns: 1fr;
    grid-template-areas: 'logo' 'items';
    padding: 0;
  }
`;

const itemsStyles = css`
  grid-area: items;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${dsmMediaQuery('medium device')} {
    justify-content: space-evenly;
  }

  ${dsmMediaQuery('large device')} {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const itemStyles = (autoMargin: Directions): SerializedStyles => css`
  display: flex;
  align-self: stretch;
  ${dsmMediaQuery('large device')} {
    ${autoMargin === 'top' && 'margin-top: auto;'}
    ${autoMargin === 'bottom' && 'margin-bottom: auto;'}
  }
`;

export { itemStyles, itemsStyles, logoStyles, navigationStyles, stickyNavigationStyles };
