import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, InfoItem } from '../../../../../components';
import { ReadFieldProps } from '../forms/types';

const ReadField = ({ isHidden, children, value, noValueLabel, hideDivider = false }: ReadFieldProps) => {
  const { t } = useTranslation();
  if (isHidden) return null;
  if (value === undefined || value === null)
    return (
      <>
        <InfoItem
          noBorder
          label={noValueLabel}
          inActive
          value={t('WP.REMOTE.READ_MODE_UNKOWN_VALUE')}
          testId={`unkonwn-value-${noValueLabel}`}
        />
        {hideDivider ? null : <Divider />}
      </>
    );
  return (
    <>
      {children}
      {hideDivider ? null : <Divider />}
    </>
  );
};
export default ReadField;
