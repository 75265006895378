import React, { Suspense } from 'react';
import { css } from '@emotion/react';
import { graphql, useFragment } from 'relay-hooks';
import { useTranslation } from 'react-i18next';
import { Tile, TileHeader, TileBody } from '../../../../components/Tiles';
import { tileBodyStyles } from './styles';
import { NoDataAvailableMessage } from '../../../../components';
import { TimeToNextServiceProps } from './types';
import TimeToServiceWarning from './TimeToServiceWarning';
import TimeToServiceProgress from './TimeToServiceProgress';
import { TimeToService_device$key } from './__generated__/TimeToService_device.graphql';
import LoadingSkeleton from './LoadingBodySkeleton';

export interface TimeToNextServiceBodyProps {
  motorHoursRounded: number;
  inWarningMode: boolean;
  maxValue: number;
  thresholdvalue: number;
}

const TimeToNextServiceBody = ({
  motorHoursRounded,
  inWarningMode,
  maxValue,
  thresholdvalue,
}: TimeToNextServiceBodyProps) =>
  inWarningMode ? (
    <TimeToServiceWarning motorHours={motorHoursRounded} />
  ) : (
    <TimeToServiceProgress maxValue={maxValue} thresholdValue={thresholdvalue} motorHours={motorHoursRounded} />
  );

const TimeToNextService = ({ device }: TimeToNextServiceProps) => {
  const { t } = useTranslation();

  const data = useFragment<TimeToService_device$key>(
    graphql`
      fragment TimeToService_device on Device {
        latestSync {
          outOfSync
        }
        staticdata {
          code
          value
        }
        timeToServiceDataPoint: timeSeriesDatapoints(
          datapointSelectors: [{ name: "sdcs:TIME_NEXT_SERVICE", raw: true, geniIdentity: $geniIdentity }]
          startDate: $startDate
          endDate: $endDate
        ) {
          name
          latestDatapoint {
            valueRaw
          }
        }
      }
    `,
    device.node,
  );

  const timeToServiceData = data?.timeToServiceDataPoint?.find((item) => item.name === 'TIME_NEXT_SERVICE');
  const hasData = data && data?.timeToServiceDataPoint && timeToServiceData;
  const motorHours = timeToServiceData?.latestDatapoint?.valueRaw;
  const motorHoursRounded = Math.round(motorHours || 0);
  const inWarningMode = motorHoursRounded < 0;
  const name = timeToServiceData?.name;
  const isOutOfSync = data?.latestSync?.outOfSync;
  const getStaticdata = (staticName: string) => data?.staticdata?.find((sd) => sd?.code === staticName);
  const maxValue = Number(getStaticdata('sdcs_TIME_NEXT_SERVICE_MAX_VALUE')?.value) || 8000;
  const thresholdvalue = Number(getStaticdata('sdcs_TIME_NEXT_SERVICE_THREHSHOLD')?.value) || 500;
  return (
    <Tile
      key={name}
      styles={css`
        grid-area: timeToService;
      `}
      Header={<TileHeader text={t('WP.OVERVIEW.TIME_TO_SERVICE')} />}
      Body={
        <TileBody css={tileBodyStyles}>
          <Suspense fallback={<LoadingSkeleton />}>
            {hasData && !isOutOfSync ? (
              <TimeToNextServiceBody {...{ inWarningMode, maxValue, thresholdvalue, motorHoursRounded }} />
            ) : (
              <NoDataAvailableMessage message={t('WP.ERROR.NO_DATA')} size="text" />
            )}
          </Suspense>
        </TileBody>
      }
    />
  );
};

export default TimeToNextService;
