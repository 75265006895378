import { css, SerializedStyles, Theme } from '@emotion/react';

const eventPageStyles = css`
  padding: 1.5rem 0;
  min-height: 100%; //needed to position SysOk component now that position has changed from absolute to relative
  grid-template-rows: max-content max-content auto;
  grid-template-areas: 'title' 'subTitle' 'alerts';
  grid-row-gap: 1rem;
`;

const navStyles = css`
  grid-column: 1/-1;
  grid-row: 1/1;
`;

const navItemStyles = (theme: Theme): SerializedStyles => css`
  text-decoration: none;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  margin-right: 2.2rem;
  font-weight: 600;
  &:hover,
  &.active {
    padding-bottom: 0.2rem;
    color: ${theme.colors.secondaryText};
    border-bottom: 0.2rem solid #0068b4;
  }
`;

const tabContentContainer = css`
  grid-column: 1/-1;
  grid-row: 3/3;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr max-content;
`;

const titleStyles = css`
  grid-column: 1/-1;
  grid-row: 1/1;
`;

const subTitleStyles = css`
  grid-column: 1/-1;
  grid-row: 2/2;
  line-height: 1.25rem;
`;

const alertItemContainerStyles = css`
  display: contents;
`;

export {
  eventPageStyles,
  navStyles,
  tabContentContainer,
  navItemStyles,
  titleStyles,
  subTitleStyles,
  alertItemContainerStyles,
};
