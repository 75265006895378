import { useLayoutEffect, useRef, useState } from 'react';
import { ContentFlow } from './types';

const useContentFlowMeasurement = (initialValue: ContentFlow = { horizontal: 'right', vertical: 'up' }) => {
  const [contentFlow, setContentFlow] = useState<ContentFlow>(initialValue);
  const ref = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (ref.current) {
      const { x, y } = ref.current.getBoundingClientRect();
      // Find the center of the window, x0 and y0
      const x0 = window.innerWidth / 2;
      const y0 = window.innerHeight / 2;

      // All of these settings makes content flow toward the center of the window, x0 and y0
      // In order to understand the decisions, be aware that x begins from left of the window and y begins from top of the window
      // Also note that if the window is 1000 X 1000 then the center is 'x0,y0' => '500,500'
      // If we have a point, 'x,y' => '750,250' this means 'x > x0' => '750 > 500' and 'y < y0' => '250 < 500.'
      // To make that flow towards the center we now that x is larger than center, and x is the horizontal axis, so we make the horizontal axis go 'left'
      // and the y is less than center (y0). We there make the vertical axis go down (towards the center)

      if (x >= x0 && y >= y0) setContentFlow({ horizontal: 'left', vertical: 'up' });
      else if (x >= x0 && y <= y0) setContentFlow({ horizontal: 'left', vertical: 'down' });
      else if (x <= x0 && y >= y0) setContentFlow({ horizontal: 'right', vertical: 'up' });
      else if (x <= x0 && y <= y0) setContentFlow({ horizontal: 'right', vertical: 'down' });
    }
  }, []);

  return { ref, contentFlow };
};

export default useContentFlowMeasurement;
