import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoItem } from '../../../../../components';
import { ControlModeIcon } from '../../../../../components/Icons';
import { gridPlacement } from '../styles';
import { ControlModeProps } from '../types';

const ControlMode: React.FC<ControlModeProps> = ({ controlMode }) => {
  const { t } = useTranslation();

  switch (controlMode) {
    case 'PULSE':
      return (
        <InfoItem
          css={gridPlacement('controlMode')}
          Icon={<ControlModeIcon mode={controlMode} />}
          label={t('WP.OVERVIEW.CONTROL_MODE')}
          value={t(`WP.OVERVIEW.${controlMode}`)}
        />
      );
    case 'MANUAL':
      return (
        <InfoItem
          css={gridPlacement('controlMode')}
          Icon={<ControlModeIcon mode={controlMode} />}
          label={t('WP.OVERVIEW.CONTROL_MODE')}
          value={t(`WP.OVERVIEW.${controlMode}`)}
        />
      );
    default:
      return (
        <InfoItem
          css={gridPlacement('controlMode')}
          label={t('WP.OVERVIEW.CONTROL_MODE')}
          value={`${t(`WP.OVERVIEW.${controlMode}`)} ${t(`WP.COMMON.NOT_SUPPORTED`).toLowerCase()}`}
        />
      );
  }
};

export default ControlMode;
