import { css, SerializedStyles, Theme } from '@emotion/react';
import dsmMediaQuery from '../../../../utils/media-queries/media-queries';

const offcanvasWrapperStyles = (isOpen: boolean): SerializedStyles => css`
  height: calc(100vh - 4rem);

  ${dsmMediaQuery('large device')} {
    height: 100vh;
    left: 4rem;
    transform: ${isOpen ? 'translateX(0)' : 'translateX(calc(-100% - 4rem))'};
  }
`;

const subNavigationBackgroundStyles = (theme: Theme) => css`
  backdrop-filter: blur(0.3125rem);
  background-color: ${theme.colors.secondaryBackground};
  height: 100%;
  z-index: 200;
`;

const subNavigationButtonStyles = css`
  all: unset;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: scale(1.2, 1.2);
  }
  &:active {
    transform: scale(0.8, 0.8);
    transition: transform 0.1s ease-in-out;
  }
`;

const subNavigationHeaderStyles = (theme: Theme): SerializedStyles => css`
  background-color: ${theme.colors.secondaryBackground};
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 1rem 1rem 2rem 1rem;
`;

export { offcanvasWrapperStyles, subNavigationBackgroundStyles, subNavigationButtonStyles, subNavigationHeaderStyles };
