/**
 * @generated SignedSource<<471e6845c85e29481880fb1ead4e5a6d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppQuery$variables = {
  accountId: string;
};
export type AppQuery$data = {
  readonly events: {
    readonly " $fragmentSpreads": FragmentRefs<"AccountEventsError_events">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"AppNavigation_data">;
};
export type AppQuery = {
  variables: AppQuery$variables;
  response: AppQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AppQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "accountId",
            "variableName": "accountId"
          }
        ],
        "kind": "FragmentSpread",
        "name": "AppNavigation_data"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "EventConnection",
        "kind": "LinkedField",
        "name": "events",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AccountEventsError_events"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AppQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountConnection",
        "kind": "LinkedField",
        "name": "accounts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "accountId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "typeCodes",
                    "value": [
                      "INSTALLATION",
                      "SECTION"
                    ]
                  }
                ],
                "concreteType": "LocationConnection",
                "kind": "LinkedField",
                "name": "locations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LocationEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "uuid",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "locations(typeCodes:[\"INSTALLATION\",\"SECTION\"])"
              }
            ],
            "type": "Account",
            "abstractKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "EventConnection",
        "kind": "LinkedField",
        "name": "events",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "errorCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "270c6b7e0969a450cb2e7766201d7d4b",
    "id": null,
    "metadata": {},
    "name": "AppQuery",
    "operationKind": "query",
    "text": "query AppQuery(\n  $accountId: ID!\n) {\n  ...AppNavigation_data_shlXP\n  events {\n    ...AccountEventsError_events\n  }\n}\n\nfragment AccountEventsError_events on EventConnection {\n  errorCount\n}\n\nfragment AppNavigation_data_shlXP on Query {\n  accounts {\n    ...MainNavigation_accounts\n  }\n  node(id: $accountId) {\n    __typename\n    ...MainNavigation_account\n    id\n  }\n}\n\nfragment MainNavigation_account on Account {\n  locations(typeCodes: [INSTALLATION, SECTION]) {\n    edges {\n      node {\n        __typename\n        uuid\n        id\n      }\n    }\n  }\n}\n\nfragment MainNavigation_accounts on AccountConnection {\n  totalCount\n}\n"
  }
};
})();

(node as any).hash = "b0596bdfb53afb6abebe524f0b614e9d";

export default node;
