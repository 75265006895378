/**
 * @generated SignedSource<<da45c6de927e7eb3ebf4d12b117e905d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type WriteGeniValuesInput = {
  deviceTwinId?: string | null;
  geniValues?: ReadonlyArray<GeniValue | null> | null;
};
export type GeniValue = {
  name?: string | null;
  value?: string | null;
  unit?: string | null;
};
export type RemoteControlWriteGeniValuesMutation$variables = {
  input?: WriteGeniValuesInput | null;
};
export type RemoteControlWriteGeniValuesMutation$data = {
  readonly writeGeniValues: {
    readonly id: string;
    readonly runningInstanceId: string | null;
  } | null;
};
export type RemoteControlWriteGeniValuesMutation = {
  variables: RemoteControlWriteGeniValuesMutation$variables;
  response: RemoteControlWriteGeniValuesMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "WriteGeniValuesPayload",
    "kind": "LinkedField",
    "name": "writeGeniValues",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "runningInstanceId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoteControlWriteGeniValuesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoteControlWriteGeniValuesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4e9e18942123905bcf97515d52966013",
    "id": null,
    "metadata": {},
    "name": "RemoteControlWriteGeniValuesMutation",
    "operationKind": "mutation",
    "text": "mutation RemoteControlWriteGeniValuesMutation(\n  $input: WriteGeniValuesInput\n) {\n  writeGeniValues(input: $input) {\n    id\n    runningInstanceId\n  }\n}\n"
  }
};
})();

(node as any).hash = "37a0751defd01f0204dcdc069de206fd";

export default node;
