import { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import { useTheme } from '@emotion/react';
import { DefaultOptionsProps } from './types';
import initRenderHelperMethods from './helperMethods';

const marginTop = 25;
const marginBottom = 55;

interface ChartExtended extends Highcharts.Chart {
  yAxisGroup?: Highcharts.SVGElement;
  minTrhesholdGroup?: Highcharts.SVGElement;
  maxTrhesholdGroup?: Highcharts.SVGElement;
  extremeGroup?: Highcharts.SVGElement;
  plotSizeY?: number;
}

const useDefaultOptions = ({
  labels,
  thresholds,
  extremes = { max: { label: '', value: '' }, min: { label: '', value: '' } },
  target: targetVoume,
  numberOfbins,
}: DefaultOptionsProps): Highcharts.Options => {
  const { xAxis, target } = labels;
  const {
    colors: { secondaryBackground, primaryText, tertiaryBackground, secondaryText, graphs },
  } = useTheme();
  const graphBlue = graphs.blue.primary
  // If we have thresholds defined, we makke additional space for rendering the thresholds (dashed line, triangle etc)
  const marginLeft = thresholds?.thresholdMin ? 250 : 200;
  const marginRight = thresholds?.thresholdMax ? 150 : 85;

  const { renderMaxWarn, renderMinWarn, renderYAxis, renderExtremesMax, renderExtremesMin } = initRenderHelperMethods({
    marginTop,
    marginLeft,
    color: primaryText,
    yAxisBottomLabelText: labels.yAxis.legend,
  });

  const defaultOptions: Highcharts.Options = {
    legend: { enabled: false },
    chart: {
      type: 'column',
      backgroundColor: secondaryBackground,
      marginLeft,
      marginRight,
      marginTop,
      marginBottom,
      events: {
        // We want to render custom svg elements that is not provided by Highcharts
        // We will render custom y-axis and thresholds
        render: function renderChart(this: ChartExtended) {
          const ren = this.renderer;
          // These are values copied from examples in Highcharts docs
          // We use them to determine the height and with of the graph area (the bars)
          const plotHeight = this.plotTop + (this?.plotSizeY || 0);
          const plotWidth = this.plotWidth + this.plotLeft;

          // To avoid duplicate elements on window resize,
          // we remove the group elements from the graph before drawing them again

          if (this.yAxisGroup) {
            this.yAxisGroup.destroy();
            this.yAxisGroup = undefined;
          }
          if (this.minTrhesholdGroup) {
            this.minTrhesholdGroup.destroy();
            this.minTrhesholdGroup = undefined;
          }
          if (this.maxTrhesholdGroup) {
            this.maxTrhesholdGroup.destroy();
            this.maxTrhesholdGroup = undefined;
          }
          if (this.extremeGroup) {
            this.extremeGroup.destroy();
            this.extremeGroup = undefined;
          }

          this.yAxisGroup = ren.g('renderY').add();
          this.minTrhesholdGroup = ren.g('min').add();
          this.maxTrhesholdGroup = ren.g('max').add();
          this.extremeGroup = ren.g('extremes').add();

          // Now that we have dealt with duplicate elements we can start drawing

          renderYAxis(ren, plotHeight, this.yAxisGroup);
          renderExtremesMin(ren, plotHeight, this.extremeGroup, extremes.min.label, extremes.min.value);
          renderExtremesMax(ren, plotHeight, plotWidth, this.extremeGroup, extremes.max.label, extremes.max.value);

          if (thresholds) {
            if (thresholds.thresholdMin) {
              renderMinWarn(
                ren,
                plotHeight,
                this.minTrhesholdGroup,
                thresholds.thresholdMin?.categoryText || '',
                thresholds.thresholdMin?.minText || '',
              );
            }
            if (thresholds.thresholdMax) {
              renderMaxWarn(
                ren,
                plotWidth,
                plotHeight,
                this.maxTrhesholdGroup,
                thresholds.thresholdMax?.categoryText || '',
                thresholds.thresholdMax?.maxText || '',
              );
            }
          }
        },
      },
    },
    // Remove the highcharts credit section
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    xAxis: {
      lineColor: 'transparent',
      // We only use the axis to show our categories / bucket.
      // We use the category option to tell highcharts it should not treat the buckets as a sequence of numbers
      type: 'category',
      title: {
        // E.g 'ml/m3 /h'
        text: xAxis.legend,
        style: { color: primaryText },
      },
      labels: {
        // We don't want the label to rotate, we only show one label for the middle bucket
        // It is ok if it overflows the space where other labels could have been rendered
        rotation: 0,
        style: {
          color: primaryText,
          // We usually don't use pixels, but em. But every other default label size is 12 px
          // If we don't set it here, we get 11px
          fontSize: '12px',
          textOverflow: 'none',
          textAlign: 'center',
        },
        formatter(this) {
          // We only show label for the middle bucket
          return Number(this.value) === targetVoume ? targetVoume.toString() : '';
        },
      },
      gridLineWidth: 0,
      plotLines: [
        {
          // This is the Target vertical line. We can use Highcharts to draw this because it is within the graph
          // It has the advantage we can use 'value' property to set exactly where the center bar is. Otherwise we would need to do a lot of tricks for find the exact center of the bars because we have margins of different sizes
          color: primaryText,
          width: 2,
          // Plot lines is zero indexed, because the axis is a cateory axais
          // To place the line in the center of the graph,
          // we just have to find the center index of our buckets
          value: Math.floor(numberOfbins / 2),
          dashStyle: 'Dash',
          label: {
            style: { color: primaryText },
            text: target,
            align: 'center',
            rotation: 0,
            y: -10,
            x: 0,
          },
        },
      ],
      crosshair: true,
    },
    // We draw a custom y-axis in the render function in charts property
    yAxis: {
      // If all the values are very small, highcharts will autoscale to the bar with the highest value
      // but we have a custom drawn y-axis that says 100%
      // This max value will make sure the bars height are drawn relative to 100, and not to the bar with the highest value
      max: 100,
      visible: false,
    },
    tooltip: {
      headerFormat: `<div style="text-transform: uppercase; margin-bottom: 0.5rem; color: ${secondaryText}">{point.y}${labels.postFixY}</div>`,
      pointFormat: `{point.name} ${labels.xAxis.legend}`,
      shape: 'square',
      footerFormat: '</p>',
      shared: true,
      useHTML: true,
      backgroundColor: tertiaryBackground,
      borderWidth: 0,
      borderRadius: 0,
      shadow: false,
      style: {
        color: primaryText,
        fontSize: '0.8rem',
      },
      padding: 16,
    },
    plotOptions: {
      column: {
        // Theese settings are used to control the margin and padding between each bar
        groupPadding: 0,
        pointPadding: 0.2,
        borderWidth: 0,
        // Each column with have a default height of 3 (pixels i guess).
        // This is nice when we have zero-values (eg. 0% in 46 bucket), then we still provide some visual
        minPointLength: 2,
        pointWidth: 10,
        maxPointWidth: 10,
      },
      series: {
        color: graphBlue,
        dataLabels: {
          enabled: true,
          style: { color: primaryText, textOutline: 'none', fontWeight: '400' },
          // Every y-value below 5 gets a sticky label
          formatter(this: Highcharts.TooltipFormatterContextObject) {
            return '';
          },
        },
      },
    },
  };

  const [options, setOptions] = useState<Highcharts.Options>(defaultOptions);

  useEffect(() => {
    setOptions(defaultOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondaryBackground, primaryText, thresholds, labels]);

  return options;
};

export default useDefaultOptions;
