import React, { useEffect } from 'react';

const INTERVAL = 10000;

const Interval: React.FC<{ onInterval: () => Promise<void> | void; intervalMS?: number }> = ({
  onInterval,
  intervalMS = INTERVAL,
}) => {
  useEffect(() => {
    const intervalHandle = setInterval(() => {
      onInterval();
    }, intervalMS);
    return () => {
      clearInterval(intervalHandle);
    };
  }, [onInterval, intervalMS]);
  return null;
};

export default Interval;
