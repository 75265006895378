import React, { useCallback, useMemo } from 'react';
import { Range } from 'react-range';
import { useTheme } from '@emotion/react';
import { IThumbProps, ITrackProps } from 'react-range/lib/types';
import { containerStyles, trackContainer, track, knob, activeTrack } from './styles';
import { DualRangeSliderProps } from './types';

const DualRangeSlider: React.FC<DualRangeSliderProps> = ({
  step = 1,
  overlapThreshold,
  min,
  max,
  leftThumb,
  rightThumb,
  setThumbValues,
  disabled,
  testId = 'slider',
}) => {
  const theme = useTheme();
  const values = useMemo(() => [leftThumb, rightThumb], [leftThumb, rightThumb]);

  // Validation of inputs
  useMemo(() => {
    values.forEach((val) => {
      if (val < min) throw Error(`Value '${val}' may not be less than the defined min value '${min}'`);
      if (val > max) throw Error(`Value '${val}' may not be more than the defined max value '${max}'`);
    });
    if (leftThumb > rightThumb)
      throw new Error(`Left thumb, '${leftThumb}', may not have a greater value than right thumb, '${rightThumb}'`);
    if (overlapThreshold && rightThumb - leftThumb < overlapThreshold)
      throw new Error(
        `The gap between left thumb, '${leftThumb}', and right thumb, '${rightThumb}', may not be less than the overlap threshold, '${overlapThreshold}'`,
      );
  }, [leftThumb, max, min, overlapThreshold, rightThumb, values]);

  const handleOverlapThreshold = useCallback(
    (nextValues: number[]) => {
      const [first, second] = nextValues;
      // We make sure that there always is the space of the overlapThreshold between the two thumbs
      if (overlapThreshold !== undefined && second - first < overlapThreshold) {
        return;
      }
      setThumbValues({ leftThumb: first, rightThumb: second });
    },
    [overlapThreshold, setThumbValues],
  );

  const renderTrack = useCallback(
    ({ props, children }: { props: ITrackProps; children: React.ReactNode }) => {
      // We will find the active track bar. That is the part of the bar between the two thumbs
      // It will later be used to paint the active track with more height and another color than the rest of the bar
      // This function, ((value - min) / (max - min)) * 100), is based on the source code of 'react-range'
      // (unfortunately their api was was not flexible enough, we cound only determine the color but not the height of the middle track / active track)
      const progress = values.map((value) => ((value - min) / (max - min)) * 100);
      const leftThumbPosition = progress[0];
      const activeTrackWidth = progress[1] - progress[0];

      // This is important so that we can show the user that the thumbs cannot come any closer to each other
      const hasReachedThreshold = rightThumb - leftThumb <= (overlapThreshold || 0);

      return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          onMouseDown={props.onMouseDown}
          onTouchStart={props.onTouchStart}
          css={trackContainer}
          data-testid={`${testId}-track-container`}
        >
          <div
            ref={props.ref}
            css={{ ...track(theme, values, min, max, disabled), ...props.style }}
            data-testid={`${testId}-track`}
          >
            <div
              css={activeTrack(theme, leftThumbPosition, activeTrackWidth, hasReachedThreshold, disabled)}
              data-testid={`${testId}-active-track`}
            />
            {children}
          </div>
        </div>
      );
    },
    [disabled, leftThumb, max, min, overlapThreshold, rightThumb, testId, theme, values],
  );

  const renderThumb = useCallback(
    ({ props }: { props: IThumbProps }) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <div {...props} css={{ ...knob(theme, disabled), ...props.style }} data-testid={`${testId}-thumb-${props.key}`} />
    ),
    [theme, disabled, testId],
  );

  return (
    <div css={containerStyles} data-testid={`${testId}-container`}>
      <Range
        disabled={disabled}
        values={values}
        allowOverlap={false}
        step={step}
        min={min}
        max={max}
        rtl={false}
        onChange={handleOverlapThreshold}
        renderTrack={renderTrack}
        renderThumb={renderThumb}
      />
    </div>
  );
};

export default DualRangeSlider;
