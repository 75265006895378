import React, {
  Suspense,
  // TODO: Enable concurrent mode once React 17 supports it and use SuspenseList and useTransition
  // unstable_SuspenseList as SuspenseList,
  // unstable_useTransition as useTransition,
  useCallback,
} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { graphql, usePaginationFragment } from 'react-relay/hooks';
import { SubNavigationList_data$key } from './__generated__/SubNavigationList_data.graphql';
import { SubNavigationListPaginationQuery } from './__generated__/SubNavigationListPaginationQuery.graphql';
import { subNavigationListStyles } from './styles';
import SubNavigationListItem from './SubNavigationListItem/SubNavigationListItem';
import { SubNavigationListProps } from './types';

const infiniteScrollerLoader = (
  <div className="loader" key={0}>
    Loading ...
  </div>
);

const SubNavigationList: React.FC<SubNavigationListProps> = (props: SubNavigationListProps) => {
  // const [startTransition] = useTransition();

  const { data, loadNext, isLoadingNext } = usePaginationFragment<
    SubNavigationListPaginationQuery,
    SubNavigationList_data$key
  >(
    graphql`
      fragment SubNavigationList_data on Account
      @argumentDefinitions(count: { type: Int, defaultValue: 100 }, cursor: { type: String })
      @refetchable(queryName: "SubNavigationListPaginationQuery") {
        locations(first: $count, after: $cursor, typeCodes: [INSTALLATION, SECTION])
          @connection(key: "SubNavigation_locations") {
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
          edges {
            node {
              id
              ...SubNavigationListItem_item
            }
          }
        }
      }
    `,
    // eslint-disable-next-line react/destructuring-assignment
    props.data,
  );

  const loadMore = useCallback((): void => {
    // Don't fetch again if we're already loading the next page
    if (isLoadingNext) return;

    // startTransition(() => {
    //   loadNext(3);
    // });

    loadNext(100);
  }, [isLoadingNext, loadNext /* , startTransition */]);

  const { locations } = { ...data };
  const { pageInfo, edges } = { ...locations };

  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={loadMore}
      hasMore={pageInfo?.hasNextPage}
      loader={infiniteScrollerLoader}
      useWindow={false}
    >
      <div css={subNavigationListStyles}>
        {/* <SuspenseList revealOrder="forwards"> */}
        {(edges ?? []).map((edge) => {
          const node = edge?.node;

          return (
            <Suspense key={node?.id} fallback="Loading...">
              {/* TODO: Investigate if there's a bug in the compiler */}
              <SubNavigationListItem item={node as any} toggleShowSubNavigation={props.toggleShowSubNavigation} />
            </Suspense>
          );
        })}
        {/* </SuspenseList> */}
      </div>
    </InfiniteScroll>
  );
};

export default SubNavigationList;
