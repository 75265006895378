/**
 * @generated SignedSource<<dcada58d1296bacd815d53f749644405>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateContactPersonInput = {
  name?: string | null;
  phone?: string | null;
  deviceId: string;
};
export type EditInformationMutation$variables = {
  input?: UpdateContactPersonInput | null;
};
export type EditInformationMutation$data = {
  readonly updateContactPerson: {
    readonly id: string;
    readonly contactPerson: {
      readonly contactPersonName: string | null;
      readonly contactPersonPhone: string | null;
    };
  } | null;
};
export type EditInformationMutation = {
  variables: EditInformationMutation$variables;
  response: EditInformationMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateContactPersonPayload",
    "kind": "LinkedField",
    "name": "updateContactPerson",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ContactPerson",
        "kind": "LinkedField",
        "name": "contactPerson",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contactPersonName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contactPersonPhone",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditInformationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditInformationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "15932c4a5ef1028028b810e585253a99",
    "id": null,
    "metadata": {},
    "name": "EditInformationMutation",
    "operationKind": "mutation",
    "text": "mutation EditInformationMutation(\n  $input: UpdateContactPersonInput\n) {\n  updateContactPerson(input: $input) {\n    id\n    contactPerson {\n      contactPersonName\n      contactPersonPhone\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1b0054739f4243a2065dab52e7f6d854";

export default node;
