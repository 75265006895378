import React, { FunctionComponent, useState, useRef } from 'react';
import { useTheme } from '@emotion/react';
import { arrow, contentWrapper, wrapper } from './styles';
import { ContentFlow, TooltipProps } from './types';
import useOutSideGestureCapture from './useOutSideGestureCapture';
import { paragraphBrightStyles } from '../../styles';

const MouseEnterWrapper = ({
  children,
  setOpen,
}: {
  children: JSX.Element | JSX.Element[];
  setOpen: (value: boolean) => void;
}): JSX.Element => (
  <div css={wrapper} id="tool-tip" onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
    {children}
  </div>
);

const ClickWrapper = ({
  children,
  setOpen,
}: {
  children: JSX.Element | JSX.Element[];
  setOpen: (value: boolean) => void;
}): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  useOutSideGestureCapture(ref, () => setOpen(false));
  return (
    <div
      ref={ref}
      role="button"
      tabIndex={0}
      onKeyDown={() => setOpen(true)}
      css={wrapper}
      id="tool-tip"
      onClick={() => setOpen(true)}
    >
      {children}
    </div>
  );
};

const BaseTooltip: FunctionComponent<
  React.PropsWithChildren<TooltipProps & { contentFlow: ContentFlow; toolTipRef?: React.RefObject<HTMLDivElement> }>
> = ({
  children,
  content,
  size = 'small',
  placement,
  centeredText = false,
  initAsOpen = false,
  trigger = 'mouse-enter',
  onClose,
  onOpen,
  visible = true,
  toolTipRef,
  contentFlow,
}) => {
  const [open, setOpen] = useState(initAsOpen);
  // const ref = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const WrapperComponent = trigger === 'mouse-enter' ? MouseEnterWrapper : ClickWrapper;
  const handleOpen = React.useCallback(
    (value: boolean) => {
      if (!value && onClose) onClose();
      if (value && onOpen) onOpen();

      setOpen(value);
    },
    [onClose, onOpen],
  );

  return (
    <WrapperComponent setOpen={handleOpen}>
      <div ref={toolTipRef}>
        {children}
        {open && visible && (
          <div
            css={[paragraphBrightStyles, contentWrapper(size, placement, contentFlow, centeredText, theme)]}
            id="tool-tip-content"
          >
            <span css={arrow(placement, contentFlow, theme)} id="arraow" />
            {content}
          </div>
        )}
      </div>
    </WrapperComponent>
  );
};

export default BaseTooltip;
