import React from 'react';
import { formatDistanceToNowStrict, fromUnixTime } from 'date-fns';

interface TimeAgoProps {
  timestamp: number;
  className?: string;
}

const TimeAgo: React.FC<TimeAgoProps> = ({ timestamp, className }) => (
  <span className={className}>
    {formatDistanceToNowStrict(fromUnixTime(timestamp), { addSuffix: true, unit: 'day' })}
  </span>
);

export default TimeAgo;
