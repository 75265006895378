import * as Locales from 'date-fns/locale';
import { Locale } from 'date-fns';
import i18n from 'i18next';
import { HourMinuteFormat, ShorDateFomat, ShortDateTimeFormat, SupportedLocales, TimeFormat } from './types';

const nonDefaultCountries = ['CA', 'US'];

const countryLanguageMap = {
  AT: 'deAT',
  DE: 'deDE',
  CH: 'frCH',
  BE: 'nlBE',
  NL: 'nlNL',
  GB: 'enGB',
  PL: 'plPL',
  IT: 'itIT',
  ES: 'esES',
  CA: 'enCA',
  US: 'enUS',
};

export const supportedLanguages = {
  de: 'German',
  fr: 'French',
  nl: 'Dutch',
  en: 'English',
  pl: 'Polish',
  it: 'Italian',
  es: 'Spanish',
};

export const languageIsSupported = (languageCode: string) =>
  !!supportedLanguages[languageCode as keyof typeof supportedLanguages];

export const defaultShortDateFormat: ShorDateFomat = {
  assetFormat: 'dd.MM.yyyy',
  dateFnsFormat: 'dd.MM.yyyy',
  highchartsFormat: '%d.%m.%Y',
};

export const shortDateFormatMap = new Map<string, ShorDateFomat>([
  [
    'dd.MM.yyyy',
    {
      assetFormat: 'dd.MM.yyyy',
      dateFnsFormat: 'dd.MM.yyyy',
      highchartsFormat: '%d.%m.%Y',
    },
  ],
  [
    'MM.dd.yyyy',
    {
      assetFormat: 'MM.dd.yyyy',
      dateFnsFormat: 'MM.dd.yyyy',
      highchartsFormat: '%m.%d.%Y',
    },
  ],
  [
    'yyyy.MM.dd',
    {
      assetFormat: 'yyyy.MM.dd',
      dateFnsFormat: 'yyyy.MM.dd',
      highchartsFormat: '%Y.%m.%d',
    },
  ],
]);

export const hourMinuteFormat = (timeformat: TimeFormat = '24-hour'): HourMinuteFormat =>
  timeformat === '24-hour'
    ? { highChartsTimeFormat: '%H:%M', dateFnsTimeFormat: 'HH:mm' }
    : { highChartsTimeFormat: '%I:%M %p', dateFnsTimeFormat: 'hh:mm a' };

export const shortDateTimeFormat = (
  dateformat?: ShorDateFomat,
  timeFormat: TimeFormat = '24-hour',
): ShortDateTimeFormat => {
  const resolvedDateFormat = dateformat || defaultShortDateFormat;
  const resolvedhourMinuteFormat = hourMinuteFormat(timeFormat);
  return {
    highchartsDateTimeFormat: `${resolvedDateFormat.highchartsFormat}, ${resolvedhourMinuteFormat.highChartsTimeFormat}`,
    dateFnsDateTimeFormat: `${resolvedDateFormat.dateFnsFormat}, ${resolvedhourMinuteFormat.dateFnsTimeFormat}`,
  };
};

// enGB is the default format for showing datetime values
export const getLocaleByCountry = (countryCode: string, locales: SupportedLocales) => {
  try {
    if (nonDefaultCountries.includes(countryCode)) {
      return locales.enUS;
    }
    return locales.enGB;
  } catch (error) {
    console.log('[getLocaleByCountry] Could not get locale', error, countryCode);
  }
};

export const getDateFnsLocale = (languageIso: string, countryCode: string): Locale => {
  let locale: Locale | null = null;
  try {
    if (languageIso && countryCode) {
      const keyLanguage = languageIso.toLocaleLowerCase();
      const localeKey = `${keyLanguage}${countryCode}`;
      const supportedLocaledKey = countryLanguageMap[countryCode as keyof typeof countryLanguageMap];
      if (localeKey === supportedLocaledKey) {
        locale = Locales[localeKey as keyof typeof Locales];
      }
    }
    // We have not found a locale yet, we try doing so only using the language
    if (languageIso && !locale) {
      const keyLanguage = languageIso.toLocaleLowerCase();
      locale = Locales[keyLanguage as keyof typeof Locales];
    }
  } catch (error) {
    console.log('[getDateFnsLocale] Could not resolve language', error, languageIso);
  }
  const result = locale || getLocaleByCountry(countryCode, Locales) || Locales.enGB;
  return result;
};

export const update18NextLanguage = async (language: string) => {
  try {
    const selectedLang = i18n.languages[0];
    if (selectedLang !== language) await i18n.changeLanguage(language);
  } catch (error) {
    console.log(`[LocaleProvider] Could not change language to '${language}'`, error);
  }
};
