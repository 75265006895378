import { format } from 'date-fns';

// eslint-disable-next-line import/prefer-default-export
export const formatDate = (timestamp?: string | null, dateFormat?: string, locale?: Locale) => {
  try {
    if (timestamp) {
      const date = new Date(timestamp);
      if (date) return format(date, dateFormat || 'Pp', { locale });
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('[Pump Information Utils] Format Date Error', timestamp, locale?.code, error);
  }
};
