import { Formik, Form } from 'formik';
import React, { useEffect, useState } from 'react';
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { toGlobalId } from 'graphql-relay';
import { useTranslation } from 'react-i18next';
import { usePageViewed } from '../../../../adobe-launch/hooks';
import { Spinner, Button, DropDownSelect } from '../../../../components';
import { SelectOrCreateCustomerQuery } from './__generated__/SelectOrCreateCustomerQuery.graphql';
import { SelectOrCreateCustomerMutation } from './__generated__/SelectOrCreateCustomerMutation.graphql';
import { formStyles, contentStyles, dropDownStyle, nextButton, backButton } from './styles';
import countries, { getCountryCode } from '../../countries';
import { heading05Styles } from '../../../../styles';
import useAuthenticatedUser from '../../../../hooks/useAuthenticatedUser/use-authenticated-user';

const CREATE_CUSTOMER = 'create-customer';

const ExistingSectionSchema = (validationMessage: string) =>
  Yup.object().shape({
    section: Yup.string().required(validationMessage),
  });

// Section is the domain in asset service. Location is history from GIM and Customer is SDCS
// But Section, Location and Customer is the same thing with the caveat that Location can aslo be installation

const SelectOrCreateCustomer: React.FC = () => {
  usePageViewed('Onboarding:SelectOrCreateCustomer');
  const { state } = useLocation();
  const claimId = (state as { claimId: string })?.claimId;
  const { t } = useTranslation();
  const user = useAuthenticatedUser();
  const navigate = useNavigate();

  // We do not have location (section in asset service) in local storage.
  // If we want to have in local storage, we have to add the sections as input param
  // in previous step and then in the mutation in previous step, pass the section input back as response
  const data = useLazyLoadQuery<SelectOrCreateCustomerQuery>(
    graphql`
      query SelectOrCreateCustomerQuery {
        clientState {
          onboarding {
            onboardingId
            account {
              company
            }
          }
        }
        onboardingAccounts {
          edges {
            node {
              name
              locations(typeCodes: [SECTION]) {
                nodes {
                  id
                  name
                  address
                  postalCode
                  city
                  country
                }
              }
            }
          }
        }
      }
    `,
    { id: toGlobalId('UnclaimedDevice', claimId) },
  );

  const account = (data?.onboardingAccounts?.edges || []).find(
    (acc) => acc?.node?.name === data?.clientState?.onboarding?.account?.company,
  );
  const sections = account?.node?.locations?.nodes;

  const [commit, isInFlight] = useMutation<SelectOrCreateCustomerMutation>(graphql`
    mutation SelectOrCreateCustomerMutation($id: ID!, $input: OnboardingCreateSectionInput!) {
      onboardingCreateSection(id: $id, input: $input) {
        id
        section {
          name
          address
          postalCode
          city
          country
        }
      }
    }
  `);

  const [isUserInfoLoaded, setIsUserInfoLoaded] = useState(false);
  const [companyFromToken, setCompanyFromToken] = useState('');
  const [countryFromToken, setCountryFromToken] = useState('');
  const onboardingRecordID = 'client:clientState:onboarding';

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (!user) return setIsUserInfoLoaded(true);

      setCompanyFromToken(user.company);
      setCountryFromToken(user.country);
      setIsUserInfoLoaded(true);
    };
    fetchUserInfo();
  }, [user]);

  if (isInFlight) return <Spinner />;

  return isUserInfoLoaded ? (
    <div css={contentStyles}>
      <h1 css={heading05Styles}>{t('WP.ONBOARDING.CHOOSE_CUSTOMER_TITLE')}</h1>
      <Formik
        initialValues={{
          section: '',
          company: !sections ? companyFromToken : '',
          address: '',
          postalCode: '',
          city: '',
          country: !sections ? countryFromToken : '',
        }}
        validationSchema={ExistingSectionSchema(t('WP.ONBOARDING.CHOOSE_CUSTOMER_VALIDATION_MESSAGE'))}
        onSubmit={(values) => {
          const { section: existingSection } = values;
          const onboardingId = data.clientState?.onboarding?.onboardingId;

          if (existingSection === CREATE_CUSTOMER) {
            if (onboardingId) {
              commit({
                variables: {
                  id: onboardingRecordID,
                  input: {
                    onboardingId,
                    address: '',
                    name: '',
                    postalCode: '',
                    city: '',
                    country: '',
                  },
                },
                onCompleted: () => navigate('/onboarding/customer-summary', { state: { claimId } }),
              });
            }
            return;
          }

          const selectedSection = sections?.find((item) => item?.name === existingSection);
          if (!selectedSection) {
            // eslint-disable-next-line no-console
            console.error('Could not find matching section');
            return;
          }

          if (onboardingId) {
            commit({
              variables: {
                id: onboardingRecordID,
                input: {
                  onboardingId,
                  address: selectedSection?.address,
                  name: selectedSection?.name || '',
                  postalCode: selectedSection?.postalCode,
                  city: selectedSection?.city,
                  country: getCountryCode(countries, selectedSection?.country) || '',
                },
              },
              onCompleted: () => navigate('/onboarding/customer-summary', { state: { claimId } }),
            });
          }
        }}
      >
        <Form css={formStyles}>
          <DropDownSelect
            name="section"
            label={t('WP.ONBOARDING.CHOOSE_CUSTOMER_SELECTION')}
            alignment="start"
            className="sectionSelect"
            css={dropDownStyle}
          >
            <option value="" disabled selected>
              {t('WP.ONBOARDING.CHOOSE_CUSTOMER_SELECT_TEXT')}
            </option>
            {sections?.map((section) => (
              <option key={section?.id} value={section?.name || ''}>
                {section?.name}
              </option>
            ))}
            <option value={CREATE_CUSTOMER}>{t('WP.ONBOARDING.CHOOSE_CUSTOMER_CREATE_TEXT')}</option>
          </DropDownSelect>

          {data?.onboardingAccounts?.edges?.length !== 1 && (
            <Button
              css={backButton}
              type="button"
              variant="secondary"
              onClick={() => navigate('/onboarding/create-account', { state: { claimId } })}
            >
              {t('WP.COMMON.BACK')}
            </Button>
          )}
          <Button variant="primary" type="submit" css={nextButton}>
            {t('WP.COMMON.NEXT')}
          </Button>
        </Form>
      </Formik>
    </div>
  ) : null;
};

export default SelectOrCreateCustomer;
