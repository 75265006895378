import React, { useContext, useEffect, useMemo, useReducer } from 'react';
import { fetchQuery, graphql, useRelayEnvironment } from 'react-relay';
import { toGlobalId } from 'graphql-relay';
import setCustomerAccount from '../../adobe-launch/set-customer-account';
import {
  AccountProviderPrimaryAccountQuery,
  AccountProviderPrimaryAccountQuery$data as AccountProviderPrimaryAccountQueryResponse,
} from './__generated__/AccountProviderPrimaryAccountQuery.graphql';
import {
  AccountProviderNodeQuery,
  AccountProviderNodeQuery$data as AccountProviderNodeQueryResponse,
} from './__generated__/AccountProviderNodeQuery.graphql';
import { initialState, reducer } from './reducer';
import { ContextProps, ProviderProps } from './types';
import RootContext from '../../components/Root/RootContext';
import createEnvironment from '../../relay/createRelayEnvironment';
import useQueryStringParam from '../../hooks/useQueryParameters/useQueryStringParam';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const Context = React.createContext<ContextProps>(undefined!);

const Provider = ({ children }: ProviderProps): JSX.Element => {
  const [queryParam, setQueryParam] = useQueryStringParam('accoundId');

  const locallyStored = localStorage.getItem('accountId');

  const environment = useRelayEnvironment();

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    let didCancel = false;

    const setAccount = (
      node: AccountProviderPrimaryAccountQueryResponse['primaryAccount'] | AccountProviderNodeQueryResponse['node'],
    ): void => {
      if (!node) return;

      const { id, uuid, name, street, postalCode, city, country, address } = node;

      if (!id || !uuid || !name) return;

      localStorage.setItem('accountId', uuid);

      dispatch({
        type: 'SET_ACCOUNT',
        account: { id, uuid, name, street, postalCode, city, country, address },
      });

      setCustomerAccount(name);
    };

    const fetchPrimaryAccount = async (): Promise<void> => {
      //  TODO:
      //  Keep an eye open for the Batch Query Execution RFC
      //  https://github.com/graphql/graphql-spec/issues/377
      fetchQuery<AccountProviderPrimaryAccountQuery>(
        environment,
        graphql`
          query AccountProviderPrimaryAccountQuery {
            primaryAccount {
              id
              uuid
              name
              street
              postalCode
              city
              country
              address
            }
          }
        `,
        {},
      ).subscribe({
        // start: () => {...},
        complete: () => {
          dispatch({ type: 'SET_HAS_LOADED' });
        },
        // error: (error) => {...},
        next: (data) => {
          if (!didCancel && data.primaryAccount) {
            setAccount(data.primaryAccount);
          }
        },
      });
    };

    const fetchNode = async (accountId: string): Promise<void> => {
      fetchQuery<AccountProviderNodeQuery>(
        environment,
        graphql`
          query AccountProviderNodeQuery($id: ID!) {
            node(id: $id) {
              ... on Account {
                id
                uuid
                name
                street
                postalCode
                city
                country
                address
              }
            }
          }
        `,
        { id: toGlobalId('Account', accountId) },
      ).subscribe({
        // start: () => {...},
        complete: () => {
          dispatch({ type: 'SET_HAS_LOADED' });
        },
        error: () => {
          // If fetching a specific node fails then fall back
          // to the user's primary account
          fetchPrimaryAccount();
        },
        next: (data) => {
          if (!didCancel && data.node) {
            setAccount(data.node);
            setQueryParam();
          }
        },
      });
    };

    /**
     * Priority:
     * #1: Set account from query params
     * #2: Set account from local storage
     * #3: Randomly set an available account
     */
    if (queryParam) {
      fetchNode(queryParam as string);
    } else if (locallyStored) {
      fetchNode(locallyStored);
    } else {
      fetchPrimaryAccount();
    }

    return (): void => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { setRelayEnvironment } = useContext(RootContext);

  const { account, hasLoaded } = state;

  const context = useMemo<ContextProps>(
    () => ({
      account,
      setAccount: ({ id, uuid, name, street, postalCode, city, country, address }): void => {
        if (!account || id !== account.id) {
          localStorage.setItem('accountId', uuid);

          dispatch({ type: 'SET_ACCOUNT', account: { id, uuid, name, street, postalCode, city, country, address } });

          setRelayEnvironment(createEnvironment());

          setCustomerAccount(name);
        }
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [account],
  );

  return <Context.Provider value={context}>{hasLoaded && children}</Context.Provider>;
};

export { Context as AccountContext, Provider as AccountProvider };
