import React from 'react';
import pulseModeIcon from '../../../assets/images/pulseModeIcon.svg';
import manualModeIcon from '../../../assets/images/handOutline.svg';
import analogModeIcon from '../../../assets/images/analogModeIcon.svg';

const ControlModeIcon = ({ mode }: { mode?: 'PULSE' | 'MANUAL' | 'BATCH' | 'ANALOG_0_20' | 'ANALOG_4_20' }) => {
  if (mode === 'PULSE') return <img height="16" src={pulseModeIcon} alt="pulse" />;
  if (mode === 'MANUAL') return <img height="16" src={manualModeIcon} alt="manual" />;
  if (mode === 'ANALOG_0_20') return <img height="16" src={analogModeIcon} alt="analog" />;
  if (mode === 'ANALOG_4_20') return <img height="16" src={analogModeIcon} alt="analog" />;
  return null;
};

export default ControlModeIcon;
