import { css, SerializedStyles, Theme } from '@emotion/react';

// eslint-disable-next-line import/prefer-default-export
export const loaderStyles = (size: 'large' | 'small', theme: Theme): SerializedStyles => css`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: center;
  position: relative;
  width: ${size === 'small' ? '4rem' : '5rem'};
  height: ${size === 'small' ? '4rem' : '5rem'};
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${size === 'small' ? '1.25rem' : '2.5rem'};
    height: ${size === 'small' ? '1.25rem' : '2.5rem'};
    border: ${size === 'small' ? '0.125rem' : '0.20rem'} solid ${theme.colors.loader.light};
    border-radius: 50%;
    animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${theme.colors.loader.light} transparent transparent transparent;
  }
  div:nth-of-type(1) {
    animation-delay: -0.45s;
  }
  div:nth-of-type(2) {
    animation-delay: -0.3s;
  }
  div:nth-of-type(3) {
    animation-delay: -0.15s;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
