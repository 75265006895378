import { css, SerializedStyles, Theme } from '@emotion/react';

const notifBarStyles = (theme: Theme): SerializedStyles => css`
  background-color: #a94442;
  text-align: center;
  color: ${theme.colors.secondaryText};
  padding: 0.2rem;

  -webkit-animation: moveDown 2s;
  animation: moveDown 2s;

  @-webkit-keyframes moveDown {
    0% {
      @-webkit-keyframes-transform: translate(0, -1.5rem);
    }
    100% {
      @-webkit-keyframes-transform: translate(0, 0);
    }
  }

  @keyframes moveDown {
    0% {
      transform: translate(0, -1.5rem);
    }
    100% {
      transform: translate(0, 0);
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { notifBarStyles };
