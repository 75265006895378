import React, { useState } from 'react';
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay';
import { useLocation, useNavigate } from 'react-router-dom';
import { toGlobalId } from 'graphql-relay';
import { useTranslation } from 'react-i18next';
import { usePageViewed } from '../../../../adobe-launch/hooks';
import { Spinner, Button, InfoItem } from '../../../../components';
import {
  contentStyles,
  titleStyles,
  headerStyles,
  fieldsStyles,
  labelStyles,
  backStyles,
  submitStyles,
  errorStyles,
  note,
  noteLabel,
  noteText,
} from './styles';
import useQueryStringParam from '../../../../hooks/useQueryParameters/useQueryStringParam';
import { SummaryQuery } from './__generated__/SummaryQuery.graphql';
import { SummaryMutation } from './__generated__/SummaryMutation.graphql';
import { heading05Styles, paragraphBaseStyles, paragraphBrightStyles } from '../../../../styles';
import { useAccount } from '../../../../providers/AccountProvider';

const Summary: React.FC = () => {
  usePageViewed('Onboarding:Summary');
  const { state } = useLocation();
  const claimId = (state as { claimId: string })?.claimId;
  const [_, setClaimId] = useQueryStringParam('claimId');
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const selectedLanguage = i18n.language;
  const regionName = new Intl.DisplayNames([selectedLanguage], { type: 'region' });
  const { setAccount } = useAccount();

  const data = useLazyLoadQuery<SummaryQuery>(
    graphql`
      query SummaryQuery($id: ID!) {
        clientState {
          onboarding {
            onboardingId
            installationName
            account {
              uuid
            }
            section {
              name
              address
              postalCode
              state
              city
              country
            }
          }
        }
        node(id: $id) {
          ... on UnclaimedDevice {
            name
            installationNote
          }
        }
      }
    `,
    { id: toGlobalId('UnclaimedDevice', claimId) },
  );

  const [commit, isInFlight] = useMutation<SummaryMutation>(graphql`
    mutation SummaryMutation($input: OnboardingConfirmInput!) {
      onboardingConfirm(input: $input) {
        account {
          uuid
          name
          id
        }
        device {
          deviceId
          subDevice {
            deviceType
          }
        }
      }
    }
  `);

  const [didFail, setDidFail] = useState(false);

  if (isInFlight) return <Spinner />;

  const { section, installationName } = data.clientState?.onboarding || {};
  const { installationNote } = data?.node || {};

  return (
    <div css={contentStyles}>
      <div css={fieldsStyles}>
        <div css={[heading05Styles, titleStyles]}>
          <h1>{t('WP.ONBOARDING.SUMMARY_TITLE')}</h1>
        </div>
        <div css={[heading05Styles, headerStyles('installation-header')]}>
          {t('WP.ONBOARDING.SUMMARY_INSTALLATION_HEADER')}
        </div>
        <InfoItem
          css={labelStyles('installation-name')}
          label={t('WP.ONBOARDING.SUMMARY_INSTALLATION_NAME')}
          value={installationName}
        />
        <label htmlFor="installationNote" css={note}>
          <div css={[paragraphBaseStyles, noteLabel]}>{t('WP.ONBOARDING.INSTALLATION_NOTE_LABEL')}</div>
          <div css={[paragraphBrightStyles, noteText]}>{installationNote || ''}</div>
        </label>
        {section && (
          <>
            <div css={[heading05Styles, headerStyles('customer-header')]}>
              {t('WP.ONBOARDING.SUMMARY_CUSTOMER_HEADER')}
            </div>
            <InfoItem
              css={labelStyles('customer-name')}
              label={t('WP.ONBOARDING.SUMMARY_CUSTOMER_NAME')}
              value={section.name || t('WP.COMMON.NONE')}
            />
            <InfoItem
              css={labelStyles('customer-address')}
              label={t('WP.ONBOARDING.SUMMARY_CUSTOMER_ADDRESS')}
              value={section.address || t('WP.COMMON.NONE')}
            />
            <InfoItem
              css={labelStyles('customer-postal-code')}
              label={t('WP.ONBOARDING.SUMMARY_CUSTOMER_POSTAL_CODE')}
              value={section.postalCode || t('WP.COMMON.NONE')}
            />
            <InfoItem
              css={labelStyles('customer-state')}
              label={t('WP.ONBOARDING.SUMMARY_CUSTOMER_STATE')}
              value={section.state || t('WP.COMMON.NONE')}
            />
            <InfoItem
              css={labelStyles('customer-city')}
              label={t('WP.ONBOARDING.SUMMARY_CUSTOMER_CITY')}
              value={section.city || t('WP.COMMON.NONE')}
            />
            <InfoItem
              css={labelStyles('customer-country')}
              label={t('WP.ONBOARDING.SUMMARY_CUSTOMER_COUNTRY')}
              value={section.country ? regionName.of(section.country || '') : t('WP.COMMON.NONE')}
              noBorder
            />
          </>
        )}
        <div css={backStyles}>
          <Button
            type="button"
            variant="secondary"
            onClick={() => navigate('/onboarding/installation', { state: { claimId } })}
          >
            {t('WP.COMMON.BACK')}
          </Button>
        </div>
        <div css={submitStyles}>
          <Button
            type="submit"
            variant="primary"
            disabled={!data.clientState?.onboarding?.onboardingId}
            onClick={() => {
              const onboardingId = data.clientState?.onboarding?.onboardingId;
              const accountId = data.clientState?.onboarding?.account?.uuid;
              if (onboardingId && accountId) {
                commit({
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  variables: { input: { onboardingId, claimId, accountId } },
                  onCompleted: (response) => {
                    setClaimId();
                    if (response.onboardingConfirm?.device?.deviceId && response.onboardingConfirm.account) {
                      const { uuid, id, name } = response.onboardingConfirm.account;
                      const { device } = response.onboardingConfirm;
                      const pumpType = device.subDevice?.deviceType;
                      setAccount({ uuid, id, name });

                      if (pumpType === 'DDA') navigate(`/account/${uuid}/gateways/${device.deviceId}/dda`);
                      if (pumpType === 'DDAC') navigate(`/account/${uuid}/gateways/${device.deviceId}/ddac`);
                    } else navigate('/');
                    setDidFail(true); // TODO: We should propably be better at using the payload error
                  },
                  onError: (error: any) => {
                    console.log(error.errors[0]?.message);
                    setDidFail(true);
                  },
                });
              }
            }}
          >
            {t('WP.COMMON.NEXT')}
          </Button>
        </div>
        {didFail && <div css={errorStyles}>{t('WP.ONBOARDING.SUMMARY_UNSPECIFIED_ERROR')}</div>}
      </div>
    </div>
  );
};

export default Summary;
