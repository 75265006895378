import React from 'react';
import { useTranslation } from 'react-i18next';
import { paragraphBoldStyles } from '../../../../../styles';
import { InfoItem } from '../../../../../components';
import { infoItemStyles, removeLastBottomBorder } from '../styles';
import { OperatingInfoProps } from './types';

const OperatingInfo: React.FC<OperatingInfoProps> = ({ motorOperatingHours, totalOperatingHours }) => {
  const { t } = useTranslation();
  return (
    <div css={removeLastBottomBorder}>
      <p css={paragraphBoldStyles}>{t('WP.MORE.DATA.OPERATION')}</p>
      <InfoItem
        css={infoItemStyles}
        label={t('WP.MORE.DATA.MOTOR_OPERATING')}
        value={motorOperatingHours || '-'}
        unit={motorOperatingHours === 1 ? t('WP.COMMON.HOUR') : t('WP.COMMON.HOURS')}
      />
      <InfoItem
        css={infoItemStyles}
        label={t('WP.MORE.DATA.OPERATING_HOURS')}
        value={totalOperatingHours || '-'}
        unit={totalOperatingHours === 1 ? t('WP.COMMON.HOUR') : t('WP.COMMON.HOURS')}
      />
    </div>
  );
};

export default OperatingInfo;
