import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tile, TileBody, TileHeader } from '../../../../../components/Tiles';
import {
  addressStyles,
  buttonStyles,
  contactPersonText,
  containerStyles,
  imageStyles,
  tileBodyStyles,
  tileStyles,
  verticalLineStyles,
} from './styles';
import { headingSmallBrightStyles, paragraphBrightStyles } from '../../../../../styles';
import { OverviewTileProps } from './types';
import { Button, NoDataAvailableMessage, Portal } from '../../../../../components';
import useOverviewData from './useOverviewData';

const DisplayAddress = ({
  street,
  postalCode,
  city,
  country,
}: {
  street: string | null | undefined;
  postalCode: string | null | undefined;
  city: string | null | undefined;
  country: string | null | undefined;
}) => (
  <div>
    <div>{street}</div>
    <div css={addressStyles}>
      <span>{postalCode}</span>
      <span>{city}</span>
    </div>
    <div>{country}</div>
  </div>
);

const OverviewTile = ({ device, className, ModalComponent, Image }: OverviewTileProps) => {
  const data = useOverviewData(device);
  const [showModal, setShowModal] = useState(false);

  const { t } = useTranslation();

  if (!data)
    return (
      <Tile
        key="noData"
        Header={<TileHeader text={t('WP.OVERVIEW.PUMP_STATUS')} />}
        Body={<NoDataAvailableMessage size="text" message={t('WP.ERROR.NO_DATA')} />}
      />
    );

  const { installationName, customerName, contactPersonName, contactPersonPhone } = data;
  const { street, city, postalCode, country } = data.location;

  return (
    <div css={[containerStyles, className]}>
      <div css={imageStyles}>{Image} </div>
      <div css={verticalLineStyles} />
      <Tile
        styles={tileStyles}
        key="overview"
        Body={
          <div>
            <TileHeader text={t('WP.OVERVIEW.OVERVIEW')} />
            <TileBody css={[tileBodyStyles, paragraphBrightStyles]}>
              <h3 css={headingSmallBrightStyles}>{customerName}</h3>
              <p>{installationName}</p>
              <DisplayAddress street={street} city={city} postalCode={postalCode} country={country} />
              <div>
                <div>
                  <span>{t('WP.OVERVIEW.CONTACT_PERSON_ON_SITE')}:</span>
                  <p css={contactPersonText}>{contactPersonName}</p>
                </div>
                <div>
                  <span>{t('WP.OVERVIEW.MODAL.CONTACT_PERSON_PHONE')}:</span>
                  <p css={contactPersonText}>{contactPersonPhone}</p>
                </div>
              </div>
              <Button css={buttonStyles} type="button" variant="text link" onClick={() => setShowModal(true)}>
                {t('WP.OVERVIEW.INSTALLATION_INFO')}
              </Button>
            </TileBody>
          </div>
        }
      />
      {showModal ? (
        <Portal>
          <ModalComponent onClose={() => setShowModal(false)} />
        </Portal>
      ) : null}
    </div>
  );
};

export default OverviewTile;
