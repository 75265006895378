import React from 'react';

const Building: React.FC<{}> = () => (
  <svg viewBox="0 0 25 24" width="25pt" height="24pt">
    <defs>
      <clipPath id="_clipPath_Kt89srmKSaobgm6Y4J4nO6opmQrXk3LK">
        <rect width="25" height="24" />
      </clipPath>
    </defs>
    <g clipPath="url(#_clipPath_Kt89srmKSaobgm6Y4J4nO6opmQrXk3LK)">
      <g>
        <g>
          <path
            d=" M 21.568 3 L 21.568 6.75 L 20.068 6.75 L 20.068 4.5 L 5.068 4.5 L 5.068 19.5 L 20.068 19.5 L 20.068 17.25 L 21.568 17.25 L 21.568 21 L 3.568 21 L 3.568 3 L 21.568 3 Z  M 16.848 6.969 L 21.879 11.989 L 16.849 17.03 L 15.787 15.97 L 19.001 12.748 L 8.818 12.742 L 8.819 11.242 L 19.013 11.248 L 15.788 8.031 L 16.848 6.969 Z "
            fill="rgb(0,0,0)"
          />
          <clipPath id="_clipPath_FVVlZjf5Of7CrESZp1MTbpPOOvVHKGuX">
            <path
              d=" M 21.568 3 L 21.568 6.75 L 20.068 6.75 L 20.068 4.5 L 5.068 4.5 L 5.068 19.5 L 20.068 19.5 L 20.068 17.25 L 21.568 17.25 L 21.568 21 L 3.568 21 L 3.568 3 L 21.568 3 Z  M 16.848 6.969 L 21.879 11.989 L 16.849 17.03 L 15.787 15.97 L 19.001 12.748 L 8.818 12.742 L 8.819 11.242 L 19.013 11.248 L 15.788 8.031 L 16.848 6.969 Z "
              fill="rgb(255,255,255)"
            />
          </clipPath>
          <g clipPath="url(#_clipPath_FVVlZjf5Of7CrESZp1MTbpPOOvVHKGuX)">
            <g>
              <g>
                <rect x="0.568" y="0" width="24" height="24" transform="matrix(1,0,0,1,0,0)" fill="rgb(255,255,255)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Building;
