import React from 'react';
import tooltip from '../../../../assets/images/tooltip.svg';
import { analogTooltip, tooltipIcon } from './styles';
import { OptionalRuleViolation, ProportionalRule } from '../types';
import { Tooltip } from '../../../../components';
import useAnalogRuleViolations from '../hooks/useAnalogRuleValidations';

const AnalogRuleInfoToolTip: React.FC<{
  children?: JSX.Element | string | number | undefined | null;
  proportionalRuleValidation: OptionalRuleViolation;
  macthRuleViolations: ProportionalRule[];
}> = ({ children, proportionalRuleValidation, macthRuleViolations }) => {
  const { ruleViolationsText, hasRuleViolations } = useAnalogRuleViolations(
    proportionalRuleValidation,
    macthRuleViolations,
  );

  if (hasRuleViolations)
    return (
      <div css={analogTooltip}>
        <Tooltip placement="top" content={ruleViolationsText} size="large" trigger="click">
          <img css={tooltipIcon} src={tooltip} alt="tooltip" />
        </Tooltip>
        {children}
      </div>
    );

  return <div css={analogTooltip}>{children}</div>;
};

export default AnalogRuleInfoToolTip;
