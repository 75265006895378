import React from 'react';
import { SpinnerProps } from './types';
import { spinnerStyles, doubleBounceOneStyles, doubleBounceTwoStyles, doubleBounceStyles } from './styles';

const Spinner: React.FC<SpinnerProps> = ({ size }) => (
  <div css={spinnerStyles(size)} data-test-id="spinner">
    <div css={[doubleBounceStyles, doubleBounceOneStyles]} />
    <div css={[doubleBounceStyles, doubleBounceTwoStyles]} />
  </div>
);

export default Spinner;
