const setInitialDataLayer = (): void => {
  const state = window.digitalData[window.digitalData.length - 1];

  window.digitalData.push({
    ...state,
    data: {
      ...state?.data,
      pageInfo: {
        ...state?.data?.pageInfo,
        toolName: 'SDCS',
        rsid: 'gfdigitalofferings',
        platform: 'digital offering',
        server: window.location.host,
        companyCode: 'GMA',
        siteCountry: 'Global',
        language: 'en',
        hier1: 'sdcs/homepage',
      },
    },
  });
};

export default setInitialDataLayer;
