import React from 'react';
import { dataBoxStyles, dataBoxInfoTextContainerStyles, topPadding } from '../styles';
import { labelStyles } from '../../../../../styles';

const DataBoxInfoText = ({ label, value }: { label: string; value?: number | string | null }) => (
  <div css={topPadding}>
    <div css={dataBoxStyles}>
      <p css={labelStyles}>{label}</p>
      <div css={dataBoxInfoTextContainerStyles}>{value}</div>
    </div>
  </div>
);

export default DataBoxInfoText;
