import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { DropDownSelect, InfoItem, TextInputField, TogglerFormik, Divider, Indent } from '../../../../../components';
import { delayOptions, sensitivityOptions } from '../utils';
import { textInputStyles } from '../styles';
import { FormValues, PumpVariant } from '../types';

export const FieldMode: React.FC<{
  isHidden: boolean;
  isRead: boolean;
  readOnlyComponent: JSX.Element;
  children: JSX.Element;
}> = ({ isHidden, children, readOnlyComponent, isRead }) => {
  if (isHidden) return null;
  if (isRead) return readOnlyComponent;
  return children;
};

const FlowControl: React.FC<{
  pumpVariant: PumpVariant | undefined;
  maxPressureAlamUnit: string | undefined | null;
}> = ({ pumpVariant, maxPressureAlamUnit }) => {
  const { t } = useTranslation();
  const { values, initialValues, resetForm } = useFormikContext<FormValues>();
  const isReadMode = values.flowControl === false;
  if (pumpVariant === 'AR') return null;
  return (
    <div>
      <div>
        <TogglerFormik
          label={<div>{t('WP.REMOTE.FLOW_CONTROL_ON_OFF')}</div>}
          name="flowControl"
          onToggle={(flowControlIsOn) => {
            if (!flowControlIsOn) {
              // Reset form needs all values for next state
              resetForm({
                values: {
                  ...values,
                  delay: initialValues.delay,
                  sensitivity: initialValues.sensitivity,
                  autoFlowAdapt: initialValues.autoFlowAdapt,
                  maxPressureAlarm: initialValues.maxPressureAlarm,
                  minPressureAsAlarm: initialValues.minPressureAsAlarm,
                },
              });
            }
          }}
          isToggled={initialValues.flowControl}
        />
      </div>
      <Divider />
      <div>
        <FieldMode
          isHidden={false}
          isRead={isReadMode}
          readOnlyComponent={
            <InfoItem
              noBorder
              label={<Indent>{t('WP.REMOTE.FLOW_CONTROL_DELAY_LABEL')}</Indent>}
              inActive
              value={t('WP.REMOTE.FLOW_CONTROL_NOT_ACTIVE')}
            />
          }
        >
          <DropDownSelect name="delay" label={<Indent>{t('WP.REMOTE.FLOW_CONTROL_DELAY_LABEL')}</Indent>}>
            {delayOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {t(`WP.REMOTE.FLOW_DELAY_OPTION_${option.label}`)}
              </option>
            ))}
          </DropDownSelect>
        </FieldMode>
        <Divider marginLeft="large" />
        <FieldMode
          isHidden={false}
          isRead={isReadMode}
          readOnlyComponent={
            <InfoItem
              noBorder
              label={<Indent>{t('WP.REMOTE.FLOW_CONTROL_SENSIVITY_LABEL')}</Indent>}
              inActive
              value={t('WP.REMOTE.FLOW_CONTROL_NOT_ACTIVE')}
            />
          }
        >
          <DropDownSelect name="sensitivity" label={<Indent>{t('WP.REMOTE.FLOW_CONTROL_SENSIVITY_LABEL')}</Indent>}>
            {sensitivityOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {t(`WP.REMOTE.REMOTE_FLOW_SENS_OPTION_${option.label}`)}
              </option>
            ))}
          </DropDownSelect>
        </FieldMode>
        <Divider marginLeft="large" />
        <FieldMode
          isHidden={pumpVariant !== 'FCM'}
          isRead={isReadMode}
          readOnlyComponent={
            <InfoItem
              noBorder
              label={<Indent>{t('WP.REMOTE.FLOW_CONTROL_AUTO_FLOW_ADAPT_LABEL')}</Indent>}
              inActive
              value={t('WP.REMOTE.FLOW_CONTROL_NOT_ACTIVE')}
            />
          }
        >
          <TogglerFormik
            label={<Indent>{t('WP.REMOTE.FLOW_CONTROL_AUTO_FLOW_ADAPT_LABEL')}</Indent>}
            name="autoFlowAdapt"
            isToggled
          />
        </FieldMode>
        {pumpVariant === 'FCM' && <Divider marginLeft="large" />}
        <FieldMode
          isHidden={false}
          isRead={isReadMode}
          readOnlyComponent={
            <InfoItem
              noBorder
              label={<Indent>{t('WP.REMOTE.FLOW_CONTROL_MAX_PRESSURE_LABEL')}</Indent>}
              inActive
              value={t('WP.REMOTE.FLOW_CONTROL_NOT_ACTIVE')}
            />
          }
        >
          <TextInputField
            css={[textInputStyles]}
            type="number"
            step="any"
            name="maxPressureAlarm"
            label={
              <Indent>
                <div>{`${t('WP.REMOTE.FLOW_CONTROL_MAX_PRESSURE_LABEL')} (${maxPressureAlamUnit})`}</div>
              </Indent>
            }
          />
        </FieldMode>
        <Divider marginLeft="large" />
        <FieldMode
          isHidden={false}
          isRead={isReadMode}
          readOnlyComponent={
            <InfoItem
              noBorder
              label={<Indent>{t('WP.REMOTE.FLOW_CONTROL_MIN_PRESSURE_LABEL')}</Indent>}
              inActive
              value={t('WP.REMOTE.FLOW_CONTROL_NOT_ACTIVE')}
            />
          }
        >
          <TogglerFormik
            label={<Indent>{t('WP.REMOTE.FLOW_CONTROL_MIN_PRESSURE_LABEL')}</Indent>}
            name="minPressureAsAlarm"
            isToggled
          />
        </FieldMode>
      </div>
    </div>
  );
};

export default FlowControl;
