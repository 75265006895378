import { useEffect, RefObject } from 'react';

const useOutSideGestureCapture = (ref: RefObject<HTMLDivElement>, onOutSideClickCapture: () => void) => {
  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (ref.current && !ref.current.contains(event.target as HTMLDivElement)) {
        onOutSideClickCapture();
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [onOutSideClickCapture, ref]);
};

export default useOutSideGestureCapture;
