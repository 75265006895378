import { getUnixTime, subHours, fromUnixTime, subMinutes } from 'date-fns';
import { groupByMap } from '../../../../../utils/array/grouping';
import { DataPointRaw, GraphDataPoints, GraphDataPoint } from './types';
import { getHour } from '../../../../../utils/date/dateUtils';
import { mergeSortedArraysOfEqualLength } from '../../../../../utils/array/merge';

const getStartDate = (date: number | Date, span: number) => {
  const minutes = new Date(date).getMinutes();
  const subtractedHours = subHours(date, span).getTime();
  if (minutes !== 0) {
    const withMinutes = subMinutes(subtractedHours, minutes).getTime();
    return withMinutes;
  }
  return subtractedHours;
};

// Used to to filter data points by a given date. First we provide the date to filter, and then return a function we can use in a filter function
export const dataPointBy = (date: number) => {
  const startDateUnix = getUnixTime(date);
  return (dp: DataPointRaw) => dp.createdAt >= startDateUnix;
};

export const groupedGraphDataPointsByFirstEndTime = (datapoints: GraphDataPoints): GraphDataPoints => {
  // We default to group by hourly interval
  const groupedHourValuesIterator = groupByMap(datapoints, (dp) => getHour(dp.endTime));
  const hourValues: GraphDataPoints = [];
  groupedHourValuesIterator.forEach((graphDataPointsInGroup) => {
    if (!graphDataPointsInGroup || graphDataPointsInGroup?.length < 1) {
      return;
    }
    // After grouping, we need to make sure each group is sorted
    const sortedGroup = [...graphDataPointsInGroup].sort((a, b) => a.endTime - b.endTime);
    const firstElement = sortedGroup[0];
    hourValues.push(firstElement);
  });
  return hourValues;
};

// This function can merge array of data points. All datapoints will be merged by their db.createdAt property.
// To use this function you provide a mergeFunction. That is a function that describes how the merged objects should look like
// And you provide an array of dataPoints arrays
const mergeDataPoints = <T>(mergeFunction: (dataToMerge: DataPointRaw[]) => T, dps: (readonly DataPointRaw[])[]) => {
  const getMergeKey = (dp: DataPointRaw) => dp.createdAt;
  return mergeSortedArraysOfEqualLength(getMergeKey, mergeFunction, dps);
};

const mergeVolumeDataPoints = (
  dosedVolumeDataPoints: readonly DataPointRaw[],
  dosedVolumeStartDataPoints: readonly DataPointRaw[],
): GraphDataPoints => {
  const mergeFunction = (dps: DataPointRaw[]): GraphDataPoint => {
    const dosedVolumeDataPoint = dps[0];
    const dosedVolumeStartDataPoint = dps[1];

    if (!dosedVolumeDataPoint.createdAt) {
      throw new Error('No end time provided');
    }

    // If no start time, we just go an hour back and set that as start time
    const startTime = dosedVolumeStartDataPoint.valueRaw
      ? dosedVolumeStartDataPoint.valueRaw * 1000
      : (dosedVolumeDataPoint.createdAt - 60 * 60) * 1000;

    return {
      startTime,
      endTime: dosedVolumeDataPoint.createdAt * 1000,
      value: dosedVolumeDataPoint.valueRaw ?? 0,
    };
  };
  const arraysOfDataPointsToBeMerged = [dosedVolumeDataPoints, dosedVolumeStartDataPoints];
  return mergeDataPoints(mergeFunction, arraysOfDataPointsToBeMerged);
};

const filterByStartDate = (dataPoints: readonly DataPointRaw[], startDate: number) =>
  dataPoints.filter(dataPointBy(startDate));

/**
 * Group the data datapoints hourly buckets. Each bucket will have a date and a value. The value is an average of all the datapoints within the given hour.
 * As a bonus we also return the volume for the last hour.
 */
export const dataPointsGroupingWithinSpan = (
  datapointsVolume: readonly DataPointRaw[],
  dataPointsVolumeStart: readonly DataPointRaw[],
  spanInHours = 24,
) => {
  if (
    datapointsVolume &&
    datapointsVolume.length > 0 &&
    spanInHours > 0 &&
    dataPointsVolumeStart &&
    dataPointsVolumeStart.length > 0
  ) {
    // We uses the last known data point and go 24 hours back from that point.
    // We could also use "new Date" but then we will miss the last entries because our telemtric data can be up to an hour late'
    // We assume that the datapoints are sorted. Therefore the lastDate / newest date will be last element in array
    const lastDate = fromUnixTime(datapointsVolume[datapointsVolume.length - 1].createdAt || 0).getTime();
    const startDate = getStartDate(lastDate, spanInHours);
    const dataPointsVolumeFiltered = filterByStartDate(datapointsVolume, startDate);
    const dataPointsVolumeStartFiltered = filterByStartDate(dataPointsVolumeStart, startDate);
    const dataPointsFromStartDate = mergeVolumeDataPoints(dataPointsVolumeFiltered, dataPointsVolumeStartFiltered);

    if (!dataPointsFromStartDate || dataPointsFromStartDate?.length < 1) {
      return { hourValues: [], volumeLastHour: 0 };
    }
    const hourValues = groupedGraphDataPointsByFirstEndTime(dataPointsFromStartDate);

    return { hourValues, volumeLastHour: hourValues[hourValues.length - 1].value };
  }
  return { hourValues: [], volumeLastHour: 0 };
};

export default dataPointsGroupingWithinSpan;
