export const getHour = (date: number) => {
  const dateContruct = new Date(date);
  return new Date(
    dateContruct.getFullYear(),
    dateContruct.getMonth(),
    dateContruct.getDate(),
    dateContruct.getHours(),
    0,
    0,
    0,
  ).getTime();
};

export const getDay = (date: number) => {
  const dateContruct = new Date(date);
  return new Date(dateContruct.getFullYear(), dateContruct.getMonth(), dateContruct.getDate(), 0, 0, 0, 0).getTime();
};
