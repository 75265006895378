const suportedLanguages = [
  {
    val: 'de',
    displayText: 'Deutch - German',
  },
  {
    val: 'en',
    displayText: 'English - English',
  },
  {
    val: 'es',
    displayText: 'Español - Spanish',
  },
  {
    val: 'fr',
    displayText: 'Français - French',
  },
  {
    val: 'it',
    displayText: 'Italiano - Italian',
  },
  {
    val: 'nl',
    displayText: 'Nederlands - Dutch',
  },

  {
    val: 'pl',
    displayText: 'Polski - Polish',
  },
];

export default suportedLanguages;
