import React from 'react';
import { InfoItem } from '../../../../../components';
import ReadField from './ReadField';

const ReadOnlyInfoItem: React.FC<{
  label: string;
  value: JSX.Element | string | number | null | undefined;
  hasError?: boolean;
  hideDivider?: boolean;
  testId: string;
  unit?: string | null;
  className?: string;
}> = ({ label, value, hideDivider, hasError, testId, unit, className }) => (
  <ReadField isHidden={false} value={hasError ? null : value} noValueLabel={label} hideDivider={hideDivider}>
    <InfoItem className={className} noBorder label={label} value={value} testId={testId} unit={unit} />
  </ReadField>
);

export default ReadOnlyInfoItem;
