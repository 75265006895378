// eslint-disable-next-line import/prefer-default-export
export const groupByMap = <T>(arr: T[], groupDecider: (value: T) => number): Map<number, T[]> => {
  if (arr.length < 1) {
    return new Map();
  }
  const grouped = new Map<number, T[]>();

  arr.forEach((value) => {
    const groupKey = groupDecider(value);
    if (grouped.has(groupKey)) {
      const groupValues = grouped.get(groupKey) as T[];
      groupValues.push(value);
    } else {
      grouped.set(groupKey, [value]);
    }
  });

  return grouped;
};
