import React from 'react';
import { radioButtonStyles, labelStyles } from './styles';

const RadioButton = ({
  label,
  isSelected = false,
  onChange,
  id,
}: {
  label: string;
  isSelected: boolean;
  onChange: () => void;
  id: string;
}) => (
  <label css={labelStyles} htmlFor={id}>
    <input css={radioButtonStyles} onChange={onChange} type="radio" id={id} checked={isSelected} />
    {label}
  </label>
);

export default RadioButton;
