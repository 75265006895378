// if no threshold value is given by the user in the app, we set a default threshold of 10% of maxVolume
export const convertThresholdOrDefault = (threshold?: string | null, maxVolume?: number): number | undefined => {
  if (threshold) return Math.floor(Number(threshold));
  if (!threshold && maxVolume) {
    return maxVolume / 10;
  }
};

// setting values are always returned from settings service in string format
export const convertValueToNumber = (value?: string | null | number): number | undefined => {
  if (!value) return undefined;
  return Math.floor(Number(value));
};

export const displayNames: Record<string, string> = {
  TIME_NEXT_SERVICE: 'Time to service',
  CALCULATED_CONTAINER_LEVEL: 'Calculated container level',
};
