import React, { Suspense } from 'react';
import RemoteControl from './RemoteControl';
import { Interval, Spinner } from '../../../../components';
import useSendRemoteControlCommands from './hooks/useSendRemoteControlCommands';
import { RemoteQueryRef } from './types';
import useDisplayRemoteControlData from './hooks/useDisplayRemoteControlData';
import useFetchRemoteControlData from './hooks/useFetchRemoteControlData';

const RemoteControlData: React.FC<{ deviceTwinId: string | null; queryRef: RemoteQueryRef }> = ({
  deviceTwinId,
  queryRef,
}) => {
  // Everything releated to reading data from pump
  const { fetchRemoteControlData, fetchRemoteControlDataRef } = useFetchRemoteControlData();
  const { formValues, remoteControlSettings } = useDisplayRemoteControlData(fetchRemoteControlDataRef || queryRef);
  // Everything related to sending the command to the server
  const { sendRemoteCommands, isLoading } = useSendRemoteControlCommands(deviceTwinId);
  if (!formValues || !remoteControlSettings) return null;

  return (
    <Suspense fallback={<Spinner />}>
      {!isLoading ? <Interval onInterval={fetchRemoteControlData} /> : null}
      <RemoteControl
        data={formValues}
        remoteControlSettings={remoteControlSettings}
        onFormSubmit={sendRemoteCommands}
        isLoading={isLoading}
      />
    </Suspense>
  );
};

export default RemoteControlData;
