import { css, Theme } from '@emotion/react';
import { getTrackBackground } from 'react-range';

const containerStyles = css`
  display: 'flex';
  justifycontent: 'center';
  flexwrap: 'wrap';
`;

// Removed fix 36 px height
const trackContainer = css`
  display: flex;
  padding: 0.625rem 0rem;
`;

const track = (theme: Theme, values: number[], min: number, max: number, disabled: boolean) => css`
  height: 0.375rem;
  width: 100%;
  border-radius: 1.25rem;
  background: ${getTrackBackground({
    values,
    colors: [
      theme.colors.neutral.neutral800.rgba,
      disabled ? theme.colors.neutral.neutral400.hex : theme.colors.blue.blue3.baseColor,
      theme.colors.neutral.neutral800.rgba,
    ],
    min,
    max,
    rtl: false,
  })};
  position: relative;
`;

const activeTrack = (theme: Theme, left: number, width: number, hasReachedThreshold: boolean, disabled: boolean) => {
  let backgroundColor = theme.colors.blue.blue3.baseColor;
  if (hasReachedThreshold) backgroundColor = theme.colors.neutral.neutral400.hex;
  if (disabled) backgroundColor = theme.colors.neutral.neutral600.hex;

  return css`
    position: absolute;
    height: 0.5rem;
    left: ${left}%;
    width: ${width}%;
    background-color: ${backgroundColor};
    border-radius: 1.25rem;
  `;
};

const knob = (theme: Theme, disabled: boolean) => css`
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 50%;
  background-color: ${disabled ? theme.colors.neutral.neutral600.hex : theme.colors.neutral.neutral100.hex};
  display: flex;
  justifycontent: center;
  alignitems: center;

  &:focus-visible {
    outline: none; /* Remove default focus ring */
  }

  &:focus::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: ${theme.colors.blue.blue3.baseColor};
    z-index: -1; /* Put the enlarged area behind the thumb */
  }
`;

export { containerStyles, trackContainer, track, knob, activeTrack };
