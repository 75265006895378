interface Country {
  id: string;
  countryName: string;
  countryCode: string;
}

export const getCountryCode = (countryList: Country[], countryName?: string | null): string | undefined => {
  const country = countryList.find((cntry) => cntry.countryName === countryName);
  return country?.countryCode;
};

const countries: Country[] = [
  {
    id: '30183644-6730-ea11-a601-281878e92c65',
    countryName: 'Afghanistan',
    countryCode: 'AF',
  },
  {
    id: '2f183644-6730-ea11-a601-281878e92c65',
    countryName: 'Åland Islands',
    countryCode: 'AX',
  },
  {
    id: '2e183644-6730-ea11-a601-281878e92c65',
    countryName: 'Albania',
    countryCode: 'AL',
  },
  {
    id: '2d183644-6730-ea11-a601-281878e92c65',
    countryName: 'Algeria',
    countryCode: 'DZ',
  },
  {
    id: '2c183644-6730-ea11-a601-281878e92c65',
    countryName: 'American Samoa',
    countryCode: 'AS',
  },
  {
    id: '2b183644-6730-ea11-a601-281878e92c65',
    countryName: 'Andorra',
    countryCode: 'AD',
  },
  {
    id: '2a183644-6730-ea11-a601-281878e92c65',
    countryName: 'Angola',
    countryCode: 'AO',
  },
  {
    id: '29183644-6730-ea11-a601-281878e92c65',
    countryName: 'Anguilla',
    countryCode: 'AI',
  },
  {
    id: '28183644-6730-ea11-a601-281878e92c65',
    countryName: 'Antarctica',
    countryCode: 'AQ',
  },
  {
    id: '27183644-6730-ea11-a601-281878e92c65',
    countryName: 'Antigua and Barbuda',
    countryCode: 'AG',
  },
  {
    id: '26183644-6730-ea11-a601-281878e92c65',
    countryName: 'Argentina',
    countryCode: 'AR',
  },
  {
    id: '25183644-6730-ea11-a601-281878e92c65',
    countryName: 'Armenia',
    countryCode: 'AM',
  },
  {
    id: '24183644-6730-ea11-a601-281878e92c65',
    countryName: 'Aruba',
    countryCode: 'AW',
  },
  {
    id: '88173644-6730-ea11-a601-281878e92c65',
    countryName: 'Ascension Island',
    countryCode: 'SH',
  },
  {
    id: '31183644-6730-ea11-a601-281878e92c65',
    countryName: 'Australia',
    countryCode: 'AU',
  },
  {
    id: '32183644-6730-ea11-a601-281878e92c65',
    countryName: 'Austria',
    countryCode: 'AT',
  },
  {
    id: '33183644-6730-ea11-a601-281878e92c65',
    countryName: 'Azerbaijan',
    countryCode: 'AZ',
  },
  {
    id: '34183644-6730-ea11-a601-281878e92c65',
    countryName: 'Bahamas',
    countryCode: 'BS',
  },
  {
    id: '35183644-6730-ea11-a601-281878e92c65',
    countryName: 'Bahrain',
    countryCode: 'BH',
  },
  {
    id: '36183644-6730-ea11-a601-281878e92c65',
    countryName: 'Bangladesh',
    countryCode: 'BD',
  },
  {
    id: '37183644-6730-ea11-a601-281878e92c65',
    countryName: 'Barbados',
    countryCode: 'BB',
  },
  {
    id: '38183644-6730-ea11-a601-281878e92c65',
    countryName: 'Belarus',
    countryCode: 'BY',
  },
  {
    id: '39183644-6730-ea11-a601-281878e92c65',
    countryName: 'Belgium',
    countryCode: 'BE',
  },
  {
    id: '3a183644-6730-ea11-a601-281878e92c65',
    countryName: 'Belize',
    countryCode: 'BZ',
  },
  {
    id: '3b183644-6730-ea11-a601-281878e92c65',
    countryName: 'Benin',
    countryCode: 'BJ',
  },
  {
    id: '3c183644-6730-ea11-a601-281878e92c65',
    countryName: 'Bermuda',
    countryCode: 'BM',
  },
  {
    id: '3d183644-6730-ea11-a601-281878e92c65',
    countryName: 'Bhutan',
    countryCode: 'BT',
  },
  {
    id: '3e183644-6730-ea11-a601-281878e92c65',
    countryName: 'Bolivia',
    countryCode: 'BO',
  },
  {
    id: '23183644-6730-ea11-a601-281878e92c65',
    countryName: 'Bonaire',
    countryCode: 'BQ',
  },
  {
    id: '3f183644-6730-ea11-a601-281878e92c65',
    countryName: 'Bosnia and Herzegovina',
    countryCode: 'BA',
  },
  {
    id: '22183644-6730-ea11-a601-281878e92c65',
    countryName: 'Botswana',
    countryCode: 'BW',
  },
  {
    id: '20183644-6730-ea11-a601-281878e92c65',
    countryName: 'Brazil',
    countryCode: 'BR',
  },
  {
    id: '05183644-6730-ea11-a601-281878e92c65',
    countryName: 'British Indian Ocean Territory',
    countryCode: 'IO',
  },
  {
    id: '06183644-6730-ea11-a601-281878e92c65',
    countryName: 'British Virgin Islands',
    countryCode: 'VG',
  },
  {
    id: '07183644-6730-ea11-a601-281878e92c65',
    countryName: 'Brunei',
    countryCode: 'BN',
  },
  {
    id: '08183644-6730-ea11-a601-281878e92c65',
    countryName: 'Bulgaria',
    countryCode: 'BG',
  },
  {
    id: '09183644-6730-ea11-a601-281878e92c65',
    countryName: 'Burkina Faso',
    countryCode: 'BF',
  },
  {
    id: '0a183644-6730-ea11-a601-281878e92c65',
    countryName: 'Burundi',
    countryCode: 'BI',
  },
  {
    id: '0b183644-6730-ea11-a601-281878e92c65',
    countryName: 'Cambodia',
    countryCode: 'KH',
  },
  {
    id: '0c183644-6730-ea11-a601-281878e92c65',
    countryName: 'Cameroon',
    countryCode: 'CM',
  },
  {
    id: '0d183644-6730-ea11-a601-281878e92c65',
    countryName: 'Canada',
    countryCode: 'CA',
  },
  {
    id: '0e183644-6730-ea11-a601-281878e92c65',
    countryName: 'Cape Verde',
    countryCode: 'CV',
  },
  {
    id: '0f183644-6730-ea11-a601-281878e92c65',
    countryName: 'Cayman Islands',
    countryCode: 'KY',
  },
  {
    id: '10183644-6730-ea11-a601-281878e92c65',
    countryName: 'Central African Republic',
    countryCode: 'CF',
  },
  {
    id: '11183644-6730-ea11-a601-281878e92c65',
    countryName: 'Chad',
    countryCode: 'TD',
  },
  {
    id: '12183644-6730-ea11-a601-281878e92c65',
    countryName: 'Chile',
    countryCode: 'CL',
  },
  {
    id: '13183644-6730-ea11-a601-281878e92c65',
    countryName: 'China',
    countryCode: 'CN',
  },
  {
    id: '14183644-6730-ea11-a601-281878e92c65',
    countryName: 'Christmas Island',
    countryCode: 'CX',
  },
  {
    id: '15183644-6730-ea11-a601-281878e92c65',
    countryName: 'Cocos Islands',
    countryCode: 'CC',
  },
  {
    id: '16183644-6730-ea11-a601-281878e92c65',
    countryName: 'Colombia',
    countryCode: 'CO',
  },
  {
    id: '17183644-6730-ea11-a601-281878e92c65',
    countryName: 'Comoros',
    countryCode: 'KM',
  },
  {
    id: '18183644-6730-ea11-a601-281878e92c65',
    countryName: 'Cook Islands',
    countryCode: 'CK',
  },
  {
    id: '19183644-6730-ea11-a601-281878e92c65',
    countryName: 'Costa Rica',
    countryCode: 'CR',
  },
  {
    id: '1a183644-6730-ea11-a601-281878e92c65',
    countryName: 'Croatia',
    countryCode: 'HR',
  },
  {
    id: '1b183644-6730-ea11-a601-281878e92c65',
    countryName: 'Cuba',
    countryCode: 'CU',
  },
  {
    id: '1c183644-6730-ea11-a601-281878e92c65',
    countryName: 'Curacao',
    countryCode: 'CW',
  },
  {
    id: '1d183644-6730-ea11-a601-281878e92c65',
    countryName: 'Cyprus',
    countryCode: 'CY',
  },
  {
    id: '1e183644-6730-ea11-a601-281878e92c65',
    countryName: 'Czech Republic',
    countryCode: 'CZ',
  },
  {
    id: '1f183644-6730-ea11-a601-281878e92c65',
    countryName: 'Democratic Republic of the Congo',
    countryCode: 'CD',
  },
  {
    id: '21183644-6730-ea11-a601-281878e92c65',
    countryName: 'Denmark',
    countryCode: 'DK',
  },
  {
    id: '40183644-6730-ea11-a601-281878e92c65',
    countryName: 'Djibouti',
    countryCode: 'DJ',
  },
  {
    id: '41183644-6730-ea11-a601-281878e92c65',
    countryName: 'Dominica',
    countryCode: 'DM',
  },
  {
    id: '42183644-6730-ea11-a601-281878e92c65',
    countryName: 'Dominican Republic',
    countryCode: 'DO',
  },
  {
    id: '62183644-6730-ea11-a601-281878e92c65',
    countryName: 'East Timor',
    countryCode: 'TL',
  },
  {
    id: '63183644-6730-ea11-a601-281878e92c65',
    countryName: 'Ecuador',
    countryCode: 'EC',
  },
  {
    id: '64183644-6730-ea11-a601-281878e92c65',
    countryName: 'Egypt',
    countryCode: 'EG',
  },
  {
    id: '65183644-6730-ea11-a601-281878e92c65',
    countryName: 'El Salvador',
    countryCode: 'SV',
  },
  {
    id: '66183644-6730-ea11-a601-281878e92c65',
    countryName: 'Equatorial Guinea',
    countryCode: 'GQ',
  },
  {
    id: '67183644-6730-ea11-a601-281878e92c65',
    countryName: 'Eritrea',
    countryCode: 'ER',
  },
  {
    id: '68183644-6730-ea11-a601-281878e92c65',
    countryName: 'Estonia',
    countryCode: 'EE',
  },
  {
    id: '69183644-6730-ea11-a601-281878e92c65',
    countryName: 'Ethiopia',
    countryCode: 'ET',
  },
  {
    id: '6a183644-6730-ea11-a601-281878e92c65',
    countryName: 'Falkland Islands',
    countryCode: 'FK',
  },
  {
    id: '6b183644-6730-ea11-a601-281878e92c65',
    countryName: 'Faroe Islands',
    countryCode: 'FO',
  },
  {
    id: '6c183644-6730-ea11-a601-281878e92c65',
    countryName: 'Fiji',
    countryCode: 'FJ',
  },
  {
    id: '6d183644-6730-ea11-a601-281878e92c65',
    countryName: 'Finland',
    countryCode: 'FI',
  },
  {
    id: '6e183644-6730-ea11-a601-281878e92c65',
    countryName: 'France',
    countryCode: 'FR',
  },
  {
    id: '70183644-6730-ea11-a601-281878e92c65',
    countryName: 'French Guiana',
    countryCode: 'GF',
  },
  {
    id: '7e183644-6730-ea11-a601-281878e92c65',
    countryName: 'French Polynesia',
    countryCode: 'PF',
  },
  {
    id: '71183644-6730-ea11-a601-281878e92c65',
    countryName: 'Gabon',
    countryCode: 'GA',
  },
  {
    id: '72183644-6730-ea11-a601-281878e92c65',
    countryName: 'Gambia',
    countryCode: 'GM',
  },
  {
    id: '73183644-6730-ea11-a601-281878e92c65',
    countryName: 'Georgia',
    countryCode: 'GE',
  },
  {
    id: '74183644-6730-ea11-a601-281878e92c65',
    countryName: 'Germany',
    countryCode: 'DE',
  },
  {
    id: '75183644-6730-ea11-a601-281878e92c65',
    countryName: 'Ghana',
    countryCode: 'GH',
  },
  {
    id: '76183644-6730-ea11-a601-281878e92c65',
    countryName: 'Gibraltar',
    countryCode: 'GI',
  },
  {
    id: '77183644-6730-ea11-a601-281878e92c65',
    countryName: 'Greece',
    countryCode: 'GR',
  },
  {
    id: '78183644-6730-ea11-a601-281878e92c65',
    countryName: 'Greenland',
    countryCode: 'GL',
  },
  {
    id: '79183644-6730-ea11-a601-281878e92c65',
    countryName: 'Grenada',
    countryCode: 'GD',
  },
  {
    id: '7a183644-6730-ea11-a601-281878e92c65',
    countryName: 'Guadeloupe',
    countryCode: 'GP',
  },
  {
    id: '7b183644-6730-ea11-a601-281878e92c65',
    countryName: 'Guam',
    countryCode: 'GU',
  },
  {
    id: '7c183644-6730-ea11-a601-281878e92c65',
    countryName: 'Guatemala',
    countryCode: 'GT',
  },
  {
    id: '7d183644-6730-ea11-a601-281878e92c65',
    countryName: 'Guernsey',
    countryCode: 'GG',
  },
  {
    id: '61183644-6730-ea11-a601-281878e92c65',
    countryName: 'Guinea',
    countryCode: 'GN',
  },
  {
    id: '60183644-6730-ea11-a601-281878e92c65',
    countryName: 'Guinea-Bissau',
    countryCode: 'GW',
  },
  {
    id: '5f183644-6730-ea11-a601-281878e92c65',
    countryName: 'Guyana',
    countryCode: 'GY',
  },
  {
    id: '5e183644-6730-ea11-a601-281878e92c65',
    countryName: 'Haiti',
    countryCode: 'HT',
  },
  {
    id: '43183644-6730-ea11-a601-281878e92c65',
    countryName: 'Honduras',
    countryCode: 'HN',
  },
  {
    id: '44183644-6730-ea11-a601-281878e92c65',
    countryName: 'Hong Kong',
    countryCode: 'HK',
  },
  {
    id: '45183644-6730-ea11-a601-281878e92c65',
    countryName: 'Hungary',
    countryCode: 'HU',
  },
  {
    id: '46183644-6730-ea11-a601-281878e92c65',
    countryName: 'Iceland',
    countryCode: 'IS',
  },
  {
    id: '47183644-6730-ea11-a601-281878e92c65',
    countryName: 'India',
    countryCode: 'IN',
  },
  {
    id: '48183644-6730-ea11-a601-281878e92c65',
    countryName: 'Indonesia',
    countryCode: 'ID',
  },
  {
    id: '49183644-6730-ea11-a601-281878e92c65',
    countryName: 'Iran',
    countryCode: 'IR',
  },
  {
    id: '4a183644-6730-ea11-a601-281878e92c65',
    countryName: 'Iraq',
    countryCode: 'IQ',
  },
  {
    id: '4b183644-6730-ea11-a601-281878e92c65',
    countryName: 'Ireland',
    countryCode: 'IE',
  },
  {
    id: '4c183644-6730-ea11-a601-281878e92c65',
    countryName: 'Isle of Man',
    countryCode: 'IM',
  },
  {
    id: '4d183644-6730-ea11-a601-281878e92c65',
    countryName: 'Israel',
    countryCode: 'IL',
  },
  {
    id: '4e183644-6730-ea11-a601-281878e92c65',
    countryName: 'Italy',
    countryCode: 'IT',
  },
  {
    id: '4f183644-6730-ea11-a601-281878e92c65',
    countryName: 'Ivory Coast',
    countryCode: 'CI',
  },
  {
    id: '50183644-6730-ea11-a601-281878e92c65',
    countryName: 'Jamaica',
    countryCode: 'JM',
  },
  {
    id: '51183644-6730-ea11-a601-281878e92c65',
    countryName: 'Japan',
    countryCode: 'JP',
  },
  {
    id: '52183644-6730-ea11-a601-281878e92c65',
    countryName: 'Jersey',
    countryCode: 'JE',
  },
  {
    id: '53183644-6730-ea11-a601-281878e92c65',
    countryName: 'Jordan',
    countryCode: 'JO',
  },
  {
    id: '54183644-6730-ea11-a601-281878e92c65',
    countryName: 'Kazakhstan',
    countryCode: 'KZ',
  },
  {
    id: '55183644-6730-ea11-a601-281878e92c65',
    countryName: 'Kenya',
    countryCode: 'KE',
  },
  {
    id: '56183644-6730-ea11-a601-281878e92c65',
    countryName: 'Kiribati',
    countryCode: 'KI',
  },
  {
    id: '57183644-6730-ea11-a601-281878e92c65',
    countryName: 'Kosovo',
    countryCode: 'XK',
  },
  {
    id: '58183644-6730-ea11-a601-281878e92c65',
    countryName: 'Kuwait',
    countryCode: 'KW',
  },
  {
    id: '59183644-6730-ea11-a601-281878e92c65',
    countryName: 'Kyrgyzstan',
    countryCode: 'KG',
  },
  {
    id: '5a183644-6730-ea11-a601-281878e92c65',
    countryName: 'Laos',
    countryCode: 'LA',
  },
  {
    id: '5b183644-6730-ea11-a601-281878e92c65',
    countryName: 'Latvia',
    countryCode: 'LV',
  },
  {
    id: '5c183644-6730-ea11-a601-281878e92c65',
    countryName: 'Lebanon',
    countryCode: 'LB',
  },
  {
    id: '5d183644-6730-ea11-a601-281878e92c65',
    countryName: 'Lesotho',
    countryCode: 'LS',
  },
  {
    id: '04183644-6730-ea11-a601-281878e92c65',
    countryName: 'Liberia',
    countryCode: 'LR',
  },
  {
    id: '03183644-6730-ea11-a601-281878e92c65',
    countryName: 'Libya',
    countryCode: 'LY',
  },
  {
    id: '02183644-6730-ea11-a601-281878e92c65',
    countryName: 'Liechtenstein',
    countryCode: 'LI',
  },
  {
    id: 'c4173644-6730-ea11-a601-281878e92c65',
    countryName: 'Lithuania',
    countryCode: 'LT',
  },
  {
    id: 'a8173644-6730-ea11-a601-281878e92c65',
    countryName: 'Luxembourg',
    countryCode: 'LU',
  },
  {
    id: 'a9173644-6730-ea11-a601-281878e92c65',
    countryName: 'Macau',
    countryCode: 'MO',
  },
  {
    id: 'aa173644-6730-ea11-a601-281878e92c65',
    countryName: 'Macedonia',
    countryCode: 'MK',
  },
  {
    id: 'ab173644-6730-ea11-a601-281878e92c65',
    countryName: 'Madagascar',
    countryCode: 'MG',
  },
  {
    id: 'ac173644-6730-ea11-a601-281878e92c65',
    countryName: 'Malawi',
    countryCode: 'MW',
  },
  {
    id: 'ad173644-6730-ea11-a601-281878e92c65',
    countryName: 'Malaysia',
    countryCode: 'MY',
  },
  {
    id: 'ae173644-6730-ea11-a601-281878e92c65',
    countryName: 'Maldives',
    countryCode: 'MV',
  },
  {
    id: 'af173644-6730-ea11-a601-281878e92c65',
    countryName: 'Mali',
    countryCode: 'ML',
  },
  {
    id: 'b0173644-6730-ea11-a601-281878e92c65',
    countryName: 'Malta',
    countryCode: 'MT',
  },
  {
    id: 'b1173644-6730-ea11-a601-281878e92c65',
    countryName: 'Marshall Islands',
    countryCode: 'MH',
  },
  {
    id: 'b2173644-6730-ea11-a601-281878e92c65',
    countryName: 'Mauritania',
    countryCode: 'MR',
  },
  {
    id: 'b3173644-6730-ea11-a601-281878e92c65',
    countryName: 'Mauritius',
    countryCode: 'MU',
  },
  {
    id: 'b4173644-6730-ea11-a601-281878e92c65',
    countryName: 'Mayotte',
    countryCode: 'YT',
  },
  {
    id: 'b5173644-6730-ea11-a601-281878e92c65',
    countryName: 'Mexico',
    countryCode: 'MX',
  },
  {
    id: 'b6173644-6730-ea11-a601-281878e92c65',
    countryName: 'Micronesia',
    countryCode: 'FM',
  },
  {
    id: 'b7173644-6730-ea11-a601-281878e92c65',
    countryName: 'Moldova',
    countryCode: 'MD',
  },
  {
    id: 'b8173644-6730-ea11-a601-281878e92c65',
    countryName: 'Monaco',
    countryCode: 'MC',
  },
  {
    id: 'b9173644-6730-ea11-a601-281878e92c65',
    countryName: 'Mongolia',
    countryCode: 'MN',
  },
  {
    id: 'ba173644-6730-ea11-a601-281878e92c65',
    countryName: 'Montenegro',
    countryCode: 'ME',
  },
  {
    id: 'bb173644-6730-ea11-a601-281878e92c65',
    countryName: 'Montserrat',
    countryCode: 'MS',
  },
  {
    id: 'bc173644-6730-ea11-a601-281878e92c65',
    countryName: 'Morocco',
    countryCode: 'MA',
  },
  {
    id: 'bd173644-6730-ea11-a601-281878e92c65',
    countryName: 'Mozambique',
    countryCode: 'MZ',
  },
  {
    id: 'be173644-6730-ea11-a601-281878e92c65',
    countryName: 'Myanmar',
    countryCode: 'MM',
  },
  {
    id: 'bf173644-6730-ea11-a601-281878e92c65',
    countryName: 'Nagorno-Karabakh Republic',
    countryCode: 'AM',
  },
  {
    id: 'c0173644-6730-ea11-a601-281878e92c65',
    countryName: 'Namibia',
    countryCode: 'NA',
  },
  {
    id: 'c1173644-6730-ea11-a601-281878e92c65',
    countryName: 'Nauru',
    countryCode: 'NR',
  },
  {
    id: 'c2173644-6730-ea11-a601-281878e92c65',
    countryName: 'Nepal',
    countryCode: 'NP',
  },
  {
    id: 'a7173644-6730-ea11-a601-281878e92c65',
    countryName: 'Netherlands',
    countryCode: 'NL',
  },
  {
    id: 'c3173644-6730-ea11-a601-281878e92c65',
    countryName: 'Netherlands Antilles',
    countryCode: 'AN',
  },
  {
    id: 'a6173644-6730-ea11-a601-281878e92c65',
    countryName: 'New Caledonia',
    countryCode: 'NC',
  },
  {
    id: 'a4173644-6730-ea11-a601-281878e92c65',
    countryName: 'New Zealand',
    countryCode: 'NZ',
  },
  {
    id: '89173644-6730-ea11-a601-281878e92c65',
    countryName: 'Nicaragua',
    countryCode: 'NI',
  },
  {
    id: '8a173644-6730-ea11-a601-281878e92c65',
    countryName: 'Niger',
    countryCode: 'NE',
  },
  {
    id: '8b173644-6730-ea11-a601-281878e92c65',
    countryName: 'Nigeria',
    countryCode: 'NG',
  },
  {
    id: '8c173644-6730-ea11-a601-281878e92c65',
    countryName: 'Niue',
    countryCode: 'NU',
  },
  {
    id: '8d173644-6730-ea11-a601-281878e92c65',
    countryName: 'Norfolk Island',
    countryCode: 'NF',
  },
  {
    id: '8e173644-6730-ea11-a601-281878e92c65',
    countryName: 'North Korea',
    countryCode: 'KP',
  },
  {
    id: '8f173644-6730-ea11-a601-281878e92c65',
    countryName: 'Northern Mariana Islands',
    countryCode: 'MP',
  },
  {
    id: '90173644-6730-ea11-a601-281878e92c65',
    countryName: 'Norway',
    countryCode: 'NO',
  },
  {
    id: '91173644-6730-ea11-a601-281878e92c65',
    countryName: 'Oman',
    countryCode: 'OM',
  },
  {
    id: '92173644-6730-ea11-a601-281878e92c65',
    countryName: 'Pakistan',
    countryCode: 'PK',
  },
  {
    id: '93173644-6730-ea11-a601-281878e92c65',
    countryName: 'Palau',
    countryCode: 'PW',
  },
  {
    id: '94173644-6730-ea11-a601-281878e92c65',
    countryName: 'Palestine',
    countryCode: 'PS',
  },
  {
    id: '95173644-6730-ea11-a601-281878e92c65',
    countryName: 'Panama',
    countryCode: 'PA',
  },
  {
    id: '96173644-6730-ea11-a601-281878e92c65',
    countryName: 'Papua New Guinea',
    countryCode: 'PG',
  },
  {
    id: '97173644-6730-ea11-a601-281878e92c65',
    countryName: 'Paraguay',
    countryCode: 'PY',
  },
  {
    id: '98173644-6730-ea11-a601-281878e92c65',
    countryName: 'Peru',
    countryCode: 'PE',
  },
  {
    id: '99173644-6730-ea11-a601-281878e92c65',
    countryName: 'Philippines',
    countryCode: 'PH',
  },
  {
    id: '9a173644-6730-ea11-a601-281878e92c65',
    countryName: 'Pitcairn',
    countryCode: 'PN',
  },
  {
    id: '9b173644-6730-ea11-a601-281878e92c65',
    countryName: 'Poland',
    countryCode: 'PL',
  },
  {
    id: '9c173644-6730-ea11-a601-281878e92c65',
    countryName: 'Portugal',
    countryCode: 'PT',
  },
  {
    id: '9d173644-6730-ea11-a601-281878e92c65',
    countryName: 'Puerto Rico',
    countryCode: 'PR',
  },
  {
    id: '9e173644-6730-ea11-a601-281878e92c65',
    countryName: 'Qatar',
    countryCode: 'QA',
  },
  {
    id: '9f173644-6730-ea11-a601-281878e92c65',
    countryName: 'Republic of the Congo',
    countryCode: 'CG',
  },
  {
    id: 'a0173644-6730-ea11-a601-281878e92c65',
    countryName: 'Reunion',
    countryCode: 'RE',
  },
  {
    id: 'a1173644-6730-ea11-a601-281878e92c65',
    countryName: 'Romania',
    countryCode: 'RO',
  },
  {
    id: 'a2173644-6730-ea11-a601-281878e92c65',
    countryName: 'Russia',
    countryCode: 'RU',
  },
  {
    id: 'a3173644-6730-ea11-a601-281878e92c65',
    countryName: 'Rwanda',
    countryCode: 'RW',
  },
  {
    id: 'a5173644-6730-ea11-a601-281878e92c65',
    countryName: 'Saint Barthelemy',
    countryCode: 'BL',
  },
  {
    id: 'c5173644-6730-ea11-a601-281878e92c65',
    countryName: 'Saint Helena',
    countryCode: 'SH',
  },
  {
    id: 'e3173644-6730-ea11-a601-281878e92c65',
    countryName: 'Saint Kitts and Nevis',
    countryCode: 'KN',
  },
  {
    id: 'c6173644-6730-ea11-a601-281878e92c65',
    countryName: 'Saint Lucia',
    countryCode: 'LC',
  },
  {
    id: 'e6173644-6730-ea11-a601-281878e92c65',
    countryName: 'Saint Martin',
    countryCode: 'MF',
  },
  {
    id: 'e7173644-6730-ea11-a601-281878e92c65',
    countryName: 'Saint Pierre and Miquelon',
    countryCode: 'PM',
  },
  {
    id: 'e8173644-6730-ea11-a601-281878e92c65',
    countryName: 'Saint Vincent and the Grenadines',
    countryCode: 'VC',
  },
  {
    id: 'e9173644-6730-ea11-a601-281878e92c65',
    countryName: 'Samoa',
    countryCode: 'WS',
  },
  {
    id: 'ea173644-6730-ea11-a601-281878e92c65',
    countryName: 'San Marino',
    countryCode: 'SM',
  },
  {
    id: 'eb173644-6730-ea11-a601-281878e92c65',
    countryName: 'Sao Tome and Principe',
    countryCode: 'ST',
  },
  {
    id: 'ec173644-6730-ea11-a601-281878e92c65',
    countryName: 'Saudi Arabia',
    countryCode: 'SA',
  },
  {
    id: 'ed173644-6730-ea11-a601-281878e92c65',
    countryName: 'Senegal',
    countryCode: 'SN',
  },
  {
    id: 'ee173644-6730-ea11-a601-281878e92c65',
    countryName: 'Serbia',
    countryCode: 'RS',
  },
  {
    id: 'ef173644-6730-ea11-a601-281878e92c65',
    countryName: 'Seychelles',
    countryCode: 'SC',
  },
  {
    id: 'f0173644-6730-ea11-a601-281878e92c65',
    countryName: 'Sierra Leone',
    countryCode: 'SL',
  },
  {
    id: 'f1173644-6730-ea11-a601-281878e92c65',
    countryName: 'Singapore',
    countryCode: 'SG',
  },
  {
    id: 'f2173644-6730-ea11-a601-281878e92c65',
    countryName: 'Sint Maarten',
    countryCode: 'SX',
  },
  {
    id: 'f3173644-6730-ea11-a601-281878e92c65',
    countryName: 'Slovakia',
    countryCode: 'SK',
  },
  {
    id: 'f4173644-6730-ea11-a601-281878e92c65',
    countryName: 'Slovenia',
    countryCode: 'SI',
  },
  {
    id: 'f5173644-6730-ea11-a601-281878e92c65',
    countryName: 'Solomon Islands',
    countryCode: 'SB',
  },
  {
    id: 'f6173644-6730-ea11-a601-281878e92c65',
    countryName: 'Somalia',
    countryCode: 'SO',
  },
  {
    id: 'f7173644-6730-ea11-a601-281878e92c65',
    countryName: 'South Africa',
    countryCode: 'ZA',
  },
  {
    id: 'f8173644-6730-ea11-a601-281878e92c65',
    countryName: 'South Korea',
    countryCode: 'KR',
  },
  {
    id: 'f9173644-6730-ea11-a601-281878e92c65',
    countryName: 'South Sudan',
    countryCode: 'SS',
  },
  {
    id: 'fa173644-6730-ea11-a601-281878e92c65',
    countryName: 'Spain',
    countryCode: 'ES',
  },
  {
    id: 'fb173644-6730-ea11-a601-281878e92c65',
    countryName: 'Sri Lanka',
    countryCode: 'LK',
  },
  {
    id: 'fc173644-6730-ea11-a601-281878e92c65',
    countryName: 'Sudan',
    countryCode: 'SD',
  },
  {
    id: 'fd173644-6730-ea11-a601-281878e92c65',
    countryName: 'Suriname',
    countryCode: 'SR',
  },
  {
    id: 'fe173644-6730-ea11-a601-281878e92c65',
    countryName: 'Svalbard and Jan Mayen',
    countryCode: 'SJ',
  },
  {
    id: 'ff173644-6730-ea11-a601-281878e92c65',
    countryName: 'Swaziland',
    countryCode: 'SZ',
  },
  {
    id: '00183644-6730-ea11-a601-281878e92c65',
    countryName: 'Sweden',
    countryCode: 'SE',
  },
  {
    id: 'e5173644-6730-ea11-a601-281878e92c65',
    countryName: 'Switzerland',
    countryCode: 'CH',
  },
  {
    id: '01183644-6730-ea11-a601-281878e92c65',
    countryName: 'Syria',
    countryCode: 'SY',
  },
  {
    id: 'e4173644-6730-ea11-a601-281878e92c65',
    countryName: 'Taiwan',
    countryCode: 'TW',
  },
  {
    id: 'e2173644-6730-ea11-a601-281878e92c65',
    countryName: 'Tajikistan',
    countryCode: 'TJ',
  },
  {
    id: 'c7173644-6730-ea11-a601-281878e92c65',
    countryName: 'Tanzania',
    countryCode: 'TZ',
  },
  {
    id: 'c8173644-6730-ea11-a601-281878e92c65',
    countryName: 'Thailand',
    countryCode: 'TH',
  },
  {
    id: 'c9173644-6730-ea11-a601-281878e92c65',
    countryName: 'Togo',
    countryCode: 'TG',
  },
  {
    id: 'ca173644-6730-ea11-a601-281878e92c65',
    countryName: 'Tokelau',
    countryCode: 'TK',
  },
  {
    id: 'cb173644-6730-ea11-a601-281878e92c65',
    countryName: 'Tonga',
    countryCode: 'TO',
  },
  {
    id: 'cc173644-6730-ea11-a601-281878e92c65',
    countryName: 'Trinidad and Tobago',
    countryCode: 'TT',
  },
  {
    id: 'cd173644-6730-ea11-a601-281878e92c65',
    countryName: 'Tristan da Cunha',
    countryCode: 'SH',
  },
  {
    id: 'ce173644-6730-ea11-a601-281878e92c65',
    countryName: 'Tunisia',
    countryCode: 'TN',
  },
  {
    id: 'cf173644-6730-ea11-a601-281878e92c65',
    countryName: 'Turkey',
    countryCode: 'TR',
  },
  {
    id: 'd0173644-6730-ea11-a601-281878e92c65',
    countryName: 'Turkmenistan',
    countryCode: 'TM',
  },
  {
    id: 'd1173644-6730-ea11-a601-281878e92c65',
    countryName: 'Turks and Caicos Islands',
    countryCode: 'TC',
  },
  {
    id: 'd2173644-6730-ea11-a601-281878e92c65',
    countryName: 'Tuvalu',
    countryCode: 'TV',
  },
  {
    id: 'd3173644-6730-ea11-a601-281878e92c65',
    countryName: 'U.S. Virgin Islands',
    countryCode: 'VI',
  },
  {
    id: 'd4173644-6730-ea11-a601-281878e92c65',
    countryName: 'Uganda',
    countryCode: 'UG',
  },
  {
    id: 'd5173644-6730-ea11-a601-281878e92c65',
    countryName: 'Ukraine',
    countryCode: 'UA',
  },
  {
    id: 'd6173644-6730-ea11-a601-281878e92c65',
    countryName: 'United Arab Emirates',
    countryCode: 'AE',
  },
  {
    id: 'd7173644-6730-ea11-a601-281878e92c65',
    countryName: 'United Kingdom',
    countryCode: 'GB',
  },
  {
    id: 'd8173644-6730-ea11-a601-281878e92c65',
    countryName: 'United States',
    countryCode: 'US',
  },
  {
    id: 'd9173644-6730-ea11-a601-281878e92c65',
    countryName: 'Uruguay',
    countryCode: 'UY',
  },
  {
    id: 'da173644-6730-ea11-a601-281878e92c65',
    countryName: 'Uzbekistan',
    countryCode: 'UZ',
  },
  {
    id: 'db173644-6730-ea11-a601-281878e92c65',
    countryName: 'Vanuatu',
    countryCode: 'VU',
  },
  {
    id: 'dc173644-6730-ea11-a601-281878e92c65',
    countryName: 'Vatican',
    countryCode: 'VA',
  },
  {
    id: 'dd173644-6730-ea11-a601-281878e92c65',
    countryName: 'Venezuela',
    countryCode: 'VE',
  },
  {
    id: 'de173644-6730-ea11-a601-281878e92c65',
    countryName: 'Vietnam',
    countryCode: 'VN',
  },
  {
    id: 'df173644-6730-ea11-a601-281878e92c65',
    countryName: 'Wallis and Futuna',
    countryCode: 'WF',
  },
  {
    id: 'e0173644-6730-ea11-a601-281878e92c65',
    countryName: 'Western Sahara',
    countryCode: 'EH',
  },
  {
    id: 'e1173644-6730-ea11-a601-281878e92c65',
    countryName: 'Yemen',
    countryCode: 'YE',
  },
  {
    id: '6f183644-6730-ea11-a601-281878e92c65',
    countryName: 'Zambia',
    countryCode: 'ZM',
  },
  {
    id: '7f183644-6730-ea11-a601-281878e92c65',
    countryName: 'Zimbabwe',
    countryCode: 'ZW',
  },
];

export default countries;
