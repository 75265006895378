import React from 'react';
import systemOk from '../../assets/images/alerts/ok.svg';
import { SystemOkCheckmarkProps } from './types';
import { actionTextStyles, checkmarkContainer, checkmarkContent, checkmarkText, checkmarkWhiteText } from './styles';
import { labelStyles } from '../../styles';

const SystemOkCheckmark: React.FC<SystemOkCheckmarkProps> = ({ styles, actionText, infoText }) => (
  <div css={[checkmarkContainer, styles]}>
    <div css={checkmarkContent}>
      <div>
        <img src={systemOk} alt="system ok" data-test-id="checkmark" />
      </div>
      <div css={checkmarkText}>
        <p css={checkmarkWhiteText} data-test-id="system-ok-msg">
          {infoText}
        </p>
        <p css={[labelStyles, actionTextStyles]}>{actionText}</p>
      </div>
    </div>
  </div>
);

export default SystemOkCheckmark;
