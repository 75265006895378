/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay';
import { toGlobalId } from 'graphql-relay';
import { Spinner } from '../../../../components';
import { FormValues } from './types';
import { CustomerSummaryDataQuery } from './__generated__/CustomerSummaryDataQuery.graphql';
import { CustomerSummaryDataMutation } from './__generated__/CustomerSummaryDataMutation.graphql';
import { Summary } from './Summary';
import CustomerSummaryForm from './CustomerSummaryForm';
import { usePageViewed } from '../../../../adobe-launch/hooks';

const CustomerSummaryData = () => {
  usePageViewed('Onboarding:CustomerSummary');
  const { state } = useLocation();
  const claimId = (state as { claimId: string })?.claimId;
  const navigate = useNavigate();
  const data = useLazyLoadQuery<CustomerSummaryDataQuery>(
    graphql`
      query CustomerSummaryDataQuery($id: ID!) {
        clientState {
          onboarding {
            onboardingId
            section {
              name
              address
              postalCode
              city
              country
              state
            }
          }
        }
        node(id: $id) {
          ... on UnclaimedDevice {
            installationNote
          }
        }
      }
    `,
    { id: toGlobalId('UnclaimedDevice', claimId) },
  );
  const installationNote = data?.node?.installationNote || '';

  const [commit, isInFlight] = useMutation<CustomerSummaryDataMutation>(graphql`
    mutation CustomerSummaryDataMutation($id: ID!, $input: OnboardingCreateSectionInput!) {
      onboardingCreateSection(id: $id, input: $input) {
        id
        section {
          name
          address
          postalCode
          city
          country
          state
        }
      }
    }
  `);

  const section = data.clientState?.onboarding?.section;

  const sendFormData = useCallback(
    (formValues: FormValues) => {
      const onboardingRecordID = 'client:clientState:onboarding';

      const { address, city, country, postalCode, name, state: stateRegion } = formValues;

      const onboardingId = data.clientState?.onboarding?.onboardingId;

      if (onboardingId) {
        commit({
          variables: {
            id: onboardingRecordID,
            input: {
              onboardingId,
              name,
              address,
              city,
              country,
              state: stateRegion,
              postalCode,
            },
          },
          onCompleted: () => navigate('/onboarding/installation', { state: { claimId } }),
        });
      }
    },
    [claimId, commit, data.clientState?.onboarding?.onboardingId, navigate],
  );

  const goBack = useCallback(() => {
    navigate('/onboarding/create-or-select-customer', { state: { claimId } });
  }, [claimId, navigate]);

  const initialValues: FormValues = {
    name: section?.name || '',
    address: section?.address || '',
    postalCode: section?.postalCode || '',
    city: section?.city || '',
    state: section?.state || '',
    country: section?.country || '',
  };

  if (isInFlight) return <Spinner />;

  if (section?.name) {
    return <Summary section={section} claimId={claimId} installationNote={installationNote} />;
  }
  return (
    <CustomerSummaryForm
      initialValues={initialValues}
      claimId={claimId}
      sendFormData={sendFormData}
      onBack={goBack}
      installationNote={installationNote}
    />
  );
};

export default CustomerSummaryData;
