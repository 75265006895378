import { css, Theme } from '@emotion/react';

const tooltipIcon = (theme: Theme) => css`
  height: 1.1rem;
  width: 1.1rem;
  fill: ${theme.colors.neutral.neutral0.hex};
`;

const analogTooltip = css`
  flex: 1;
  display: flex;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;
`;

const displayValuesContainerStyles = css`
  display: flex;
  flex-direction: row;
  &:only-child {
    gap: 0.25rem;
  }
`;

const initialValueStyles = (theme: Theme) => css`
  color: ${theme.colors.neutral.neutral600.hex};
`;

export { tooltipIcon, analogTooltip, displayValuesContainerStyles, initialValueStyles };
