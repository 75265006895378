import { toGlobalId } from 'graphql-relay';
import React, { useCallback, useState } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import usePageViewed from '../../../adobe-launch/hooks/usePageViewed';
import { DDACQuery } from './__generated__/DDACQuery.graphql';
import Events from '../Events';
import DeviceLatestSync from '../DeviceLatestSync/DeviceLatestSync';
import { TParams, GeniIdentity } from '../types';
import { useAccount } from '../../../providers/AccountProvider';
import Reports from '../Reports/Reports';
import MoreDataTab from './MoreDataTab/MoreDataDDAC';
import RemoteControl from './RemoteControl/RemoteControlData';
import OverviewChemPairingDDAC from './OverviewChemPairingDDAC';
import useUnixStartEndDate from '../useUnixStartEndDate';
import DevicePage from '../Components/DevicePage';
import { NoDataAvailableMessage } from '../../../components';
import useFetchRemoteControlData from './RemoteControl/hooks/useFetchRemoteControlData';

const defaultDDACGeniIdentity: GeniIdentity = { familyCode: 30, unitType: 4, unitVersion: 1 };

const DDAC = () => {
  usePageViewed('DDAC');
  const { fetchRemoteControlData, fetchRemoteControlDataRef } = useFetchRemoteControlData();
  const { id } = useParams<TParams>();
  const [currentTab, setCurrentTab] = useState('overview');
  const { account } = useAccount();
  const { t } = useTranslation();
  const { startDate, endDate } = useUnixStartEndDate();

  const goToEvents = useCallback(() => setCurrentTab('events'), []);

  const changeTab = useCallback(
    (tab: string) => {
      switch (tab) {
        case 'remote':
          // Every time we navigate to remote control
          // We want to make sure we don't use any existing state
          // This is because existing state might be stale and we only want to use the most updated state
          fetchRemoteControlData('network-only');
          break;
        default:
          break;
      }
      setCurrentTab(tab);
    },
    [fetchRemoteControlData],
  );

  const data = useLazyLoadQuery<DDACQuery>(
    graphql`
      query DDACQuery($id: ID!, $count: Int, $cursor: String) {
        node(id: $id) {
          ... on Device {
            uuid
            name
            customerName
            deviceTwinId
            ...DeviceLatestSync_device
            ...DeviceEvents_device
          }
        }
      }
    `,
    { id: toGlobalId('Device', id || ''), count: 30 },
  );

  const { node } = data;
  if (!node) return <NoDataAvailableMessage message={t('WP.ERROR.NO_DATA')} />;
  if (!startDate || !endDate) return null;

  return (
    <DevicePage
      currentTab={currentTab}
      setCurrentTab={changeTab}
      customerName={`${node.customerName}`}
      installationName={`${node.name}`}
      LastSync={<DeviceLatestSync device={node} />}
      tabs={{
        overview: {
          analyticsName: 'Overview',
          linkLabel: t('WP.OVERVIEW.OVERVIEW'),
          page: id ? (
            <OverviewChemPairingDDAC id={id} startDate={startDate} endDate={endDate} goToEvents={goToEvents} />
          ) : null,
        },
        events: {
          linkLabel: t('WP.EVENTS.TAB.ALL_EVENTS'),
          analyticsName: 'events',
          page: <Events device={node} />,
        },
        'more-data': {
          linkLabel: t('WP.MORE.DATA.TAB_TITLE'),
          analyticsName: 'More Data',
          page: <MoreDataTab endDate={endDate} startDate={startDate} geniIdentity={defaultDDACGeniIdentity} />,
        },
        remote: {
          linkLabel: t('WP.REMOTE.REMOTE'),
          analyticsName: 'Remote',
          page: fetchRemoteControlDataRef ? (
            <RemoteControl queryRef={fetchRemoteControlDataRef} deviceTwinId={node.deviceTwinId || null} />
          ) : null,
        },
        reports: {
          linkLabel: t('WP.REPORTS.REPORTS'),
          analyticsName: 'REPORTS',
          page: (
            <Reports deviceTwinId={node.deviceTwinId} deviceId={node.uuid} accountId={account.uuid} pumpType="DDA-C" />
          ),
        },
      }}
    />
  );
};

export default DDAC;
