/**
 * @generated SignedSource<<05cbec95dc2e5a9ba748585c05213f02>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InfoContainerDDACData_device$data = {
  readonly descendantDeviceInfo: {
    readonly productInfo?: {
      readonly typeKey: string | null;
      readonly productNumber: string | null;
      readonly firmware: string | null;
      readonly pumpName: string | null;
      readonly serialNumber: string | null;
    } | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"useInfoContainerData_device">;
  readonly " $fragmentType": "InfoContainerDDACData_device";
};
export type InfoContainerDDACData_device$key = {
  readonly " $data"?: InfoContainerDDACData_device$data;
  readonly " $fragmentSpreads": FragmentRefs<"InfoContainerDDACData_device">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InfoContainerDDACData_device",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useInfoContainerData_device"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "descendantDeviceInfo",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DDACProductInfo",
              "kind": "LinkedField",
              "name": "productInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "typeKey",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "productNumber",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firmware",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "pumpName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "serialNumber",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "DDAC",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};

(node as any).hash = "8a69c4f563906100ba4960ec7fb1630c";

export default node;
