import React from 'react';
import { css } from '@emotion/react';
import { ProgressIndicatorProps } from './types';

const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({ children, className }) => (
  <div
    className={className}
    css={css`
      display: grid;
      grid-template-columns: repeat(${React.Children.count(children)}, 1fr);
    `}
  >
    {children}
  </div>
);
export default ProgressIndicator;
