import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoItem } from '../../../../../components';
import ReadField from './ReadField';

const ShowActiveReadField = ({
  children,
  value,
  label,
  showActive,
  isHidden = false,
  hideDivider = false,
  testId = `${label}`,
}: {
  value: string;
  label: string;
  children: JSX.Element;
  showActive: boolean;
  isHidden?: boolean;
  hideDivider?: boolean;
  testId?: string;
}) => {
  const { t } = useTranslation();
  return (
    <ReadField isHidden={isHidden} value={value} noValueLabel={label} hideDivider={hideDivider}>
      {showActive ? (
        children
      ) : (
        <InfoItem testId={`${testId}-inactive`} noBorder label={t(label)} inActive value={value} />
      )}
    </ReadField>
  );
};

export default ShowActiveReadField;
