import { css, SerializedStyles } from '@emotion/react';
import dsmMediaQuery from '../../utils/media-queries/media-queries';
import { NoAvailableDataTextSize } from './types';

const sizeMap = (size: NoAvailableDataTextSize) => {
  switch (size) {
    case 'text':
      return 1.5;
    case 'title':
      return 2;
    default:
      return 2;
  }
};
const noDataStyles = (size: NoAvailableDataTextSize): SerializedStyles => css`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;

  ${dsmMediaQuery('medium device')} {
    font-size: ${sizeMap(size)}rem;
  }
`;

export default noDataStyles;
