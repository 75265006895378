import React from 'react';
import { Tooltip } from '../../../../../../components';
import { tooltipIcon, summaryStyles, nonproportionalwarning } from '../styles';
import { paragraphBaseStyles } from '../../../../../../styles';
import tooltip from '../../../../../../assets/images/tooltip.svg';

const NonProportionalDosing = ({
  label,
  tooltiptext,
  alarmtext,
}: {
  label: string;
  tooltiptext: string;
  alarmtext: string;
}) => (
  <div css={[summaryStyles, { maxWidth: '12.5rem' }]}>
    <div>{label}</div>
    <div css={[paragraphBaseStyles, nonproportionalwarning]}>
      <Tooltip placement="top" content={tooltiptext} size="large" trigger="mouse-enter">
        <img css={tooltipIcon} src={tooltip} alt="tooltip" />
      </Tooltip>
      <span>{alarmtext}</span>
    </div>
  </div>
);

export default NonProportionalDosing;
