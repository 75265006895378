import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tile, TileHeader } from '../../../../../components/Tiles';
import { NoDataAvailableMessage } from '../../../../../components';

const NoPumpStatusData = () => {
  const { t } = useTranslation();
  return (
    <Tile
      Header={<TileHeader text={t('WP.OVERVIEW.PUMP_STATUS')} />}
      Body={<NoDataAvailableMessage size="text" message={t('WP.ERROR.NO_DATA')} />}
    />
  );
};

export default NoPumpStatusData;
