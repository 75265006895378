const pageViewed = (
  name: string,
  channelTitle?: string,
  url = `${window.location.host + window.location.pathname}`,
): void => {
  const state = window.digitalData[window.digitalData.length - 1];
  window.digitalData.push({
    ...state,
    event: 'Page Load',
    data: {
      ...state?.data,
      pageInfo: {
        ...state?.data?.pageInfo,
        pageName: name,
        hier1: url,
        channelTitle: channelTitle ?? name,
      },
    },
  });
};

export default pageViewed;
