import React, { useRef } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { css, useTheme } from '@emotion/react';
import { VolumetricConcentrationGraphProps } from './types';
import useDefaultOptions from './useDefaultOptions';

const VolumetricConcentrationGraph = ({
  height,
  data,
  labels,
  thresholds,
  target,
}: VolumetricConcentrationGraphProps) => {
  const ref = useRef<HighchartsReact.RefObject>(null);
  const binDataCopy = [...data];
  const {
    colors: { primaryText },
  } = useTheme();
  const minExtreme = binDataCopy.shift();
  const maxExtreme = binDataCopy.pop();

  const extremes =
    data && data.length > 0
      ? {
          max: {
            label: `${maxExtreme?.bucket}`,
            value: `${maxExtreme?.value.toFixed(1)}${labels.postFixY}`,
          },
          min: { label: minExtreme?.bucket || '', value: `${minExtreme?.value.toFixed(1)}${labels.postFixY}` },
        }
      : undefined;

  const defaultOptions = useDefaultOptions({ labels, thresholds, extremes, target, numberOfbins: binDataCopy.length });
  return (
    <div
      // When using grid, we also need to make the direct parent to highcharts be a grid. Otherwise we can resizing issues
      // https://github.com/highcharts/highcharts/issues/9491
      css={css`
        display: grid;
        .highcharts-container,
        .highcharts-container svg {
          display: grid;
        }
      `}
    >
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          ...defaultOptions,
          chart: {
            ...defaultOptions.chart,
            height,
          },
          xAxis: {
            categories: binDataCopy.map((bin) => bin.bucket),
            ...defaultOptions.xAxis,
          },
          series: [
            {
              animation: false,
              data: binDataCopy.map((bin) => ({
                y: bin.value,
                name: bin.bucket,
                color: bin.value === 0 ? primaryText : undefined,
              })),
            },
          ],
        }}
        constructorType="chart"
        ref={ref}
      />
    </div>
  );
};

export default VolumetricConcentrationGraph;
