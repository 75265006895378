import * as React from 'react';
import { IconProps } from './types';

const WarningTriangleOutline: React.FC<IconProps> = ({ color, size, styles, x, y }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" css={styles} x={x} y={y} height={size} width={size}>
    <path fill={color} d="M15 13h2v7h-2zM16 22a1.5 1.5 0 101.53 1.49A1.49 1.49 0 0016 22z" />
    <path fill={color} d="M16 2L.26 29h31.48zm0 4l12.26 21H3.74z" />
  </svg>
);

export default WarningTriangleOutline;
