import { PreloadedQuery } from 'react-relay';
import { useDisplayRemoteControlDataQuery as RemoteControlDataQuery } from './hooks/__generated__/useDisplayRemoteControlDataQuery.graphql';
import { OptionalRuleViolation } from '../types';

interface minMax {
  min?: number | null;
  max?: number | null;
}

export interface AnalogSettings {
  proportionalValidation: OptionalRuleViolation;
  lowerInput: {
    electricCurrent: {
      quantity?: number | null;
      unit?: string | null;
    };
    dosingVolume: {
      quantity?: number | null;
      unit?: string | null;
    };
  };
  higherInput: {
    electricCurrent: {
      quantity?: number | null;
      unit?: string | null;
    };
    dosingVolume: {
      quantity?: number | null;
      unit?: string | null;
    };
  };
}

export interface RemoteControlSettings {
  pumpCapacity: {
    min: number | null | undefined;
    max: number | null | undefined;
    unit: string | null | undefined;
  };
  manualDosingFlowUnit: string | null;
  maxCapacityUnit: string | null;
  maxPressureUnit: string | null;
  minPressureUnit: string | null;
  remoteControlEnabled: RemoteControlEnabled | null;
  pressureMinRange: number | null | undefined;
  pressureMaxRange: number | null | undefined;
  pumpVariant: PumpVariant | null;
  stoppedBy: StoppedByState | null;
  pulseMinMax: minMax;
  analog: AnalogSettings;
}

export interface FormValues {
  maxCapacity: string | null;
  slowMode: SlowModeValues | null;
  pumpState: PumpState | null;
  controlMode: ControlMode | null;
  mlPerPulse: string | null;
  manualDosingFlowValue: string | null;
  pulseMemory: boolean | null;
  autoDeaeration: boolean | null;
  flowControl: boolean | null;
  flowControlAutoFlowAdapt: boolean | null;
  maxPressureValue: number | null;
  minPressureValue: number | null;
  minPressureAsAlarm: boolean | null;
  flowControlDelay: FlowControlDelay | null;
  flowControlSensitivity: FlowSensitivityValues | null;
  stopAfterPowerFailure: boolean | null;
  analogDosingFlowHigher: string | null;
  analogDosingFlowLower: string | null;
  analogInputHigher: string | null;
  analogInputLower: string | null;
}

export interface UseSchemaValidationProps {
  pumpCapacity: RemoteControlSettings['pumpCapacity'];
  pulseMinMax: RemoteControlSettings['pulseMinMax'];
}

export const getFormValuesKey = (key: keyof FormValues) => key;

export interface RemoteControlProps {
  data: FormValues;
  remoteControlSettings: RemoteControlSettings;
  onFormSubmit: (values: FormValues) => Promise<void | readonly string[]>;
  isLoading: boolean;
}

// eslint-disable-next-line no-shadow
export enum ControlModeOptions {
  MANUAL = 'MANUAL',
  PULSE = 'PULSE',
  ANALOG_0_20 = 'ANALOG_0_20',
  ANALOG_4_20 = 'ANALOG_4_20',
  INTERVAL_BATCH = 'INTERVAL_BATCH',
  PULSE_BATCH = 'PULSE_BATCH',
  WEEK_TIMER_BATCH = 'WEEK_TIMER_BATCH',
  EXTERNAL_PULSE_BATCH = 'EXTERNAL_PULSE_BATCH',
}

export const supportedControlModeOptions = [
  ControlModeOptions.MANUAL,
  ControlModeOptions.PULSE,
  ControlModeOptions.ANALOG_0_20,
  ControlModeOptions.ANALOG_4_20,
];

export const unsupportedControlModeOptions = [
  'ANALOG',
  'TIMER',
  'BATCH',
  ControlModeOptions.INTERVAL_BATCH,
  ControlModeOptions.EXTERNAL_PULSE_BATCH,
  ControlModeOptions.WEEK_TIMER_BATCH,
  ControlModeOptions.PULSE_BATCH,
];

// eslint-disable-next-line no-shadow
export enum PumpStateOptions {
  'START' = 'START',
  'STOP' = 'STOP',
}

// eslint-disable-next-line no-shadow
export enum SlowModeOptions {
  'OFF' = 'OFF',
  'QUARTER' = '25',
  'HALF' = '50',
}

export const mapSlowModeToValue = (slowModeValue: SlowModeValues | null) => {
  switch (slowModeValue) {
    case 'OFF':
      return 1;
    case 'HALF':
      return 0.5;
    case 'QUARTER':
      return 0.25;
    default:
      return 1;
  }
};

export const mapSlowMode = (slowModeValue: SlowModeValues | null) => {
  switch (slowModeValue) {
    case 'OFF':
      return SlowModeOptions.OFF;
    case 'HALF':
      return SlowModeOptions.HALF;
    case 'QUARTER':
      return SlowModeOptions.QUARTER;
    default:
      return null;
  }
};

// eslint-disable-next-line no-shadow
export enum FlowSensitivtyOptions {
  'LOW' = 'LOW',
  'MEDIUM' = 'MEDIUM',
  'HIGH' = 'HIGH',
}

// eslint-disable-next-line no-shadow
export enum FlowDelayOptions {
  'SHORT' = 'SHORT',
  'MEDIUM' = 'MEDIUM',
  'LONG' = 'LONG',
}

export type SlowModeValues = keyof typeof SlowModeOptions;
export type FlowControlDelay = keyof typeof FlowDelayOptions;
export type FlowSensitivityValues = keyof typeof FlowSensitivtyOptions;
export type PumpVariant = 'FCM' | 'FC' | 'AR';
export type PumpState = keyof typeof PumpStateOptions;
export type ControlMode = keyof typeof ControlModeOptions;
export type RemoteControlEnabled = boolean;

export interface GeniValue {
  name: string;
  value: string;
  unit: string;
}

export type LocalRemoteAction =
  | { type: 'SET_FLOW_CONTROL'; value: FormValues['flowControl'] }
  | { type: 'SET_PUMP_STATE'; value: FormValues['pumpState'] }
  | { type: 'SET_INITIAL_STATE' }
  | { type: 'SET_CONTROL_MODE'; value: FormValues['controlMode'] };

// // This type is a way to extect the value based on the type, so that we dont have to define seperate types for each value
// export type PumpActionInput<ActionType> = PumpAction extends { type: ActionType; value: infer P } ? P : never;

export interface SetState {
  setFlowControl: (value: FormValues['flowControl']) => void;
  setControlMode: (value: FormValues['controlMode']) => void;
  cancelChanges: () => void;
}

// TODO: We are having same types here as we have in PumpState...
export type StoppedByState =
  | 'DISPLAY'
  | 'EXTERNAL'
  | 'DISPLAY_AND_EXTERNAL'
  | 'REMOTE'
  | 'DISPLAY_AND_BUS'
  | 'EXTERNAL_AND_BUS'
  | 'DISPLAY_EXTERNAL_AND_BUS'
  | undefined;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormValuesKeys = Partial<Record<keyof FormValues, any>>;

export type RemoteQueryRef = PreloadedQuery<RemoteControlDataQuery, Record<string, unknown>>;
