import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RefetchFnDynamic } from 'react-relay/hooks';
import { Dialog, Portal } from '../../../components';
import { Alert } from './types';
import { grid, headerContainer, item, alertHeader, headerItem } from './styles';
import AlertsListItem from './AlertListItem';
import { DeviceEvents_device$key } from './__generated__/DeviceEvents_device.graphql';
import { DeviceEventsPaginationQuery } from './__generated__/DeviceEventsPaginationQuery.graphql';
// eslint-disable-next-line no-empty-pattern
const AlertsList = ({
  alerts,
  refetch,
}: {
  alerts: Alert[];
  refetch: RefetchFnDynamic<DeviceEventsPaginationQuery, DeviceEvents_device$key>;
}) => {
  const { t } = useTranslation();

  return (
    <div css={grid}>
      <div css={headerContainer}>
        <div css={[item, headerItem]}>{t('WP.ALERTS.GRID_TITLE')}</div>
        <div css={[item, headerItem]}>{t('WP.ALERTS.GRID_NAME')}</div>
        <div css={[item, headerItem]}>{t('WP.ALERTS.GRID_INITIATOR')}</div>
        <div css={[alertHeader, item, headerItem]}>{t('WP.ALERTS.GRID_DATE')}</div>
      </div>
      {alerts.map((alert) => (
        <AlertsListItem key={alert.alertCorrelationId} alert={alert} />
      ))}
    </div>
  );
};

export default AlertsList;
