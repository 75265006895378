/**
 * @generated SignedSource<<1cf7784901a988a48d30cef8f59b0fec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppNavigation_data$data = {
  readonly accounts: {
    readonly " $fragmentSpreads": FragmentRefs<"MainNavigation_accounts">;
  } | null;
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"MainNavigation_account">;
  } | null;
  readonly " $fragmentType": "AppNavigation_data";
};
export type AppNavigation_data$key = {
  readonly " $data"?: AppNavigation_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppNavigation_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "accountId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppNavigation_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountConnection",
      "kind": "LinkedField",
      "name": "accounts",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MainNavigation_accounts"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "accountId"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MainNavigation_account"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "3aafb555e54bdf42a6afa0a6260899e4";

export default node;
