import React from 'react';
import { useTheme } from '@emotion/react';
import { loaderStyles } from './styles';

export interface LoaderProps {
  size?: 'small' | 'large';
  className?: string;
}

const Loader: React.FC<LoaderProps> = ({ size = 'large', className }) => {
  const theme = useTheme();
  return (
    <div css={[loaderStyles(size, theme), className]}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default Loader;
