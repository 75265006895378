import React, { PropsWithChildren } from 'react';
import Bowser from 'bowser';
import logo from '../../assets/images/logo-with-text.svg';
import Waves from '../Waves/Waves';
import { logoStyles, messageStyles, unsupportedBrowserStyles, wavesStyles } from './styles';

const browser = Bowser.getParser(window.navigator.userAgent);
const isValidBrowser = browser.satisfies({
  chrome: '>=90',
  edge: '>=90',
  mobile: {
    safari: '>=14',
  },
});

const UnsupportedBrowser: React.FC = () => (
  <div css={unsupportedBrowserStyles}>
    <img src={logo} alt="Grundfos" css={logoStyles} />
    <div css={messageStyles}>
      SDCS Chempairing does not support this browser. Please use a newer version of Google Chrome or Microsoft Edge.
    </div>
    <Waves styles={wavesStyles} />
  </div>
);

const BrowserSupport: React.FC<PropsWithChildren<unknown>> = ({ children }) =>
  // eslint-disable-next-line react/jsx-no-useless-fragment
  isValidBrowser ? <>{children}</> : <UnsupportedBrowser />;

export default BrowserSupport;
