import React from 'react';
import { useTranslation } from 'react-i18next';
import { paragraphBoldStyles } from '../../../../styles';
import { Tile, TileHeader, TileBody, InfoItem } from '../../../../components';
import {
  containerStyles,
  twoColumsTileStyles,
  infoItemStyles,
  leftColumnStyles,
  removeLastBottomBorder,
  rightColumnStyles,
  topPadding,
  removeBorder,
  tileStyles,
} from './styles';
import { formatPulseRateValue, flowMeterLabel } from '../../utils';
import ChemicalContainer from './components/ChemicalContainer';
import ProportionalDosingData from './components/ProportionalDosingData';

import OperatingInfo from './components/OperatingInfo';
import ProportionalLastHour from './components/ProportionalLastHour';
import ProportionalConcentrationTarget from './components/ProportionalConcentrationTarget';
import TotalVolumeProcessed from './components/TotalVolumeProcessed';
import { TotalDosedChemical } from './components';
import { MoreDataTabPulseProps } from './types';

const MoreDataTabPulse: React.FC<MoreDataTabPulseProps> = ({
  chemicalContainer,
  totalDosedChemical,
  motorOperatingHours,
  totalOperatingHours,
  dosedChecmicalLastHour,
  dosedChecmicalLastHourUnit,
  dosedProcessVolLastHour,
  actualProportionalConcentration,
  targetProportionalConcentration,
  totalVolumeProcessLiquid,
  meterType,
  mlPerPulse,
  pulseRate,
}) => {
  const { t } = useTranslation();

  return (
    <div css={containerStyles}>
      <div css={leftColumnStyles}>
        <ProportionalDosingData>
          <div>
            <ProportionalLastHour
              dosedChecmicalLastHour={dosedChecmicalLastHour}
              dosedChecmicalLastHourUnit={dosedChecmicalLastHourUnit}
              dosedProcessVolLastHour={dosedProcessVolLastHour}
              actualProportionalConcentration={actualProportionalConcentration}
            />
          </div>
          <div>
            <ProportionalConcentrationTarget targetProportionalConcentration={targetProportionalConcentration} />
            <div>
              <InfoItem
                css={infoItemStyles}
                label={t(
                  flowMeterLabel(
                    'WP.MORE.DATA.FLOW_TURBINE',
                    'WP.MORE.DATA.WATER_METER',
                    'WP.MORE.DATA.FLOW_METER_PULSE_RATE',
                    meterType,
                  ),
                )}
                value={meterType && pulseRate?.quantity ? formatPulseRateValue(pulseRate.quantity, meterType) : '-'}
                unit={pulseRate?.unit || ''}
              />
              <InfoItem
                css={[infoItemStyles, removeBorder]}
                label={t('WP.MORE.DATA.DDA_VOL_PER_PULSE')}
                value={mlPerPulse?.toFixed(4)}
                unit={t('WP.COMMON.ML_PER_PULSE')}
              />
            </div>
          </div>
        </ProportionalDosingData>
        <ChemicalContainer chemicalContainer={chemicalContainer} />
      </div>
      <div css={rightColumnStyles}>
        <Tile
          styles={tileStyles}
          Header={<TileHeader text={t('WP.MORE.DATA.DOSING_COUNTER_TILE')} />}
          Body={
            <TileBody css={[topPadding, twoColumsTileStyles]}>
              <div css={removeLastBottomBorder}>
                <p css={paragraphBoldStyles}>{t('WP.MORE.DATA.VOLUME')}</p>
                <TotalDosedChemical totalDosedChemical={totalDosedChemical} />
                <TotalVolumeProcessed totalVolumeProcessLiquid={totalVolumeProcessLiquid} />
              </div>
              <OperatingInfo motorOperatingHours={motorOperatingHours} totalOperatingHours={totalOperatingHours} />
            </TileBody>
          }
        />
      </div>
    </div>
  );
};

export default MoreDataTabPulse;
