import { css, SerializedStyles, Theme } from '@emotion/react';

export const spinnerStyles = (size: number | string = 6.25): SerializedStyles => css`
  width: ${size}rem;
  height: ${size}rem;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const doubleBounceStyles = css`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #5bc0ec;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;

  @-webkit-keyframes sk-bounce {
    0%,
    100% {
      -webkit-transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bounce {
    0%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }
    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
`;

export const doubleBounceOneStyles = css``;

export const doubleBounceTwoStyles = css`
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
`;
