import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tile, TileBody, TileHeader } from '../../../../../components';
import { topPadding, twoColumsTileStyles } from '../styles';

const ManualData = ({ children }: { children: JSX.Element[] | JSX.Element }) => {
  const { t } = useTranslation();
  return (
    <Tile
      Header={<TileHeader text={t('WP.MORE.DATA.DOSED_VOL_TILE')} />}
      Body={
        <TileBody css={topPadding}>
          <div css={twoColumsTileStyles}>{children}</div>
        </TileBody>
      }
    />
  );
};

export default ManualData;
