import React, { useRef } from 'react';
import { useOnClickOutside } from '../../hooks';
import Portal from '../Portal/Portal';
import { offcanvasOverlayStyles, offcanvasStyles, offcanvasWrapperStyles } from './styles';
import { OffcanvasProps } from './types';

const Offcanvas: React.FC<OffcanvasProps> = ({
  children,
  dataTestId,
  isOpen,
  noOverlay,
  onClose,
  styles,
  stylesOverlay,
  stylesWrapper,
  toggleIsOpen,
}) => {
  const DOMNode = useRef<HTMLDivElement>(null);
  // TODO: Improve typings in the useOnClickOutside hook
  useOnClickOutside(DOMNode, (e) => onClose(e as any));

  return (
    <div css={stylesWrapper ? [offcanvasWrapperStyles(isOpen), stylesWrapper] : offcanvasWrapperStyles(isOpen)}>
      <div css={styles ? [offcanvasStyles, styles] : offcanvasStyles} data-test-id={dataTestId} ref={DOMNode}>
        {children}
        {isOpen && !noOverlay && (
          <Portal>
            <div css={stylesOverlay ? [offcanvasOverlayStyles, stylesOverlay] : offcanvasOverlayStyles} />
          </Portal>
        )}
      </div>
    </div>
  );
};

export default Offcanvas;
