import React from 'react';
import { css } from '@emotion/react';
import DosedVolume from '../Overview/OverviewChemPairing/DosedVolume/DosedVolume';
import { ControlMode } from './types';
import { OverviewChemPairingDDACQuery$data } from './__generated__/OverviewChemPairingDDACQuery.graphql';
import { Tile } from '../../../components';
import VolumetricConcentrationData from '../Overview/OverviewChemPairing/VolumetricConcentration/components/VolumetricConcentrationData';

const EmptyVolumetricTile = () => (
  <Tile
    key="volumetric"
    styles={css`
      grid-area: volumetric;
    `}
  />
);

const GraphDosingComponent: React.FC<{
  controlMode: ControlMode | null | undefined;
  data: OverviewChemPairingDDACQuery$data;
}> = ({ controlMode, data }) => {
  switch (controlMode) {
    case 'MANUAL':
      return <DosedVolume device={data} />;
    case 'ANALOG_0_20':
    case 'ANALOG_4_20':
      return <VolumetricConcentrationData device={data} controlMode={controlMode} />;
    case 'PULSE':
      return <VolumetricConcentrationData device={data} controlMode={controlMode} />;
    default:
      return <EmptyVolumetricTile />;
  }
};

export default GraphDosingComponent;
