/**
 * @generated SignedSource<<21aeda388860f17f0772024fcffc5514>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TimeToServiceDDAC_device$data = {
  readonly latestSync: {
    readonly outOfSync: boolean | null;
  } | null;
  readonly descendantDeviceInfo: {
    readonly timeToService?: {
      readonly hoursToNextService: number | null;
      readonly monthsToNextService: number | null;
      readonly hoursThreshold: number | null;
      readonly hoursMax: number | null;
      readonly monthsThreshold: number | null;
      readonly monthsMax: number | null;
    } | null;
  } | null;
  readonly " $fragmentType": "TimeToServiceDDAC_device";
};
export type TimeToServiceDDAC_device$key = {
  readonly " $data"?: TimeToServiceDDAC_device$data;
  readonly " $fragmentSpreads": FragmentRefs<"TimeToServiceDDAC_device">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TimeToServiceDDAC_device",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LatestSync",
      "kind": "LinkedField",
      "name": "latestSync",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "outOfSync",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "descendantDeviceInfo",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TimeToService",
              "kind": "LinkedField",
              "name": "timeToService",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hoursToNextService",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "monthsToNextService",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hoursThreshold",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hoursMax",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "monthsThreshold",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "monthsMax",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "DDAC",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};

(node as any).hash = "eca5d231db6fbf5574df6298c7d69e23";

export default node;
