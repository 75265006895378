import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components';
import useQueryStringParam from '../../../hooks/useQueryParameters/useQueryStringParam';
import { useAccount } from '../../../providers/AccountProvider';
import CorrelatedError from '../../../utils/errors/CorrelatedError';
import { breadTextStyles, notFoundErrorFallbackStyles, subHeaderStyles } from './styles';

interface NotFoundErrorFallbackProps extends FallbackProps {
  error: CorrelatedError;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NotFoundErrorFallback: React.FC<NotFoundErrorFallbackProps> = ({ error, resetErrorBoundary }) => {
  const navigate = useNavigate();
  const [_, setClaimId] = useQueryStringParam('claimId');
  const { account } = useAccount();
  const { code } = error.errors[0].extensions;
  const { t } = useTranslation();
  const onClick = () => {
    setClaimId();
    navigate('/');
  };

  return (
    <div css={notFoundErrorFallbackStyles}>
      <h1>{t('WP.ONBOARDING.ERROR_TITLE')}</h1>
      <h2 css={subHeaderStyles}>
        {t('WP.ONBOARDING.ERROR_CODE')}: {code}
      </h2>
      <div css={breadTextStyles}>{t('WP.ONBOARDING.ERROR_DESCRIPTION')}</div>
      {account && (
        <Button type="button" variant="primary" onClick={onClick}>
          {t('WP.ONBOARDING.ERROR_GO_OVERVIEW')}
        </Button>
      )}
    </div>
  );
};

export default NotFoundErrorFallback;
