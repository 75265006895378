import React from 'react';
import { useTranslation } from 'react-i18next';
import { container, heading, linkStyles, subHeading, text } from './styles';

const NoAccount = () => {
  const { t } = useTranslation();
  return (
    <div css={container}>
      <h1 css={heading}>{t('WP.ONBOARDING.ERROR_WELCOME')}</h1>
      <h2 css={subHeading}>{t('WP.ONBOARDING.ERROR_NO_ACCOUNT')}</h2>
      <p css={text}>
        {t('WP.ONBOARDING.ERROR_NO_ACCOUNT_TEXT_1')}{' '}
        <a css={linkStyles} href={`mailto:${t('WP.COMMON.CSM_EMAIL_GENERAL')}`}>
          {t('WP.COMMON.CSM_EMAIL_GENERAL')}
        </a>{' '}
        {t('WP.ONBOARDING.ERROR_NO_ACCOUNT_TEXT_2')}
      </p>
    </div>
  );
};

export default NoAccount;
