import React from 'react';
import { useTheme } from '@emotion/react';
import { buttonStyles } from './styles';
import { ButtonProps } from './types';

const Button: React.FC<ButtonProps> = ({ variant, type, children, onClick, disabled, className, formId, testId }) => {
  const theme = useTheme();

  return (
    <button
      data-testid={testId || type}
      className={className}
      disabled={disabled}
      onClick={onClick}
      type={type === 'button' ? 'button' : 'submit'}
      css={buttonStyles(theme, variant)}
      form={formId}
    >
      {children}
    </button>
  );
};

export default Button;
