import { css } from '@emotion/react';
import dsmMediaQuery from '../../utils/media-queries/media-queries';

const unsupportedBrowserStyles = css`
  margin: 2rem auto;
  max-width: 20rem;
  text-align: center;
  padding: 1rem;

  ${dsmMediaQuery('medium device')} {
    max-width: 35rem;
  }
`;

const logoStyles = css`
  height: 2rem;

  ${dsmMediaQuery('medium device')} {
    height: 2.5rem;
    margin-top: 2rem;
  }
`;

const messageStyles = css`
  margin: 2rem 0;

  ${dsmMediaQuery('medium device')} {
    margin: 3rem 0;
    font-size: 1.1rem;
  }
`;

const wavesStyles = css`
  position: absolute;
  left: 0;
  bottom: 0;
`;

export { unsupportedBrowserStyles, wavesStyles, logoStyles, messageStyles };
