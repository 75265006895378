import { graphql, useFragment } from 'react-relay/hooks';
import { OverviewDevice } from './types';
// eslint-disable-next-line camelcase
import { useOverviewData_device$key } from './__generated__/useOverviewData_device.graphql';

const useOverviewData = (device: OverviewDevice) => {
  // eslint-disable-next-line camelcase
  const data = useFragment<useOverviewData_device$key>(
    graphql`
      fragment useOverviewData_device on Device {
        name
        customerName
        deviceInfo {
          ... on Gateway {
            contactPerson {
              contactPersonName
              contactPersonPhone
            }
          }
        }
        location {
          street
          city
          postalCode
          country
        }
      }
    `,
    device.node,
  );
  if (!data) return null;
  const { name: installationName, location, customerName, deviceInfo } = data;

  const contactPersonName = deviceInfo?.contactPerson?.contactPersonName || '';
  const contactPersonPhone = deviceInfo?.contactPerson?.contactPersonPhone || '';
  const { street, city, postalCode, country } = location ?? {};

  return {
    installationName,
    customerName,
    contactPersonName,
    contactPersonPhone,
    location: { street, city, postalCode, country },
  };
};

export default useOverviewData;
