/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { Disposable } from 'react-relay';
import { graphql, usePaginationFragment } from 'react-relay/hooks';
import { useTranslation } from 'react-i18next';
import { Loader, NoDataAvailableMessage } from '../../../components';
import { DeviceEvents_device$key } from './__generated__/DeviceEvents_device.graphql';
import { DeviceEventsPaginationQuery } from './__generated__/DeviceEventsPaginationQuery.graphql';
import { eventsContainer, loadingMoreStyles, showMoreButtonStyles, showMoreStyles } from './styles';
import { DeviceEventsProps, Alert } from './types';
import AlertsList from './AlertsList';

const DeviceEvents: React.FC<DeviceEventsProps> = ({ device }) => {
  const { t } = useTranslation();
  const { data, loadNext, hasNext, isLoadingNext, refetch } = usePaginationFragment<
    DeviceEventsPaginationQuery,
    DeviceEvents_device$key
  >(
    graphql`
      fragment DeviceEvents_device on Device @refetchable(queryName: "DeviceEventsPaginationQuery") {
        deviceTwinId
        uuid
        eventsTab: events(first: $count, after: $cursor)
          @connection(key: "PaginationQuery_eventsTab", filters: ["withCleared"]) {
          edges {
            node {
              __typename
              ... on Event {
                id
                alertCorrelationId
                createdAt
                type
                code
                device {
                  deviceInfo {
                    type
                  }
                }
              }
            }
          }
        }
      }
    `,
    device,
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    refetch({}, { fetchPolicy: 'store-and-network' });
  }, [refetch]);

  if (!data.eventsTab?.edges?.length)
    return <NoDataAvailableMessage message={t('WP.EVENTS.TAB.NO_EVENTS_REGISTERED')} />;
  const alerts = data.eventsTab.edges.reduce<Alert[]>((acc, cur) => {
    // eslint-disable-next-line no-underscore-dangle
    if (cur?.node?.__typename === 'Event')
      acc.push({
        id: cur.node.id,
        alertCorrelationId: cur.node.alertCorrelationId,
        code: cur.node.code,
        createdAt: cur.node.createdAt,
        type: cur.node.type,
        // For now this will work but later we will have events as well.
        initiator:
          cur.node?.device?.deviceInfo?.type === 'Gateway'
            ? t('WP.ALERTS.GRID_ITEM_DOSING_PUMP')
            : t('WP.ALERTS.GRID_ITEM_CALCULATED'), // This is a virtual device
      });
    return acc;
  }, []);

  return (
    <div css={eventsContainer}>
      {alerts ? <AlertsList alerts={alerts.sort((a1, a2) => a2.createdAt - a1.createdAt)} refetch={refetch} /> : null}
      {hasNext && !isLoadingNext && (
        <div css={showMoreStyles}>
          <button
            css={showMoreButtonStyles}
            type="button"
            onClick={(): Disposable => loadNext(15)}
            data-test-id="show-more"
          >
            {t('WP.ALERTS.SHOW_MORE_EVENTS')}
          </button>
        </div>
      )}
      {isLoadingNext && (
        <div css={loadingMoreStyles}>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default DeviceEvents;
