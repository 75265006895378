// eslint-disable-next-line import/prefer-default-export
export const mergeSortedArraysOfEqualLength = <T, Key, MergedObject>(
  mergeKeySelector: (obj: T) => Key,
  mergeFn: (objs: T[]) => MergedObject,
  arraysToMerge: (readonly T[])[],
): MergedObject[] => {
  const merged: MergedObject[] = [];

  const [baseArray, ...restArrays] = arraysToMerge;

  if (!baseArray || baseArray.length < 1) {
    return merged;
  }

  if (!restArrays || restArrays.length < 1) {
    return merged;
  }

  baseArray.forEach((obj, index) => {
    const mergeCandidates: T[] = [];
    restArrays.forEach((arr) => {
      if (arr.length !== baseArray.length) {
        throw new Error('Incomming array may not have different length than base array');
      }
      const mergeCandidate = arr[index];

      const key1 = mergeKeySelector(obj);
      const key2 = mergeKeySelector(mergeCandidate);

      if (key1 === key2) {
        mergeCandidates.push(mergeCandidate);
      } else {
        throw new Error('Merge key mismatch. Are all the arrays sorted?');
      }
    });
    merged.push(mergeFn([obj, ...mergeCandidates]));
  });

  return merged;
};
