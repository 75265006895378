import React from 'react';
import { useTranslation } from 'react-i18next';
import { paragraphBoldStyles } from '../../../../styles';
import { Tile, TileHeader, TileBody } from '../../../../components';
import {
  containerStyles,
  twoColumsTileStyles,
  leftColumnStyles,
  removeLastBottomBorder,
  rightColumnStyles,
  topPadding,
  tileStyles,
} from './styles';
import DataBox from './components/Databox';
import ManualData from './components/ManualData';
import ChemicalContainer from './components/ChemicalContainer';
import { MoreDataTabManualProps } from './types';
import { OperatingInfo, TotalDosedChemical } from './components';

const MoreDataTabManual: React.FC<MoreDataTabManualProps> = ({
  chemicalContainer,
  totalDosedChemical,
  motorOperatingHours,
  totalOperatingHours,
  dosedChecmicalLastHour,
  dosedChecmicalLastHourUnit,
  manualDosingFlow,
}) => {
  const { t } = useTranslation();

  return (
    <div css={containerStyles}>
      <div css={leftColumnStyles}>
        <ManualData>
          <DataBox
            label={t('WP.MORE.DATA.MANUAL_DOSED_VOL_LAST_HOUR')}
            value={dosedChecmicalLastHour?.toFixed(4)}
            unit={dosedChecmicalLastHourUnit}
          />
          <DataBox
            label={t('WP.MORE.DATA.MANUAL_SELECTED_DOSING_VOL')}
            value={manualDosingFlow?.quantity?.toFixed(4)}
            unit={manualDosingFlow?.unit}
          />
        </ManualData>
        <ChemicalContainer chemicalContainer={chemicalContainer} />
      </div>
      <div css={rightColumnStyles}>
        <Tile
          styles={tileStyles}
          Header={<TileHeader text={t('WP.MORE.DATA.DOSING_COUNTER_TILE')} />}
          Body={
            <TileBody css={[topPadding, twoColumsTileStyles]}>
              <div css={removeLastBottomBorder}>
                <p css={paragraphBoldStyles}>{t('WP.MORE.DATA.VOLUME')}</p>
                <TotalDosedChemical totalDosedChemical={totalDosedChemical} />
              </div>
              <OperatingInfo motorOperatingHours={motorOperatingHours} totalOperatingHours={totalOperatingHours} />
            </TileBody>
          }
        />
      </div>
    </div>
  );
};

export default MoreDataTabManual;
