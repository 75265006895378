import React from 'react';
import { graphql, useFragment } from 'relay-hooks';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import sentryCaptureError from '../../../../utils/sentry/sentry-capture-error';
import { ErrorBoundary, ErrorFallback } from '../../../../components';
import { pageContentStyles } from './styles';
import { pageStyles, headingLargeStyles, paragraphBrightStyles, headingSmallDimmedStyles } from '../../../../styles';
import { PageContentProps } from './types';

const PageContent: React.FC<PageContentProps> = (props) => {
  const { children } = props;
  const location = useFragment(
    graphql`
      fragment PageContent_location on Location {
        ... on Installation {
          name
          deviceCount
        }
        ... on Section {
          name

          deviceCount
        }
      }
    `,
    // eslint-disable-next-line react/destructuring-assignment
    props.location,
  );
  const { t } = useTranslation();

  if (!location) return null;

  const { deviceCount, name } = location;
  return (
    <div css={[pageStyles, pageContentStyles]}>
      <ErrorBoundary
        onError={(error): void => sentryCaptureError(error, 'PageContent')}
        FallbackComponent={ErrorFallback}
      >
        <div
          css={css`
            grid-column: 1/-1;
            display: grid;
            align-items: center;
            padding: 1.5rem 0 0.5rem 0;
          `}
        >
          <h1
            css={[
              headingLargeStyles,
              css`
                text-align: center;
                padding-bottom: 1.5rem;
                font-size: 0.875;
              `,
            ]}
          >
            {name}
          </h1>
          <div>
            <span css={headingSmallDimmedStyles} data-test-id="no-of-assets">
              {`${deviceCount} ${t('WP.LOCATIONS.INSTALLATION', { count: deviceCount || 0 })}`}
            </span>
          </div>
        </div>
        {children}
      </ErrorBoundary>
    </div>
  );
};

export default PageContent;
