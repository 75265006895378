import { css, keyframes, SerializedStyles, Theme } from '@emotion/react';
import dsmMediaQuery from '../../../../../utils/media-queries/media-queries';

const closeButtonStyles = css`
  padding: 0;
  min-width: 0;
  ${dsmMediaQuery('medium device')} {
    min-width: unset;
  }
`;

const modalStyles = css`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 6000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const containerStyles = (theme: Theme) => css`
  background-color: ${theme.colors.secondaryBackground};
  display: grid;
  max-height: 95%;
  gap: 1rem;
  grid-template-rows: max-content 1fr max-content;
  padding: 1rem;
`;

const scrollbar = css`
  ::-webkit-scrollbar {
    width: 4px;
    height: 0px;
  }
  ::-webkit-scrollbar-track {
    // background-color: background: #222527;
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  }
  ::-webkit-scrollbar-thumb {
    background-color: #f8f8f8;
    width: 4px;
  }
`;

const mainContentContainerStyles = css`
  display: grid;
  gap: 0 2rem;

  ${dsmMediaQuery('medium device')} {
    grid-template-columns: repeat(2, 1fr);
    overflow-y: auto;
    padding-right: 14px;
    ${scrollbar}
  }
`;

const headerContainerStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const footerButtons = css`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 1rem;
`;

const sectionStyles = css`
  padding-bottom: 0.5rem;
`;

const installationNoteStyles = css`
  grid-template-columns: max-content minmax(0, 18rem);
  text-align: right;
  gap: 1rem;
  justify-items: flex-end;
`;

const popIn = keyframes`
0% { opacity: 0; transform: scale(0.1); }
100% { opacity: 1; transform: scale(1); }`;

const displayStyles = css`
  animation: ${popIn} 0.1s;
  background: rgba(18, 21, 22, 0.7); // (primaryBackground in rgba) Make sure this color has an opacity of less than 1
`;

const formContentStyles = ({ colors }: Theme): SerializedStyles => css`
  display: grid;
  padding-bottom: 0.5rem;
  background-color: ${colors.secondaryBackground};
`;

const formStyles: SerializedStyles = css`
  display: grid;
  grid-template-rows: '1fr';
  grid-template-areas:
    'name'
    'phone';
  gap: 1rem;
  input,
  select {
    padding: 0.5rem;
  }
`;
const noteTextStyles = ({ colors }: Theme): SerializedStyles => css`
  min-height: 4rem;
  border: 0.063rem solid ${colors.border.subtle};
  overflow-wrap: break-word;
  padding: 0.5rem;
`;

export {
  formStyles,
  formContentStyles,
  installationNoteStyles,
  containerStyles,
  sectionStyles,
  footerButtons,
  mainContentContainerStyles,
  modalStyles,
  headerContainerStyles,
  closeButtonStyles,
  displayStyles,
  noteTextStyles,
};
