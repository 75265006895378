import { graphql, useFragment } from 'relay-hooks';
import { InfoContainerProps } from './types';
// eslint-disable-next-line camelcase
import { useInfoContainerData_device$key } from './__generated__/useInfoContainerData_device.graphql';

const useInfoContainerData = (device: InfoContainerProps['device']) => {
  // eslint-disable-next-line camelcase
  const data = useFragment<useInfoContainerData_device$key>(
    graphql`
      fragment useInfoContainerData_device on Device {
        gatewayIMEI
        name
        customerName
        qrCode
        location {
          name
          street
          country
          postalCode
          city
          ... on Installation {
            geoLocation
            commissioningData {
              commissioningDate
              commissioningEngineer
              commissioningNote
            }
          }
        }
      }
    `,
    device.node,
  );

  if (!data) return null;
  const { name: installationName, location, gatewayIMEI, customerName, qrCode } = data;
  const { commissioningDate, commissioningEngineer, commissioningNote } = location?.commissioningData ?? {};
  const { street, postalCode, country, city, geoLocation } = data.location ?? {};

  return {
    installationName,
    gatewayIMEI,
    customerName,
    commissioningDate,
    commissioningEngineer,
    commissioningNote,
    location: { street, postalCode, country, city, geoLocation },
    qrCode,
  };
};

export default useInfoContainerData;
