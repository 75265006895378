import React from 'react';
import CloseIcon from '../../../../../assets/images/close.svg';
import Offcanvas from '../../../../../components/Offcanvas/Offcanvas';
import {
  subNavigationButtonStyles,
  subNavigationHeaderStyles,
  subNavigationBackgroundStyles,
  offcanvasWrapperStyles,
} from '../styles';
import { subNavigationMainStyles } from './styles';
import { ErrorFallbackProps } from './types';

const ErrorFallback: React.FC<ErrorFallbackProps> = ({
  dataTestId,
  error,
  isOpen,
  onClose,
  resetErrorBoundary,
  styles,
  toggleShowSubNavigation,
}) => (
  <Offcanvas
    isOpen={isOpen}
    onClose={onClose}
    styles={subNavigationBackgroundStyles}
    stylesWrapper={offcanvasWrapperStyles(isOpen)}
    toggleIsOpen={toggleShowSubNavigation}
  >
    <nav data-test-id={`${dataTestId ?? 'sub-navigation-error'}`}>
      <div css={subNavigationHeaderStyles}>
        <button
          css={subNavigationButtonStyles}
          // eslint-disable-next-line react/destructuring-assignment
          onClick={onClose}
          type="button"
        >
          <img width="32" height="32" src={CloseIcon} alt="close-subnavigation" />
        </button>
      </div>
      <div css={subNavigationMainStyles}>
        There was an error in loading the sub navigation. &nbsp;
        <button type="button" onClick={resetErrorBoundary}>
          Try again
        </button>
      </div>
    </nav>
  </Offcanvas>
);

export default ErrorFallback;
