import { css, SerializedStyles, Theme } from '@emotion/react';
import dsmMediaQuery from '../../../../utils/media-queries/media-queries';

const contentStyles = ({ colors }: Theme): SerializedStyles => css`
  display: flex;
  flex-direction: column;
  background-color: ${colors.secondaryBackground};
  padding: 1rem;
  margin: 1rem;

  ${dsmMediaQuery('medium device')} {
    max-width: 30rem;
    display: flex;
    flex-direction: column;
    background-color: ${colors.secondaryBackground};
    padding: 1.5rem;
    position: relative;
    z-index: 1000;
    opacity: 0.9;
    margin-bottom: 2rem;
  }
`;

const formStyles = css`
  display: grid;

  grid-template-areas:
    'account account'
    'description description'
    'back back'
    'next next';
  gap: 1rem;

  ${dsmMediaQuery('medium device')} {
    grid-template-areas:
      'account account'
      'description description'
      'back next';
  }
`;

const dropDownStyle = css`
  grid-area: account;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  select {
    width: 100%;
  }
  label {
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: start;
  }
  .accountSelect {
    align-items: start;
  }
`;

const linkStyles = ({ colors }: Theme): SerializedStyles => css`
  color: ${colors.tertiaryText};

  &:hover {
    text-decoration: underline;
  }
`;

const nextButton = css`
  grid-area: next;
  ${dsmMediaQuery('medium device')} {
    justify-self: right;
  }
`;

const backButton = css`
  grid-area: back;
  ${dsmMediaQuery('medium device')} {
    justify-self: left;
  }
`;

export { contentStyles, formStyles, linkStyles, dropDownStyle, nextButton, backButton };
