import { SerializedStyles, Theme, css } from '@emotion/react';

const pumpStatusContainerStyles = css`
  display: grid;
  grid-template-columns: 2rem 1fr;
  grid-template-areas:
    'controlMode controlMode'
    'pumpOperation pumpOperation'
    '. pumpStatus'
    '. pumpRunning'
    '. stoppedBy'
    'remoteControl remoteControl';
  padding-top: 1rem;
  gap: 0.5rem;
`;

const containerStyles = css`
  grid-template-rows: max-content 1fr;
  height: 100%;
`;

const textContainerStyles = css`
  grid-column: 3/-3;
  grid-row: 2/2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const textBoxStyles = css`
  max-width: 49.5rem;
  max-height: 12rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const titleStyles = (theme: Theme): SerializedStyles => css`
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 2.25rem;
  color: ${theme.colors.neutral.neutral100.hex};
`;

const paragraphStyles = (theme: Theme): SerializedStyles => css`
  line-height: 1.25rem;
  color: ${theme.colors.neutral.neutral100.hex};
  margin-top: 2rem;
`;

const buttonStyles = css`
  margin-top: 3rem;
`;

export {
  pumpStatusContainerStyles,
  containerStyles,
  textContainerStyles,
  textBoxStyles,
  titleStyles,
  paragraphStyles,
  buttonStyles,
};
