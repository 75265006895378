import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import SignedInRoot from '../components/Root/SignedInRoot';
import App from './App/App';
import Events from './Events/Events';
import Section from './locations/Section/Section';
import SelectAccount from './Onboarding/Steps/01_SelectAccountStep/SelectAccount';
import Summary from './Onboarding/Steps/05_SummaryStep/Summary';
import Onboarding from './Onboarding';
import SelectOrCreateCustomer from './Onboarding/Steps/02_SelectOrCreateCustomerStep/SelectOrCreateCustomer';
import CustomerSummary from './Onboarding/Steps/03_CustomerSummaryStep/CustomerSummaryData';
import InstallationDetails from './Onboarding/Steps/04_InstallationDetailsStep/InstallationDetails';
import NoAccount from './Onboarding/Errors/NoAccount';
import NoAccess from './Onboarding/Errors/NoAccess';
import DDA from './Device/DDA/DDA';
import DDAC from './Device/DDAC/DDAC';
import ProfileData from './Profile/ProfileData';

const Router: React.FC = () => (
  <Routes>
    <Route path="/" element={<SignedInRoot />}>
      <Route element={<App />}>
        <Route path="/alerts" element={<Events />} />
        <Route path="/sections/:id" element={<Section />} />
        <Route path="/profile" element={<ProfileData />} />
        <Route path="/account/:id/gateways/:id/dda" element={<DDA />} />
        <Route path="/account/:id/gateways/:id/ddac" element={<DDAC />} />
        <Route index element={<Navigate replace to="/alerts" />} />
      </Route>
      <Route path="/onboarding" element={<Onboarding />}>
        <Route path="/onboarding/create-account" element={<SelectAccount />} />
        <Route path="/onboarding/create-or-select-customer" element={<SelectOrCreateCustomer />} />
        <Route path="/onboarding/customer-summary" element={<CustomerSummary />} />
        <Route path="/onboarding/installation" element={<InstallationDetails />} />
        <Route path="/onboarding/summary" element={<Summary />} />
        <Route path="/onboarding/no-account" element={<NoAccount />} />
        <Route path="/onboarding/no-access" element={<NoAccess />} />
      </Route>
    </Route>
  </Routes>
);

export default Router;
