import { css } from '@emotion/react';
import dsmMediaQuery from '../../utils/media-queries/media-queries';

const appNavigationStyles = css`
  grid-area: navigation;
  position: sticky;
  bottom: 0;
  z-index: 5500;
`;

const appStyles = css`
  display: grid;
  grid-template-areas:
    'main'
    'navigation';
  grid-template-rows: 1fr 4rem;

  ${dsmMediaQuery('large device')} {
    grid-template-areas: 'navigation main';
    grid-template-columns: 4rem auto;
    grid-template-rows: unset;
  }
`;

const mainStyles = css`
  grid-area: main;
`;

export { appNavigationStyles, appStyles, mainStyles };
