import { css } from '@emotion/react';
import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { errorFallbackStyles } from './styles';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ErrorFallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => (
  <div css={errorFallbackStyles}>
    There was an error in loading the account switcher. &nbsp;
    <button type="button" onClick={resetErrorBoundary}>
      Try again
    </button>
  </div>
);

export default ErrorFallback;
