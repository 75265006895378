import { css, SerializedStyles, Theme } from '@emotion/react';
import dsmMediaQuery from '../../utils/media-queries/media-queries';

const navStyles = css`
  padding-bottom: 1rem;
`;

const navItemStyles = (theme: Theme): SerializedStyles => css`
  display: inline-block;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  margin: 0.5rem 2rem 0.5rem 0.5rem;
  padding-bottom: 0.4rem;
  font-weight: 600;
  &:hover,
  &.active {
    padding-bottom: 0.2rem;
    color: ${theme.colors.secondaryText};
    border-bottom: 0.2rem solid ${theme.colors.blue.blue3.baseColor};
  }
`;

const condMonitorTileStyles = css`
  padding: unset;
  padding-bottom: 1rem;
  ${dsmMediaQuery('medium device')} {
    grid-column: 2 / span 1;
    grid-row: 2 / span 1;
  }
  ${dsmMediaQuery('large device')} {
    grid-column: 3 / span 1;
    grid-row: 1 / span 1;
  }
`;

const condMonitorTileHeaderPad = css`
  padding: 1rem;
`;

const condMonitorTileBody = css`
  align-self: start;
`;

const checkmarkContainerTileStyles = css`
  color: #67ba4c;
  div {
    grid-template-columns: 1fr;
  }
  span {
    text-align: center;
    line-height: 1.8rem;
    p:first-child {
      font-size: 1.2rem;
    }
    p:last-child {
      font-size: 1rem;
    }
  }
`;

const childrenRoutesContainer = css`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
`;

const scrollContentWrapperStyles = css`
  padding: 0 1rem;
`;

const simpleBarStyles = {
  maxHeight: '16.5rem',
};

const deviceStyles = css`
  height: 100%;
  grid-template-rows: max-content auto;
  grid-row-gap: 1rem;
`;

const tabContainerStyles = css`
  grid-column: 1/-1;
  grid-row: 2/2;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;
`;

export {
  navStyles,
  condMonitorTileStyles,
  navItemStyles,
  checkmarkContainerTileStyles,
  condMonitorTileBody,
  childrenRoutesContainer,
  condMonitorTileHeaderPad,
  scrollContentWrapperStyles,
  simpleBarStyles,
  deviceStyles,
  tabContainerStyles,
};
