/**
 * @generated SignedSource<<a6dfc6ab5b9e354d2b5add28f21bf7e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventState = "ACTIVE" | "CLEARED";
export type EventsQuery$variables = {
  exclude?: EventState | null;
  cursor?: string | null;
  count?: number | null;
};
export type EventsQuery$data = {
  readonly events: {
    readonly totalCount: number;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"EventList_items">;
};
export type EventsQuery = {
  variables: EventsQuery$variables;
  response: EventsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "exclude"
},
v3 = {
  "kind": "Variable",
  "name": "exclude",
  "variableName": "exclude"
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v3/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
],
v5 = {
  "alias": null,
  "args": (v4/*: any*/),
  "concreteType": "EventConnection",
  "kind": "LinkedField",
  "name": "events",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v9 = [
  (v6/*: any*/),
  (v8/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "descendantDeviceInfo",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EventsQuery",
    "selections": [
      (v5/*: any*/),
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          (v3/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "EventList_items"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EventsQuery",
    "selections": [
      (v5/*: any*/),
      {
        "alias": "eventList",
        "args": (v4/*: any*/),
        "concreteType": "EventConnection",
        "kind": "LinkedField",
        "name": "events",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EventEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Event",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Device",
                    "kind": "LinkedField",
                    "name": "device",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "deviceInfo",
                        "plural": false,
                        "selections": (v9/*: any*/),
                        "storageKey": null
                      },
                      (v7/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "location",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": "installationName",
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "parentLocation",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": "customerName",
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "name",
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "Section",
                                    "abstractKey": null
                                  },
                                  (v7/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v10/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "DeviceConnection",
                                "kind": "LinkedField",
                                "name": "devices",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "DeviceEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Device",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v10/*: any*/),
                                          (v11/*: any*/),
                                          (v7/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "Installation",
                            "abstractKey": null
                          },
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "code",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "eventList",
        "args": (v4/*: any*/),
        "filters": [
          "exclude"
        ],
        "handle": "connection",
        "key": "EventList_eventList",
        "kind": "LinkedHandle",
        "name": "events"
      }
    ]
  },
  "params": {
    "cacheID": "ad0365acf0e4ae22a012796c492d8c8b",
    "id": null,
    "metadata": {},
    "name": "EventsQuery",
    "operationKind": "query",
    "text": "query EventsQuery(\n  $exclude: EventState\n  $cursor: String\n  $count: Int\n) {\n  events(exclude: $exclude, first: $count, after: $cursor) {\n    totalCount\n  }\n  ...EventList_items_1k9XdX\n}\n\nfragment EventListItemGateway_item on Event {\n  createdAt\n  device {\n    uuid\n    location {\n      __typename\n      ... on Installation {\n        installationName: name\n        parentLocation {\n          __typename\n          ... on Section {\n            customerName: name\n          }\n          id\n        }\n      }\n      id\n    }\n    deviceInfo {\n      __typename\n      type\n    }\n    descendantDeviceInfo {\n      __typename\n      type\n    }\n    id\n  }\n  type\n  code\n}\n\nfragment EventListItemVirtualDevice_item on Event {\n  createdAt\n  device {\n    uuid\n    deviceInfo {\n      __typename\n      type\n    }\n    location {\n      __typename\n      ... on Installation {\n        uuid\n        installationName: name\n        parentLocation {\n          __typename\n          ... on Section {\n            customerName: name\n          }\n          id\n        }\n        devices {\n          edges {\n            node {\n              uuid\n              descendantDeviceInfo {\n                __typename\n                type\n              }\n              id\n            }\n          }\n        }\n      }\n      id\n    }\n    id\n  }\n  type\n  code\n}\n\nfragment EventList_items_1k9XdX on Query {\n  eventList: events(first: $count, after: $cursor, exclude: $exclude) {\n    edges {\n      node {\n        __typename\n        id\n        device {\n          deviceInfo {\n            __typename\n            type\n          }\n          id\n        }\n        ...EventListItemGateway_item\n        ...EventListItemVirtualDevice_item\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bba6e2e78678b6ce6250b37fb1985839";

export default node;
