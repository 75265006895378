import { useTranslation } from 'react-i18next';
import { OptionalRuleViolation, ProportionalRule } from '../types';
import { formatRuleViolationText, hasRuleViolation } from '../components/utils';

const useAnalogRuleViolations = (
  proportionalRuleValidation: OptionalRuleViolation,
  macthRuleViolations: ProportionalRule[],
) => {
  const { t } = useTranslation();
  const { remedy, ruleViolations } = proportionalRuleValidation || {};
  const ddaRuleViolations = hasRuleViolation(ruleViolations, ...macthRuleViolations);
  const ddaRuleViolationToolTip = formatRuleViolationText(remedy, ddaRuleViolations, t);

  return {
    ruleViolationsText: ddaRuleViolationToolTip,
    hasRuleViolations: ddaRuleViolations.length > 0,
  };
};

export default useAnalogRuleViolations;
