import { css, SerializedStyles, Theme } from '@emotion/react';
import { paragraphBaseStyles } from '../../styles';

const labelStyles = (disabled: boolean, theme: Theme): SerializedStyles => css`
  ${paragraphBaseStyles}
  color: ${disabled ? theme.colors.neutral.neutral100.transparent25 : theme.colors.neutral.neutral100.hex};
`;

const errorStyles = (theme: Theme) => css`
  ${paragraphBaseStyles}
  color: ${theme.colors.errorText};
  padding-top: 0.5rem;
`;

const fieldStyles = (disabled: boolean, theme: Theme): SerializedStyles => css`
  color: ${disabled ? theme.colors.neutral.neutral100.transparent25 : theme.colors.neutral.neutral400.hex};
  background: ${theme.colors.tertiaryBackground};
  border: ${theme.colors.tertiaryBackground};
  padding: 0.5rem;
`;

const containerStyles = (alignment: 'center' | 'start' | 'end' = 'center') => css`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  align-items: ${alignment};
`;

const dropdownContainer = css`
  display: flex;
  justify-content: flex-end;
`;

const dropdownField = css`
  flex-grow: 1;
  display: flex;
`;

export { labelStyles, errorStyles, fieldStyles, containerStyles, dropdownContainer, dropdownField };
