/**
 * @generated SignedSource<<6c188ec5eb1be5698cfe929618dc4680>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SectionAssets_section$data = {
  readonly locations: {
    readonly totalCount: number;
    readonly " $fragmentSpreads": FragmentRefs<"SectionList_sections">;
  } | null;
  readonly devices: {
    readonly " $fragmentSpreads": FragmentRefs<"DeviceList_devices">;
  } | null;
  readonly " $fragmentType": "SectionAssets_section";
};
export type SectionAssets_section$key = {
  readonly " $data"?: SectionAssets_section$data;
  readonly " $fragmentSpreads": FragmentRefs<"SectionAssets_section">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SectionAssets_section",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "typeCodes",
          "value": [
            "SECTION"
          ]
        }
      ],
      "concreteType": "LocationConnection",
      "kind": "LinkedField",
      "name": "locations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SectionList_sections"
        }
      ],
      "storageKey": "locations(typeCodes:[\"SECTION\"])"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceConnection",
      "kind": "LinkedField",
      "name": "devices",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DeviceList_devices"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Section",
  "abstractKey": null
};

(node as any).hash = "50b7f6210d34a076eedaee95b85a7c57";

export default node;
